import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { CANVASSER_TYPE } from '@/constants/Account';
import AccountModule from '@/vuex/modules/AccountModule';
import AddressModule from '@/vuex/modules/AddressModule';
import AuthModule from '@/vuex/modules/AuthModule';
import CanvasserModule from '@/vuex/modules/CanvasserModule';
import CookieModule from '@/vuex/modules/CookieModule';
import CmsModule from '@/vuex/modules/CmsModule';
import FaqModule from '@/vuex/modules/FaqModule';
import GiftingModule from '@/vuex/modules/GiftingModule';
import GiftVoucherModule from '@/vuex/modules/GiftVoucherModule';
import LocalisationModule from '@/vuex/modules/LocalisationModule';
import NavModule from '@/vuex/modules/NavModule';
import OrderModule from '@/vuex/modules/OrderModule';
import PaymentModule from '@/vuex/modules/PaymentModule';
import RegisterModule from '@/vuex/modules/RegisterModule';
import ScheduledOrderModule from '@/vuex/modules/ScheduledOrderModule';
import ShopModule from '@/vuex/modules/ShopModule';
import SubscriptionModule from '@/vuex/modules/SubscriptionModule';
import SpecialDatesModule from '@/vuex/modules/SpecialDatesModule';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'freddiesflowers',
    storage: window.localStorage,
    modules: process.env.VUE_APP_MODE === CANVASSER_TYPE ? [
        'auth',
        'account',
        'canvasser',
        'localisation',
        'register',
    ] : [
        'auth',
        'account',
        'register',
        'faq',
        'localisation',
        'scheduledOrder',
        'gifting',
    ],
});

export default new Vuex.Store({
    modules: process.env.VUE_APP_MODE === CANVASSER_TYPE ? {
        auth: AuthModule,
        account: AccountModule,
        nav: NavModule,
        register: RegisterModule,
        canvasser: CanvasserModule,
        localisation: LocalisationModule,
        cookies: CookieModule,
        payment: PaymentModule,
    } : {
        auth: AuthModule,
        address: AddressModule,
        account: AccountModule,
        cms: CmsModule,
        cookies: CookieModule,
        faq: FaqModule,
        nav: NavModule,
        gifting: GiftingModule,
        giftVoucher: GiftVoucherModule,
        localisation: LocalisationModule,
        payment: PaymentModule,
        register: RegisterModule,
        subscription: SubscriptionModule,
        order: OrderModule,
        scheduledOrder: ScheduledOrderModule,
        shop: ShopModule,
        specialDates: SpecialDatesModule,
    },
    plugins: [vuexLocalStorage.plugin],
});
