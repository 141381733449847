<template>
    <div id="remind-later-upgrade-in" class="upgrade-in--banner">
        <img class="upgrade-in--banner--img" :src="getDashboardOverlayBanner.image" alt="">
        <div class="desktop-class">
            <div>
                <p class="upgrade-in--banner--semi-bold">{{ getDashboardOverlayBanner.header}}</p>
                <p v-if="isBannerBody && !isBenefits">{{ isBannerBody }}</p>
            </div>
            <div>
                <div class="class-icon">
                    <div class="icon-witdh" v-for="( benefit, index ) in isBenefits" :key="`benifit-${index}`">
                        <img :src="benefit.icon" alt="box-icon">
                        <div>
                            <p class="text-class">{{ benefit.text }}</p>
                            <p v-if="benefit.sub_text" class="sub-text">{{ benefit.sub_text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="upgrade-in--banner--btn-container">
            <submit-button
                class="button-class"
                :processing="isProcessing"
                @click="goTo(getDashboardOverlayBanner.cta_url)">
                    {{ getDashboardOverlayBanner.cta }}
            </submit-button>
            <p class="text-header">{{ getDashboardOverlayBanner.banner_handler }}</p>
        </div>
        <div class="upgrade-in--banner--close">
            <p class="upgrade-in--banner--close--btn" @click="remindLater()">X</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubmitButton from '@/components/forms/buttons/SubmitButton';
import { setCookie } from '@/helpers/QueryHelpers';

export default {
    name: 'ShowstopperUpgradeMobile',
    components: { SubmitButton },
    data() {
        return {
            isProcessing: false,
        };
    },
    computed: {
        ...mapGetters({
            getDashboardOverlayBanner: 'account/getDashboardOverlayBanner',
        }),
        isBannerBody() {
            return this.getDashboardOverlayBanner?.body;
        },
        isBenefits() {
            return this.getDashboardOverlayBanner.benefits;
        },
    },
    methods: {
        remindLater() {
            setCookie('remind-later-upgrade-in', 1, 1);
            this.$root.$emit('hide-sticky-banner');
            this.$root.$emit('hide-bottom-sheet');
        },
        goTo(path) {
            return this.$router.push(path);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';
    .upgrade-in {
        &--banner {
            display: flex;
            flex-direction: row;
            &--img {
                width: 148px;
                object-fit: cover;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
            }
            &--btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px 30px;
                flex: wrap;
                @media (max-width: 768px) {
                    padding: 10px 10px;
                }
            }
            &--close {
                &--btn {
                    position: absolute;
                    top: 5px;
                    right: 33px;
                    cursor: pointer;
                    margin: 0px;
                    @include label()
                }
            }
            &--semi-bold {
                @include sub-title()
            }
        }
    }
    .class-icon {
        display: flex;
        flex-direction: row;
        gap: 21px;
        @media (max-width: 1059px) {
            flex-wrap: wrap;
        }
    }
    .icon-witdh {
        display: flex;
        flex-direction: row;
        gap: 9px;
        align-items: center;
    }
    .text-class {
        @include small-bold();
        width: max-content;
        align-items: center;
    }
    .desktop-class {
        width: 686px;
        margin: 30px 0px 30px 28px;
        @media (max-width: 1062px) {
            width: auto;
        }
    }
    .text-header {
        @include small();
        color: $shade-dark-color;
        margin-top: 9px;
    }
    .sub-text {
        @include small();
        color: $shade-dark-color;
        width: max-content;
        margin: -8px;
    }
    .button-class {
        @include button();
        height: 48px;
        padding: 0px;
        min-width: 197px;
    }
</style>
