import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class UpdateUser {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = ApiEndpoints['users.update'];

        return this.apiService
            .patch(apiUrl, {
                'first_name': payload.firstName,
                'last_name': payload.lastName,
                'email': payload.email,
                'telephone': payload.telephone,
                'opt_in': payload.optIn,
                'opt_in_call': payload.optIn || null,
                'opt_in_email': payload.optIn || null,
                'opt_in_letter': payload.optIn || null,
                'double_optin': payload.doubleOptin || null,
                'double_optin_spoiler': payload.doubleOptinSpoiler || null,
                'weekly_email': payload.weeklyEmail,
                'password': payload.password || null,
            });
    }
}
