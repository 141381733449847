import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class UpdatePaymentSource {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(userId, cardToken, cardId, gateway, nameOnCard, paymentMethod, deviceData) {
        let apiUrl = prepareUrl(ApiEndpoints['payments.payment-sources.update'], [userId]);

        let payload = {
            card_token: cardToken,
            card_id: cardId,
            gateway: gateway,
            name_on_card: nameOnCard,
            payment_method: paymentMethod,
            device_data: deviceData,
        };

        return this.apiService.patch(apiUrl, payload);
    }
}
