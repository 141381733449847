import CreateGift from '@/services/gifting/CreateGift';
import CreateDraftGift from '@/services/gifting/CreateDraftGift';
import FetchPrices from '@/services/gifting/FetchPrices';
import SendIncompleteGiftingData from '@/services/gifting/SendIncompleteGiftingData';
import FetchDeliveryDays from '@/services/gifting/FetchDeliveryDays';
import SendGuestGiftingData from '@/services/gifting/SendGuestGiftingData';
import GetGiftingProducts from '@/services/gifting/GetGiftingProducts';
import FetchGiftDeliveryDates from '@/services/gifting/FetchGiftDeliveryDates';
import FetchGiftDeliveryOptions from '@/services/gifting/FetchGiftDeliveryOptions';
import GetGiftingMessage from '@/services/gifting/GetGiftingMessage';
import GetGiftingDeliveryCaps from '@/services/gifting/GetGiftingDeliveryCaps';

export default class GiftingService {
    createGift(payload) {
        return (new CreateGift()).handle(payload);
    }

    createDraftGift(payload) {
        return (new CreateDraftGift()).handle(payload);
    }

    fetchPrices() {
        return (new FetchPrices()).handle();
    }

    async sendIncompleteGiftingData(giftingData, giftingId, userId) {
        return await (new SendIncompleteGiftingData()).handle(giftingData, giftingId, userId);
    }

    fetchDeliveryDays() {
        return (new FetchDeliveryDays()).handle();
    }

    sendGuestGiftingData(payload, uid = null) {
        return (new SendGuestGiftingData()).handle(payload, uid);
    }

    getGiftingProducts(payload) {
        return (new GetGiftingProducts()).handle(payload);
    }

    fetchGiftDeliveryDates() {
        return (new FetchGiftDeliveryDates()).handle();
    }

    fetchGiftDeliveryOptions() {
        return (new FetchGiftDeliveryOptions()).handle();
    }

    gerGiftingMessage(giftRef) {
        return (new GetGiftingMessage()).handle(giftRef);
    }

    getGiftingDeliveryCaps(payload) {
        return (new GetGiftingDeliveryCaps()).handle(payload);
    }
}
