import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class PausedUntil {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.paused-until'], [payload.subscriptionId]);
        let parameters = {
            delivery_date: payload.deliveryDate,
        };

        if (payload.feedback) {
            parameters.feedback = payload.feedback;
            parameters.origin = 'pause';
        }

        return this.apiService.post(apiUrl, parameters);
    }
}
