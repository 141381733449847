import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class UpdateSubscriptionQuantity {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, quantity) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.quantity.update'], [subscriptionId]);

        return this.apiService
            .patch(apiUrl, {
                amount: quantity,
            });
    }
}
