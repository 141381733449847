import GetAvailableDeliveryDay from '@/services/logistics/postcodeService/GetAvailableDeliveryDay';
import GetDeliveryDatesForPostcode from '@/services/logistics/postcodeService/GetDeliveryDatesForPostcode';
import LegacyIsPostcodeDeliverable from '@/services/logistics/postcodeService/LegacyIsPostcodeDeliverable';
import FetchPostcodeDeliverableStatus from '@/services/logistics/postcodeService/FetchPostcodeDeliverableStatus';
import GetFirstDeliveryDayForPostcode from '@/services/logistics/postcodeService/GetFirstDeliveryDayForPostcode';
import LegacyGetFirstDeliveryDayForPostcode from '@/services/logistics/postcodeService/LegacyGetFirstDeliveryDayForPostcode';

export default class PostcodeService {
    getAvailableDeliveryDay(postcode) {
        return (new GetAvailableDeliveryDay()).handle(postcode);
    }

    static getDeliveryDatesForPostcode(postcode, numberOfDeliveryDays) {
        return (new GetDeliveryDatesForPostcode()).handle(postcode, numberOfDeliveryDays);
    }

    async legacyGetFirstDeliveryDayForPostcode(postcode) {
        return (new LegacyGetFirstDeliveryDayForPostcode()).handle(postcode);
    }

    async isPostcodeDeliverable(postcode) {
        return (new LegacyIsPostcodeDeliverable()).handle(postcode);
    }

    static fetchPostcodeDeliverableStatus(postcode) {
        return (new FetchPostcodeDeliverableStatus()).handle(postcode);
    }

    static GetFirstDeliveryDayForPostcode(postcode) {
        return (new GetFirstDeliveryDayForPostcode()).handle(postcode);
    }
}
