import Footer from '@/components/structure/Footer';
import TopNavigation from '@/components/structure/navigation/TopNavigation';
import TopNavigationV2 from '@/components/structure/navigation/TopNavigationV2';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].styleGuide,
        name: 'style-guide',
        components: {
            default: () => import(/* webpackChunkName: "style" */ '@/views/StyleGuide'),
            footer: Footer,
            topNavigation: TopNavigation,
            topNavigationV2: TopNavigationV2,
        },
    },
];
