import SpecialDatesService from '@/services/users/SpecialDatesService';
import store from '@/store';

const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';

const getDefaultState = () => {
    return {
        monthOptions: isGBR ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] : ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        typeOptions: [],
        specialDates: [],
        selectedOccassion: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        updateSpecialDates: (state, payload) => state.specialDates = payload,
        updateOccasionTypes: (state, payload) => state.typeOptions = payload,
        updatedSelectedOccassion: (state, payload) => state.selectedOccassion = payload,
    },
    actions: {
        getAllSpecialDates({ state, commit }) {
            let service = new SpecialDatesService();
            const userId = store.getters['account/getUserId'];
            return new Promise(function(resolve, reject) {
                service.getSpecialDates(userId)
                    .then(response => {
                        commit('updateSpecialDates', response);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getAllOccasionTypes({ state, commit }) {
            if (!state.typeOptions.length) {
                let service = new SpecialDatesService();
                return new Promise(function(resolve, reject) {
                    service.getOccasionTypes()
                        .then(response => {
                            commit('updateOccasionTypes', response.types);
                            resolve(response);
                        })
                        .catch(error => {
                            reject(error);
                        });
                });
            }
        },
        addSpecialDate({ state, dispatch, commit }, payload) {
            let service = new SpecialDatesService();
            const data = {
                name: payload.name,
                type_id: '1',
                date: payload.date,
                is_recurring: true,
            };

            return new Promise(function(resolve, reject) {
                service.createSpecialDates(data)
                    .then(response => {
                        dispatch('getAllSpecialDates');
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteSpecialDate({ state, dispatch, commit }, id) {
            let service = new SpecialDatesService();

            return new Promise(function(resolve, reject) {
                service.deleteSpecialDates(id)
                    .then(response => {
                        dispatch('getAllSpecialDates');
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        editSpecialDate({ state, dispatch, commit }, payload) {
            let service = new SpecialDatesService();
            const data = {
                name: payload.name,
                type_id: '1',
                date: `${state.monthOptions.indexOf(payload.month) + 1}-${payload.day}`,
                is_recurring: true,
            };
            return new Promise(function(resolve, reject) {
                service.updateSpecialDates(payload.id, data)
                    .then(response => {
                        dispatch('getAllSpecialDates');
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        getSpecialDates: state => state.specialDates,

        getMonthOptions: state => state.monthOptions,

        getTypeOptions: state => state.typeOptions,

        getSelectedOccasion: state => state.selectedOccassion,
    },
};
