<template>
    <div class="header-v2" :class="{ 'header-v2-hidden': isDEU }">
        <div class="navigation-v2">

            <!-- nav-left -->
            <div class="navigation-v2__left">
                <a v-if="showBackButton" class="back-button" @click="$router.go(-1)">
                    <img :src="backArrow.image" :alt="backArrow.imageAlt">
                    <p v-if="isDesktop">{{$t('TOP_NAVIGATION_BACK_BUTTON')}}</p>
                </a>

                <NavButtonGroupV2 aria-labelledby="left-navigation" :buttons="isUserLoggedIn ? filteredButtons2 : filteredButtons1" :isDesktop="isDesktop" :includeLearnMoreDropdown="!isUserLoggedIn" />

                <help-button-v2 v-if="showHelpButton" class="navigation-v2__help-v2"></help-button-v2>
            </div>

            <!-- nav-center -->
            <div class="navigation-v2__center">
                <router-link :to="{ name: 'home'}">
                    <img class="navigation-v2__desktop-logo-v2"
                        :src="freddiesLogo"
                        alt="Freddie's Flowers Desktop Logo"/>
                </router-link>
            </div>

            <!-- nav-right -->
            <div class="navigation-v2__right" :class="{ 'nav-right': showSignIn }">

                <div class="navigation-v2__right--login-link" v-if="showSignIn">
                    <img class="navigation-v2__right--login-link--icon" :src="myAccountIcon.image" :alt="myAccountIcon.imageAlt" @click="loginLinkClicked">
                </div>

                <hr class="navigation-v2__right--separator-line" v-if="showTheSeparatorLine" />

                <div v-if="showSignUp" class="navigation-v2__right--signup-link">
                    <button class="btn--primary-light sign-up-btn"
                        @click="$router.push({ name: 'register' })">
                        {{ $t('SUBSCRIBE_NOW')}}
                    </button>
                </div>
                <div class="after-login-container">
                    <NavButtonGroupV2 aria-labelledby="right-navigation" v-if="isUserLoggedIn" :buttons="filteredButtons1" :isDesktop="isDesktop" />
                    <my-account-v2 v-if="showMyAccount"></my-account-v2>
                    <order-summary-button-v2 v-if="showBasket"/>
                </div>
            </div>

        </div>
        <div class="divider-paper--marker divider--not-fixed"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginLink from '@/components/structure/navigation/navigation/LogInLink';
import HelpButtonV2 from '@/components/structure/navigation/navigation/HelpButtonV2';
import NavButtonGroupV2 from '@/components/structure/navigation/navigation/NavButtonGroupV2';
import OrderSummaryButtonV2 from '@/components/structure/navigation/navigation/OrderSummaryButtonV2';
import MyAccountV2 from '@/components/structure/navigation/navigation/MyAccountV2';
import countryUtils from '@/resources/countryUtils.json';

import GreenArrowDownIcon from '@/assets/images/decoration/arrow-down-green.svg';
import WhiteArrowDownIcon from '@/assets/images/decoration/arrow-down-white.svg';
import HowFreddiesWorksImage from '@/assets/images/navigation/how-freddies-works.png';
import MeetFreddieImage from '@/assets/images/navigation/meet-freddie.png';
import OurGrowersImage from '@/assets/images/navigation/our-growers.png';
import MyAccountIcon from '@/assets/images/decoration/my-account-icon-1.svg';
import BackArrow from '@/assets/images/navigation/back-arrow.png';

export default {
    name: 'TopNavigationV2',
    data() {
        return {
            countryUtils: countryUtils.filter(item => item.locale.toLowerCase() === process.env.VUE_APP_DATE_LOCALE.toLowerCase())[0],
            flowerBondIcon: require('@/assets/images/decoration/icon-bondholder-nav.svg'),
            window: {
                width: 0,
                mobileBreakpoint: 767,
                desktopLowerBreakpoint: 1024,
            },
            isXmas: true,
            greenArrowDownIcon: GreenArrowDownIcon,
            whiteArrowDownIcon: WhiteArrowDownIcon,
            showLearnMoreDropdown: false,
            myAccountIcon: {
                image: MyAccountIcon,
                imageAlt: this.$t('MY_ACCOUNT_ICON'),
            },
            backArrow: {
                image: BackArrow,
                imageAlt: this.$t('BACK_ARROW_ICON'),
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            isDEU: 'localisation/isDEU',
            buttons: 'nav/getButtons',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            isFlowerBondHolder: 'account/getFlowerBondStatus',
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
            isGifterAccount: 'account/isGifterAccount',
            themes: 'account/getThemes',
            isSubscriberAccount: 'account/isSubscriberAccount',
        }),
        filteredButtons() {
            const status = (this.isUserLoggedIn ? 'loggedIn' : 'loggedOut');
            return this.buttons.filter(button => this.hasNoActiveSubscription ? button[status] && button.label.toLowerCase() !== 'shop' : button[status] && button.enabled);
        },
        filteredButtons1() {
            return this.filteredButtons?.slice(0, 2);
        },
        filteredButtons2() {
            return this.filteredButtons?.slice(2);
        },
        checkoutHelpButton() {
            return this.buttons.filter(button => button.label.toLowerCase() === 'help')[0];
        },
        isDesktop() {
            const isDesktop = this.window.width >= this.window.desktopLowerBreakpoint;
            return isDesktop;
        },
        leftArrowImage() {
            return this.isDesktop ? require('@/assets/images/decoration/arrow-left-secondary-color.svg') : require('@/assets/images/decoration/arrow-left-secondary-large.svg');
        },
        isMobile() {
            let isMobile = this.window.width <= this.window.mobileBreakpoint;
            return isMobile;
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
        showSignIn() {
            return !this.isPage(['account-confirmation', 'account', 'faq', 'register', 'login', 'gifter', 'guest-gifting']) ||
            (this.isUserLoggedIn && this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription && !this.isGifterAccount);
        },
        showSignUp() {
            return this.isDesktop && !this.isUserLoggedIn && !this.isPage(['register']);
        },
        showTheSeparatorLine() {
            return this.showSignIn && this.showSignUp;
        },
        showSignInSeparationLine() {
            return (this.showSignIn || this.isPage(['account'])) && (!this.isPage(['guest-gifting']) || this.isPage(['guest-gifting.home'], true)) && this.isDesktop;
        },
        showBasket() {
            return this.isUserLoggedIn && this.hasActiveSubscription && this.isSubscriberAccount && !this.isPage(['guest-gifting']);
        },
        showMyAccount() {
            return !this.isMobile && ((this.isUserLoggedIn && this.hasSubscriptionFetched && this.hasActiveSubscription) || (this.isGifterAccount));
        },
        customStyles() {
            return !this.isDesktop ? { fontSize: '12px', lineHeight: '16px' } : {};
        },
        showBackButton() {
            return (this.isPage(['register', 'guest-gifting', 'gifting']) && !this.isPage(['guest-gifting.home'], true)) || this.isPage(['account.bumper-box'], true);
        },
        showHelpButton() {
            return !this.isPage(['register', 'guest-gifting']) || this.isPage(['guest-gifting.home'], true);
        },
        freddiesLogo() {
            return this.themes?.logo || require('@/assets/images/logos/freddies_flowers_horizontal_secondary_color.svg');
        },
    },
    methods: {
        isPage(pageArr, isSubPage = false) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = isSubPage ? this.$route.name : this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        go(route) {
            route === 'faq.categories' ? window.location.href = this.countryUtils.zendesk_hc_url : this.$router.push({ name: route });
        },
        handleResize() {
            this.window.width = window.innerWidth;
        },
        loginLinkClicked() {
            if (this.isUserLoggedIn) {
                this.$router.push({ name: 'logout' });
                CSPageEvents(undefined, { userLogged: 'Logged-out' });
            } else {
                this.$router.push({ name: 'login' });
            }
        },
    },
    components: {
        HelpButtonV2,
        OrderSummaryButtonV2,
        NavButtonGroupV2,
        MyAccountV2,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

    .header-v2 {
        position: fixed;
        width: 100vw;
        z-index: 99;

        display: none;
    }
    .header-v2-hidden {
        display: none;
    }
    .navigation-v2 {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 0 24px;
        height: 53px;
        z-index: 99;
        background-color: $primary-color;
        color: $secondary-color;

        &__left {
            flex: 1;
        }

        &__left * {
            @include button();
        }

        &__center {
            flex: 1;
            justify-content: center;
        }

        &__right {
            flex: 1;
            justify-content: flex-end;

            &--login-link {
                margin-left: 15px !important;
                margin-right: 23px !important;

                @media (max-width: $large) {
                    margin-right: 0 !important;
                }

                @media (min-width: $large) and (max-width: 1077px) {
                    margin-right: 0px !important;
                }

                &--icon {
                    cursor: pointer;

                    width: 40px !important;
                    height: 40px !important;
                }
            }

            &--login-link:hover {
                opacity: 0.8;
            }

            &--separator-line {
                width: 1px;
                height: 31px;
                background: $secondary-color;
                opacity: 0.3;
            }

            &--signup-link {
                padding-left: 31px;
            }
        }

        &__left, &__center, &__right {
            display: flex;
            align-items: center;
        }

        &__landing-button {
            cursor: pointer;
            text-align: center;
            img {
                margin: 0 auto;
                height: 45px;
            }
            h5 {
                font-size: 10px;
                margin: 0;
            }
        }

        &__help-v2 {
            display: block;
        }

        &__logo-container {
            margin-left: 32px;
        }

        &__desktop-logo-v2 {
            cursor: pointer;
            display: block;
            max-width: 200px;
            height: 25px;
        }
        &__mobile-logo-container {
            width: 100%;
        }
        &__mobile-logo {
            display: block;
            width: 100%;
            max-width: 380px;
        }
        &__title {
            font-size: 1.25rem;
            line-height: 1;
            font-family: $primary-font;
            padding-top: 4px;
        }
        &__title > span {
            font-size: 0.5em;
            font-family: $secondary-font;
            display: block;
            text-transform: uppercase;
        }
    }

    .after-login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 8px 0px;
    }
    @media (max-width: $medium) {
        .navigation-v2 {
            &__right {
                flex: 1;
            }
        }
    }
    @media (min-width: $medium) {
        .navigation-v2 {

            &__left {
                padding-left: 29px;
            }
            &__right {
                margin-right: -16px;
                flex: 1;
            }

            &__landing-buttons {
                display: none;
                margin: 0 !important;
                padding-right: 15px;
                border: none;
                line-height: 29px;
            }
        }

        .after-login-container {
            margin-bottom: 0px;
        }
    }
    @media (min-width: $large) {
        .navigation-v2 {
            height: 69px;
            padding: 16px 0px;

            &__right {
                align-items: center;
                display: flex;

                margin-right: 0px;
                padding-right: 8px;
                padding-bottom: 0;
                > * {
                    margin: 0 8px;
                }
                flex: 1;
            }

            &__landing-buttons-container {
                margin: 0;
                order: 0;
                width: -webkit-max-content;
                width: max-content;
            }

            &__landing-buttons {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin: 0 0 0 auto;
                max-width: 500px;

                grid-gap: 1rem;
                box-sizing: border-box;
                list-style: none;
                width: 100%;
            }

            li .underline {
                height: 3px;
                background-color: transparent;
                width: 0%;
                transition: width 0.2s, background-color 0.5s;
                -moz-transition: width 0.2s, background-color 0.5s;
                -webkit-transition: width 0.2s, background-color 0.5s;
                margin: 0 auto;
                border-radius: 24px;
            }

            li.active-link .underline {
                width: 100%;
                background-color: $secondary-color;
            }

            li:hover .underline {
                background-color: $secondary-color;
                width: 100%;
            }

            &__landing-button {
                margin-right: 25px;
            }

            &__help-v2 {
                display: none;
            }

            &__desktop-logo-v2 {
                cursor: pointer;
                display: block;
                max-width: 284px;
                height: 40px;
            }

            &__mobile-logo-container {
                width: auto;
            }

            &__mobile-logo {
                display: none;
            }
        }
    }

    .sign-up-btn {
        width: 182px;
        border-radius: 4px;

        @include label();
    }

    .sign-up-btn:hover {
        opacity: 0.8;
    }

    .back-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        @media (min-width: $large) {
            margin-left: 16px;
        }
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        & img {
            width: 40px;
            height: 40px;
        }
        & p {
            @include body-link();

            margin: 2px 0 0 0;
        }
    }

    .checkout-help-button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        font-size: 12px;
        & img {
            width: 36px;
            height: 36px;
        }
    }

    .nav-right {
        margin-right: 0px;
    }
</style>
