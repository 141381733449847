module.exports = {
    'blacklisted-passwords': 'blacklisted-passwords',
    'delivery-waiting-list.store': 'delivery-waiting-list',
    'rate-flowers.index': 'rate-flowers',
    'rate-flowers.store': 'rate-flowers',
    'recent-flowers.index': 'cms/schedules',
    'vacancies': 'vacancies',
    'schedules.index.gbr': 'subscriptions/$1/arrangements',
    'schedules.index': 'cms/schedules',
    'weekly-email.store': 'customer/$1/weekly-email',
    'gifting.undeliverable-dates.index': 'cms/gifting-undeliverable-dates',
    'plans': 'plans',
    'missed-arrangements': 'subscriptions/$1/arrangements/getMissedArrangements',
    'landing-page-messages': 'landing-page-messages',
};
