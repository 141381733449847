import NotFound from '@/views/errors/NotFound';
import Footer from '@/components/structure/Footer';
import TopNavigation from '@/components/structure/navigation/TopNavigation';
import TopNavigationV2 from '@/components/structure/navigation/TopNavigationV2';

export default [
    {
        path: '/login/refresh',
        name: 'login-refresh',
        components: {
            footer: Footer,
            default: NotFound,
            topNavigation: TopNavigation,
            topNavigationV2: TopNavigationV2,
        },
    },
    {
        path: '/404',
        name: '404',
        components: {
            footer: Footer,
            default: NotFound,
            topNavigation: TopNavigation,
            topNavigationV2: TopNavigationV2,
        },
    },
    {
        path: '/*',
        name: 'butter-cms-catchall',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
];
