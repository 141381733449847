import { url } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class GetOutstandingOrders {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(userId, payload) {
        let apiUrl = url(ApiEndpoints['users.outstanding-orders.update'], [userId]);
        return this.apiService.patch(apiUrl, payload);
    }
}
