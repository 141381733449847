import Vue from 'vue';
import store from '@/store';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';
import CountryService from '@/services/logistics/CountryService';
import { getVariant, getDeliveryPrice, isFirstDeliveryFree } from '@/helpers/QueryHelpers';

const isDEU = process.env.VUE_APP_COUNTRY_ISO === 'DEU';

export default class RegisterCustomer {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    // Handle registering a new WEB customer.
    handle(paymentDetails) {
        let payload = Object.assign(this.getPayload(), paymentDetails);
        return this.apiService
            .post(ApiEndpoints['users.register.store'], payload);
    }

    getPayload() {
        let payload = {};
        let uuid = store.getters['register/getIncompleteRegistrantUuid'];
        let deliveryAddress = store.getters['register/getDeliveryAddress'];
        let deliveryAddressIsBilling = store.getters['register/getDeliveryAddressIsBilling'];
        let billingAddress = store.getters['register/getBillingAddress'];
        const productId = store.getters['register/getSelectedProductID'];
        const selectedProduct = store.getters['register/getSelectedProductDetails'];
        let appliedVouchers = store.getters['giftVoucher/getAppliedVouchers'];

        let voucherCodes = [];
        appliedVouchers.forEach(voucher => {
            voucherCodes.push(voucher.voucher_code);
        });

        billingAddress = deliveryAddressIsBilling ? deliveryAddress : billingAddress;

        if (uuid) {
            payload.incomplete_registration_uuid = uuid;
            payload.start_date = store.getters['register/getFirstDeliveryDateDesired'];
        }

        if (productId) payload.primary_product_id = productId;
        if (selectedProduct?.product_family) payload.product_family = selectedProduct?.product_family;
        if (selectedProduct?.override) payload.price_override = selectedProduct?.price;

        // let billingIso = billingAddress.country instanceof Object ? billingAddress.country.iso : billingAddress.country;
        let billingIso = process.env.VUE_APP_COUNTRY_ISO;
        let billingAddressCountry = CountryService.findCountryByIso(billingIso).name;
        let deliveryAddressCountry = CountryService.findCountryByIso(deliveryAddress.country).name;
        const optInMarketing = store.getters['register/getOptInMarketing'];
        const originURL = process.env.VUE_APP_ORIGIN_URL || '';
        const selectedPlan = store.getters['register/getSelectedPlan'];
        let queryParams = '';
        if (!/(%3C*|<)[^*]?script/gi.test(Vue.prototype.$globalQueryParams.queryString)) {
            queryParams = Vue.prototype.$globalQueryParams.queryString;
        }
        return {
            ...payload,
            billing_address_first_name: billingAddress.firstName || store.getters['register/getFirstName'],
            billing_address_last_name: billingAddress.lastName || store.getters['register/getLastName'],
            billing_address_house_number: billingAddress.houseNumber || null,
            billing_address_line_1: billingAddress.addressLine1,
            billing_address_line_2: billingAddress.addressLine2,
            billing_address_city: billingAddress.city,
            billing_address_postcode: billingAddress.postcode,
            billing_address_country: billingAddressCountry,
            coupon_code: store.getters['register/getFriendCode'],
            voucher_codes: voucherCodes,
            delivery_address_house_number: deliveryAddress.houseNumber || null,
            delivery_address_line_1: deliveryAddress.addressLine1,
            delivery_address_line_2: deliveryAddress.addressLine2,
            delivery_address_city: deliveryAddress.city,
            delivery_address_postcode: deliveryAddress.postcode,
            delivery_address_state: deliveryAddress.state || null,
            delivery_address_country: deliveryAddressCountry,
            delivery_address_property_type: store.getters['register/getPropertyType'] || undefined,
            delivery_instructions: store.getters['register/getDeliveryComment'],
            delivery_amount: getDeliveryPrice(),
            email: store.getters['register/getEmail'],
            first_name: store.getters['register/getFirstName'],
            free_first_delivery: isDEU ? false : isFirstDeliveryFree(),
            gateway: store.getters['payment/getStripeGateway'],
            last_name: store.getters['register/getLastName'],
            mode: getVariant(),
            opt_in_marketing: store.getters['register/getOptInMarketing'],
            opt_in_call: store.getters['register/getOptInMarketing'],
            opt_in_email: store.getters['register/getOptInMarketing'],
            opt_in_letter: store.getters['register/getOptInMarketing'],
            opt_in_third_party_marketing: store.getters['register/getOptInThirdPartyMarketing'],
            originURL: originURL,
            password: store.getters['register/getPassword'],
            preferred_delivery_day: this.getPreferredDeliveryDay(),
            awc: this.getAwinCookie(),
            plan_slug: selectedPlan.plan_interval,
            telephone: store.getters['register/getTelephone'],
            source_id: store.getters['register/getSourceId'],
            region_id: store.getters['register/getRegionId'],
            canvasser_id: store.getters['register/getCanvasserId'],
            paramsPayload: {
                queryParams,
            },
        };
    }

    getAwinCookie() {
        const awinKey = `_aw_m_${process.env.VUE_APP_AWIN_KEY}` || `_aw_j_${process.env.VUE_APP_AWIN_KEY}` || `_aw_sn_${process.env.VUE_APP_AWIN_KEY}` || `aw${process.env.VUE_APP_AWIN_KEY}`;
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${awinKey}=`);

        return parts.length === 2 ? parts.pop().split(';').shift() : null;
    }

    getPreferredDeliveryDay() {
        const selectedDay = store.getters['register/getPreferredDeliveryDay'];
        const defaultDay = store.getters['register/getFirstDeliveryDay'];
        return selectedDay === defaultDay ? null : selectedDay;
    }
}
