<template>
    <div :class="cardExpiryInputClass">
        <label class="input__label"
            :class="{ 'required-input': mandatory }"
            v-if="showLabel"
            v-text="$t('STRIPE_ELEMENTS_EXPIRY')"/>
        <div ref="input" :id="`${_uid}-input`" class="stripe_card_number"/>

        <p class="input__message"
           v-show="errorMessage"
           v-text="errorMessage"/>
    </div>
</template>

<script>
import { stripeElementsClasses, stripeElementsStyle } from '@/config/StripeElements';

export default {
    name: 'StripeCardExpirationInput',
    data: () => {
        return {
            cardExpiry: null,
        };
    },
    props: {
        stripeElements: {
            required: true,
        },
        hasBeenRequested: {
            required: true,
            default: false,
        },
        noPadding: {
            default: false,
            type: Boolean,
        },
        mandatory: {
            default: false,
            type: Boolean,
        },
        showLabel: {
            required: false,
            default: true,
        },
    },
    mounted() {
        this.mountCardExpiryField();
    },
    destroy() {
        this.cardExpiry.unmount();
        this.cardExpiry.destroy();
    },
    methods: {
        mountCardExpiryField() {
            this.cardExpiry = this.stripeElements.create('cardExpiry', {
                classes: stripeElementsClasses,
                style: stripeElementsStyle,
            });

            this.cardExpiry.mount(this.$refs.input);
        },
    },
    computed: {
        isValid() {
            return this.cardExpiryValid;
        },
        cardExpiryInputClass() {
            const cardValid = !(this.cardExpiryEmpty && this.hasBeenRequested) && this.cardExpiryValid && !this.errorMessage;
            return cardValid ? `input mr-1 ${this.noPadding ? 'noPadding' : ''}` : `input input--invalid ${this.noPadding ? 'noPadding' : ''}`;
        },
        cardExpiryEmpty() {
            return this.cardExpiry?._implementation?._empty;
        },
        cardExpiryValid() {
            return this.cardExpiry && !(this.cardExpiry._implementation?._invalid && !this.cardExpiry._implementation?._empty);
        },
        errorMessage() {
            if (this.hasBeenRequested) {
                if (this.cardExpiryEmpty) {
                    return this.$t('STRIPE_ELEMENTS_YOUR_CARD_EXPIRY');
                } else if (!this.cardExpiryValid) {
                    return this.$t('STRIPE_ELEMENTS_THAT_MEANS');
                }
                return null;
            }
            return null;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/sass/brand/theme.scss';

.stripe_card_number {
    @include border-thin();
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: calc(20 + (25 / 2)) + px;
    background-color: $light-color;
}
.noPadding {
    padding: 0;
}
</style>
