<template>
    <div class="radio-field--content">
        <h4 class="stripe-pay-req-btn--text">{{$t('STRIPE_WALLET_NOTHING_TO_PAY_TODAY', { deliveryDay: firstDeliveryDay })}}</h4>
        <div id="payment-request-button" :class="{'disable-btn': isProcessing}"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CountryService from '@/services/logistics/CountryService';

export default {
    name: 'StripePaymentRequestButton',
    data() {
        return {
            stripe: null,
            paymentRequest: null,
            elements: null,
            prButton: null,
            isProcessing: false,
        };
    },
    mounted() {
        this.initializePaymentRequest();
    },
    props: {
        setupIntent: {
            type: String,
            required: true,
        },
        isUpdate: {
            default: false,
        },
        isOutstandingPayments: {
            default: false,
        },
        isGifting: {
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            couponProperties: 'register/getCouponProperties',
            checkoutPrice: 'register/getCheckoutPrice',
            paymentGateway: 'payment/getStripeGateway',
            userId: 'account/getUserId',
            fullName: 'account/getFullName',
            firstDeliveryDay: 'register/getFirstDeliveryDay',
        }),
    },
    methods: {
        initializePaymentRequest() {
            this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
            let country = CountryService.findCountryByIso(process.env.VUE_APP_COUNTRY_ISO);
            this.paymentRequest = this.stripe.paymentRequest({
                country: country.iso2,
                currency: (process.env.VUE_APP_CURRENCY).toLowerCase(),
                requestPayerName: true,
                requestPayerEmail: true,
                total: {
                    label: 'Order Total',
                    amount: Number(this.checkoutPrice || process.env.VUE_APP_BOX_PRICE) * 100 || 0,
                },
            });
            this.elements = this.stripe.elements();
            this.prButton = this.elements.create('paymentRequestButton', {
                paymentRequest: this.paymentRequest,
            });
            // Check the availability of the Payment Request API first.
            this.paymentRequest.canMakePayment().then((result) => {
                if (result) {
                    this.prButton.mount('#payment-request-button');
                } else {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
            });
            this.paymentRequest.on('paymentmethod', (ev) => {
                // Confirm the PaymentIntent without handling potential next actions (yet).
                this.isProcessing = true;
                ev.complete('success');
                this.$store.dispatch('payment/getGatewayFromKey', 'wallet');
                if (!ev.paymentMethod?.id) return;
                let payload = {
                    payment_token: ev.paymentMethod.id,
                    stripe_card_id: ev.paymentMethod.id,
                    payment_method: ev.paymentMethod?.card?.wallet?.type || ev.paymentMethod.type,
                    gateway: this.paymentGateway,
                    name_on_card: this.fullName,
                };
                this.isUpdate && this.$$emit('updateCard', this.userId, { ...payload, card_token: ev.paymentMethod.id });
                !this.isUpdate && this.$emit('register', payload);
                this.isOutstandingPayments && this.$emit('pay', ev.paymentMethod.id, ev.paymentMethod?.card?.wallet?.type || ev.paymentMethod.type, this.paymentGateway);
                this.isGifting && this.$emit('save-card-token', paymentToken);
                !this.isUpdate && this.$emit('register', paymentToken, cardToken, paymentMethod, nameOfCard);
            });
        },
    },
};
</script>

<style scoped>
    .disable-btn {
        pointer-events: none;
        cursor: not-allowed;
    }
    .stripe-pay-req-btn--text {
        text-align: left;
    }
    .radio-field--content {
        padding: 10px 16px;
        border: 1px solid #DFDFDF;
    }
</style>
