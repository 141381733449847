import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class PauseSubscription {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle({ subscriptionId, reason, feedback, resumeDate, flowersStart }) {
        const apiUrl = prepareUrl(ApiEndpoints['subscriptions.pause'], [subscriptionId]);

        return this.apiService.patch(apiUrl, {
            reason,
            feedback,
            resume_date: resumeDate,
            flowers_start: flowersStart,
        });
    }
}
