import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class GetAnyCouponByCouponCode {
    apiService;

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(coupon) {
        let apiUrl = url(ApiEndpoints['lapsed.coupons.show'], [coupon]);

        return this.apiService.get(apiUrl);
    }
}
