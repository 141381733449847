import axios from 'axios';
import axiosRetry from 'axios-retry';
import ApiEndpoints from '@/config/ApiEndpoints';
import AuthService from '@/services/auth/AuthService';
import AuthRedirectService from '@/services/auth/AuthRedirectService';

export default class ApiService {
    // Axios http object.
    http

    // ApiService constructor
    constructor(baseUrl, parameters = {}, headers = {}, options = {}) {
        let defaultHeaders = {
            'Content-Type': 'application/json',
        };
        if (process.env.VUE_APP_COUNTRY_ISO !== 'GBR') defaultHeaders.Country = process.env.VUE_APP_COUNTRY_ISO;
        let token = localStorage.getItem('access_token');
        if (token) {
            defaultHeaders['Authorization'] = `Bearer ${token}`;
        }

        this.http = axios.create(Object.assign(options, {
            headers: Object.assign(defaultHeaders, headers),
            baseURL: baseUrl,
            params: parameters,
            validateStatus: this.constructor.validateStatus,
        }));
    }

    // Handles failed token refresh.
    handleFailedTokenRefresh(url) {
        if (url === ApiEndpoints['auth.login.refresh']) {
            this.redirectToLoginPage();
        }
    }

    redirectToLoginPage() {
        AuthService.frontendLogout();
        AuthRedirectService.redirectToLoginPage();
    }

    // Retries request if there's the Forbidden error.
    enableRetry() {
        const retryDelay = retryCount => retryCount * 1000;
        const retryCondition = error => error.response?.status === 403;

        axiosRetry(this.http, {
            retryDelay,
            retryCondition,
            retries: 1,
        });
    }

    // Sets auth parameters.
    enableAuth() {
        this.enableRetry();

        this.http.defaults.withCredentials = true;
        this.http.defaults.headers.common['Content-Type'] = 'application/json';
        this.http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }

    // Validate response status.
    static validateStatus(status) {
        if (status === 403) {
            AuthRedirectService.redirectAfterAccessTokenExpiration();
        }

        return status >= 200 && status < 300;
    }

    // Axios delete request.
    delete(url, parameters) {
        parameters = this.cleanData(parameters);

        return new Promise((resolve, reject) => {
            this.http
                .delete(url, parameters)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.message === 'Token not valid') {
                        this.redirectToLoginPage();
                    }
                    reject(error);
                });
        });
    }

    // Axios get request.
    get(url, parameters) {
        parameters = this.cleanData(parameters);

        return new Promise((resolve, reject) => {
            this.http
                .get(url, parameters)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.error({ error });
                    if (error && error.response && error.response.data.message === 'Token not valid') {
                        this.redirectToLoginPage();
                    }
                    reject(error);
                });
        });
    }

    // Axios post request.
    post(url, parameters, config = {}) {
        parameters = this.cleanData(parameters);

        return new Promise((resolve, reject) => {
            this.http
                .post(url, parameters, config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.message === 'Token not valid') {
                        this.redirectToLoginPage();
                    } else {
                        this.handleFailedTokenRefresh(url);
                    }

                    reject(error);
                });
        });
    }

    // Axios patch request.
    patch(url, parameters) {
        parameters = this.cleanData(parameters);

        return new Promise((resolve, reject) => {
            this.http
                .patch(url, parameters)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (error && error.response && error.response.data.message === 'Token not valid') {
                        this.redirectToLoginPage();
                    }
                    reject(error);
                });
        });
    }

    cleanData(params) {
        if (params) {
            let stringified = JSON.stringify(params);

            stringified = stringified.replace('<script>', '');
            stringified = stringified.replace('</script>', '');
            stringified = stringified.replace('&lt;script', '');
            stringified = stringified.replace('&LT;script', '');
            stringified = stringified.replace('&#x0003C;script', '');
            stringified = stringified.replace('&#60;script', '');
            stringified = stringified.replace('<script', '');
            stringified = stringified.replace('script>', '');

            return JSON.parse(stringified);
        }
    }
}
