<template>
    <transition name="transitionSlideHamburgerFromLeft">
        <div v-if="show" class="overlay" @click="$emit('closed')">
            <div class="hamburger__container" @click.stop="() => null" style="width: 80%">
                <div class="close-container">
                    <button class="hamburgerHeader__left__button close-btn" @click="toggleShow">
                        <span class="hamburger__close"></span>
                    </button>
                </div>

                <div class="hamburgerBody">
                    <router-link :to="{ name: 'home'}">
                        <div @click="$emit('closed')" style="display: flex; justify-content: center">
                            <img
                                width="120"
                                height="120"
                                src="@/assets/images/logos/freddies_flowers_stacked.svg"
                                alt="Freddie's Flowers"/>
                        </div>
                    </router-link>

                    <nav v-if="!isPage(['account'])" class="hamburger__landing-buttons__container">
                        <ul class="hamburger__landing-buttons">
                          <li :key="index" v-for="(button, index) in filteredButtons" @click="go(button.route)" class="icon-text">
                            <nav-button
                                v-if="button.enabled"
                                :button="button"
                                :key="index"
                                :isMobile="true" class="hamburger__landing-button mt-4"/>
                          </li>
                        </ul>
                        <button class="btn btn--primary-black btn--half cta"
                            @click="goToSignUp()">
                            {{ $t('SEND_ME_FLOWERS') }}
                        </button>
                        <div class="bottom-menu-container">
                            <p class="text">{{ $t('GOT_AN_ACCOUNT') }}</p>
                            <u @click="toggleShow()"><login-link></login-link></u>
                        </div>
                    </nav>
                </div>
                <img class="flower-decoration" src="@/assets/images/decoration/burger-flowers.jpg" alt="">
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginLink from '@/components/structure/navigation/navigation/LogInLink';
import NavButton from '@/components/structure/navigation/navigation/NavButton';

export default {
    name: 'Hamburger',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            buttons: 'nav/getButtons',
            isUserLoggedIn: 'auth/isUserLoggedIn',
        }),
        filteredButtons() {
            const status = (this.isUserLoggedIn ? 'loggedIn' : 'loggedOut');
            if (this.buttons.filter(button => button.label.toLowerCase() === 'gifting' && !this.isUserLoggedIn)[0]) this.buttons.filter(button => button.label.toLowerCase() === 'gifting' && !this.isUserLoggedIn)[0].route = 'guest-gifting';
            return this.buttons.filter(button => button[status] || button['isMobile']);
        },
    },
    methods: {
        go(route) {
            if (this.$route.name !== route) this.$router.push({ name: route });
            this.toggleShow();
        },
        toggleShow() {
            this.$emit('closed');
        },
        isPage(pageArr) {
            const pageRoot = this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        goToSignUp() {
            this.toggleShow();
            this.$router.push({ name: 'register' });
        },
    },
    components: {
        LoginLink,
        NavButton,
    },
};
</script>

<style lang="scss">
@import '@/assets/sass/brand/theme.scss';
    .hamburger__landing-buttons__container {
        width: 100%;
        text-align: center;
    }
    .hamburger__landing-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 16px 10px;
        color: $primary-color;
    }
    .hamburger__landing-button {
        text-align: center;
        margin: 0 24px;
        img {
            margin: 0 auto;
            height: 45px;
            width: 45px;
        }
        h5 {
            font-size: 12px;
            margin: 8px 0 0;
        }
    }
    @media (min-width: 1024px) {
        .hamburger__landing-buttons__container {
            display: none;
        }
    }

    .bottom-menu-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $link-color;
    }

    .overlay {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100%;
        height: 120%;
        background: transparent;
        animation: fade .5s ease forwards;
    }

    @keyframes fade {
        from {
            background: transparent;
        }

        to {
            background:  rgba(1, 1, 1, 0.5);
        }
    }

    .flower-decoration {
        position: absolute;
        bottom: 0px;
    }
    .icon-text {
        position: relative;
    }
    .icon-image-text {
        position: absolute;
    }
</style>
