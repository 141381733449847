import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchClientToken {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        const apiUrl = ApiEndpoints['setupintents.users.store'];

        return this.apiService.post(apiUrl, { ...payload, is_terminal: process.env.VUE_APP_MODE === 'canvasser' });
    }
}
