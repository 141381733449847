import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].jobs,
        name: 'jobs',
        components: {
            default: () => import(/* webpackChunkName: "jobs" */ '@/views/content/Jobs'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].privacyPolicy,
        name: 'privacy-policy',
        components: {
            default: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/content/PrivacyPolicy'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].termsAndConditions,
        name: 'terms-and-conditions',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/content/TermsAndConditions'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: '/tag-rugby-trust',
        name: 'tag-rugby-trust',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/content/TRTPage'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].impressum,
        name: 'impressum',
        components: {
            default: () => import(/* webpackChunkName: "impressum" */ '@/views/content/Impressum'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].paymentAndDelivery,
        name: 'payment-and-delivery',
        components: {
            default: () => import(/* webpackChunkName: "impressum" */ '@/views/content/PaymentAndDelivery'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].rightToCancel,
        name: 'right-to-cancel',
        components: {
            default: () => import(/* webpackChunkName: "impressum" */ '@/views/content/RightToCancel'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].trt,
        name: 'tag-rugby-trust',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/content/TRTPage'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].accessibility,
        name: 'accessibility',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/Accessibility'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].sustainability,
        name: 'sustainability',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/SustainabilityPage'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].growers,
        name: 'growers',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/GrowersPage'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: '/christmas1',
        name: 'christmas1',
    },
    {
        path: '/christmas2',
        name: 'christmas2',
    },
    {
        path: '/christmas3',
        name: 'christmas3',
    },
    {
        path: '/christmas4',
        name: 'christmas4',
    },
    {
        path: '/christmas5',
        name: 'christmas5',
    },
    {
        path: '/christmas6',
        name: 'christmas6',
    },
    {
        path: '/christmas7',
        name: 'christmas7',
    },
    {
        path: '/christmas8',
        name: 'christmas8',
    },
    {
        path: '/christmas9',
        name: 'christmas9',
    },
    {
        path: '/christmas10',
        name: 'christmas10',
    },
];
