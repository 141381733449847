import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class UpdateProductType {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, prodId, name, date, plan) {
        if (date) {
            let apiUrl = prepareUrl(ApiEndpoints['subscriptions.update.single-box-type'], [subscriptionId]);
            let payload = {
                delivery_date: date,
                product_family: name,
            };
            return this.apiService.post(apiUrl, payload);
        }
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.update.box-type'], [subscriptionId, prodId]);
        return this.apiService.patch(apiUrl, { plan_slug: plan });
    }
}
