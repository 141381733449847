import store from '@/store';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';
import CountryService from '@/services/logistics/CountryService';
import { getVariant, getDeliveryPrice } from '@/helpers/QueryHelpers';

export default class RegisterCanvassedCustomer {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    // Handle registering a new WEB customer.
    handle(tokenId = null, cardId = null, customerId = null) {
        let payload = this.getPayload();

        if (tokenId !== null && cardId !== null) {
            payload = { ...payload, stripe_token: tokenId, stripe_card_id: cardId, customer_id: customerId };
        }

        let startDate = store.getters['register/getFirstDeliveryDateDesired'];
        if (startDate?.value) {
            payload = { ...payload, start_date: startDate.value };
        }

        return this.apiService
            .post(ApiEndpoints['users.register.store'], payload);
    }

    getPayload() {
        let payload = {};
        let uuid = store.getters['register/getIncompleteRegistrantUuid'];
        let deliveryAddress = store.getters['register/getDeliveryAddress'];
        let deliveryAddressIsBilling = store.getters['register/getDeliveryAddressIsBilling'];
        let billingAddress = store.getters['register/getBillingAddress'];
        billingAddress = deliveryAddressIsBilling ? deliveryAddress : billingAddress;

        if (uuid) {
            payload.incomplete_registration_uuid = uuid;
        }

        let billingIso = billingAddress.country instanceof Object ? billingAddress.country.iso : billingAddress.country;
        let billingAddressCountry = CountryService.findCountryByIso(billingIso).name;
        let deliveryAddressCountry = CountryService.findCountryByIso(deliveryAddress.country).name;
        const selectedPlan = store.getters['register/getSelectedPlan'];
        const selectedProduct = store.getters['register/getSelectedProductDetails'];
        const defaultProductFamily = store.getters['register/getDefaultProductFamily'];
        payload.product_family = selectedProduct?.product_family ? selectedProduct?.product_family : defaultProductFamily;

        return {
            ...payload,
            billing_address_first_name: store.getters['register/getFirstName'],
            billing_address_last_name: store.getters['register/getLastName'],
            billing_address_house_number: billingAddress.houseNumber || null,
            billing_address_line_1: billingAddress.addressLine1,
            billing_address_line_2: billingAddress.addressLine2,
            billing_address_city: billingAddress.city,
            billing_address_postcode: billingAddress.postcode,
            billing_address_country: billingAddressCountry,
            delivery_address_house_number: deliveryAddress.houseNumber || null,
            coupon_code: store.getters['account/getSignupCoupon'],
            delivery_address_line_1: deliveryAddress.addressLine1,
            delivery_address_line_2: deliveryAddress.addressLine2,
            delivery_address_city: deliveryAddress.city,
            delivery_address_postcode: deliveryAddress.postcode,
            delivery_address_state: deliveryAddress.state || null,
            delivery_address_country: deliveryAddressCountry,
            delivery_address_property_type: store.getters['register/getPropertyType'],
            delivery_amount: getDeliveryPrice(),
            delivery_instructions: store.getters['register/getDeliveryComment'],
            email: store.getters['register/getEmail'],
            first_name: store.getters['register/getFirstName'],
            last_name: store.getters['register/getLastName'],
            opt_in_marketing: store.getters['register/getOptInMarketing'],
            opt_in_third_party_marketing: store.getters['register/getOptInThirdPartyMarketing'],
            password: store.getters['register/getPassword'],
            telephone: store.getters['register/getTelephone'],
            source_id: store.getters['canvasser/getSourceId'] || store.getters['canvasser/getParentSourceId'],
            region_id: store.getters['canvasser/getRegionId'],
            canvasser_id: store.getters['account/getUserId'],
            plan_slug: selectedPlan.plan_interval,
            mode: getVariant(),
            free_first_delivery: false,
            added_add_on_product: store.getters['register/getAddedAddonItem'],
        };
    }
}
