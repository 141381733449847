<template>
    <div class="orderSummaryButton border-right" @click="openSummaryModal">
        <div v-if="cartItems" class="items-count">
            {{ cartItems }}
        </div>
        <div class="orderSummaryButton__icon__container basket-container" :class="{ 'basket-opened': isOrderSummaryOpened }">
            <img class="orderSummaryButton__icon basket-icon" src='@/assets/images/decoration/basket.svg'>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { turnScrollOff } from '@/helpers/ScrollHelpers.js';

export default {
    name: 'OrderSummaryButton',
    computed: {
        ...mapGetters({
            orderSummary: 'shop/getOrderSummary',
            isOrderSummaryOpened: 'shop/getOrderSummaryModalState',
        }),
        cartItems() {
            return this.orderSummary[0] && this.orderSummary[0].products && this.orderSummary[0].products.length;
        },
    },
    methods: {
        openSummaryModal() {
            turnScrollOff(document);
            return this.$store.commit('shop/openOrderSummary');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

    .border-right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 81px;
        padding-right: 4px;
        border-right: 1px solid $secondary-color;
        position: relative;
    }

    .basket-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        position: relative;
        background-color: $secondary-color;
        border-radius: 50%;
        transition: background-color .25s linear;
        -moz-transition: background-color .25s linear;
        -webkit-transition: background-color .25s linear;
    }

    .basket-container:hover {
        transition: background-color .25s linear;
        -moz-transition: background-color .25s linear;
        -webkit-transition: background-color .25s linear;
        background-color: $secondary-color;
        opacity: 0.8;
    }

    .basket-opened {
        background-color: $secondary-color;
        border-radius: 50%;
    }

    .basket-icon {
        height: 40px;
        width: 40px;
    }

    .items-count {
        position: absolute;
        background-color: $primary-color;
        color: $secondary-color;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        z-index: 9999;
        right: 23px;
        top: -4px;
    }

    @media (max-width: $large) {
        .items-count {
            right: 22px;
            top: -4px;
        }
    }

    @media (max-width: $medium) {
        .border-right {
            justify-content: flex-end;
            border-right: none;
            margin-right: -16px;
        }

        .items-count {
            right: 8px;
            top: 0px;
        }
    }
</style>
