import RedirectMiddleware from '@/middleware/RedirectMiddleware';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].home,
        name: 'home',
        beforeEnter: (too, from, next) => { RedirectMiddleware(too, from, next); },
        components: {
            default: () => import(/* webpackChunkName: "home" */ '@/views/DynamicHome.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 1,
        },
    },
    {
        path: '/v2',
        name: 'v2-home',
        redirect: { name: 'home' },
        props: true,
    },
    {
        path: '/v2/c/:code',
        name: 'v2-coupon',
        redirect: { name: 'coupon' },
        props: true,
    },
    {
        path: routesMap[country].theFlowers,
        name: 'flowers',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 2,
        },
    },
    {
        path: routesMap[country].theDelivery,
        name: 'delivery',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 3,
        },
    },
    {
        path: '/l/:pageKey',
        name: 'butter-cms-page',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 9,
        },
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 2,
        },
    },
    {
        path: '/freddies-story',
        name: 'freddies-story',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 2,
        },
    },
    {
        path: '/brighten-your-home',
        name: 'brighten-your-home',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].theArranging,
        name: 'arranging',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 4,
        },
    },
    {
        path: '/faq',
        name: 'faq',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/LandingPage.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 5,
        },
    },
    {
        path: '/christmas',
        name: 'christmas',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/content/Christmas.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 5,
        },
    },
    {
        path: `${routesMap[country].survey}/:surveyId/:uuid`,
        name: 'nps-survey',
        components: {
            default: () => import(/* webpackChunkName: "landing" */ '@/views/SurveyLandingPage.vue'),
        },
        meta: {
            order: 6,
        },
    },
    {
        path: routesMap[country].business,
        name: 'business-enquiries',
        components: {
            default: () => import(/* webpackChunkName: "home" */ '@/views/content/BusinessCapture.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        meta: {
            order: 1,
        },
    },
    {
        path: routesMap[country].pennies,
        name: 'pennies',
        components: {
            default: () => import(/* webpackChunkName: "home" */ '@/views/account/Pennies.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
];
