import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: `${routesMap[country].referenceFromAFriend}/:code`,
        name: 'reference-from-a-friend',
        component: () => import(/* webpackChunkName: "reference-from-a-friend" */ '@/views/ReferenceFromAFriend'),
        props: true,
    },
    {
        path: `${routesMap[country].referenceFromWeb}/:source/:code`,
        name: 'reference-from-web',
        component: () => import(/* webpackChunkName: "reference-from-a-friend" */ '@/views/ReferenceFromAFriend'),
        props: true,
    },
];
