import RedirectMiddlewareSubscriptions from '@/middleware/RedirectMiddlewareSubscriptions';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].subscriptions,
        name: 'subscriptions',
        beforeEnter: RedirectMiddlewareSubscriptions,
        redirect: { name: country === 'DEU' ? 'register.email' : 'register.choose-subscription' },
        components: {
            // route level code-splitting. This generates a separate chunk (register.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "subscriptions" */ '@/views/Subscriptions'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
        },
        children: [
            {
                path: routesMap[country].size,
                name: 'subscriptions.size',
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/ChooseSize'),
            },
            {
                path: routesMap[country].frequency,
                name: 'subscriptions.frequency',
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/ChooseFrequency'),
            },
            {
                path: `${routesMap[country].checkout}/${routesMap[country].yourDetails}`,
                name: 'subscriptions.checkout.your-details',
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/YourDetails'),
            },
            {
                path: `${routesMap[country].checkout}/${routesMap[country].deliveryDetails}`,
                name: 'subscriptions.checkout.delivery-details',
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/DeliveryDetails'),
            },
            {
                path: `${routesMap[country].checkout}/${routesMap[country].paymentDetails}`,
                name: 'subscriptions.checkout.payment-details',
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/PaymentDetails'),
            },
            {
                path: `${routesMap[country].subscriptions}/${routesMap[country].accountConfirmation}`,
                name: 'subscriptions.account-confirmation',
                beforeEnter: RedirectMiddlewareSubscriptions,
                component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/account/AccountConfirmationV2'),
            },
        ],
    },
];
