import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class updateSpecialDates {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    async handle(occasionId, payload) {
        const apiUrl = url(ApiEndpoints['users.updateSpecialDates'], [occasionId]);
        let request = await this.apiService
            .patch(apiUrl, payload)
            .catch(() => false);

        return request;
    }
}
