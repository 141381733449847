import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].login,
        name: 'login',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].logout,
        name: 'logout',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Logout'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].forgotPassword,
        name: 'forgot-password',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgottenPassword'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: routesMap[country].resetPasswordNotification,
        name: 'reset-password-notification',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPasswordNotification'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: `${routesMap[country].resetPassword}/:token/:messageEmail`,
        name: 'reset-password',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        props: {
            default: true,
            topNavigation: false,
            topNavigationV2: false,
        },
    },
    {
        path: '/email/opt-out/:referralId',
        name: 'email-opt-out',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/EmailOptOut'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
];
