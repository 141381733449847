import GetCouponByCouponCode from '@/services/coupons/couponService/GetCouponByCouponCode';
import GetAnyCouponByCouponCode from '@/services/coupons/couponService/GetAnyCouponByCouponCode';
import GetCurrentPauseCoupon from '@/services/coupons/couponService/GetCurrentPauseCoupon';
import RedeemPauseCoupon from '@/services/coupons/couponService/RedeemPauseCoupon';

export default class CouponService {
    static getCouponByCouponCode(coupon, giftProductId, userId) {
        return (new GetCouponByCouponCode()).handle(coupon, giftProductId, userId);
    }

    static getAnyCouponByCouponCode(coupon) {
        return (new GetAnyCouponByCouponCode()).handle(coupon);
    }

    static getCurrentPauseCoupon() {
        return (new GetCurrentPauseCoupon()).handle();
    }

    static redeemPauseCoupon(couponId, subscriptionId) {
        return (new RedeemPauseCoupon()).handle(couponId, subscriptionId);
    }
}
