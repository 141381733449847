import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import CountryService from '@/services/logistics/CountryService';

export default class CreateGiftingAddress {
    apiService

    countryService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
        this.countryService = new CountryService();

        this.apiService.enableAuth();
    }

    handle(payload, userId) {
        let apiUrl = prepareUrl(ApiEndpoints['gifting-addresses.store'], [userId]);

        // Get the countries' name since vuex stores country iso.
        let giftingAddressCountry = CountryService.findCountryByIso(payload.country);

        let address = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            house_number: payload.houseNumber || null,
            address1: payload.address1,
            address2: payload.address2 || null,
            city: payload.city,
            country: giftingAddressCountry?.name,
            postcode: payload.postcode,
            delivery_comment: payload.deliveryComment,
            property_type: payload.propertyType,
        };

        return this.apiService.post(apiUrl, address);
    }
}
