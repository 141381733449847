import HowFreddiesWorksImage from '@/assets/images/navigation/how-freddies-works.png';

const i18n = require('@/i18n').default;

const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';

export default {
    namespaced: true,
    state: {
        buttons: [
            {
                route: 'home',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('NAV_HOME_ICON'),
                loggedIn: false,
                loggedOut: true,
                enabled: false,
            },
            {
                route: 'register.email',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('NAV_SUBSCRIPTIONS'),
                loggedIn: false,
                loggedOut: true,
                enabled: true,
            },
            {
                route: 'guest-gifting.home',
                src: process.env.VUE_APP_COUNTRY_ISO === 'GBR' ? require('@/assets/images/decoration/new.svg') : require('@/assets/images/decoration/neu.svg'),
                altText: 'New! icon',
                showImage: true,
                label: i18n.t('NAV_GIFTS_ICON'),
                loggedIn: false,
                loggedOut: process.env.VUE_APP_GUEST_GIFTING_ENABLED === 'true',
                enabled: true,
            },
            {
                route: 'guest-gifting.vouchers',
                src: process.env.VUE_APP_COUNTRY_ISO === 'GBR' ? require('@/assets/images/decoration/new.svg') : require('@/assets/images/decoration/neu.svg'),
                altText: 'New! icon',
                showImage: true,
                label: i18n.t('NAV_GIFT_VOUCHERS'),
                loggedIn: false,
                loggedOut: true,
                enabled: process.env.VUE_APP_GIFTI_VOUCHER_ENABLED === 'true',
            },
            {
                route: 'delivery',
                src: require('@/assets/images/decoration/link-icon-van.svg'),
                showImage: false,
                label: i18n.t('LANDING_DELIVERY'),
                loggedIn: false,
                loggedOut: true,
                enabled: process.env.VUE_APP_COUNTRY_ISO !== 'GBR',
            },
            {
                route: 'how-it-works',
                src: require('@/assets/images/decoration/link-icon-van.svg'),
                showImage: false,
                label: i18n.t('LANDING_HOW_IT_WORKS'),
                loggedIn: false,
                loggedOut: true,
                enabled: isGBR,
            },
            {
                route: 'flowers',
                src: require('@/assets/images/decoration/link-icon-flowers.svg'),
                showImage: false,
                label: i18n.t('LANDING_FLOWERS'),
                loggedIn: false,
                loggedOut: true,
                enabled: true,
            },
            {
                route: 'freddies-story',
                src: require('@/assets/images/decoration/link-icon-van.svg'),
                showImage: false,
                label: i18n.t('LANDING_MEET_FREDDIE'),
                loggedIn: false,
                loggedOut: true,
                enabled: false,
            },
            {
                route: 'brighten-your-home',
                src: require('@/assets/images/decoration/link-icon-van.svg'),
                showImage: false,
                label: i18n.t('LANDING_BRIGHTEN_YOUR_HOME'),
                loggedIn: false,
                loggedOut: true,
                enabled: false,
            },
            {
                route: 'arranging',
                src: require('@/assets/images/decoration/link-icon-vase.svg'),
                label: i18n.t('LANDING_ARRANGING'),
                loggedIn: false,
                loggedOut: true,
                enabled: process.env.VUE_APP_COUNTRY_ISO !== 'GBR',
            },
            {
                route: 'growers',
                // src: require('@/assets/images/decoration/link-icon-vase.svg'),
                label: i18n.t('OUR_GROWERS'),
                loggedIn: false,
                loggedOut: true,
                enabled: false,
            },
            {
                route: 'sustainability',
                // src: require('@/assets/images/decoration/link-icon-vase.svg'),
                label: i18n.t('CONTACT_US_NAV_LINK_SUSTAINABILITY'),
                loggedIn: false,
                loggedOut: true,
                enabled: process.env.VUE_APP_COUNTRY_ISO === 'DEU',
            },
            {
                route: 'account.dashboard',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('DASHBOARD_DASHBOARD'),
                loggedIn: true,
                loggedOut: false,
                isLapser: true,
                enabled: true,
            },
            {
                route: 'account.delivery-schedule',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('DASHBOARD_CALENDAR'),
                loggedIn: true,
                loggedOut: false,
                enabled: true,
            },
            {
                route: isGBR ? 'guest-gifting.home' : 'account.gifting.choose-your-gift',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('TOP_NAV_ACCOUNT_GIFTING'),
                loggedIn: true,
                loggedOut: false,
                isLapser: true,
                enabled: true,
            },
            {
                route: 'guest-gifting.vouchers',
                showImage: false,
                label: i18n.t('NAV_GIFT_VOUCHERS'),
                loggedIn: process.env.VUE_APP_GIFTI_VOUCHER_ENABLED === 'true',
                loggedOut: false,
                enabled: process.env.VUE_APP_GIFTI_VOUCHER_ENABLED === 'true',
            },
            {
                route: 'account.shop',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('DASHBOARD_FREDDIES_SHOP'),
                loggedIn: true,
                loggedOut: false,
                enabled: true,
            },
            {
                route: 'faq.categories',
                src: require('@/assets/images/decoration/link-icon-hi.svg'),
                showImage: false,
                label: i18n.t('TOP_NAVIGATION_HELP'),
                loggedIn: true,
                loggedOut: false,
                isMobile: false,
                isLapser: true,
                enabled: true,
            },

        ],
        routesUnderLearnMoreDropdown: [
            {
                route: 'how-it-works',
                image: HowFreddiesWorksImage,
                imageAlt: i18n.t('IMAGE_ALT_TEXT_RIDER_ON_A_BIKE'),
                heading: i18n.t('LANDING_HOW_FREDDIES_WORKS'),
                description: i18n.t('LANDING_HOW_FREDDIES_WORKS_DESCRIPTION'),
            },

            {
                route: 'flowers',
                image: require('@/assets/images/navigation/the-flowers.jpg'),
                imageAlt: i18n.t('IMAGE_ALT_TEXT_FREDDIE_WITH_FLOWERS'),
                heading: i18n.t('LANDING_OUR_FLOWERS'),
                description: i18n.t('LANDING_FLOWERS_DESCRIPTION'),
            },
            // {
            //     route: 'freddies-story',
            //     image: require('@/assets/images/navigation/meet-freddie.png),
            //     imageAlt: i18n.t('IMAGE_ALT_TEXT_FREDDIE_WITH_FLOWERS'),
            //     heading: i18n.t('LANDING_MEET_FREDDIE'),
            //     description: i18n.t('LANDING_MEET_FREDDIE_DESCRIPTION'),
            // },
            // {
            //     route: 'growers',
            //     image: OurGrowersImage,
            //     imageAlt: i18n.t('IMAGE_ALT_TEXT_OUR_GROWERS_FLOWER_GARDEN'),
            //     heading: i18n.t('OUR_GROWERS'),
            //     description: i18n.t('OUR_GROWERS_DESCRIPTION'),
            // },
        ],
    },
    getters: {
        // Gets buttons for navigation bar.
        getButtons: state => state.buttons,
        getRoutesUnderLearnMoreDropdown: state => state.routesUnderLearnMoreDropdown,
    },
};
