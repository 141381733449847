import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class SendCookieData {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(payload) {
        const apiUrl = url(ApiEndpoints['cookie.optin']);
        return this.apiService.post(apiUrl, payload);
    }
}
