import { VOUCHER_BOX_TYPE, VOUCHER_AMOUNT_TYPE } from '@/constants/GiftVouchers.js';

const getDefaultState = () => {
    return {
        appliedBoxVoucher: [],
        appliedAmountVoucher: [],
        payablePriceAfterVoucherDiscount: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        addAppliedBoxVoucher: (state, payload) => state.appliedBoxVoucher.push(payload),

        addAppliedAmountVoucher: (state, payload) => state.appliedAmountVoucher.push(payload),

        setPayablePrice: (state, payload) => state.payablePriceAfterVoucherDiscount = payload,

        removeGiftVoucher: (state, payload) => {
            if (payload.voucher_type === VOUCHER_BOX_TYPE) state.appliedBoxVoucher = state.appliedBoxVoucher.filter(voucher => voucher.voucher_code !== payload.voucher_code);
            if (payload.voucher_type === VOUCHER_AMOUNT_TYPE) state.appliedAmountVoucher = state.appliedAmountVoucher.filter(voucher => voucher.voucher_code !== payload.voucher_code);
        },
    },

    actions: {
        addAppliedVoucher({ state, commit }, voucher) {
            if (voucher.voucher_type === VOUCHER_BOX_TYPE) {
                if (state.appliedBoxVoucher.some(item => item.voucher_code === voucher.voucher_code)) return;
                commit('addAppliedBoxVoucher', voucher);
            } else if (voucher.voucher_type === VOUCHER_AMOUNT_TYPE) {
                if (state.appliedAmountVoucher.some(item => item.voucher_code === voucher.voucher_code)) return;
                commit('addAppliedAmountVoucher', voucher);
            }
        },
    },

    getters: {
        getGiftVouchers: state => state.availableVouchers,

        getBoxVouchers: state => state.appliedBoxVoucher.sort((a, b) => a.balance - b.balance),

        getAmountVouchers: state => state.appliedAmountVoucher.sort((a, b) => a.balance - b.balance),

        getAppliedVouchers: state => {
            let boxVouchers = state.appliedBoxVoucher.sort((a, b) => a.balance - b.balance);
            let amountVouchers = state.appliedAmountVoucher.sort((a, b) => a.balance - b.balance);
            return [...boxVouchers, ...amountVouchers];
        },
    },
};
