import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class FetchClientToken {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(email) {
        let apiUrl = url(ApiEndpoints['primer.client-token.store']);
        const payload = {
            gateway: 'Primer',
            email,
        };

        return this.apiService.post(apiUrl, payload);
    }
}
