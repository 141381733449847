import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class Login {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(email, password) {
        return this.apiService.post(ApiEndpoints['auth.login'], {
            email: email,
            password: password,
        });
    }
}
