import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class LegacyGetFirstDeliveryDayForPostcode {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    async handle(postcode) {
        let apiUrl = url(ApiEndpoints['postcodes.first-delivery-day'], [postcode]);
        let request = await this.apiService.get(apiUrl);

        return request.data.properties.first_delivery_day;
    }
}
