import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class CancelOptInOrder {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.opt-in.destroy'], [payload.subscriptionId, payload.optInBoxId]);

        return this.apiService.delete(apiUrl);
    }
}
