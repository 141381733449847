<template>
    <nav v-if="showTopNavMenus" class="navigation__landing-buttons-container">
        <ul class="navigation__landing-buttons">
            <li
                v-for="button in buttons"
                :key="`${button.label}-button`"
                class="nav-link"
                :class="{ 'active-link': isCurrentPage(button)}">

                <a
                    v-if="button.route === 'faq.categories'"
                    :href="zendeskHcUrl"
                    class="nav-link">
                    {{ $t('TOP_NAVIGATION_HELP') }}
                    <div class="underline"></div>
                </a>

                <router-link v-else :to="{name: isSelectedGifter(button)}" class="nav-button">
                    <img :src="button.src" :alt="button.altText || `${button.label} icon`" v-if="button.showImage" class="icon-class"/>
                    {{ $t('NAV_BUTTON_LABEL', { 'button.label': $t(`${button.label}`)}) }}
                    <div class="underline"></div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import countryUtils from '@/resources/countryUtils.json';

export default {
    name: 'NavButtonGroup',
    props: {
        buttons: {
            type: Array,
            required: true,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        isDesktop: {
            type: Boolean,
        },
        customStyles: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            countryUtils: countryUtils.filter(item => item.iso.toLowerCase() === process.env.VUE_APP_COUNTRY_ISO.toLowerCase())[0],
        };
    },
    computed: {
        ...mapGetters({
            isNewGiftingFlow: 'account/isNewGiftingFlow',
            isGifter: 'account/isGifterAccount',
        }),
        showTopNavMenus() {
            return this.isDesktop && (!this.isPage(['register', 'subscriptions', 'guest-gifting', 'gifting']) || this.isPage(['guest-gifting.home'], true));
        },
        zendeskHcUrl() {
            return this.countryUtils?.zendesk_hc_url;
        },
        newGiftingFlow() {
            return this.isNewGiftingFlow ? 'guest-gifting' : 'account.gifting.choose-your-gift';
        },
    },
    methods: {
        isPage(pageArr, isSubPage = false) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = isSubPage ? this.$route.name : this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        isCurrentPage(button) {
            return this.$route.name === button.route;
        },
        isSelectedGifter(button) {
            let routeName = button.route;
            if (routeName === 'account.gifting.choose-your-gift') {
                return this.newGiftingFlow;
            }
            return routeName;
        },
        // isSelected(button) {
        //     return this.$route.name === button.route;
        // },
    },
    // watch: {
    //     '$route'(to) {
    //         this.isSelected(button, to);
    //     },
    // },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';
@import '@/assets/sass/brand/constants.scss';

.nav-menu {
    font-family: $secondary-font;
    font-style: $font-style-normal;
    font-weight: $font-weight-300;
    font-size: $font-medium;
    line-height: 24px;
}

.nav-mobile {
    text-decoration: none;
    &--selected {
        border-radius: 136px;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: $secondary-color;
        text-decoration: none;
    }
}

li {
    list-style-type: none;
    font-size: $font-x-large;
}

li .underline {
    height: 3px;
    background-color: transparent;
    width: 0%;
    transition: width 0.2s, background-color 0.5s;
    -moz-transition: width 0.2s, background-color 0.5s;
    -webkit-transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
    border-radius: 24px;
}

li.active-link .underline {
    width: 100%;
    background-color: $secondary-color;
}

li:hover .underline {
    background-color: $secondary-color;
    width: 100%;
}

.icon-class {
    position: absolute;
    top: -15px;
    left: -10px;
}

.nav-button {
    position: relative;
    list-style: none;
}

.nav-link {
    list-style: none;
}
</style>
