import uniqBy from 'lodash/uniqBy';
import FaqAnswers from '@/services/faq/faqService/FaqAnswers';

export default class FaqSearch {
    async handle() {
        let searchQuestions = await (new FaqAnswers()).handle();

        return uniqBy(searchQuestions.filter((question) => typeof question.expose !== 'undefined'), 'title');
    }
}
