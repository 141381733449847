import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class FetchAllUserAddresses {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(userId) {
        let apiUrl = prepareUrl(ApiEndpoints['addresses.index'], [userId]);

        return this.apiService.get(apiUrl);
    }
}
