export const filterHolidays = (deliveries) => {
    return deliveries.filter(delivery => {
        return delivery['holiday_set'];
    }).map(delivery => {
        return {
            date: delivery['datetime'],
            id: delivery['holiday_id'],
        };
    });
};

export const filterOptInDeliveryDays = (deliveries) => {
    return deliveries.filter(delivery => {
        return delivery['can_opt_in'];
    }).map(delivery => {
        return {
            date: delivery['datetime'],
            id: delivery['scheduled_order_id'],
        };
    });
};

export const filterUndeliverableDays = (deliveries) => {
    return deliveries.filter(delivery => {
        return delivery['is_undeliverable_date'];
    }).map(delivery => {
        return {
            date: delivery['datetime'],
        };
    });
};

export const filterDeliverableDays = (deliveries) => {
    return deliveries.filter(delivery => {
        return !delivery['is_undeliverable_date'];
    }).map(delivery => {
        return delivery['datetime'];
    });
};
