import store from '@/store';

export default function(to, from, next) {
    if (store.getters['account/getUserId']) {
        next();
    } else {
        store.commit('auth/updateIntendedUrl', to.path);
        next({ name: 'logout' });
    }
}
