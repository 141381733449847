import store from '@/store';
import router from '@/router';
import AuthService from '@/services/auth/AuthService';
import AuthRedirectService from '@/services/auth/AuthRedirectService';

export default class RedirectAfterAccessTokenExpiration {
    authService

    constructor() {
        this.authService = new AuthService();
    }

    handle() {
        if (store.getters['auth/isRefreshProcessing']) {
            return;
        }

        if (store.getters['auth/getRefreshTokenAttempts'] < 3) {
            store.commit('auth/updateRefreshProcessing', true);
            store.commit('auth/updateIntendedUrl', router.currentRoute.path);

            this.refreshToken();
        } else {
            store.dispatch('auth/logout');

            router.push({ name: 'logout' });
        }
    }

    handleRefreshTokenErrorResponse() {
        AuthRedirectService.redirectToLoginPage();
    }

    handleRefreshTokenSuccessResponse() {
        store.dispatch('auth/loginUser', store.getters['auth/getUserType']);

        this.redirectToIntendedUrl();
    }

    redirectToIntendedUrl() {
        router.push(store.getters['auth/isUserLoggedIn'], () => {});
    }

    refreshCanvasserToken() {
        this.authService.refreshCanvasser()
            .then(() => this.handleRefreshTokenSuccessResponse())
            .catch(() => this.handleRefreshTokenErrorResponse());
    }

    refreshCustomerToken() {
        this.authService.refresh()
            .then(() => this.handleRefreshTokenSuccessResponse())
            .catch(() => this.handleRefreshTokenErrorResponse());
    }

    refreshToken() {
        if (store.getters['auth/isCanvasser']) {
            this.refreshCanvasserToken();
        } else {
            this.refreshCustomerToken();
        }
    }
}
