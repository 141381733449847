import Vue from 'vue';
import Router from 'vue-router';
import RouterConfig from '@/config/Router';
import FaqRoutes from '@/routes/web/Faq';
import AuthRoutes from '@/routes/web/Auth';
import HomeRoutes from '@/routes/web/Home';
import Coupon from '@/routes/web/Coupon';
import { CANVASSER_TYPE } from '@/constants/Account';
import ErrorRoutes from '@/routes/web/Errors';
import AccountRoutes from '@/routes/web/Account';
import ExternalRoutes from '@/routes/web/External';
import RegisterRoutes from '@/routes/web/Register';
import SubscriptionRoutes from '@/routes/web/Subscriptions';
import ContentPagesRoutes from '@/routes/web/Content';
import CanvasserAuthRoutes from '@/routes/canvasser/Auth';
import DevelopmentRoutes from '@/routes/web/Development';
import CanvasserContentPagesRoutes from '@/routes/canvasser/Content';
import CanvasserRoutes from '@/routes/canvasser/Canvasser';
import RecommendAFriendRoutes from '@/routes/web/RecommendAFriend';
import marketingConfirmationRoutes from '@/routes/web/marketingConfirmation';
import GuestGiftingRoutes from '@/routes/web/GuestGifting';

Vue.use(Router);

let routes = [];

// Add either canvasser or main routes depending what mode is in the env
if (process.env.VUE_APP_MODE === CANVASSER_TYPE) {
    routes = routes.concat(
        CanvasserAuthRoutes,
        CanvasserRoutes,
        CanvasserContentPagesRoutes,
    );
} else {
    routes = routes.concat(
        Coupon,
        FaqRoutes,
        HomeRoutes,
        AuthRoutes,
        AccountRoutes,
        ExternalRoutes,
        RegisterRoutes,
        SubscriptionRoutes,
        ContentPagesRoutes,
        RecommendAFriendRoutes,
        marketingConfirmationRoutes,
        GuestGiftingRoutes,
    );
}

// Routes available only on development or UAT.
if (process.env.VUE_APP_ENV !== 'Production') {
    routes = routes.concat(DevelopmentRoutes);
}

// Error routes contain catch-all route for 404 error.
routes = routes.concat(ErrorRoutes);

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});

router.beforeEach(RouterConfig);

export default router;
