import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { exception } from '@/exceptions/Exceptions';
import { convertToSnakeCase, removeWhiteSpaces, replaceWhiteSpaces } from '@/helpers/StringHelpers';

export const createUrlQueryString = (parameters) => {
    if (!isObject(parameters) || isEmpty(parameters)) exception('Parameters is not a valid object.');

    return Object.keys(parameters)
        .map(function(key) {
            if (parameters[key] !== null) {
                return convertToSnakeCase(key) + '=' + parameters[key];
            }
        })
        .filter(function(option) {
            return option != null;
        })
        .join('&');
};

export const prepareUrl = (baseUrl, urlParameters) => {
    if (!isString(baseUrl) || isEmpty(baseUrl)) exception('Base url must be a string.');
    if (isEmpty(urlParameters)) exception('Url parameters can\'t be empty.');

    let url = baseUrl;

    // Find all the url parameter place holders e.g. $1, $2.
    let placeHolders = url.match(/\$\d/g);

    for (let index = 0; index < placeHolders.length; index++) {
        // remove all spaces from the string
        let replaceString = null;
        if (process.env.VUE_APP_COUNTRY_ISO.toUpperCase() === 'NLD') replaceString = isString(urlParameters[index]) ? replaceWhiteSpaces(urlParameters[index]) : urlParameters[index];
        else replaceString = isString(urlParameters[index]) ? removeWhiteSpaces(urlParameters[index]) : urlParameters[index];

        url = url.replace(placeHolders[index], replaceString);
    }

    return url;
};

export const url = (baseUrl, urlParameters, queryParameters = {}) => {
    let url = urlParameters ? prepareUrl(baseUrl, urlParameters) : baseUrl;

    if (isEmpty(queryParameters)) return url;

    // Create url query string.
    let queryString = createUrlQueryString(queryParameters);

    return url.concat('?', queryString);
};
