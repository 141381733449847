<template>
    <button @click="clicked" :type="buttonType" :disabled="processing || disabled" :class="btnClass" :style="{ borderRadius: buttonBorderRadius, fontWeight: buttonFontWeight, fontSize: buttonFontSize }">
        <slot v-if="!processing"/>
        <i v-else class="btn__loading-icon fa-spin"/>
        {{ buttonText }}
    </button>
</template>
<script>
export default {
    name: 'SubmitButton',
    props: {
        buttonType: {
            type: String,
            default: 'submit',
        },
        hoverable: {
            type: Boolean,
            default: true,
        },
        processing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        processingText: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'primary',
        },
        buttonBorderRadius: {
            type: String,
            default: '4px',
        },
        buttonFontSize: {
            type: String,
            default: '100%',
        },
        buttonFontWeight: {
            type: String,
            default: '400',
        },
    },
    computed: {
        buttonText() {
            return this.processing && this.processingText ? this.$t('SUBMIT_BUTTON_PLEASE_WAIT') : '';
        },
        btnClass() {
            return this.hoverable ? `btn btn--${this.disabled ? 'disabled' : this.color} btn--hoverable` : `btn btn--${this.disabled ? 'disabled' : this.color}`;
        },
    },
    methods: {
        clicked() {
            if (!this.processing) this.$emit('click');
        },
    },
};
</script>
