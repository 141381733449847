import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class CreateDraftGift {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = ApiEndpoints['draft.gifting.store'];
        payload.awc = this.getAwinCookie();

        if (payload.order_id) {
            return this.apiService.patch(apiUrl, payload);
        }

        return this.apiService.post(apiUrl, payload);
    }

    getAwinCookie() {
        const awinKey = `_aw_m_${process.env.VUE_APP_AWIN_KEY}` || `_aw_j_${process.env.VUE_APP_AWIN_KEY}` || `_aw_sn_${process.env.VUE_APP_AWIN_KEY}` || `aw${process.env.VUE_APP_AWIN_KEY}`;
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${awinKey}=`);

        return parts.length === 2 ? parts.pop().split(';').shift() : null;
    }
}
