import store from '@/store';

export default function(to, from, next) {
    if (store.getters['auth/isUser']) {
        next({ name: 'account.dashboard' });
    } else if (to.name === 'register.account' && !store.getters['auth/isCanvasser']) {
        next({ name: 'register.email' });
    } else {
        next();
    }
}
