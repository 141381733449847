import Login from '@/services/auth/authService/Login';
import Logout from '@/services/auth/authService/Logout';
import Refresh from '@/services/auth/authService/Refresh';
import CanvasserLogin from '@/services/auth/authService/CanvasserLogin';
import CanvasserRefresh from '@/services/auth/authService/CanvasserRefresh';

export default class AuthService {
    login(email, password) {
        return (new Login()).handle(email, password);
    }

    canvasserLogin(email, password) {
        return (new CanvasserLogin()).handle(email, password);
    }

    logout() {
        return (new Logout()).handle();
    }

    static frontendLogout() {
        return (new Logout()).frontendLogout();
    }

    refresh() {
        return (new Refresh()).handle();
    }

    refreshCanvasser() {
        return (new CanvasserRefresh()).handle();
    }
}
