import router from '@/router';
import { gtmConsent } from '@/helpers/GAHelpers';

const getDefaultState = () => {
    return {
        awcValue: null,
        cookiePreferences: JSON.parse(localStorage.getItem('cookie-preferences')),
        hasAnsweredCookieForm: JSON.parse(localStorage.getItem('cookie-preferences')),
        hasAgreedToCookies: JSON.parse(localStorage.getItem('cookie-preferences-agreed')) || false,
        scriptsMounted: {
            necessary: false,
            analytics: false,
            tracking: false,
            stripe: false,
            elevio: false,
            impact: false,
            awin: false,
            ga: false,
            gtm: false,
            gtm1: false,
            trustedshops: false,
            primer: false,
            chargebee: false,
            trustpilot: false,
            weAreEight: false,
            tikTok: false,
            mux: false,
            braintreePaypal: false,
            braintreeClient: false,
            braintreeDeviceData: false,
            ra: false,
            dixa: false,
            zendesk: false,
            attn: false,
            convert: false,
            facebookPixel: false,
        },
    };
};

const country = process.env.VUE_APP_COUNTRY_ISO;
const isZendeskEnabled = process.env.VUE_APP_ENABLE_ZENDESK === 'true';
const isDixaEnabled = process.env.VUE_APP_ENABLE_DIXA === 'true';

function gtag() {
    dataLayer.push(arguments);
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetScripts(state) {
            Object.assign(state, getDefaultState().scriptsMounted);
        },
        setAwcValue(state, payload) {
            state.awcValue = payload;
        },
        setCookiePreferences(state, payload) {
            state.cookiePreferences = payload;
            // state.hasAnsweredCookieForm = true;
            localStorage.setItem('cookie-preferences', JSON.stringify(payload));
        },
        setHasAgreedToCookies(state, payload) {
            state.hasAgreedToCookies = payload;
            localStorage.setItem('cookie-preferences-agreed', payload);
        },
        showCookieBanner(state) {
            state.hasAnsweredCookieForm = false;
        },
        hideCookieBanner(state) {
            state.hasAnsweredCookieForm = true;
        },
        setScriptMounted(state, script) {
            state.scriptsMounted[script] = true;
        },
        setTrustedShops(state, payload) {
            state.scriptsMounted.trustedshops = payload;
        },
    },
    actions: {
        async mountScripts({ state, dispatch }) {
            dispatch('mountGoogleTagManager');

            if (process.env.VUE_APP_GTM_KEY_1) dispatch('mountGoogleTagManager', process.env.VUE_APP_GTM_KEY_1);
            dispatch('mountImpact');
            dispatch('mountWeAreEight');
            dispatch('mountTikTok');

            isDixaEnabled ? dispatch('mountDixa') : dispatch('mountElevio');

            if (country === 'GBR' || country === 'USA') dispatch('mountTrustPilot');
            if (country === 'DEU' || country === 'NLD') dispatch('mountTrustedShops');

            if (country === 'GBR') {
                dispatch('mountAttentiveTag');
                dispatch('mountConvert');
                dispatch('mountRA');
                dispatch('mountFacebookPixel');
            }

            setTimeout(function() {
                if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent?.marketing) {
                    if (country === 'GBR') {
                        dispatch('mountGoogleAnalytics');
                    } else if (country === 'DEU') {
                        dispatch('mountConvert');
                        dispatch('mountRA');
                        dispatch('mountFacebookPixel');
                    }
                }
            }, 1000);
        },

        async mountConvert({ state, commit }) {
            if (!state.scriptsMounted.convert) {
                let convertTag = document.createElement('script');
                const CONVERT_TAG_ID = process.env.VUE_APP_CONVERT_KEY;
                convertTag.src = `https://cdn-4.convertexperiments.com/js/${CONVERT_TAG_ID}.js`;
                convertTag.type = 'text/javascript';
                document.getElementsByTagName('head')[0].appendChild(convertTag);
                commit('setScriptMounted', 'convert');
            }
        },

        async mountTrustedShops({ state, commit }) {
            if (!state.scriptsMounted.trustedshops && process.env.VUE_APP_TRUSTED_SHOPS_KEY) {
                (function() {
                    window._tsConfig = {
                        'yOffset': '0', /* offset from page bottom */
                        'variant': 'custom_reviews', /* default, reviews, custom, custom_reviews */
                        'customElementId': 'MyCustomTrustbadge', /* required for variants custom and custom_reviews */
                        'responsive': { 'variant': 'custom_reviews', 'customElementId': 'MyMobileCustomTrustbadge' },
                        'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
                        'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
                        'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
                        'disableResponsive': 'false', /* deactivate responsive behaviour */
                        'disableTrustbadge': 'false', /* deactivate trustbadge */
                    };
                    var _ts = document.createElement('script');
                    _ts.type = 'text/javascript';
                    _ts.charset = 'utf-8';
                    _ts.async = true;
                    _ts.src = `//widgets.trustedshops.com/js/${process.env.VUE_APP_TRUSTED_SHOPS_KEY}.js`;
                    var __ts = document.getElementsByTagName('script')[0];
                    __ts.parentNode.insertBefore(_ts, __ts);
                    commit('setScriptMounted', 'trustedshops');
                })();
            }
        },

        async mountStripe({ state, commit }) {
            if (!state.scriptsMounted.stripe) {
                let stripe = document.createElement('script');
                stripe.src = 'https://js.stripe.com/v3/';
                stripe.type = 'text/javascript';
                stripe.async = 1;
                document.getElementsByTagName('body')[0].appendChild(stripe);

                setTimeout(() => {
                    commit('setScriptMounted', 'stripe');
                }, 1000);
            }
        },

        async mountTrustPilot({ state, commit }) {
            if (!state.scriptsMounted.trustpilot) {
                let trustpilot = document.createElement('script');
                trustpilot.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
                trustpilot.type = 'text/javascript';
                trustpilot.async = 1;
                document.getElementsByTagName('body')[0].appendChild(trustpilot);
                commit('setScriptMounted', 'trustpilot');
            }
        },

        async mountGoogleAnalytics({ state, commit }) {
            if (process.env.VUE_APP_ENABLE_TRACKING === 'true' && !state.scriptsMounted.ga) {
                let googleTag = document.createElement('script');
                const GOOGLE_TAG_ID = process.env.VUE_APP_GA_KEY;
                googleTag.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`;
                googleTag.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(googleTag);
                commit('setScriptMounted', 'ga');

                window.GA_TRACKING_ID = GOOGLE_TAG_ID;
                window.dataLayer = window.dataLayer || [];

                gtag('js', new Date());
                gtag('config', GA_TRACKING_ID, { app_name: 'Freddies Flowers Web App', send_page_view: false });

                router.afterEach((to) => {
                    gtag('config', window.GA_TRACKING_ID, {
                        page_path: to.fullPath,
                        app_name: 'Freddies Flowers Web App',
                        send_page_view: true,
                    });
                });

                router.afterEach((to) => {
                    dataLayer.push({
                        path: to.fullPath,
                    });
                });
            }
        },

        async mountGoogleTagManager({ state, commit }, gtmKey = process.env.VUE_APP_GTM_KEY) {
            if (process.env.VUE_APP_ENABLE_TRACKING === 'true' && ((!state.scriptsMounted.gtm && process.env.VUE_APP_GTM_KEY) || (!state.scriptsMounted.gtm1 && process.env.VUE_APP_GTM_KEY_1))) {
                (function(w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js',
                    });
                    var f = d.getElementsByTagName(s)[0];
                    var j = d.createElement(s);
                    var dl = l !== 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', gtmKey);
                if (gtmKey === process.env.VUE_APP_GTM_KEY) commit('setScriptMounted', 'gtm');
                if (gtmKey === process.env.VUE_APP_GTM_KEY_1) commit('setScriptMounted', 'gtm1');
                gtmConsent('default', {
                    ad_storage: 'denied',
                    analytics_storage: 'denied',
                    functionality_storage: 'denied',
                    personalization_storage: 'denied',
                });
                let cookiePreferences = JSON.parse(localStorage.getItem('cookie-preferences'));
                if (cookiePreferences) {
                    gtmConsent('update', {
                        ad_storage: cookiePreferences.tracking ? 'granted' : 'denied',
                        analytics_storage: cookiePreferences.analytics ? 'granted' : 'denied',
                        functionality_storage: cookiePreferences.necessary ? 'granted' : 'denied',
                        personalization_storage: cookiePreferences.preferences ? 'granted' : 'denied',
                    });
                }

                router.afterEach((to) => {
                    dataLayer.push({ 'event': 'optimize.activate' });
                });
            }
        },

        async mountRokt() {
            (function(r, o, k, t, n, e, w, a, _) {
                r._ROKT_ = n;
                r[n] = r[n] || {
                    id: t,
                    h: w,
                    lc: [],
                    it: new Date(),
                    onLoaded: (c) => {
                        r[n].lc.push(c);
                    },
                };
                a = o.createElement('script');
                a.type = 'text/javascript';
                a.async = !0;
                a.src = k;
                if (e) {
                    a.integrity = e;
                    a.crossOrigin = 'anonymous';
                }
                _ = o.getElementsByTagName('script')[0];
                _.parentNode.insertBefore(a, _);
            })(window, document, 'https://apps.rokt.com/wsdk/integrations/snippet.js', process.env.VUE_APP_ROKT_API_KEY, 'rokt');

            rokt.onLoaded((rokt) => {
                rokt.init({
                    skipInitialSelection: true,
                });
            });
        },

        async mountImpact({ state, commit }) {
            if (!state.scriptsMounted.impact) {
                (
                    function(a, b, c, d, e, f, g) {
                        e['ire_o'] = c;
                        e[c] = e[c] || function() {
                            (e[c].a = e[c].a || []).push(arguments);
                        };
                        f = d.createElement(b);
                        g = d.getElementsByTagName(b)[0];
                        f.async = 1;
                        f.src = a;
                        g.parentNode.insertBefore(f, g);
                    }
                )(`https://d.impactradius-event.com/${process.env.VUE_APP_IMPACT_RADIOUS_SCRIPT}.js`, 'script', 'ire', document, window);
                ire('identify', { customerid: '', customeremail: '' });
                commit('setScriptMounted', 'impact');
            }
        },

        async mountDWIN({ state, commit }) {
            if (!state.scriptsMounted.awin && process.env.VUE_APP_AWIN_KEY) {
                var awMastertag = document.createElement('script');
                awMastertag.setAttribute('defer', 'defer');
                awMastertag.src = `https://www.dwin1.com/${process.env.VUE_APP_AWIN_KEY}.js`;
                awMastertag.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(awMastertag);
                commit('setScriptMounted', 'awin');
            }
        },

        async mountPrimer({ state, commit }) {
            if (!state.scriptsMounted.primer) {
                var primer = document.createElement('script');
                primer.src = `https://assets.primer.io/primer-sdk-web/v1-latest/Primer.min.js`;
                primer.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(primer);
                commit('setScriptMounted', 'primer');
            }
        },

        async mountChargebee({ state, commit }) {
            if (!state.scriptsMounted.chargebee) {
                var chargebee = document.createElement('script');
                chargebee.src = `https://js.chargebee.com/v2/chargebee.js`;
                chargebee.type = 'text/javascript';
                chargebee.setAttribute('data-cb-domain', process.env.VUE_APP_CHARGEBEE_ENVIRONMENT);
                document.getElementsByTagName('body')[0].appendChild(chargebee);
                commit('setScriptMounted', 'chargebee');
            }
        },

        async mountWeAreEight({ state, commit }) {
            if (!state.scriptsMounted.weAreEight) {
                !(function(e, t, i, n, s, c, a) {
                    e[n] ||
                    (((s = e[n] = function() {
                        s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
                    }).queue = []),
                    (s.t = +new Date()),
                    ((c = t.createElement(i)).async = 1),
                    (c.src = 'https://pixel.weare8.com/eightpixel.min.js?t=' + 864e5 * Math.ceil(new Date() / 864e5)),
                    (a = t.getElementsByTagName(i)[0]).parentNode.insertBefore(c, a));
                // eslint-disable-next-line no-sequences
                })(window, document, 'script', 'eight'), eight('init', '623b6fef-5385-4e27-bc95-8947ee594043'), eight('event', 'pageView');
                commit('setScriptMounted', 'weAreEight');
            }
        },

        async mountTikTok({ state, commit }) {
            if (!state.scriptsMounted.weAreEight) {
                !(function(w, d, t) {
                    w.TiktokAnalyticsObject = t;
                    var ttq = w[t] = w[t] || [];
                    // eslint-disable-next-line no-sequences
                    ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie'],
                    ttq.setAndDefer = function(t, e) {
                        t[e] = function() {
                            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                        };
                    };
                    for (var i = 0; i < ttq.methods.length; i++) {
                        ttq.setAndDefer(ttq, ttq.methods[i]);
                    }
                    ttq.instance = function(t) {
                        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) { ttq.setAndDefer(e, ttq.methods[n]); }
                        return e;
                    // eslint-disable-next-line no-sequences
                    }, ttq.load = function(e, n) {
                        var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
                        // eslint-disable-next-line no-sequences
                        ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date(), ttq._o = ttq._o || {}, ttq._o[e] = n || {};
                        // eslint-disable-next-line no-sequences
                        var o = document.createElement('script'); o.type = 'text/javascript', o.async = !0, o.src = i + '?sdkid=' + e + '&lib=' + t;
                        var a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(o, a);
                    };

                    ttq.load('C40JT9CVE57P75FJSF9G');
                    ttq.page();
                }(window, document, 'ttq'));
            }
        },

        async mountMUX({ state, commit }) {
            if (!state.scriptsMounted.mux) {
                var mux = document.createElement('script');
                mux.setAttribute('defer', 'defer');
                mux.src = 'https://cdn.jsdelivr.net/npm/@mux/mux-player';
                mux.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(mux);
                commit('setScriptMounted', 'mux');
            }
        },

        async mountBrainTreePayPal({ state, commit }) {
            if (!state.scriptsMounted.braintreePaypal) {
                var braintreePaypal = document.createElement('script');
                braintreePaypal.src = `https://js.braintreegateway.com/web/3.94.0/js/paypal-checkout.min.js`;
                braintreePaypal.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(braintreePaypal);
                commit('setScriptMounted', 'braintreePaypal');
            }
        },

        async mountBrainTreeClient({ state, commit }) {
            if (!state.scriptsMounted.braintreeClient) {
                var braintreeClient = document.createElement('script');
                braintreeClient.src = `https://js.braintreegateway.com/web/3.94.0/js/client.min.js`;
                braintreeClient.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(braintreeClient);
                commit('setScriptMounted', 'braintreeClient');
            }
        },

        async mountBrainTreeDeviceData({ state, commit }) {
            if (!state.scriptsMounted.braintreeClient) {
                var braintreeDeviceData = document.createElement('script');
                braintreeDeviceData.src = `https://js.braintreegateway.com/web/3.94.0/js/data-collector.min.js`;
                braintreeDeviceData.type = 'text/javascript';
                document.getElementsByTagName('body')[0].appendChild(braintreeDeviceData);
                commit('setScriptMounted', 'braintreeDeviceData');
            }
        },

        async mountAttentiveTag({ state, commit }) {
            if (!state.scriptsMounted.attn) {
                var attn = document.createElement('script');
                attn.src = `https://cdn.attn.tv/freddiesflowers-gb/dtag.js`;
                attn.type = 'text/javascript';
                attn.async = true;
                document.getElementsByTagName('body')[0].appendChild(attn);
                commit('setScriptMounted', 'attn');
            }
        },

        async mountRA({ state, commit }) {
            if (!state.scriptsMounted.ra) {
                var __raconfig = __raconfig || {};
                __raconfig.uid = process.env.VUE_APP_RULER_KEY;
                __raconfig.action = 'track';
                window.__raconfig = __raconfig;
                (function() {
                    var ra = document.createElement('script');
                    ra.type = 'text/javascript';
                    ra.src = 'https://ruler.nyltx.com/lib/1.0/ra-bootstrap.min.js';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ra, s);
                }());
                commit('setScriptMounted', 'ra');
            }
        },

        async mountDixa({ state, commit }) {
            if (!state.scriptsMounted.dixa) {
                (function(w, d, s) {
                    if (w._dixa_) return;
                    w._dixa_ = {
                        invoke: function(m, a) {
                            w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
                            w._dixa_.pendingCalls.push([m, a]);
                        },
                        addListener: function(event, listener) {
                            w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
                            w._dixa_.pendingAddListenerCalls.push([event, listener]);
                        },
                    };
                    s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.setAttribute('charset', 'utf-8');
                    s.async = true;
                    s.src = 'https://messenger.dixa.io/bootstrap.js';
                    var before = d.getElementsByTagName('script')[0];
                    before.parentNode.insertBefore(s, before);
                })(window, document);
                _dixa_.invoke('init', { messengerToken: process.env.VUE_APP_DIXA_MESSENGER_TOKEN });
                commit('setScriptMounted', 'dixa');
            }
        },

        async mountElevio({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (!state.scriptsMounted.elevio) {
                    !(function(e, l, v, i, o, n) { e[i] || (e[i] = {}), e[i].account_id = n; var g, h; g = l.createElement(v), g.type = 'text/javascript', g.async = 1, g.src = o + n, h = l.getElementsByTagName(v)[0], h.parentNode.insertBefore(g, h); e[i].q = []; e[i].on = function(z, y) { e[i].q.push([z, y]); }; }(window, document, 'script', '_elev', 'https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=', process.env.VUE_APP_ELEVIO_KEY));
                    commit('setScriptMounted', 'elevio');
                }
            });
        },

        async mountZenDesk({ state, commit }) {
            // returns as a promise in order to call the zE functions after script has loaded
            return new Promise((resolve, reject) => {
                if (!state.scriptsMounted.zendesk) {
                    let zendesk = document.createElement('script');
                    zendesk.src = 'https://static.zdassets.com/ekr/snippet.js?key=38e0af3b-899e-470e-9b97-b5daaf7b2655';
                    zendesk.type = 'text/javascript';
                    zendesk.id = 'ze-snippet';
                    zendesk.async = 1;
                    zendesk.onload = resolve;
                    zendesk.onerror = reject;
                    document.getElementsByTagName('body')[0].appendChild(zendesk);
                    commit('setScriptMounted', 'zendesk');
                } else {
                    resolve();
                }
            });
        },

        async mountFacebookPixel({ state, commit }) {
            if (!state.scriptsMounted.facebookPixel) {
                !(function(f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function() {
                        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));

                commit('setScriptMounted', 'facebookPixel');
            }
        },
    },
    getters: {
        getAllowsAnalytics: (state) => state.cookiePreferences && state.cookiePreferences?.analytics,

        getAwcValue: (state) => state.awcValue,

        getCookiePreferences: (state) => state.cookiePreferences,

        getHasAgreedToCookies: state => state.hasAgreedToCookies,

        getHasAnsweredCookieForm: (state) => state.hasAnsweredCookieForm,

        getTrustpilotMounted: (state) => state.scriptsMounted.trustpilot,

        isStripeMounted: state => state.scriptsMounted.stripe,

        isMuxPlayer: state => state.scriptsMounted.mux,

        isGAMounted: state => state.scriptsMounted.ga,

        isGTMMounted: state => state.scriptsMounted.gtm,

        isGTMMounted1: state => state.scriptsMounted.gtm1,

        isRulerMounted: state => state.scriptsMounted.ra,

        isZendeskMounted: state => state.scriptsMounted.zendesk,

        isDixaMounted: state => state.scriptsMounted.dixa,

        isElevioMounted: state => state.scriptsMounted.elevio,
    },
};
