import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class ContinueSubscription {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, payload) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.continue'], [subscriptionId]);
        let data = payload ? { coupon_code: payload.coupon_code } : null;

        return this.apiService.patch(apiUrl, data);
    }
}
