module.exports = {
    'gifting.store': 'customers/gifts',
    'draft.gifting.store': 'customers/draft-gift-order',
    'gifting.prices.index': 'gifts/prices',
    'gifting.delivery-dates': 'gifts/delivery-dates',
    'gifting.incomplete.create': 'user/$1/incomplete-giftings',
    'gifting.incomplete.update': 'user/$1/incomplete-giftings/$2',
    'gifting.delivery-days.index': 'zones/deliverable-days',
    'gifting.delivery-caps': 'gifts/delivery-caps',
    'guest.gifting.signup': 'guest-signup',
    'guest.gifting.update': 'guest-signup/$1',
    'guest.gifting.products': 'gift-products',
    'delivery-options': 'delivery-options',
    'gift-message': 'gifting/gift-message/$1',
};
