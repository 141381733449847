import GiftingMiddleware from '@/middleware/GiftingMiddleware';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

const i18n = require('@/i18n').default;

export default [
    {
        path: routesMap[country].chooseYourGift,
        name: 'account.gifting.choose-your-gift',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/ChooseGiftType.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_CHOOSE'),
            },
        },
    },
    {
        path: routesMap[country].numberOfDeliveries,
        name: 'account.gifting.number-of-deliveries',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/NumberOfDeliveries.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_NUMBER_DELIVERIES'),
            },
        },
    },
    {
        path: routesMap[country].deliveryAddress,
        name: 'account.gifting.delivery-address',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/DeliveryAddress.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_DELIVERY_ADDRESS'),
            },
        },
    },
    {
        path: routesMap[country].freddiesAddons,
        name: 'account.gifting.freddies-addons',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/FreddiesAddons.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_EXTRAS'),
            },
        },
    },
    {
        path: routesMap[country].freddiesshop,
        name: 'account.gifting.freddies-shop',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/FreddiesShop.vue'),
        meta: {
            navigation: {
                title: 'Extras!',
            },
        },
    },
    {
        path: routesMap[country].deliveryDay,
        name: 'account.gifting.delivery-day',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/DeliveryDay.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_DELIVERY_DATE'),
            },
        },
    },
    {
        path: routesMap[country].giftCard,
        name: 'account.gifting.gift-card',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/GiftCard.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_PERSONALISED_CARD'),
            },
        },
    },
    {
        path: routesMap[country].payment,
        name: 'account.gifting.payment',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/Payment.vue'),
        meta: {
            navigation: {
                title: i18n.t('GIFTING_NAV_PAYMENT_DETAILS'),
            },
        },
    },
    {
        path: routesMap[country].thankYou,
        name: 'account.gifting.thank-you',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/SaveGiftingReminder' + country + '.vue'),
        meta: {
            navigation: {
                title: i18n.t('CONFIRMATION_DONE'),
            },
        },
    },
    {
        // UK specific step
        path: routesMap[country].chooseBoxType,
        name: 'account.gifting.choose-your-box-type',
        beforeEnter: GiftingMiddleware,
        component: () => import(/* webpackChunkName: "gifting" */ '@/views/account/gifting/ChooseYourBoxType.vue'),
        meta: {
            navigation: {
                title: 'Choose a size',
            },
        },
    },
];
