<template>
    <div>
        <modal :allow-cancel="false" :enableCloseButton="false" :allowEscape="false" class="text-center">

            <div class="container">
                <p class="title" v-text="$t('COUNTRY_SWITCH_TITLE')"></p>
                <p v-if="appLocation && userLocation" class="content" v-text="$t('COUNTRY_SWITCH_CONTENT', {from: appLocation.content_replace[selectedLocale], to: userLocation.content_replace[selectedLocale]})"></p>

                <div v-if="appLocation && userLocation" class="flags switch-container">
                    <a @click="clicked" :href="userLocation.link" class="left switch-link">
                        <span class="flag-icon switch-icon" :class="userLocation.icon"></span>
                        <p class="switch-text" v-text="$t('COUNTRY_SWITCH_REDIRECT', {siteName: userLocation.content_replace[selectedLocale]})"></p>
                    </a>

                    <div @click="clicked" :href="appLocation.link" class="right selected">
                        <span class="flag-icon switch-icon" :class="appLocation.icon"></span>
                        <p class="switch-text" v-text="$t('COUNTRY_SWITCH_PROCEED', {from: appLocation.content_replace[selectedLocale]})"></p>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Modal from '@/components/structure/Modal';

export default {
    name: 'CountrySwitchModal',
    props: {
        appLocation: null,
        userLocation: null,
    },
    components: {
        Modal,
    },
    computed: {
        selectedLocale() {
            return this.$root.$i18n.locale.split('-')[1];
        },
    },
    methods: {
        ...mapMutations({
            countrySwitchHasAnswered: 'account/countrySwitchHasAnswered',
        }),
        clicked() {
            this.countrySwitchHasAnswered(true);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/brand/theme.scss';

.text-center {
    text-align: center;
}
.title {
    font-family: $primary-font;
    font-size: 1.25rem;
}
content {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.switch-container {
    display: flex;
    justify-content: center;
}
.switch-link {
    padding-right: 1rem;
    cursor: pointer;
}
.switch-icon {
    font-size: 2rem;
}
.switch-text {
    font-size: 0.875rem;
}
.selected {
    padding-left: 1rem;
    cursor: pointer;
}
</style>
