import { url } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class GetAllSchedules {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId) {
        let apiUrl = url(ApiEndpoints['schedules.index.gbr'], [subscriptionId]);
        // if (process.env.VUE_APP_COUNTRY_ISO !== 'GBR') apiUrl = url(ApiEndpoints['schedules.index']);

        return this.apiService.get(apiUrl);
    }
}
