<template>
    <div id="upgrade-in-top" class="upgrade-in--container">
        <div class="upgrade-in--container--top-section">
            <div style="text-align: left; margin-bottom: 13px">
                <h3 class="upgrade-in--container--semi-bold">{{ getDashboardOverlayBanner.header }}</h3>
            </div>
            <button class="btn-green" :class="{ 'btn--secondary-black btn-outline' : isOpen }" @click="toggleOpen">
                {{actionCTAText}}
            </button>
        </div>
        <div class="upgrade-in--container--body-section">
            <img class="upgrade-in--container--body-section--img" :src="getDashboardOverlayBanner.image">
          <div class="icon-row" :class="{ 'body-text' : isBannerBody }">
            <div class="class-icon">
                <p v-if="isBannerBody && !isBenefits">{{ isBannerBody }}</p>
                <div class="icon-witdh" v-for="( benefit, index ) in benefitItems1" :key="`benifit-${index}`">
                    <img :src="benefit.icon" alt="icon">
                <div class="sub-para">
                    <p class="text-class">{{ benefit.text }}</p>
                    <p v-if="benefit.sub_text" class="sub-text">{{ benefit.sub_text}}</p>
                </div>
                </div>
            </div>
            <div class="class-icon">
                <div class="icon-witdh" v-for="(benefit, index) in benefitItems2" :key="`benifit-${index}`">
                <img :src="benefit.icon" alt="icon">
                <div class="sub-para">
                    <p class="text-class">{{ benefit.text }}</p>
                    <p v-if="benefit.sub_text" class="sub-text">{{ benefit.sub_text}}</p>
                </div>
                </div>
            </div>
          </div>
            <submit-button
                class="btn btn-full btn--primary-black mb-2 mt-2 btn-green"
                :processing="isProcessing"
                @click="goTo(getDashboardOverlayBanner.cta_url)">
                    {{ getDashboardOverlayBanner.cta }}
            </submit-button>
            <p class="text-para">{{ getDashboardOverlayBanner.banner_handler }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubmitButton from '@/components/forms/buttons/SubmitButton';

export default {
    name: 'ShowstopperUpgrade',
    components: { SubmitButton },
    data() {
        return {
            isOpen: false,
            isProcessing: false,
        };
    },
    mounted() {
        this.$root.$on('bottom-sheet-state', this.toggleOpen);
    },
    computed: {
        ...mapGetters({
            getDashboardOverlayBanner: 'account/getDashboardOverlayBanner',
        }),
        actionCTAText() {
            return !this.isOpen ? this.$t('OPT_IN_SEE_MORE') : this.$t('OPT_IN_HIDE');
        },
        benefitItems1() {
            return this.isBenefits?.slice(0, 2);
        },
        benefitItems2() {
            return this.isBenefits?.slice(2, 4);
        },
        isBannerBody() {
            return this.getDashboardOverlayBanner?.body;
        },
        isBenefits() {
            return this.getDashboardOverlayBanner?.benefits;
        },
    },
    methods: {
        toggleOpen(payload) {
            if (this.isOpen || payload === 'half') {
                this.isOpen = false;
                this.$root.$emit('close-bottom-sheet');
            } else {
                this.isOpen = true;
                this.$root.$emit('open-bottom-sheet');
            }
        },
        scrollToDiv() {
            let optInDiv = document.getElementById('upgrade-in-top');
            optInDiv.scrollIntoView({ behavior: 'smooth' }, true);
        },
        goTo(path) {
            return this.$router.push(path);
        },
    },
    watch: {
        isOpen: {
            handler(val) {
                if (!val) this.scrollToDiv();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';
    .upgrade-in {
        &--container {
            display: flex;
            flex-direction: column;
            padding: 11px;
            text-align: center;
            width: 100%;
            &--top-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }
            &--body-section {
                display: flex;
                flex-direction: column;
                &--img {
                    height: 16rem;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                }
            }
            &--semi-bold {
                @include body-bold();
            }
        }
    }
    .btn-green {
        background: $primary-color;
        color: $light-color;
        min-width: 89px;
        height: 48px;
        border-radius: 8px;
        outline: none;
        padding: 8px 0px 10px;
        @include button();
    }
    .btn-outline {
        background-color: $light-color;
        color: $primary-color;
        width: 89px;
        height: 48px;
    }
    .class-icon {
        display: flex;
        flex-direction: column;
    }
    .icon-row {
        display: flex;
        flex-direction: row;
        border: 1px solid $shade-dark-color;
        border-radius: 8px;
        min-height: 92px;
        display: flex;
        background-color: $shade-light-color;
        padding: 12px 28px;
        gap: 48px;
        margin: 12px 0px;
    }
    .icon-witdh {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
    .text-class {
        @include small-bold();
        width: max-content
    }
    .text-para {
        @include small();
        color: $shade-dark-color;
        margin-top: 0px;
    }
    .sub-text {
        @include small();
        color: $shade-dark-color;
        width: max-content;
        margin: -8px;
    }
    .sub-para {
        display: flex;
        flex-direction: column;
    }
    .body-text {
        width: 100%;
        gap: 30px;
    }
</style>
