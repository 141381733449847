import PaymentService from '@/services/payments/PaymentService';
import Countries from '@/resources/countries.json';
import { payments } from '@/helpers/PaymentHelpers';

let paymentService = new PaymentService();
const countryISO = process.env.VUE_APP_COUNTRY_ISO;
const defaultGateway = Countries.filter((country) => country.iso === countryISO)[0].stripe;

export default {
    namespaced: true,
    state: {
        paymentSources: [],
        selectedPaymentSource: null,
        stripeGateway: defaultGateway,
    },
    mutations: {
        setPaymentSources(state, payload) {
            state.paymentSources = payload;
        },
        setSelectedPaymentSource(state, payload) {
            state.selectedPaymentSource = payload;
        },
        setPaymentGateway(state, payload) {
            state.stripeGateway = payload;
        },
    },
    actions: {
        getGatewayFromKey({ commit }, key) {
            const gateway = payments.find(option => option.key === key).gateway[countryISO];
            commit('setPaymentGateway', gateway);
        },
        async fetchPaymentSources({ commit, state }, userId, forceReload = false) {
            return await new Promise((resolve, reject) => {
                paymentService.fetchPaymentSources(userId)
                    .then(response => {
                        commit('setPaymentSources', response.data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        async updatePaymentSource({ dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                paymentService.updatePaymentSource(payload.userId, payload.data)
                    .then((res) => {
                        dispatch('account/fetchUserData', true, { root: true });
                        resolve(res);
                    })
                    .catch((error) => reject(error));
            });
        },
    },
    getters: {
        getBillingAddress(state, getters, rootState, rootGetters) {
            let addresses = rootGetters['address/getAddresses'];

            return addresses ? addresses.find(address => address.is_billing === true) : null;
        },

        getCardEnding(state, getters, rootState, rootGetters) {
            let user = rootGetters['account/getUser'];

            return (user || {}).card_ending || null;
        },

        getSelectedPaymentSource: (state) => state.selectedPaymentSource,

        getPaymentSources: (state) => state.paymentSources.filter((item) => item.payment_source.status === 'valid'),

        getPaypalPaymentSources: (state) => state.paymentSources.filter((item) => item.payment_source.type === 'paypal_express_checkout'),

        getCardPaymentSources: (state) => state.paymentSources.filter((item) => item.payment_source.type === 'card'),

        hasCardAttached: (getters) => getters.getCardEnding !== null,

        is3DSEnabled: () => process.env.VUE_APP_SCA_ENABLED === 'true',

        getStripeGateway: (state) => state.stripeGateway,

        isPaypalEnabled: () => process.env.VUE_APP_ENABLE_PAYPAL === 'true',

    },
};
