import Vue from 'vue';
import { format } from 'date-fns';
import i18n from '@/i18n';
import store from '@/store';
import { isDateBefore, addDays, addWeeks } from '@/helpers/DateHelpers';
import * as giftConsts from '@/constants/Gifting';
import GiftingService from '@/services/gifting/GiftingService';
import PostcodeService from '@/services/logistics/PostcodeService';
import { PRODUCT_TYPE_MULTI, PRODUCT_TYPE_SINGLE, PRODUCT_TYPE_BUMPER_SINGLE, SPECIAL_DATES_GIFT_COUPON, PRODUCT_TYPE_POP_SINGLE, GIFT_TYPE_ONE_OFF, GIFT_TYPE_SUBSCRIPTION, PRODUCT_TYPE_VOUCHER } from '@/constants/Gifting';

const wreathProductId = process.env.VUE_APP_WREATH_ID;

const dayMap = [
    {
        day: 'Sunday',
        index: 0,
    },
    {
        day: 'Monday',
        index: 1,
    },
    {
        day: 'Tuesday',
        index: 2,
    },
    {
        day: 'Wednesday',
        index: 3,
    },
    {
        day: 'Thursday',
        index: 4,
    },
    {
        day: 'Friday',
        index: 5,
    },
    {
        day: 'Saturday',
        index: 6,
    },
];

const getDefaultState = () => {
    return {
        sections: {
            [giftConsts.SECTION_CHOOSE_A_GIFT]: false,
            [giftConsts.SECTION_CHOOSE_A_BOX_TYPE]: false,
            [giftConsts.SECTION_NUMBER_OF_DELIVERIES]: false,
            [giftConsts.SECTION_DELIVERY_ADDRESS]: false,
            [giftConsts.SECTION_FREDDIES_ADDONS]: false,
            [giftConsts.SECTION_FREDDIES_SHOP]: false,
            [giftConsts.SECTION_DELIVERY_DATE]: false,
            [giftConsts.SECTION_GIFT_CARD]: false,
        },
        giftAddressIdToEdit: null,
        incompleteGuestGiftingId: null,
        incompleteGuestGifting: {
            total_steps: null,
            current_step: null,
            data: {
                type: null,
                first_name: null,
                last_name: null,
                gift_note_url: null,
                paid_vase: null,
                frequency: null,
                number_of_months: null,
                gift_product_id: null,
                address: {
                    address_1: null,
                    address_2: null,
                    city: null,
                    country: null,
                    postcode: null,
                },
                delivery: {
                    delivery_date: null,
                    text_to_me: null,
                    telephone: null,
                },
                gift: {
                    from: null,
                    body: null,
                    to: null,
                },
            },
        },
        giftVoucherInfo: null,
        guestGifting: {
            additionalCoupon: null,
            selfGift: false,
            discountedPrice: 0,
            discountedAmount: 0,
            activeFamilyFilter: null,
            activeDateFilter: null,
            maxCalendarDate: null,
            deliveryPrice: null,
            selectedGiftProduct: null,
            selectedMultiPlan: null,
            selectedGiftDate: null,
            selectedGiftDateIsBooked: false,
            giftProducts: null,
            uid: null,
            userType: null,
            recipientFirstName: null,
            recipientLastName: null,
            senderEmail: null,
            recipientDeliveryComment: null,
            recipientPhoneNumber: null,
            senderPhoneNumber: null,
            addOns: [],
            gift_product_id: null,
            appliedGiftingCouponCode: null,
            guestGiftingCouponProperties: null,
            filteredPromotionCoupons: [],
            isPromotionCoupon: false,
            recipientDeliveryAddress: {
                selectedAddressId: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                postcode: null,
            },
            giftCreationPayload: null,
            stripeBillingAddress: {
                firstName: '',
                lastName: '',
                line1: null,
                line2: null,
                city: null,
                country: process.env.VUE_APP_COUNTRY_ISO,
                postal_code: null,
                state: null,
            },
            giftNote: {
                addStemToCard: true,
                selectedCover: null,
                giftNoteMessage: null,
                giftEventName: null,
                messageURL: null,
                messageType: null,
                assetId: null,
                playBackId: null,
                blobData: null,
            },
        },
        giftDetails: {
            additionalProducts: [],
            additionalProductsCost: 0,
            selectedBoxName: null,
            deliveryType: null,
            subscription: {
                frequency: null,
                numberOfMonths: 1,
                availableDeliveryDay: null,
            },
            addons: {
                paidVase: false,
                kultVase: false,
            },
            contact: {
                mobileNumber: null,
                notifier: 'You',
            },
            hasProcessed: false,
            id: null,
            giftReference: null,
            giftFriendCode: '',
            giftNoteUrl: null,
        },
        prices: {
            haveFetched: false,
            paidVase: null,
            kultVase: null,
            oneOff: null,
            subscription: null,
            oneOffBumper: null,
            popSingle: null,
            popMulti: null,
            subscriptionBumper: null,
        },
        totalPrice: null,
        productTypes: null,
        message: {
            to: null,
            body: null,
            from: null,
        },
        logistics: {
            firstDeliveryDateAvailable: null,
            deliveryDateSelected: null,
        },
        coupons: {
            multiGift: null,
            singleGift: null,
            specialDatesCoupons: null,
        },
        deliveryDays: [],
        deliveryOptions: [],
        deliveryCaps: [],
        paymentSources: [],
        incompleteGiftingId: null,
        incompleteGifting: {
            total_steps: null,
            current_step: null,
            data: {
                type: null,
                first_name: null,
                last_name: null,
                gift_note_url: null,
                paid_vase: null,
                kult_Vase: null,
                frequency: null,
                number_of_months: null,
                address: {
                    address_1: null,
                    address_2: null,
                    city: null,
                    country: null,
                    postcode: null,
                },
                delivery: {
                    delivery_date: null,
                    text_to_me: null,
                    telephone: null,
                },
                gift: {
                    from: null,
                    body: null,
                    to: null,
                },
            },
        },
        holidayBox: {
            showHolidayBoxModal: false,
        },
        orderedBoxName: null,
        products: [
            {
                id: 1,
                boxName: i18n.t('GIFTING_POP_BOX'),
                type: PRODUCT_TYPE_POP_SINGLE,
                description: i18n.t('GIFTING_POP_BOX_TEXT'),
                product_family: 'lite',
                image_url: 'https://freddiesflowers-com.s3.eu-west-2.amazonaws.com/images/gifting/Gifting-Web-Mobile-Pop%402x.jpg',
                enabled: false,
            },
            {
                id: 2,
                boxName: i18n.t('GIFTING_CLASSIC_BOX'),
                type: PRODUCT_TYPE_SINGLE,
                description: i18n.t('GIFTING_CLASSIC_BOX_TEXT'),
                product_family: 'standard',
                image_url: 'https://freddiesflowers-com.s3.eu-west-2.amazonaws.com/images/gifting/Gifting-Web-Mobile-Classic%402x.jpg',
                enabled: true,
            },
            {
                id: 3,
                boxName: i18n.t('GIFTING_SHOWSTOPPER_BOX'),
                type: PRODUCT_TYPE_BUMPER_SINGLE,
                description: i18n.t('GIFTING_SHOWSTOPPER_BOX_TEXT'),
                product_family: 'bumper',
                image_url: 'https://freddiesflowers-com.s3.eu-west-2.amazonaws.com/images/gifting/Gifting-Web-Mobile-SS%402x.jpg',
                enabled: true,
            },
        ],
        giftDeliveryDates: [],
        isBundle: false,
        draftGiftData: null,
        giftingPriceWithDiscount: null,
    };
};

let postcodeService = new PostcodeService();
let giftingService = new GiftingService();

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        setGiftAddressIdToEdit(state, payload) {
            state.giftAddressIdToEdit = payload;
        },

        setAddonVase(state, payload) {
            state.giftDetails.addons.paidVase = payload;
        },

        setSelectedBoxName(state, payload) {
            state.giftDetails.selectedBoxName = payload;
        },

        setDeliveryDateSelected(state, payload) {
            state.logistics.deliveryDateSelected = payload;
        },

        setDeliveryType(state, payload) {
            state.giftDetails.deliveryType = payload;
        },

        setDeliveryDays(state, payload) {
            state.deliveryDays = payload;
        },

        setDeliveryOptions(state, payload) {
            state.deliveryOptions = payload;
        },

        updateGiftingDeliveryCaps(state, payload) {
            state.deliveryCaps = payload;
        },

        setFirstDeliveryDateAvailable(state, payload) {
            state.logistics.firstDeliveryDateAvailable = payload;
        },

        setGiftNoteUrl(state, payload) {
            state.giftDetails.giftNoteUrl = payload;
        },

        setGiftProcessed(state, payload) {
            state.giftDetails.hasProcessed = true;
        },

        setGiftId(state, payload) {
            state.giftDetails.id = payload;
        },

        setGiftReference(state, payload) {
            state.giftDetails.giftReference = payload;
        },

        setGiftFriendCode(state, payload) {
            state.giftDetails.giftFriendCode = payload;
        },

        setGiftVoucherInfo(state, payload) {
            state.giftVoucherInfo = payload;
        },

        setMultiGiftCoupon(state, payload) {
            state.coupons.multiGift = payload;
        },

        setNotifier(state, payload) {
            state.giftDetails.contact.notifier = payload;
        },

        setNotifierNumber(state, payload) {
            state.giftDetails.contact.mobileNumber = payload;
        },

        setProductTypes(state, payload) {
            state.productTypes = payload;
        },

        setFirstName: (state, name) => state.guestGifting.stripeBillingAddress.firstName = name,

        setLastName: (state, name) => state.guestGifting.stripeBillingAddress.lastName = name,

        setProductPrices(state, payload) {
            payload.forEach(item => {
                switch (item.name) {
                case 'paidVase':
                    Vue.set(state.prices, 'paidVase', item.price);
                    break;
                case 'freddiesKultvase':
                    Vue.set(state.prices, 'kultVase', item.price);
                    break;
                case 'freddiesHurricaneVase':
                    Vue.set(state.prices, 'hurricaneVase', item.price);
                    break;
                case 'freddiesOpticVase':
                    Vue.set(state.prices, 'opticVase', item.price);
                    break;
                case 'oneDelivery':
                    Vue.set(state.prices, 'oneOff', item.price);
                    break;
                case 'multipleDeliveries':
                    Vue.set(state.prices, 'subscription', item.price);
                    break;
                case 'bumperMulti':
                    Vue.set(state.prices, 'subscriptionBumper', item.price);
                    break;
                case 'bumperSingle':
                    Vue.set(state.prices, 'oneOffBumper', item.price);
                    break;
                case 'popSingle':
                    Vue.set(state.prices, 'popSingle', item.price);
                    break;
                case 'popMulti':
                    Vue.set(state.prices, 'popMulti', item.price);
                    break;
                }
            });
        },

        setSingleGiftCoupon(state, payload) {
            state.coupons.singleGift = payload;
        },

        setSpecialDatesCoupon(state, payload) {
            state.coupons.specialDatesCoupons = payload;
        },

        setSectionComplete(state, payload) {
            state.sections[payload] = true;
        },

        setSubscriptionFrequency(state, payload) {
            state.giftDetails.subscription.frequency = payload;
        },

        setSubscriptionNumberOfMonths(state, payload) {
            state.giftDetails.subscription.numberOfMonths = payload;
        },

        updateMessageBody(state, payload) {
            state.message.body = payload;
        },

        updateMessageFrom(state, payload) {
            state.message.from = payload;
        },

        updateMessageTo(state, payload) {
            state.message.to = payload;
        },

        updateAvailableDeliveryDay(state, payload) {
            state.giftDetails.subscription.availableDeliveryDay = payload;
        },

        setincompleteGiftingId(state, payload) {
            state.incompleteGiftingId = payload;
        },

        setIncompleteGuestGiftingId(state, payload) {
            state.incompleteGuestGiftingId = payload;
        },

        updateTotalSteps(state, payload) {
            state.incompleteGifting.total_steps = payload;
        },

        updateCurrentStep(state, payload) {
            state.incompleteGifting.current_step = payload;
        },

        updateProducts(state, payload) {
            state.giftDetails.additionalProducts = payload;
        },

        updateProductsTotalCost(state) {
            let price = 0;

            state.giftDetails.additionalProducts.forEach(item => {
                price += parseFloat(item.price) * parseFloat(item.quantity);
            });
            state.giftDetails.additionalProductsCost = parseFloat(price.toFixed(2));
        },

        updateGiftingAddress(state, payload) {
            state.incompleteGifting.data.first_name = payload.first_name;
            state.incompleteGifting.data.last_name = payload.last_name;
            state.incompleteGifting.data.address.address_1 = payload.address1;
            state.incompleteGifting.data.address.address_2 = payload.address2;
            state.incompleteGifting.data.address.city = payload.city;
            state.incompleteGifting.data.address.country = payload.country;
            state.incompleteGifting.data.address.postcode = payload.postcode;
        },

        updateIncompleteGiftingData(state, payload) {
            state.incompleteGifting.data.type = state.giftDetails.deliveryType;
            state.incompleteGifting.data.delivery.delivery_date = state.logistics.deliveryDateSelected;
            state.incompleteGifting.data.delivery.text_to_me = state.giftDetails.contact.notifier === 'You';
            state.incompleteGifting.data.delivery.telephone = state.giftDetails.contact.mobileNumber;
            state.incompleteGifting.data.gift.from = state.message.from;
            state.incompleteGifting.data.gift.body = state.message.body;
            state.incompleteGifting.data.gift.to = state.message.to;
            state.incompleteGifting.data.gift_note_url = state.giftDetails.giftNoteUrl;
            state.incompleteGifting.data.paid_vase = state.giftDetails.addons.paidVase;
            state.incompleteGifting.data.frequency = state.giftDetails.subscription.frequency;
            state.incompleteGifting.data.number_of_months = state.giftDetails.subscription.numberOfMonths;
        },

        updateIncompleteGuestGiftingData(state, payload) {
            state.incompleteGuestGifting.data.type = state.isBundle ? GIFT_TYPE_SUBSCRIPTION : GIFT_TYPE_ONE_OFF;
            state.incompleteGuestGifting.data.first_name = state.guestGifting.firstName;
            state.incompleteGuestGifting.data.last_name = state.guestGifting.lastName;
            state.incompleteGuestGifting.data.delivery.delivery_date = store.getters['gifting/getFormattedSelectedGiftDate'];
            state.incompleteGuestGifting.data.delivery.text_to_me = state.guestGifting.senderPhoneNumber;
            state.incompleteGuestGifting.data.delivery.telephone = state.guestGifting.recipientPhoneNumber;
            state.incompleteGuestGifting.data.gift.from = state.guestGifting.senderEmail;
            state.incompleteGuestGifting.data.gift.body = state.guestGifting.giftNote.giftNoteMessage;
            state.incompleteGuestGifting.data.gift.to = state.guestGifting.recipientFirstName;
            state.incompleteGuestGifting.data.gift_note_url = state.guestGifting.giftNote.messageURL;
            state.incompleteGuestGifting.data.gift_product_id = state.guestGifting.selectedGiftProduct?.id;
            state.incompleteGuestGifting.data.address.address_1 = state.guestGifting.recipientDeliveryAddress.addressLine1;
            state.incompleteGuestGifting.data.address.address_2 = state.guestGifting.recipientDeliveryAddress.addressLine2;
            state.incompleteGuestGifting.data.address.city = state.guestGifting.recipientDeliveryAddress.city;
            state.incompleteGuestGifting.data.address.country = state.guestGifting.stripeBillingAddress.country;
            state.incompleteGuestGifting.data.address.postcode = state.guestGifting.recipientDeliveryAddress.postcode;
            state.incompleteGuestGifting.data.gifting_completed = state.giftDetails.giftReference ? 1 : 0;
        },
        setShowHolidayBoxModal(state, payload) {
            state.holidayBox.showHolidayBoxModal = payload;
        },

        setRecipientFirstName: (state, payload) => {
            state.guestGifting.recipientFirstName = payload;
        },

        setRecipientLastName: (state, payload) => {
            state.guestGifting.recipientLastName = payload;
        },

        setSenderEmail: (state, payload) => {
            state.guestGifting.senderEmail = payload;
        },

        setRecipientDeliveryAddress: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress = payload;
        },

        setGiftCreationPayload: (state, payload) => {
            state.guestGifting.giftCreationPayload = payload;
        },

        setStripeBillingAddress: (state, address) => {
            state.guestGifting.stripeBillingAddress = Object.assign(state.guestGifting.stripeBillingAddress, address);
        },

        setRecipientDeliveryComment: (state, payload) => {
            state.guestGifting.recipientDeliveryComment = payload;
        },

        setRecipientPhoneNumber: (state, payload) => {
            state.guestGifting.recipientPhoneNumber = payload;
        },

        setSenderPhoneNumber: (state, payload) => {
            state.guestGifting.senderPhoneNumber = payload;
        },

        addExtra: (state, payload) => {
            state.guestGifting.addOns.push(payload);
        },

        removeExtra: (state, payload) => {
            state.guestGifting.addOns = state.guestGifting.addOns.filter(item => item.title !== payload.title);
        },

        setGuestUserUid: (state, payload) => {
            state.guestGifting.uid = payload;
        },

        setGuestUserType: (state, payload) => {
            state.guestGifting.userType = payload;
        },

        setSelectedRecipientAddressId: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.selectedAddressId = payload;
        },

        setSelectedRecipientAddressLine1: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.addressLine1 = payload;
        },

        setSelectedRecipientAddressLine2: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.addressLine2 = payload;
        },

        setSelectedRecipientCity: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.city = payload;
        },

        setSelectedRecipientPostcode: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.postcode = payload;
        },

        setSelectedRecipientHouseNumber: (state, payload) => {
            state.guestGifting.recipientDeliveryAddress.houseNumber = payload;
        },

        setGiftProducts: (state, payload) => {
            state.guestGifting.giftProducts = payload;
        },

        setSelectedGiftProduct: (state, payload) => {
            state.guestGifting.selectedGiftProduct = payload;
        },

        setSelectedGiftDate: (state, payload) => {
            state.guestGifting.selectedGiftDate = payload;
        },

        setSelectedGiftDateIsBooked: (state, payload) => {
            state.guestGifting.selectedGiftDateIsBooked = payload;
        },

        setDeliveryPrice: (state, payload) => {
            state.guestGifting.deliveryPrice = payload;
        },

        setDiscountedPrice: async(state, payload) => {
            state.guestGifting.discountedPrice = payload.totalBoxesPrice - payload.discountAmount;
        },

        setDiscountedAmount: async(state, payload) => {
            state.guestGifting.discountedAmount = payload;
        },

        setOrderedBoxName(state, payload) {
            state.orderedBoxName = payload;
        },

        setGiftDeliveryDates(state, payload) {
            state.giftDeliveryDates = payload;
        },

        setAllGiftingData(state, payload) {
            state.guestGifting = payload;
        },

        setGiftNoteCover(state, payload) {
            state.guestGifting.giftNote.selectedCover = payload;
        },

        setGiftNoteMessage(state, payload) {
            state.guestGifting.giftNote.giftNoteMessage = payload;
        },

        setGiftEventName(state, payload) {
            state.guestGifting.giftNote.giftEventName = payload;
        },

        setAddStemToCard(state, payload) {
            state.guestGifting.giftNote.addStemToCard = payload;
        },

        setGiftNoteMessageURL(state, payload) {
            state.guestGifting.giftNote.messageURL = payload;
        },

        setGiftNoteMessageType(state, payload) {
            state.guestGifting.giftNote.messageType = payload;
        },

        setGiftNotePlayBackId(state, payload) {
            state.guestGifting.giftNote.playBackId = payload;
        },

        setGiftNoteAssetId(state, payload) {
            state.guestGifting.giftNote.assetId = payload;
        },

        setGiftNoteBlobData(state, payload) {
            state.guestGifting.giftNote.blobData = payload;
        },

        setMaxCalendarDate(state, payload) {
            state.guestGifting.maxCalendarDate = payload;
        },

        setTotalPrice(state, payload) {
            state.totalPrice = payload;
        },

        setActiveFamilyFilter(state, payload) {
            state.guestGifting.activeFamilyFilter = payload;
        },

        setActiveDateFilter(state, payload) {
            state.guestGifting.activeDateFilter = payload;
        },

        setGuestGiftingCouponProperties(state, payload) {
            state.guestGifting.guestGiftingCouponProperties = payload;
        },

        setFilteredPromotionCoupons(state, payload) {
            state.guestGifting.filteredPromotionCoupons = payload;
        },

        setAdditionalCoupon(state, payload) {
            state.guestGifting.additionalCoupon = payload;
        },

        setIsPromotionCoupon(state, payload) {
            state.guestGifting.isPromotionCoupon = payload;
        },

        setAppliedGiftingCouponCode(state, payload) {
            state.guestGifting.appliedGiftingCouponCode = payload;
        },
        setMultiGiftPlanOption(state, payload) {
            state.guestGifting.selectedMultiPlan = payload;
        },
        setSelfGift(state, payload) {
            state.guestGifting.selfGift = payload;
        },
        setIsBundle(state, payload) {
            state.isBundle = payload;
        },
        setDraftGiftData(state, payload) {
            state.draftGiftData = payload;
        },
        setGiftingPriceWithDiscount(state, payload) {
            state.giftingPriceWithDiscount = payload;
        },
    },
    actions: {

        setGuestData({ commit }) {
            return new Promise(function(resolve, reject) {
                let giftData = JSON.parse(localStorage.getItem('guest-gifting'));
                if (isDateBefore(new Date(), addDays(new Date(giftData.date), 2))) {
                    commit('setAllGiftingData', giftData.guestGifting);
                    resolve();
                } else reject(new Error('No gift data available'));
            });
        },

        async createGift({ commit }, payload) {
            return await new Promise(function(resolve, reject) {
                giftingService.createGift(payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },

        async createDraftGift({ commit }, payload) {
            return await new Promise(function(resolve, reject) {
                giftingService.createDraftGift(payload)
                    .then(response => {
                        commit('setDraftGiftData', response?.data?.properties);
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },

        async getGuestGiftProducts({ commit }, payload = null) {
            return await new Promise(function(resolve, reject) {
                giftingService.getGiftingProducts(payload)
                    .then(response => {
                        commit('setGiftProducts', response.data.gift_products);
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },

        async fetchAvailableDeliveryDay({ commit, state, rootGetters }, postcode = null) {
            if (!postcode) {
                let selectedAddress = rootGetters['address/getSelectedGiftingAddress'];

                postcode = selectedAddress.postcode;
            }
            return await new Promise(function(resolve, reject) {
                postcodeService.getAvailableDeliveryDay(postcode)
                    .then(response => {
                        commit('updateAvailableDeliveryDay', response.data.message);
                        store.commit('subscription/setProductTypes', response.data.products);
                    })
                    .catch(error => reject(error));
            });
        },

        async fetchFirstDeliveryDateAvailable({ commit, state, rootGetters }, forceReload = false) {
            if (state.prices.haveFetched === false || forceReload === true) {
                let selectedAddress = rootGetters['address/getSelectedGiftingAddress'];
                let postcode = selectedAddress.postcode;

                return await new Promise(function(resolve, reject) {
                    let date = postcodeService.legacyGetFirstDeliveryDayForPostcode(postcode);

                    if (date !== false) {
                        commit('setFirstDeliveryDateAvailable', date);
                    }

                    reject(date);
                });
            }
        },

        async fetchPrices({ commit, state }, forceReload = false) {
            if (state.prices.haveFetched === false || forceReload === true) {
                state.haveFetched = true;

                return await new Promise(function(resolve, reject) {
                    giftingService.fetchPrices()
                        .then(response => {
                            commit('setProductPrices', response.data.items);
                            commit('setProductTypes', response.data.items);
                            Vue.set(state.prices, 'haveFetched', true);
                        })
                        .catch(error => {
                            Vue.set(state.prices, 'haveFetched', false);
                            reject(error);
                        });
                });
            }
        },

        async fetchDeliveryDays({ commit, state }, forceReload = false) {
            return await new Promise(function(resolve, reject) {
                giftingService.fetchDeliveryDays()
                    .then(response => {
                        commit('setDeliveryDays', response.data.properties['delivery-days']);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        async fetchDeliveryOptions({ commit }) {
            return await new Promise(function(resolve, reject) {
                giftingService.fetchGiftDeliveryOptions()
                    .then(response => {
                        commit('setDeliveryOptions', response.data.data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        async getGiftingDeliveryCaps({ commit }, payload) {
            let service = new GiftingService();

            return new Promise(function(resolve, reject) {
                service.getGiftingDeliveryCaps(payload)
                    .then(response => {
                        commit('updateGiftingDeliveryCaps', response.data.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        fetchGiftingCoupons({ commit, rootGetters }) {
            const coupons = rootGetters['account/getCoupons'];
            const multiCoupon = coupons && coupons.multiGift;
            const singleCoupon = coupons && coupons.singleGift;
            const specialDatesCoupon = (coupons && coupons[SPECIAL_DATES_GIFT_COUPON]);
            commit('setMultiGiftCoupon', multiCoupon);
            commit('setSingleGiftCoupon', singleCoupon);
            commit('setSpecialDatesCoupon', specialDatesCoupon);
        },

        async fetchGiftDeliveryDate({ commit, state, rootGetters }) {
            return await new Promise(function(resolve, reject) {
                giftingService.fetchGiftDeliveryDates()
                    .then(response => {
                        commit('setGiftDeliveryDates', response.data.data);
                    })
                    .catch(error => reject(error));
            });
        },

        resetGifting({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        getGiftAddressIdToEdit: (state) => state.giftAddressIdToEdit,

        getAdditionalProducts: (state) => state.giftDetails.additionalProducts,

        getAdditionalProductsTotalCost: (state) => state.giftDetails.additionalProductsCost,

        getDeliverableDateNumber: (state) => {
            let date = state.logistics.firstDeliveryDateAvailable;

            return date !== null ? moment(date).format('d') : null;
        },

        getDeliverableDateString: (state) => {
            let date = state.logistics.firstDeliveryDateAvailable;

            return date !== null ? moment(date).format('dddd') : null;
        },

        getDeliveryDateSelected: (state) => state.logistics.deliveryDateSelected,

        getDeliveryType: (state) => state.giftDetails.deliveryType,

        getFrequencyForSubscription: (state) => state.giftDetails.subscription.frequency,

        getGiftMessage: (state) => {
            return {
                to: state.message.to,
                body: state.message.body,
                from: state.message.from,
            };
        },

        getGiftNoteUrl: (state) => state.giftDetails.giftNoteUrl,

        getGiftId: (state) => state.giftDetails.id,

        getGiftReference: (state) => state.giftDetails.giftReference,

        getGiftType: (state) => state.giftDetails.deliveryType,

        getNotifier: (state) => state.giftDetails.contact.notifier,

        getNotifierNumber: (state) => state.giftDetails.contact.mobileNumber,

        getMessageBody: (state) => state.message.body,

        getMessageFrom: (state) => state.message.from,

        getMessageTo: (state) => state.message.to,

        getMultiGiftCoupon: (state) => state.coupons.multiGift,

        getSelectedBoxName: (state) => state.giftDetails.selectedBoxName,

        getPricePerOneOffPop: (state) => state.prices.popSingle,

        getPricePerSubscriptionPop: (state) => state.prices.popMulti,

        getProductTypeIdFromName: (state, getters) => (name) => {
            if (name === null) name = getters.isSubscription ? PRODUCT_TYPE_MULTI : PRODUCT_TYPE_SINGLE;
            return state.productTypes?.filter((item) => item.name === name)[0]?.id;
        },

        getProductTypePriceFromName: (state, getters) => (name) => {
            if (name === null) name = getters.isSubscription ? PRODUCT_TYPE_MULTI : PRODUCT_TYPE_SINGLE;
            return state.productTypes?.filter((item) => item.name === name)[0]?.price;
        },

        getNumberOfDeliveriesForSubscription: (state, getters) => {
            if (getters.isSubscription) {
                let months = state.giftDetails.subscription.numberOfMonths;

                switch (getters.getFrequencyForSubscription) {
                case giftConsts.PLAN_WEEKLY:
                    return parseInt(months * (52 / 12));
                case giftConsts.PLAN_FORTNIGHTLY:
                    return parseInt(months * (26 / 12));
                case giftConsts.PLAN_MONTHLY:
                    return parseInt(months);
                default:
                    return 0;
                }
            }
        },

        isSubscription: (state, getters) => {
            return getters.getGiftType === giftConsts.GIFT_TYPE_SUBSCRIPTION;
        },

        getNumberOfMonthsForSubscription: (state) => state.giftDetails.subscription.numberOfMonths,

        getHavePricesFetched: state => state.prices.haveFetched,

        getSelectedVasePrice: (state) => state.giftDetails.selectedBoxName === PRODUCT_TYPE_BUMPER_SINGLE ? state.prices.hurricaneVase : state.prices.kultVase,

        getPaidVasePrice: (state) => state.prices.paidVase,

        getKultVasePrice: (state) => state.prices.kultVase,

        getHurricaneVasePrice: (state) => state.prices.hurricaneVase,

        getPricePerOneOffDelivery: (state) => state.prices.oneOff,

        getPricePerSubscriptionDelivery: (state) => state.prices.subscription,

        getPricePerOneOffBumperDelivery: (state) => state.prices.oneOffBumper,

        getPricePerSubscriptionBumperDelivery: (state) => state.prices.subscriptionBumper,

        getProductTypePriceFromId: (state) => (id) => {
            return state.productTypes?.filter((item) => item.id === id)[0].price;
        },

        getSingleGiftCoupon: (state) => state.coupons.singleGift,

        getSpecialDatesGiftCoupon: (state) => state.coupons.specialDatesCoupons,

        getIncompleteGifting: (state) => state.incompleteGifting,

        getIncompleteGiftingId: (state) => state.incompleteGiftingId,

        getIncompleteGuestGiftingId: (state) => state.incompleteGuestGiftingId,

        getIncompleteGuestGifting: (state) => state.incompleteGuestGifting,

        getSubscriptionMaximumDeliveryMonthsAllowed: () => 12,

        getSubscriptionMinimumDeliveryMonthsAllowed: (state, getters) => {
            switch (getters.getFrequencyForSubscription) {
            case giftConsts.PLAN_WEEKLY:
                return 1;
            case giftConsts.PLAN_FORTNIGHTLY:
                return 1;
            case giftConsts.PLAN_MONTHLY:
                return 3;
            default:
                return 1;
            }
        },

        getSubscriptionPredictedLastDeliveryDate: (state, getters) => {
            const frequency = getters.getFrequencyForSubscription;
            const count = getters.getNumberOfDeliveriesForSubscription;
            const deliveryDay = getters.getDeliveryDateSelected;

            let deliveryDate = null;
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            if (frequency !== null && count !== null && deliveryDay !== null) {
                switch (frequency) {
                case giftConsts.PLAN_WEEKLY:
                    deliveryDate = addWeeks(deliveryDay, count);
                    break;
                case giftConsts.PLAN_FORTNIGHTLY:
                    deliveryDate = addWeeks(deliveryDay, count * 2);
                    break;
                case giftConsts.PLAN_MONTHLY:
                    const deliveriesRemaining = count - 1;
                    const weeks = deliveriesRemaining * 4.33;
                    deliveryDate = addWeeks(deliveryDay, Math.round(weeks));
                    break;
                }
            }

            return !deliveryDate ? null : deliveryDate.toLocaleString(process.env.VUE_APP_DATE_LOCALE, options);
        },

        getTotalPriceForDeliveries: (state, getters) => {
            if (getters.isSubscription) {
                return getters.getNumberOfDeliveriesForSubscription * getters.getProductTypePriceFromName(state.giftDetails.selectedBoxName);
            }

            // If user selected one delivery, return one delivery price.
            return getters.getProductTypePriceFromName(state.giftDetails.selectedBoxName);
        },

        getBoxPrice: (state, getters) => {
            return parseInt(getters.getProductTypePriceFromName(state.giftDetails.selectedBoxName));
        },

        hasProcessed: state => state.giftDetails.hasProcessed,

        getDeliverableDate: (state) => {
            switch (state.giftDetails.subscription.availableDeliveryDay) {
            case 'Sunday':
                return 0;
            case 'Monday':
                return 1;
            case 'Tuesday':
                return 2;
            case 'Wednesday':
                return 3;
            case 'Thursday':
                return 4;
            case 'Friday':
                return 5;
            case 'Saturday':
                return 6;
            }
        },

        isGiftingEnabled: () => !!process.env.VUE_APP_GIFTING_ENABLED,

        isPaidVaseAttached: (state) => state.giftDetails.addons.paidVase === true,

        getShowHolidayBoxModal: (state) => state.holidayBox.showHolidayBoxModal,

        isSectionComplete: (state) => (section) => state.sections[section],

        getRecipientFirstName: (state) => state.guestGifting.recipientFirstName,

        getRecipientLastName: (state) => state.guestGifting.recipientLastName,

        getSenderEmail: (state) => state.guestGifting.senderEmail,

        getRecipientDeliveryAddress: (state) => state.guestGifting.recipientDeliveryAddress,

        getGiftCreationPayload: (state) => state.guestGifting.giftCreationPayload,

        getStripeBillingAddress: state => state.guestGifting.stripeBillingAddress,

        getRecipientDeliveryComment: (state) => state.guestGifting.recipientDeliveryComment,

        getRecipientPhoneNumber: (state) => state.guestGifting.recipientPhoneNumber,

        getSenderPhoneNumber: (state) => state.guestGifting.senderPhoneNumber,

        getUid: (state) => state.guestGifting.uid,

        getGuestUserType: (state) => state.guestGifting.userType,

        getSelectedRecipientAddressId: (state) => state.guestGifting.recipientDeliveryAddress.selectedAddressId,

        getSelectedRecipientAddressLine1: (state) => state.guestGifting.recipientDeliveryAddress.addressLine1,

        getSelectedRecipientAddressLine2: (state) => state.guestGifting.recipientDeliveryAddress.addressLine2,

        getSelectedRecipientCity: (state) => state.guestGifting.recipientDeliveryAddress.city,

        getSelectedRecipientPostcode: (state) => state.guestGifting.recipientDeliveryAddress.postcode,

        getSelectedRecipientHouseNumber: (state) => state.guestGifting.recipientDeliveryAddress.houseNumber,

        getGuestGiftProducts: (state) => (type) => {
            return state.guestGifting.giftProducts?.filter(item => (item.type_of_product !== PRODUCT_TYPE_VOUCHER && item.name !== 'Love, Amaryllis') && item.is_bundle === type);
        },

        getGuestGiftSpecialProducts: (state) => (occasion) => {
            return state.guestGifting.giftProducts?.filter(item => item.is_special_product && item.product_categories.includes(occasion));
        },

        getGuestGiftProductById: (state) => (id) => {
            return state.guestGifting.giftProducts?.find(item => item.id === id);
        },

        getSelectedGiftProduct: (state) => state.guestGifting.selectedGiftProduct,

        getSelectedGiftDate: (state) => state.guestGifting.selectedGiftDate,

        getFormattedSelectedGiftDate: (state) => {
            return format(new Date(state.guestGifting.selectedGiftDate), 'yyyy-MM-dd');
        },

        getSelectedGiftDateIsBooked: (state) => state.guestGifting.selectedGiftDateIsBooked,

        getGiftProductById: (state) => (id) => {
            return state.guestGifting.giftProducts?.find(item => item.id === id) || {};
        },

        getAllGiftProducts: (state) => state.guestGifting.giftProducts?.filter(item => item.name !== 'Love, Amaryllis'),

        getGuestGiftProductTypes: (state) => {
            let productTypes = [];
            state.guestGifting.giftProducts?.map(item => productTypes.push(item.products_family_display_name));
            return productTypes.filter((value, index, self) => self.indexOf(value) === index);
        },

        getGiftProducts: (state, getters) => {
            let products = state.products;
            products.map(item => {
                if (item.type === PRODUCT_TYPE_POP_SINGLE) {
                    item.enabled = store.getters['account/getPopSingleGift'];
                }
            });
            return products;
        },

        getOrderedBoxName: (state) => state.orderedBoxName,

        getGiftDeliveryDates: (state) => state.giftDeliveryDates,

        getDeliveryPrice: (state) => state.guestGifting?.deliveryPrice,

        getDiscountedPrice: (state) => state.guestGifting.discountedPrice,

        getDiscountedAmount: (state) => state.guestGifting.discountedAmount,

        getAddOns: (state) => state.guestGifting.addOns,

        getAllGiftingData: (state) => state.guestGifting,

        getGiftNoteCover: (state) => state.guestGifting.giftNote.selectedCover,

        getGiftNoteMessage: (state) => state.guestGifting.giftNote.giftNoteMessage,

        getGiftEventName: (state) => state.guestGifting.giftNote.giftEventName,

        getAddStemToCard: (state) => state.guestGifting.giftNote.addStemToCard,

        getGiftNoteMessageURL: (state) => state.guestGifting.giftNote.messageURL,

        getGiftNoteMessageType: (state) => state.guestGifting.giftNote.messageType,

        getGiftNotePlayBackId: (state) => state.guestGifting.giftNote.playBackId,

        getGiftNoteAssetId: (state) => state.guestGifting.giftNote.assetId,

        getGiftNoteBlobData: (state) => state.guestGifting.giftNote.blobData,

        getDeliveryOptions: (state) => state.deliveryOptions,

        getDeliveryCaps: (state) => state.deliveryCaps,

        getMaxCalendarDate: (state) => state.guestGifting.maxCalendarDate,

        getActiveFamilyFilter: (state) => state.guestGifting.activeFamilyFilter,

        getActiveDateFilter: (state) => state.guestGifting.activeDateFilter,

        getTotalGiftPriceWithDiscount: (state) => state.giftingPriceWithDiscount,

        getTotalGiftPriceWithoutDiscount: (state, getters) => {
            let addonPrice = 0;
            if (!state.guestGifting.addOns.length) addonPrice = 0;
            else {
                state.guestGifting.addOns.forEach(element => {
                    addonPrice += Number(element.price);
                });
            }
            let productPrice = Number(state.guestGifting.selectedGiftProduct?.actual_price) || 0;

            return Number(addonPrice + productPrice + getters['getDeliveryPriceOnHundredPercentCoupon']).toFixed(2);
        },

        getTotalGiftPriceWithoutDiscountAndAddOns: (state, getters) => {
            let productPrice = process.env.VUE_APP_COUNTRY_ISO === 'GBR'
                ? (Number(state.guestGifting.selectedGiftProduct?.actual_price) || 0)
                : Number(state.guestGifting.selectedGiftProduct?.total_bundle_price_without_delivery || 0);

            return Number(productPrice + getters['getDeliveryPriceOnHundredPercentCoupon']).toFixed(2);
        },

        getTotalGiftPriceWithCoupon: (state, getters) => {
            let productPrice = getters['getTotalGiftPriceWithoutDiscountAndAddOns'] - getters['getDeliveryPriceOnHundredPercentCoupon'];

            state.guestGifting.appliedGiftingCouponCode = null;

            // The calculation for discount_type 'amount' has the coupon amount divided by 100 because we get the discount amount in pennies (i.e., 100 Pennies / 100 = 1 Pound)
            if (state.guestGifting.guestGiftingCouponProperties && getters['getApplyCoupon']) {
                productPrice = getters['getGiftDiscountAmount'](productPrice, state.guestGifting.guestGiftingCouponProperties?.code);
                state.guestGifting.appliedGiftingCouponCode = state.guestGifting.guestGiftingCouponProperties.code;
            } else if (state.guestGifting?.selectedGiftProduct?.promotion_coupons && state.guestGifting?.selectedGiftProduct?.promotion_coupons[0]?.discount_type === 'percentage' && state.guestGifting.selectedGiftProduct?.id !== Number(wreathProductId)) {
                productPrice -= (productPrice * (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.amount / 100));
                state.guestGifting.appliedGiftingCouponCode = state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.code;
            } else if (state.guestGifting?.selectedGiftProduct?.promotion_coupons && state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.discount_type === 'amount' && state.guestGifting.selectedGiftProduct?.id !== Number(wreathProductId)) {
                productPrice -= (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.amount / 100);
                state.guestGifting.appliedGiftingCouponCode = state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.code;
            } else if (state.guestGifting.guestGiftingCouponProperties?.product_type === SPECIAL_DATES_GIFT_COUPON && state.coupons.specialDatesCoupons?.discountType === 'percentage' && state.guestGifting.selectedGiftProduct?.id !== Number(wreathProductId)) {
                productPrice -= (state.guestGifting.guestGiftingCouponProperties?.amount !== 0)
                    ? (state.guestGifting.guestGiftingCouponProperties?.amount / 100)
                    : (productPrice * (state.coupons.specialDatesCoupons?.discount / 100));
                state.guestGifting.appliedGiftingCouponCode = state.coupons.specialDatesCoupons.code;
            } else if (state.guestGifting.guestGiftingCouponProperties?.product_type === SPECIAL_DATES_GIFT_COUPON && state.coupons.specialDatesCoupons?.discountType === 'amount' && state.guestGifting.selectedGiftProduct?.id !== Number(wreathProductId)) {
                productPrice -= (state.coupons.specialDatesCoupons?.discount / 100);
                state.guestGifting.appliedGiftingCouponCode = state.coupons.specialDatesCoupons.code;
            } else if (state.guestGifting.guestGiftingCouponProperties) {
                productPrice = getters['getGiftDiscountAmount'](productPrice, state.guestGifting.guestGiftingCouponProperties?.code);
                state.guestGifting.appliedGiftingCouponCode = state.guestGifting.guestGiftingCouponProperties.code;
            }

            let addonPrice = 0;
            if (!state.guestGifting.addOns.length) addonPrice = 0;
            else {
                state.guestGifting.addOns.forEach(element => {
                    addonPrice += Number(element.price);
                });
            }

            let total = Number(addonPrice + productPrice + getters['getDeliveryPriceOnHundredPercentCoupon']);
            return total <= 0 ? 0 : total.toFixed(2);
        },

        getApplyCoupon: (state) => {
            return (state.guestGifting.guestGiftingCouponProperties?.product_families?.includes(state.guestGifting.activeFamilyFilter)) &&
            state.guestGifting.filteredPromotionCoupons.includes(state.guestGifting.guestGiftingCouponProperties?.code)
                ? (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.code === state.guestGifting.guestGiftingCouponProperties?.code) && state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.is_stackable
                : state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.is_stackable;
            // (state.guestGifting.selectedGiftProduct ? state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.code === state.guestGifting.guestGiftingCouponProperties?.code : true));
        },

        getGiftDiscountAmount: (state) => (val, productPromotionCode) => {
            if (productPromotionCode !== state.guestGifting.guestGiftingCouponProperties?.code) {
                return val;
            }
            let amount = Number(val);
            let discountAmount = 0;
            let coupon = state.guestGifting.guestGiftingCouponProperties;
            if (!coupon?.product_families?.includes(state.guestGifting.activeFamilyFilter)) return amount;

            if (coupon?.discount_type === 'percentage') {
                discountAmount = amount - (amount * (coupon?.amount / 100));
            } else if (coupon?.discount_type === 'amount') {
                discountAmount = amount - (coupon?.amount / 100);
            }

            if (state.guestGifting.additionalCoupon) {
                if (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.discount_type === 'percentage') {
                    discountAmount = discountAmount - (discountAmount * (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.amount / 100));
                } else if (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.discount_type === 'amount') {
                    discountAmount = discountAmount - (state.guestGifting.selectedGiftProduct?.promotion_coupons[0]?.amount / 100);
                }
            }
            return discountAmount;
        },

        getPerBoxDiscountAmount: (state) => (total, count, product) => {
            if (product?.promotion_coupons[0]?.code && product?.promotion_coupons[0]?.code !== state.guestGifting.guestGiftingCouponProperties?.code) {
                return parseInt(total / count);
            }
            let coupon = state.guestGifting.guestGiftingCouponProperties;
            if (!coupon?.product_families?.includes(state.guestGifting.activeFamilyFilter)) return parseInt(total / count);

            if (coupon?.discount_type === 'percentage') {
                return parseInt((total - (total * (coupon?.amount / 100))) / count);
            } else if (coupon?.discount_type === 'amount') {
                return parseInt((total - (coupon?.amount / 100)) / count);
            }
        },

        getTotalPrice: (state) => state.totalPrice,

        getGuestGiftingCouponProperties: (state) => state.guestGifting.guestGiftingCouponProperties,

        getFilteredPromotionCoupons: (state) => state.guestGifting.filteredPromotionCoupons,

        getIsPromotionCoupon: (state) => state.guestGifting.isPromotionCoupon,

        getAppliedGiftingCouponCode: (state) => state.guestGifting.appliedGiftingCouponCode,

        getSelectedMultiPlan: (state) => state.guestGifting.selectedMultiPlan,

        getIsBundle: (state) => state.isBundle,

        getDraftGiftData: (state) => state.draftGiftData,

        getBraintreeClientToken: (state) => state.draftGiftData?.brain_tree_token,

        getPaymentIntent: (state) => state.draftGiftData?.stripe_token,

        getGuestFriendCode: (state) => state.giftDetails.giftFriendCode,

        getGiftVoucherInfo: (state) => state.giftVoucherInfo,

        getFirstName: state => state.guestGifting.stripeBillingAddress.firstName,

        getLastName: state => state.guestGifting.stripeBillingAddress.lastName,

        getSelfGift: state => state.guestGifting.selfGift,

        getAdditionalCoupon: state => state.guestGifting.additionalCoupon,

        getSteps: () => currentRoute => [
            {
                number: 1,
                label: i18n.t('GIFTING_CHECKOUT_PROGRESS_BAR_SIGN_IN'),
                route: 'guest-gifting.checkout.email',
                isActive: currentRoute === 'guest-gifting.checkout.email',
            },
            {
                number: 2,
                label: i18n.t('GIFTING_CHECKOUT_PROGRESS_BAR_DELIVERY_DETAILS'),
                route: 'guest-gifting.checkout.delivery-address',
                isActive: currentRoute === 'guest-gifting.checkout.delivery-address',
            },
            {
                number: 3,
                label: i18n.t('GIFTING_CHECKOUT_PROGRESS_BAR_PAYMENT_DETAILS'),
                route: 'guest-gifting.checkout.payment',
                isActive: currentRoute === 'guest-gifting.checkout.payment',
            },
        ],

        getGiftVouchers: state => {
            return state.guestGifting.giftProducts?.filter(item => item.type_of_product === PRODUCT_TYPE_VOUCHER);
        },

        getDeliveryPriceOnHundredPercentCoupon: state => {
            let deliveryPrice = 0;
            if (
                state.guestGifting.guestGiftingCouponProperties?.custom_coupon_discount_type === 'percentage' &&
                state.guestGifting.guestGiftingCouponProperties?.custom_coupon_amount === 100
            ) {
                deliveryPrice = 0;
            } else {
                deliveryPrice = Number(state.guestGifting.deliveryPrice?.price) || 0;
            }

            return deliveryPrice;
        },
    },
};
