<template>
    <div :class="cardCvcInputClass">
        <label class="input__label"
            :class="{ 'required-input': mandatory }"
            v-if="showLabel"
            v-text="$t('STRIPE_ELEMENTS_SECURITY_NUMBER')"/>
        <div ref="input" :id="`${_uid}-input`" class="stripe_card_number"/>
    </div>
</template>

<script>
import { stripeElementsClasses, stripeElementsStyle } from '@/config/StripeElements';

export default {
    name: 'StripeCardCvcInput',
    data: () => {
        return {
            cardCvc: null,
        };
    },
    props: {
        stripeElements: {
            required: true,
        },
        hasBeenRequested: {
            required: true,
            default: false,
        },
        noPadding: {
            default: false,
            type: Boolean,
        },
        mandatory: {
            default: false,
            type: Boolean,
        },
        showLabel: {
            required: false,
            default: true,
        },
    },
    mounted: function() {
        this.mountCardCvcField();
    },
    destroy: function() {
        this.cardCvc.unmount();
        this.cardCvc.destroy();
    },
    methods: {
        mountCardCvcField() {
            this.cardCvc = this.stripeElements.create('cardCvc', {
                classes: stripeElementsClasses,
                style: stripeElementsStyle,
                placeholder: '–––',
            });

            this.cardCvc.mount(this.$refs.input);
        },
    },
    computed: {
        isValid() {
            return this.cardCvcValid;
        },
        cardCvcInputClass() {
            return !(this.cardCvcEmpty && this.hasBeenRequested) && this.cardCvcValid && !this.errorMessage
                ? `input ${this.noPadding ? 'noPadding' : ''}`
                : `input input--invalid ${this.noPadding ? 'noPadding' : ''}`;
        },
        cardCvcEmpty() {
            return this.cardCvc?._implementation?._empty;
        },
        cardCvcValid() {
            return this.cardCvc && !(this.cardCvc._implementation?._invalid && !this.cardCvc._implementation._empty);
        },
        errorMessage() {
            if (!this.hasBeenRequested) {
                return null;
            }

            if (this.cardCvcEmpty) {
                return this.$t('STRIPE_ELEMENTS_SECURITY_CODE');
            }

            if (!this.cardCvcValid) {
                return this.$t('STRIPE_ELEMENTS_TAKE_ANOTHER_LOOK');
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/sass/brand/theme.scss';

.stripe_card_number {
    @include border-thin();
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: calc(20 + (25 / 2)) + px;
    background-color: $light-color;
}
.noPadding {
    padding: 0;
}
</style>
