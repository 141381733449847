import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class FetchPaymentSources {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(userId) {
        let apiUrl = prepareUrl(ApiEndpoints['payments.payment-sources.index'], [userId]);

        return this.apiService.get(apiUrl);
    }
}
