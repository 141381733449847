import UpdatePaymentSource from '@/services/payments/UpdatePaymentSource';
import UpdateBillingAddress from '@/services/payments/UpdateBillingAddress';
import FetchPaymentSources from '@/services/payments/FetchPaymentSources';

export default class PaymentService {
    fetchPaymentSources(userId) {
        return (new FetchPaymentSources()).handle(userId);
    }

    updateBillingAddress(userId, addressId, payload) {
        return (new UpdateBillingAddress()).handle(userId, addressId, payload);
    }

    updatePaymentSource(userId, cardToken, cardId, gateway, nameOnCard, paymentMethod, deviceData) {
        return (new UpdatePaymentSource()).handle(userId, cardToken, cardId, gateway, nameOnCard, paymentMethod, deviceData);
    }
}
