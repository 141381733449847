import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class GetAllProducts {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(subscriptionId) {
        let apiUrl = ApiEndpoints['products.families'];
        apiUrl = subscriptionId ? `${apiUrl}?subscription_id=${subscriptionId}` : apiUrl;

        return this.apiService.get(apiUrl);
    }
}
