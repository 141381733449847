import { createUrlQueryString, prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchUserOrders {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(userId, page) {
        let apiUrl = prepareUrl(ApiEndpoints['orders.users.orders.index'], [userId]) +
            '?' + createUrlQueryString({ page: page }) + '&' + createUrlQueryString({ payment_status: 'paid' });

        return this.apiService.get(apiUrl);
    }
}
