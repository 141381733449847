<template>
    <div class="card-form">
        <!--Name-->
        <input-field
            v-model="card.name"
            :noPadding="noPadding"
            autocomplete="name"
            :mandatory="true"
            :placeholder="$t('STRIPE_ELEMENTS_NAME_ON_CARD')"
            :label="showLabels ? $t('STRIPE_ELEMENTS_NAME_ON_CARD') : ''"/>
        <stripe-card-number-input
            v-if="stripeElements"
            ref="number"
            :noPadding="noPadding"
            :showLabel="showLabels"
            :mandatory="true"
            :brand="card.brand"
            :has-been-requested="hasBeenRequested"
            :stripe-elements="stripeElements"/>

            <div class="stripe">
                <div class="stripe--date">
                    <!-- Stripe Elements card expiry input field -->
                    <stripe-card-expiration-input
                        v-if="stripeElements"
                        ref="expiry"
                        :showLabel="showLabels"
                        :mandatory="true"
                        :has-been-requested="hasBeenRequested"
                        :stripe-elements="stripeElements"/>
                </div>
                <div class="stripe--cvv">
                    <!-- Stripe Elements card cvc input field -->
                    <stripe-card-cvc-input
                        v-if="stripeElements"
                        ref="cvc"
                        :showLabel="showLabels"
                        :mandatory="true"
                        :noPadding="false"
                        :has-been-requested="hasBeenRequested"
                        :stripe-elements="stripeElements"/>
                </div>
            </div>

            <div class="input input--invalid">
                <p class="input__message">
                    <!-- Validation for expiry-->
                    <span
                        class="error-message"
                        v-if="$refs.expiry && $refs.expiry.errorMessage"
                        v-text="$refs.expiry.errorMessage"/>

                    <!-- Validation for cvc-->
                    <span
                        class="error-message"
                        v-if="$refs.cvc && $refs.cvc.errorMessage"
                        v-text="$refs.cvc.errorMessage"/>
                </p>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AMEX_CARD_BRAND, stripeElementsFonts } from '@/config/StripeElements';
import InputField from '@/components/forms/fields/InputField';
import StripeCardCvcInput from '@/components/forms/cardDetails/StripeCardCvcInput';
import StripeCardNumberInput from '@/components/forms/cardDetails/StripeCardNumberInput';
import StripeCardExpirationInput from '@/components/forms/cardDetails/StripeCardExpirationInput';
import CountryService from '@/services/logistics/CountryService';
// import countriesJson from '@/resources/countries.json';

export default {
    name: 'StripeElementsForm',
    data() {
        return {
            card: {
                brand: null,
                name: null,
            },
            stripe: null,
            stripeElements: null,
        };
    },
    props: {
        fullName: {
            type: String,
            default: null,
        },
        hasBeenRequested: {
            required: true,
            default: false,
        },
        billingAddress: {
            required: true,
        },
        noPadding: {
            default: false,
            type: Boolean,
        },
        showLabels: {
            default: true,
            type: Boolean,
        },
    },
    created() {
        this.initNameOnCard();
    },
    mounted() {
        this.mountStripeElements();
    },
    methods: {
        initNameOnCard() {
            this.card.name = this.fullName;
        },

        mountStripeElements() {
            this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
            this.stripeElements = this.stripe.elements({
                fonts: [stripeElementsFonts],
            });
        },

        areStripeElementFieldsInvalid() {
            return !this.card.name ||
                !this.$refs.number.isValid ||
                !this.$refs.expiry.isValid ||
                !this.$refs.cvc.isValid;
        },

        handleScaCardSubmission() {
            return new Promise((resolve, reject) => {
                // check if all fields appear to be filled and valid
                if (this.areStripeElementFieldsInvalid()) {
                    reject(this.$t('STRIPE_ELEMENTS_GENERIC_INVALID_MESSAGE'));
                } else {
                    // const billingCountry = this.billingAddress.country instanceof Object ? this.billingAddress.country.name : this.billingAddress.country;
                    // const countryCode = countriesJson.find((item) => item.iso === billingCountry).code;
                    let country = CountryService.findCountryByIso(process.env.VUE_APP_COUNTRY_ISO);

                    this.stripe.confirmCardSetup(
                        this.setupIntent,
                        {
                            payment_method: {
                                card: this.$refs.number.cardNumber,
                                billing_details: {
                                    name: this.card.name,
                                    address: {
                                        line1: this.billingAddress?.addressLine1,
                                        city: this.billingAddress?.city,
                                        postal_code: this.billingAddress?.postcode,
                                        country: country.iso2,
                                    },
                                },
                            },
                        },
                    ).then((result) => {
                        if (result.error) {
                            reject(result.error.message);
                        } else {
                            resolve({
                                setupIntentId: result.setupIntent.payment_method,
                            });
                        }
                    });
                }
            });
        },

        handleScaCardPayment() {
            return new Promise((resolve, reject) => {
                if (this.areStripeElementFieldsInvalid()) {
                    reject(this.$t('STRIPE_ELEMENTS_GENERIC_INVALID_MESSAGE'));
                } else {
                    let country = CountryService.findCountryByIso(process.env.VUE_APP_COUNTRY_ISO);
                    this.stripe.confirmCardPayment(
                        this.paymentIntent,
                        {
                            payment_method: {
                                card: this.$refs.number.cardNumber,
                                billing_details: {
                                    name: this.card.name,
                                    address: {
                                        line1: this.billingAddress?.addressLine1,
                                        city: this.billingAddress?.city,
                                        postal_code: this.billingAddress?.postcode,
                                        country: country.iso2,
                                    },
                                },
                            },
                        },
                    ).then((result) => {
                        if (result.error) {
                            reject(result.error.message);
                        } else {
                            resolve({
                                paymentIntentId: result.paymentIntent.id,
                            });
                        }
                    });
                }
            });
        },

        handleFetchingToken() {
            return new Promise((resolve, reject) => {
                // check if all fields appear to be filled and valid
                if (this.areStripeElementFieldsInvalid()) {
                    reject(this.$t('STRIPE_ELEMENTS_GENERIC_INVALID_MESSAGE'));
                } else {
                    this.stripe
                        .createToken(this.$refs.number.cardNumber, {
                            ...this.stripeBillingAddressMap,
                            name: this.card.name,
                        })
                        .then(response => {
                            if (response.token) {
                                this.card.brand = response.token.card.brand;

                                if (AMEX_CARD_BRAND === response.token.card.brand) {
                                    reject(this.$t('STRIPE_ELEMENTS_BRAND_NOT_SUPPORTED', { brand: AMEX_CARD_BRAND }));
                                } else {
                                    resolve({
                                        tokenId: response.token.id,
                                        cardId: response.token.card.id,
                                    });
                                }
                            } else if (response.error) {
                                reject(response.error.message);
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            });
        },
    },
    computed: {
        ...mapGetters({
            setupIntent: 'account/getSetupIntent',
            paymentIntent: 'account/getPaymentIntent',
        }),

        stripeBillingAddressMap() {
            const billingCountry = this.billingAddress.country instanceof Object ? this.billingAddress.country.name : this.billingAddress.country;

            const stripeAddress = {
                address_line1: this.billingAddress.addressLine1,
                address_city: this.billingAddress.city,
                address_zip: this.billingAddress.postcode,
                address_country: billingCountry,
            };

            const keys = Object.keys(stripeAddress);
            keys.forEach(key => {
                if (!stripeAddress[key]) {
                    delete stripeAddress[key];
                }
            });

            return stripeAddress;
        },
    },
    components: {
        InputField,
        StripeCardNumberInput,
        StripeCardExpirationInput,
        StripeCardCvcInput,
    },
};
</script>

<style lang="scss" scoped>
.stripe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--cvv {
        width: 50%;
    }
    &--date {
        width: 50%;
    }
}
.card-container{
        padding: 20px;
    }
.card-form {
    margin-top: 20px;
}
.error-message {
    margin-bottom: 00.75rem;
    display: block;
}
</style>
