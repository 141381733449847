import AddressService from '@/services/logistics/AddressService';
import ApiService from '@/services/ApiService';
import store from '@/store';

const getDefaultState = () => {
    return {
        addresses: {
            addresses: null,
            haveFetched: false,
        },
        giftingAddresses: {
            addresses: null,
            haveFetched: false,
            selectedId: null,
        },
        collectsPackaging: null,
        isPostcodeStatusFetched: false,
    };
};

let addressService = new AddressService();

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        updateAddresses(state, payload) {
            state.addresses.addresses = payload;
        },

        updateGiftingAddresses(state, payload) {
            state.giftingAddresses.addresses = payload;
        },

        updateSelectedGiftingAddressId(state, payload) {
            state.giftingAddresses.selectedId = payload;
        },

        updateCollectionStatus(state, payload) {
            state.collectsPackaging = payload;
        },
        updateFetchedStatus(state, payload) {
            state.isPostcodeStatusFetched = payload;
        },
    },
    actions: {
        fetchAllAddresses({ commit, state, rootGetters }, forceReload = false) {
            if (state.addresses.haveFetched === false || forceReload === true) {
                state.addresses.haveFetched = true;
                let uid = rootGetters['account/getUserId'];
                if (!uid) return;
                return new Promise((resolve, reject) => {
                    addressService.fetchAllUserAddresses(uid)
                        .then(response => {
                            commit('updateAddresses', response.data.data);
                            store.dispatch('subscription/fetchAvailableDeliveryDay');
                            resolve(response);
                        })
                        .catch(error => {
                            state.haveFetched = false;
                            reject(error);
                        });
                });
            }
        },

        fetchAllGiftingAddresses({ commit, state, rootGetters }, forceReload = false) {
            if (!state.giftingAddresses.haveFetched || forceReload) {
                state.giftingAddresses.haveFetched = true;
                let uid = rootGetters['account/getUserId'];

                return new Promise((resolve, reject) => {
                    addressService.fetchAllUserGiftingAddresses(uid)
                        .then(response => {
                            commit('updateGiftingAddresses', response.data.data);
                            resolve(response);
                        })
                        .catch(error => {
                            state.haveFetched = false;
                            reject(error);
                        });
                });
            }
        },

        resetAddress({ commit }) {
            commit('resetState');
        },

        getPackageCollectionStatus({ commit }, postcode) {
            const apiService = new ApiService(`${process.env.VUE_APP_API_URL}couriers/${postcode}`);
            return new Promise((resolve, reject) => {
                apiService.get()
                    .then(response => {
                        commit('updateCollectionStatus', response.data.collects_packaging);
                        commit('updateFetchedStatus', true);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        deleteAddress({ commit, state, rootGetters }, addressId) {
            const uid = rootGetters['account/getUserId'];

            return new Promise((resolve, reject) => {
                addressService.deleteAddress(uid, addressId)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        deleteGiftingAddress({ commit, state, rootGetters }, addressId) {
            const uid = rootGetters['account/getUserId'];

            return new Promise((resolve, reject) => {
                addressService.deleteGiftingAddress(uid, addressId)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        getAddresses: state => state.addresses.addresses,

        getAddressById(state) {
            return addressId => {
                return state.addresses.addresses
                    ? state.addresses.addresses.find(item => { return item.id === addressId; })
                    : {};
            };
        },

        getBillingAddress(state) {
            return state.addresses.addresses ? state.addresses.addresses.find(item => item.is_billing) : null;
        },

        getGiftingAddresses: state => state.giftingAddresses.addresses || [],

        getGiftingAddressById(state) {
            return addressId => {
                return state.giftingAddresses.addresses
                    ? state.giftingAddresses.addresses.find(item => { return item.id === addressId; })
                    : {};
            };
        },

        getSelectedGiftingAddress: (state, getters) => getters.getGiftingAddressById(state.giftingAddresses.selectedId),
        isCollectsPackaging: state => state.collectsPackaging,
        isPostcodeStatusFetched: state => state.isPostcodeStatusFetched,
    },
};
