import store from '@/store';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import CountryService from '@/services/logistics/CountryService';

export default class ValidateDelivery {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    handle() {
        return this.apiService
            .post(ApiEndpoints['users.validate.registration.delivery.store'], this.getPayload());
    }

    getPayload() {
        let deliveryAddress = store.getters['register/getDeliveryAddress'];
        let deliveryAddressCountry = CountryService.findCountryByIso(deliveryAddress.country);
        deliveryAddressCountry = deliveryAddressCountry ? deliveryAddressCountry.name : null;

        return {
            first_name: store.getters['register/getFirstName'],
            last_name: store.getters['register/getLastName'],
            email: store.getters['register/getEmail'],
            delivery_address_house_number: deliveryAddress.houseNumber || null,
            delivery_address_line_1: deliveryAddress.addressLine1,
            delivery_address_line_2: deliveryAddress.addressLine2,
            delivery_address_city: deliveryAddress.city,
            delivery_address_postcode: deliveryAddress.postcode,
            delivery_address_state: deliveryAddress.state,
            delivery_address_country: deliveryAddressCountry,
            delivery_address_country_iso: deliveryAddress.country || null,
            delivery_address_property_type: store.getters['register/getPropertyType'],
            delivery_instructions: store.getters['register/getDeliveryComment'],
            telephone: store.getters['register/getTelephone'],
        };
    }
}
