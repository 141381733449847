<template>
    <div v-if="showToolTipModal" class="toolTip-modal" @click="closeToolTipModal">
        <div class="toolTip-modal--container">
            <p class="toolTip-modal--title">{{$t(fields.title)}}</p>
            <p class="toolTip-modal--text">{{$t(fields.text)}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToolTipModal',
    props: {
        showToolTipModal: {
            default: false,
            type: Boolean,
        },
        fields: {
            default: () => {},
            type: Object,
        },
    },
    methods: {
        closeToolTipModal() {
            this.$root.$emit('hide-tool-tip-modal');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.toolTip-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-color, $alpha: 0.3);
    background: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    &--container {
        padding: 5px 10px;
        background: $primary-color;
        color: $secondary-color;
        width: 100%;
        min-width: 300px;
        max-width: 420px;
        border-radius: $radius-large;
        margin: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &--text, &--title {
        text-align: center;
        font-size: 15px;
        margin: 2px;
        line-height: 18px;
    }
    &--title {
        font-weight: 600;
        line-height: 20px;
    }
}
</style>
