<template>
    <div v-if="getScheduleData" id="opt-in-top" class="opt-in--container">
        <div class="opt-in--container--top-section">
            <div style="text-align: left; margin-bottom: 13px">
                <p class="opt-in--container--paragraph">{{ $t('OPT_IN_DATE_FLOWERS', {dateString}) }}</p>
                <h3 class="opt-in--container--semi-bold">{{ getScheduleData.title }}</h3>
                <p v-if="getScheduleData.is_normal_delivery_day" class="opt-in--container--current-status" v-text="$t('OPT_IN_SKIPPED')"></p>
            </div>
            <button class="btn btn--half btn-green" :class="{ 'btn--secondary-black btn-outline' : isOpen }" @click="toggleOpen">
                {{actionCTAText}}
            </button>
        </div>
        <div class="opt-in--container--body-section">
            <div class="opt-in--container--body-section--img" :style="imgStyle"></div>
            <!-- <h3 class="opt-in--container--body-section--heading">{{ $t('OPT_IN_HEADING')}}</h3> -->
            <p class="opt-in--container--body-section--paragraph">{{ optInParagraph }}</p>
            <p class="opt-in--container--paragraph">{{ $t('OPT_IN_DELIVERY_DATE', {dateString})}}</p>
            <submit-button
                class="btn btn-full btn--primary-black mb-2 mt-2 btn-green"
                :processing="isProcessing"
                @click="getArrangement">
                    {{ mainCTAText }}
            </submit-button>
            <button class="btn btn-full btn--secondary-black" @click="remindLater">{{ $t('OPT_IN_REMIND_LATER') }}</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Dates from '@/constants/Dates';
import { formatDate } from '@/helpers/DateHelpers';
import ScheduledOrderService from '@/services/subscriptions/Subscriptions/ScheduledOrderService';
import SubscriptionService from '@/services/subscriptions/SubscriptionService';
import SubmitButton from '@/components/forms/buttons/SubmitButton';
import { setCookie } from '@/helpers/QueryHelpers';

let scheduledOrderService = new ScheduledOrderService();
let subscriptionService = new SubscriptionService();

export default {
    name: 'OptInUpcomingDelivery',
    components: { SubmitButton },
    data() {
        return {
            isOpen: false,
            isProcessing: false,
        };
    },
    mounted() {
        this.$root.$on('bottom-sheet-state', this.toggleOpen);
    },
    computed: {
        ...mapGetters({
            isBringForward: 'account/isBringForward',
            isDeliveryOptIn: 'account/isDeliveryOptIn',
            getScheduleData: 'account/getUserSchedule',
            nextPossibleDate: 'account/getNextPossibleDate',
            activeSubscription: 'subscription/getActiveSubscription',
        }),
        dateString() {
            let nextDeliveryDate = this.getScheduleData?.next_delivery_date;
            return nextDeliveryDate && formatDate(nextDeliveryDate, Dates.DAY_DATE_MONTH);
        },
        actionCTAText() {
            return !this.isOpen ? this.$t('OPT_IN_SEE_MORE') : this.$t('OPT_IN_HIDE');
        },
        mainCTAText() {
            return this.isBringForward ? this.$t('OPT_IN_BRING_FORWARD') : this.$t('OPT_IN_GET_ARRANGEMENT');
        },
        optInParagraph() {
            return this.isBringForward ? this.$t('OPT_IN_PARAGRAPH_BRING_FORWARD', { planName: this.getScheduleData?.plan_slug }) : this.$t('OPT_IN_PARAGRAPH');
        },
        imgStyle() {
            return {
                'background-image': `url(${this.getScheduleData?.image_url})`,
            };
        },
    },
    methods: {
        toggleOpen(payload) {
            if (this.isOpen || payload === 'half') {
                this.isOpen = false;
                this.$root.$emit('close-bottom-sheet');
            } else {
                this.isOpen = true;
                this.$root.$emit('open-bottom-sheet');
            }
        },
        getArrangement() {
            if (this.isDeliveryOptIn) {
                this.getScheduleData?.is_normal_delivery_day ? this.cancelHoliday() : this.createOptInDelivery();
            }
            this.isBringForward && this.changePlan();
        },
        cancelHoliday() {
            this.isProcessing = true;
            scheduledOrderService.cancelHoliday({
                subscriptionId: this.getScheduleData?.subscription_id,
                holidayId: this.getScheduleData?.holiday_id,
                source: 'dashboard',
            }).then(() => {
                this.$store.dispatch('account/fetchUserData', true)
                    .then(() => {
                        this.isProcessing = false;
                        this.setNotificationMsg();
                    }).catch(() => this.isProcessing = false);
            }).catch(() => this.isProcessing = false);
        },
        createOptInDelivery() {
            this.isProcessing = true;
            scheduledOrderService.createOptInOrder({
                subscriptionId: this.getScheduleData?.subscription_id,
                deliveryDate: this.getScheduleData?.next_delivery_date,
                source: 'dashboard',
            }).then(() => {
                this.$store.dispatch('account/fetchUserData', true)
                    .then(() => {
                        this.isProcessing = false;
                        this.setNotificationMsg();
                    }).catch(() => this.isProcessing = false);
            }).catch(() => this.isProcessing = false);
        },
        changePlan() {
            this.isProcessing = true;
            let subsId = this.getScheduleData?.subscription_id;
            let currentPlanSlug = this.getScheduleData?.plan_slug;
            let nextDeliveryDate = this.getScheduleData?.next_delivery_date;
            subscriptionService.updateSubscriptionPlan(subsId, currentPlanSlug, nextDeliveryDate, true, null, null, 'dashboard')
                .then(() => {
                    this.$store.dispatch('account/fetchUserData', true)
                        .then(() => this.$store.dispatch('subscription/fetchAllSubscriptions', true)
                            .then(() => this.$store.dispatch('scheduledOrder/fetchAllDeliveries', true))
                            .then(() => {
                                this.isProcessing = false;
                                this.setNotificationMsg();
                            }));
                }).catch(error => console.log(error.response.data));
        },
        setNotificationMsg() {
            let dateStr = this.nextPossibleDate && formatDate(this.nextPossibleDate, Dates.DAY_DATE_MONTH);
            let planName = this.activeSubscription?.plan_slug;
            this.$root.$emit('hide-bottom-sheet');
            this.$root.$emit('hide-sticky-banner');
            this.$store.commit('account/setShowFloatingBanner', true);
            this.$store.commit('account/setBannerHeading', this.$t('FLOATING_BANNER_DELIVERY_DATE_MSG', { dateString: dateStr }));
            this.isBringForward
                ? this.$store.commit('account/setBannerMessage', this.$t('FLOATING_BANNER_PLAN_CHANGE_MSG_BRING_FORWARD', { planName }))
                : this.$store.commit('account/setBannerMessage', this.$t('FLOATING_BANNER_PLAN_CHANGE_MSG', { planName }));
            setTimeout(() => {
                this.$store.commit('account/setShowFloatingBanner', false);
                this.$store.commit('account/setBannerMessage', null);
            }, 5000);
        },
        remindLater() {
            setCookie('remind-later-opt-in', 1, 1);
            this.$root.$emit('hide-bottom-sheet');
            this.$root.$emit('hide-sticky-banner');
        },
        scrollToDiv() {
            let optInDiv = document.getElementById('opt-in-top');
            optInDiv.scrollIntoView({ behavior: 'smooth' }, true);
        },
    },
    watch: {
        isOpen: {
            handler(val) {
                if (!val) this.scrollToDiv();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/brand/theme.scss';
    .opt-in {
        &--container {
            display: flex;
            flex-direction: column;
            padding: 11px;
            text-align: center;
            width: 100%;
            &--top-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }
            &--body-section {
                display: flex;
                flex-direction: column;
                &--img {
                    height: 16rem;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                }
                &--heading {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 30px;
                    margin: 5px 0px;
                }
                &--paragraph {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;
                }
            }
            &--paragraph {
                font-family: $secondary-color;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                margin: 0px;
                margin-bottom: 5px;
            }
            &--semi-bold {
                font-family: $secondary-color;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                margin: 0px;
            }
            &--current-status {
                font-family: $secondary-color;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #868686;
                background: #ECE9E8;
                border-radius: 4px;
                width: 117px;
                margin: 0px;
                text-align: center;
            }
        }
    }
    .btn-green {
        background: #64A667;
        color: white;
    }
    .btn-outline {
        background-color: #FFFFFF;
        color: #000000;
    }
</style>
