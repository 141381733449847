<template>
    <top-banner banner-image="not-found" :enable-bottom-divider="false">
        <div class="w-full h-full flex items-center justify-center">
            <div class="text-center text-white leading-snug text-shadow">
                <h1>404</h1>
                <h3 v-html="$t('NOT_FOUND_CANT_FIND')"></h3>
                <router-link :to="{ name: 'home' }" class="btn btn--primary btn--white-border mt-3"
                    v-text="$t('NOT_FOUND_BACK')"></router-link>
            </div>
        </div>
    </top-banner>
</template>
<script>
import TopBanner from '@/components/structure/TopBanner';

export default {
    name: 'NotFound',
    components: {
        TopBanner,
    },
};
</script>
