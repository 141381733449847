<template>
    <nav v-if="showTopNavMenus" class="navigation-v2__landing-buttons-container">
        <ul class="navigation-v2__landing-buttons">
            <li
                v-for="button in buttons"
                :key="`${button.label}-button`"
                class="nav-link"
                :class="{ 'active-link': isCurrentPage(button)}">

                <a
                    v-if="button.route === 'faq.categories'"
                    :href="zendeskHcUrl"
                    class="nav-link nav-button-v2">
                    {{ $t('TOP_NAVIGATION_HELP') }}
                    <!-- <div class="underline"></div> -->
                </a>

                <router-link v-else :to="{name: isSelectedGifter(button)}" class="nav-button-v2">
                    <p class="nav-button-v2--container">
                        <img v-if="button.showImage" :src="button.src" :alt="button.altText || `${button.label} icon`" class="icon-class"/>
                        {{ $t('NAV_BUTTON_LABEL', { 'button.label': $t(`${button.label}`)}) }}
                    </p>
                    <!-- <div class="underline"></div> -->
                </router-link>
            </li>

            <li v-if="includeLearnMoreDropdown" class="nav-link nav-dropdown-container" @click="toggleShowLearnMoreDropdown" v-click-outside="unShowLearnMoreDropdown">
                <div :class="showLearnMoreDropdown ? 'nbg-menu-container-v2--dropdown--placeholder--active' : 'nbg-menu-container-v2--dropdown--placeholder'">
                    <p :style="learnMorePlaceholderStyle">{{ $t('FREDDIES_LEARN') }}</p>
                    <img class="nbg-menu-container-v2--dropdown--placeholder--arrow-icon" :style="learnMoreArrowStyle" :src="learnMoreArrowImage" alt="Down-Arrow icon in Cream">
                </div>
                <NavDropdown class="nav-dropdown" :show="showLearnMoreDropdown" :routesUnderDropdown="routesUnderLearnMoreDropdown" />
            </li>
        </ul>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import countryUtils from '@/resources/countryUtils.json';

import NavDropdown from '@/components/structure/navigation/navigation/NavDropdown.vue';

import GreenArrowDownIcon from '@/assets/images/decoration/arrow-down-green.svg';
import CreamArrowDownIcon from '@/assets/images/decoration/arrow-down-cream.svg';

export default {
    name: 'NavButtonGroupV2',
    components: { NavDropdown },
    props: {
        buttons: {
            type: Array,
            required: true,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        isDesktop: {
            type: Boolean,
        },
        customStyles: {
            type: Object,
            default: () => {},
        },
        includeLearnMoreDropdown: Boolean,
    },
    data() {
        return {
            countryUtils: countryUtils.filter(item => item.iso.toLowerCase() === process.env.VUE_APP_COUNTRY_ISO.toLowerCase())[0],
            greenArrowDownIcon: GreenArrowDownIcon,
            creamArrowDownIcon: CreamArrowDownIcon,
            showLearnMoreDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            isNewGiftingFlow: 'account/isNewGiftingFlow',
            isGifter: 'account/isGifterAccount',
            routesUnderLearnMoreDropdown: 'nav/getRoutesUnderLearnMoreDropdown',
            isUserLoggedIn: 'auth/isUserLoggedIn',
        }),
        showTopNavMenus() {
            return this.isDesktop && (!this.isPage(['register', 'guest-gifting', 'gifting']) || this.isPage(['guest-gifting.home'], true));
        },
        zendeskHcUrl() {
            return this.countryUtils?.zendesk_hc_url;
        },
        newGiftingFlow() {
            return this.isNewGiftingFlow ? 'guest-gifting' : 'account.gifting.choose-your-gift';
        },
        learnMorePlaceholderStyle() {
            if (this.showLearnMoreDropdown) return { color: '#285A53' };
        },
        learnMoreArrowStyle() {
            return this.showLearnMoreDropdown ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' };
        },
        learnMoreArrowImage() {
            return this.showLearnMoreDropdown ? this.greenArrowDownIcon : this.creamArrowDownIcon;
        },
    },
    methods: {
        isPage(pageArr, isSubPage = false) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = isSubPage ? this.$route.name : this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        isCurrentPage(button) {
            return this.$route.name === button.route;
        },
        isSelectedGifter(button) {
            let routeName = button.route;
            if (routeName === 'account.gifting.choose-your-gift') {
                return this.newGiftingFlow;
            }
            return routeName;
        },
        toggleShowLearnMoreDropdown() {
            this.showLearnMoreDropdown = !this.showLearnMoreDropdown;
        },
        unShowLearnMoreDropdown() {
            if (this.showLearnMoreDropdown) this.showLearnMoreDropdown = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.navigation-v2 {
    // &__landing-buttons-container {}

    &__landing-buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0 0 0 auto;
        max-width: 500px;

        grid-gap: 1rem;
        box-sizing: border-box;
        list-style: none;
        width: 100%;
    }

    li .underline {
        height: 3px;
        background-color: transparent;
        width: 0%;
        transition: width 0.2s, background-color 0.5s;
        -moz-transition: width 0.2s, background-color 0.5s;
        -webkit-transition: width 0.2s, background-color 0.5s;
        margin: 0 auto;
        border-radius: 24px;
    }

    li.active-link .underline {
        width: 100%;
        background-color: $secondary-color;
    }

    li:hover .underline {
        background-color: $secondary-color;
        width: 100%;
    }

    &__landing-button {
        margin-right: 25px;
    }
}

.nav-menu {
    font-family: $secondary-font;
    font-style: $font-style-normal;
    font-weight: $font-weight-300;
    font-size: $font-medium;
    line-height: 24px;
}

.nav-mobile {
    text-decoration: none;
    &--selected {
        border-radius: 136px;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: $secondary-color;
        text-decoration: none;
    }
}

li {
    list-style-type: none;
    font-size: $font-x-large;
}

li .underline {
    height: 3px;
    background-color: transparent;
    width: 0%;
    transition: width 0.2s, background-color 0.5s;
    -moz-transition: width 0.2s, background-color 0.5s;
    -webkit-transition: width 0.2s, background-color 0.5s;
    margin: 0 auto;
    border-radius: 24px;
}

li.active-link .underline {
    width: 100%;
    background-color: $secondary-color;
}

li.active-link .nav-button-v2 {
    background-color: transparentize($card-white-color, 0.9);
    padding: 8px 13px 8px 15px;
    border-radius: 20px;
}

li:hover .underline {
    background-color: $secondary-color;
    width: 100%;
}

.icon-class {
    position: absolute;
    top: -15px;
    left: -10px;
}

.nav-button-v2 {
    position: relative;
    list-style: none;

    margin-left: .5em;
    margin-right: .5em;

    transition: .5s;

    &--container {
        position: relative;
        display: inline-block;
        min-width: max-content;
        margin: 0;

        @include button();
    }
}

.nav-link {
    list-style: none;
}

.nav-link:hover {
    opacity: 0.8;
    transition: .2s;
}

.nav-link:last-child:hover {
    opacity: 1;
}

.nav-dropdown-container {
    position: relative;
}

.nbg-menu-container-v2 {
    position: relative;

    &--dropdown {
        position: absolute;
        top: 50%;

        width: 100%;

        transition: .2s;

        // margin-top: 0;

        &--placeholder, &--placeholder--active {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 125%;
            position: relative;
            left: -9px;

            transition: .2s;

            cursor: pointer;

            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            p {
                @include button();

                margin-right: 3px;
            }

            img {
                width: 24px;
                height: 24px;

                margin-left: 4px;
                align-self: center;
            }

            &--arrow-icon {
                transform: rotate(0deg);
                transition: .2s;
            }

            &--active {
                background: $card-white-color;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
        }

        &--placeholder:hover {
            opacity: 0.8;
        }

        &--active {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 32px;

            width: 50%;

            padding: 16px 0px 16px;
            margin: 20px 18px 25px;

            border-radius: 16px;
            background: $card-white-color;

            cursor: pointer;

            transition: .2s;
            z-index: 1;

            &--hr-line {
                width: 226px;
                height: 1px;

                opacity: 0.25;
                background: $shade-card-color;

                margin-top: 0 !important;
            }

            &--menu-container {
                width: 327px;
                background: inherit;
                border-radius: 0px 16px 16px 16px;

                position: relative;
                left: 65px;
            }

            .nbg-menu-container-v2--list {
                margin-top: 0 !important;
            }

            .nbg-menu-container-v2--list--item {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.nbg-menu-container-v2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    color: $primary-color;

    &--list * {
        @include button();

        margin-top: 29px !important;

        @media screen and (max-height: $medium) {
            margin-top: 19px !important;
        }
    }

    &--list img, p {
        margin: 0 !important;
    }

    &--list *:first-child {
        margin-top: 0 !important;
    }

    &--list {
        list-style: none;

        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &--item {
            .dropdown-link {
                display: flex;
                justify-content: space-evenly;
            }

            &--container {
                width: 278px;
                height: 80px;
                flex-shrink: 0;

                display: flex;
                justify-content: space-between;

                &--image-section {
                    &--image {
                        width: 80px;
                        height: 80px;

                        max-width: none;

                        border-radius: 8px;

                        @include small();
                        overflow: scroll;
                    }
                }

                &--text-section {
                    margin-top: 0 !important;

                    text-align: left;

                    width: 180px;

                    align-self: center;

                    &--description {
                        @include small();
                    }
                }
            }
        }
    }

    &--list-2 * {
        @include button();
    }

    &--list-2 {
        &--item {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-top: 27px;
        }

        &--item:first-child {
            margin-top: 0;
        }

        &--item:last-child {
            margin-bottom: 44px;
        }

        .item-btn {
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            border-radius: $radius-small;
        }
    }
}
</style>
