import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchStripeTerminalToken {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle() {
        const apiUrl = ApiEndpoints['users.terminal.connection'];
        return this.apiService.post(apiUrl, []);
    }
}
