import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import store from '@/store';

export default class GetGiftingUndeliverableDates {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle() {
        let getSelectedProductID = store.getters['gifting/getSelectedGiftProduct'] && store.getters['gifting/getSelectedGiftProduct'].id;
        let apiUrl = url(ApiEndpoints['gifting.undeliverable-dates.index'], null, { gift_product_id: getSelectedProductID || null });

        return this.apiService.get(apiUrl);
    }
}
