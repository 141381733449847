import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';
import { prepareUrl } from '@/helpers/UrlHelpers';

export default class SendSkipDeliveryReasons {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, payload) {
        const apiUrl = prepareUrl(ApiEndpoints['subscriptions.Send-skip-reason'], [subscriptionId]);
        return this.apiService.patch(apiUrl, payload);
    }
}
