import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class GetRateableArrangements {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle() {
        let apiUrl = ApiEndpoints['rate-flowers.index'];

        return this.apiService.get(apiUrl);
    }
}
