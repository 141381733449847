import {
    add,
    differenceInDays,
    differenceInCalendarDays,
    differenceInHours,
    format,
    formatISO,
    getDay,
    getHours,
    getWeek,
    getYear,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    isSameYear,
    setISODay,
    startOfDay,
    startOfISOWeek,
    startOfWeek,
    subDays,
} from 'date-fns';
import localeDEU from 'date-fns/locale/de';
import localeGBR from 'date-fns/locale/en-GB';

const daysOfWeek = {
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
    'Sunday': 7,
};

const locales = {
    DEU: localeDEU,
    GBR: localeGBR,
};
const locale = locales[process.env.VUE_APP_COUNTRY_ISO];

export const addHours = (date, hoursToAdd) => {
    return add(new Date(date), { hours: hoursToAdd });
};

export const addDays = (date, daysToAdd) => {
    return add(new Date(date), { days: daysToAdd });
};

export const addWeeks = (date, weeksToAdd) => {
    return add(new Date(date), { weeks: weeksToAdd });
};

export const addYears = (date, yearsToAdd) => {
    return add(new Date(date), { years: yearsToAdd });
};

export const diffDays = (date, dateToCompare) => {
    return differenceInDays(date, dateToCompare);
};

export const formatDate = (date, dateFormat) => {
    return format(new Date(date), dateFormat, { locale });
};

export const diffCalendarDays = (date, dateToCompare) => {
    return differenceInCalendarDays(date, dateToCompare);
};

export const diffInHours = (dateLeft, dateRight, options = {}) => {
    return differenceInHours(dateLeft, dateRight, options);
};

// this function converts a date with a timezone to UTC for comparisons
export const getUTCDateTime = (dateToConvert) => {
    const date = new Date(dateToConvert);
    const formattedDate = formatISO(date);
    const indexOfTz = formattedDate.indexOf('+') > 0 ? formattedDate.indexOf('+') : formattedDate.indexOf('-');

    // if timezone is +0400 then we subtract 4 hours to set to UTC time
    const plusOrMinus = formattedDate.indexOf('+') > 0 ? '-' : '+';
    const timezone = formattedDate.substr(indexOfTz + 1, indexOfTz + 4);
    const hoursMinutes = timezone.split(':');

    return add(date, {
        hours: parseFloat(`${plusOrMinus}${hoursMinutes[0]}`),
        minutes: parseFloat(`${plusOrMinus}${hoursMinutes[1]}`),
    });
};

export const getDayFromDate = (date) => {
    return getDay(new Date(date));
};

export const getHoursFromDate = (date) => {
    return getHours(new Date(date));
};

export const getStartOfISOWeek = (date) => {
    return startOfISOWeek(new Date(date));
};

export const getStartOfWeek = (date) => {
    return startOfWeek(new Date(date), { weekStartsOn: 0 });
};

export const getStartOfDay = (date) => {
    return startOfDay(new Date(date));
};

// check the first date is after the second date
export const isDateAfter = (firstDate, secondDate) => {
    return isAfter(new Date(firstDate), new Date(secondDate));
};

// check the first date is before the second date
export const isDateBefore = (firstDate, secondDate) => {
    return isBefore(new Date(firstDate), new Date(secondDate));
};

export const isBetween = (betweenDate, firstDate, secondDate) => {
    return isAfter(new Date(betweenDate), new Date(firstDate)) && isBefore(new Date(betweenDate), new Date(secondDate));
};

export const isSameDate = (firstDate, secondDate) => {
    const dateA = new Date(firstDate);
    const dateB = new Date(secondDate);
    return isSameDay(dateA, dateB) && isSameMonth(dateA, dateB) && isSameYear(dateA, dateB);
};

export const isInCurrentWeek = (dateA, dateB) => {
    return getWeek(new Date(dateA)) === getWeek(new Date(dateB)) && isInCurrentYear(dateA, dateB);
};

export const isInCurrentYear = (dateA, dateB) => {
    return getYear(new Date(dateA)) === getYear(new Date(dateB));
};

// only in calendar
export const isObjectWithDateInArray = (dateString, array) => {
    return array.reduce((accumulator, value) => value.date === dateString || accumulator, false);
};

// only in calendar
export const includesDayInCurrentWeek = (day, array) => {
    return array.reduce((doesContainDay, value) => {
        return isInCurrentWeek(day, value.date) || doesContainDay;
    }, false);
};

// only in calendar
export const includesDayWithNoIdInCurrentWeek = (day, array) => {
    return array.reduce((doesContainDay, value) => {
        return (isInCurrentWeek(day, value.date) && value.id === null) || doesContainDay;
    }, false);
};

// only in calendar
export const includesDay = (day, array) => {
    return array.reduce((value) => isSameDay(value.date, day), false);
};

// day is a string e.g Monday, Tuesday
export const setDayOfWeek = (date, day) => {
    return setISODay(new Date(date), daysOfWeek[day]);
};

// number is a number of days
export const getSubDays = (date, number) => {
    return subDays(date, number);
};

export const getMonths = () => {
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((monthIndex) => {
        return format(new Date(2000, monthIndex, 1), 'LLLL', { locale });
    });
    return months;
};
