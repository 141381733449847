<template>
    <li
        v-if="button.enabled" class="nav-link" :class="{ 'active-link': isSelected || isCurrentPage}" :style="customStyles">
        <a
            v-if="button.route === 'faq.categories'"
            :href="zendeskHcUrl"
            class="nav-link">
            <img :src="button.src" v-if="button.showImage" class="icon-image-text"/>
            <span>{{ $t('TOP_NAVIGATION_HELP') }}</span>
        </a>
        <router-link v-else :to="{name: this.isSelectedGifter}">
            <img :src="button.src" v-if="button.showImage" class="icon-image-text"/>
            <span>{{ $t('NAV_BUTTON_LABEL', { 'button.label': $t(`${button.label}`)}) }}</span>
        </router-link>
    </li>
</template>
<script>
import { mapGetters } from 'vuex';
import countryUtils from '@/resources/countryUtils.json';

export default {
    name: 'NavButton',
    props: {
        button: {
            required: true,
            default: {},
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        customStyles: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            isSelected: false,
            countryUtils: countryUtils.filter(item => item.iso.toLowerCase() === process.env.VUE_APP_COUNTRY_ISO.toLowerCase())[0],
        };
    },
    mounted() {
        this.isSelected = this.$route.name === this.button.route;
    },
    computed: {
        ...mapGetters({
            isNewGiftingFlow: 'account/isNewGiftingFlow',
            isGifter: 'account/isGifterAccount',
        }),
        zendeskHcUrl() {
            return this.countryUtils?.zendesk_hc_url;
        },
        isCurrentPage() {
            return this.$route.name === this.button.route;
        },
        newGiftingFlow() {
            return this.isNewGiftingFlow ? 'guest-gifting' : 'account.gifting.choose-your-gift';
        },
        isSelectedGifter() {
            let routeName = this.button.route;
            if (routeName === 'account.gifting.choose-your-gift') {
                return this.newGiftingFlow;
            }
            return routeName;
        },
    },
    watch: {
        '$route'(to) {
            this.isSelected = to.name === this.button.route;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

    .nav-menu {
        font-family: $secondary-font;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
    }

    .nav-mobile {
        text-decoration: none;
        &--selected {
            border-radius: 136px;
            padding: 8px;
            padding-left: 16px;
            padding-right: 16px;
            background-color: $secondary-color;
            text-decoration: none;
        }
    }

    li {
        list-style-type: none;
        font-size: 24px;
    }

    li .underline {
        height: 3px;
        background-color: transparent;
        width: 0%;
        transition: width 0.2s, background-color 0.5s;
        -moz-transition: width 0.2s, background-color 0.5s;
        -webkit-transition: width 0.2s, background-color 0.5s;
        margin: 0 auto;
        border-radius: 24px;
    }

    li.active-link .underline {
        width: 100%;
        background-color: $secondary-color;
    }

    li:hover .underline {
        background-color: $secondary-color;
        width: 100%;
    }

    .icon-image-text {
        position: absolute;
        top: 3%;
        left: -13%;
    }
</style>
