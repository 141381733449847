import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class SendGuestGiftingData {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(params) {
        // let apiUrl = ApiEndpoints['guest.gifting.products'];
        let apiUrl = url(ApiEndpoints['guest.gifting.products'], null, params);
        return this.apiService.get(apiUrl);
    }
}
