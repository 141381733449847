<template>
    <transition name="transitionSlideHamburgerFromLeft">
        <div v-if="show" class="overlay" @click="$emit('closed')">
            <div class="hamburger__container-v2" @click.stop="() => null" style="width: 100%">
                <div v-if="isFlowerBondHolder" class="bondholder">
                    <router-link  target="_blank" :to="{ name: 'flowerbond'}">
                        <div class="bondHolderButton">
                            <img class="bondHolderButton__icon" :src="flowerBondIcon" alt="Flower Bond Holder"/>
                            <span class="bondHolderButton__text" v-text="$t('NAV_BOND_HOLDER')"></span>
                        </div>
                    </router-link>
               </div>
                <div class="close-container">
                    <button class="hamburgerHeader__left__button close-btn-v2" :class="{'close-btn-bh' : isFlowerBondHolder }" @click="toggleShow">
                        <span class="hamburger__close-v2"></span>
                    </button>
                </div>

                <router-link :to="{ name: 'home'}" class="logo-section">
                    <div @click="$emit('closed')" style="display: flex; justify-content: center;">
                        <img
                            width="239"
                            height="34"
                            :src="fFLogoHorizontalGreen"
                            alt="Freddie's Flowers' logo"/>
                    </div>
                </router-link>

                <div class="navigation-gap-1"></div>

                <nav class="menu-container-v2">
                    <ul class="menu-container-v2--list">
                        <menu-item
                            class="mt-2"
                            v-for="(item, index) in menuItems2_V2_main"
                            :disabled="false"
                            :key="`menu-item-${index}`"
                            :item="item"
                            @menu="close()"
                            >
                        </menu-item>
                    </ul>

                    <ul v-if="hasNoActiveSubscription || isGifterAccount" class="menu-container-v2--list">
                        <menu-item
                            class="mt-2"
                            v-for="item in menuItems3_V2"
                            :disabled="false"
                            :key="item.route"
                            :item="item"
                            @menu="close()"/>
                        <li class="mt-2">
                            <a class="help" :href="countryUtils && countryUtils.zendesk_hc_url">{{ $t('TOP_NAVIGATION_HELP') }}</a>
                        </li>
                    </ul>

                    <ul v-else class="menu-container-v2--list">
                        <menu-item
                            class="mt-2"
                            v-for="item in menuItems1_V2"
                            :disabled="false"
                            :key="item.route"
                            :item="item"
                            @menu="close()"/>
                        <li class="mt-2">
                            <a class="help" :href="countryUtils && countryUtils.zendesk_hc_url">{{ $t('TOP_NAVIGATION_HELP') }}</a>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <div id="myAccountDropdown" :class="showMyAccountDropdown ? 'menu-container-v2--dropdown--active' : 'menu-container-v2--dropdown'">
                                <div class="menu-container-v2--dropdown--placeholder" @click="toggleShowMyAccountDropdown">
                                    <img :src="myAccountIcon" alt="MyAccount icon">
                                    <p class="placeholder-text">{{ $t('DASHBOARD_MY_ACCOUNT') }}</p>
                                    <img class="placeholder-icon" :src="greenArrowDownIcon" :style="myAccountArrowStyle" alt="Arrow icon">
                                </div>
                                <ul v-if="showMyAccountDropdown" class="menu-container-v2--list">
                                    <menu-item
                                        class="mt-2"
                                        v-for="(item, index) in menuItems2_V2_dropdown"
                                        :key="`menu-item-${index}`"
                                        :disabled="false"
                                        :item="item"
                                        @menu="close()"
                                        >
                                    </menu-item>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>

                <img class="nav-illustration-v2--1" src="@/assets/images/decoration/flower-illustration-nav-v2-1.svg" alt="Flower illustration">
                <img class="nav-illustration-v2--2" src="@/assets/images/decoration/flower-illustration-nav-v2-2.svg" alt="Flower illustration">
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationLinks from '@/config/NavigationLinks';
import MenuItem from '@/components/partials/account/MenuItem';
import countryUtils from '@/resources/countryUtils.json';

import FFLogoStacked from '@/assets/images/logos/freddies_flowers_stacked.svg';
import FFLogoHorizontalGreen from '@/assets/images/logos/freddies_flowers_logo_horizontal_green.svg';
import MyAccountIcon from '@/assets/images/decoration/my-account-icon.svg';
import GreenArrowDownIcon from '@/assets/images/decoration/arrow-down-green.svg';

export default {
    name: 'UserMenuV2',
    components: {
        MenuItem,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            flowerBondIcon: require('@/assets/images/decoration/icon-bondholder-nav.svg'),
            countryUtils: countryUtils.filter(item => item.locale.toLowerCase() === process.env.VUE_APP_DATE_LOCALE.toLowerCase())[0],
            window: {
                width: 0,
                mobileBreakpoint: 768,
            },
            fFLogoStacked: FFLogoStacked,
            fFLogoHorizontalGreen: FFLogoHorizontalGreen,
            myAccountIcon: MyAccountIcon,
            showMyAccountDropdown: true,
            greenArrowDownIcon: GreenArrowDownIcon,
        };
    },
    computed: {
        ...mapGetters({
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
            isFlowerBondHolder: 'account/getFlowerBondStatus',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            isGifterAccount: 'account/isGifterAccount',
        }),
        menuItems() {
            const allMenuItems = NavigationLinks.items;
            const enabled = allMenuItems.filter((item) => { return typeof item.hamburgerMenu !== 'undefined' && item.hamburgerMenu.enabled; });
            const hamburgerItems = enabled.map((item) => Object.assign(item.hamburgerMenu, item));
            const sortedItems = hamburgerItems.sort((prevItem, nextItem) => prevItem.order - nextItem.order);
            return sortedItems;
        },
        menuItems1() {
            return this.menuItems?.slice(0, 3);
        },
        menuItems1_V2() {
            return this.menuItems1?.slice(1);
        },
        menuItems2() {
            let length = this.menuItems.length;
            return this.isGifterAccount
                ? this.menuItems?.filter(menuItem => menuItem?.hamburgerMenu?.gifterDashboard)
                : this.menuItems.slice(3, length);
        },
        menuItems2_V2() {
            const menuItems2 = [...this.menuItems2];
            menuItems2.unshift(...this.menuItems.slice(0, 1));
            return menuItems2;
        },
        menuItems2_V2_main() {
            return this.menuItems2_V2?.slice(0, 2);
        },
        menuItems2_V2_dropdown() {
            return this.menuItems2_V2?.slice(2);
        },
        isMobile() {
            return this.window.width <= this.window.mobileBreakpoint;
        },
        menuItems3() {
            return this.menuItems.slice(0, 2);
        },
        menuItems3_V2() {
            return this.menuItems3.slice(1);
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
        myAccountArrowStyle() {
            return this.showMyAccountDropdown ? { transform: 'rotate(-180deg)' } : { transform: 'rotate(0deg)' };
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
        },
        toggleShow() {
            this.$emit('closed');
        },
        toggleShowMyAccountDropdown() {
            this.showMyAccountDropdown = !this.showMyAccountDropdown;
            // this.$scrollTo('#myAccountDropdown');
        },
        isPage(pageArr) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        close() {
            this.$emit('menu-clicked');
        },
    },
    // watch: {
    //     show(val) {
    //         if (!val) this.showMyAccountDropdown = false;
    //     },
    // },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

    .hamburger__divider {
        height: 5px;
        background-image: url('~@/assets/images/decoration/burger-marker.png');
        background-repeat: no-repeat;
        align-self: center;
        margin: 16px auto;
        width: 200px;
        background-size: cover;
    }
    .hamburger__landing-buttons__container {
        width: 100%;
    }
    .hamburger__landing-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 16px 10px;
    }
    .hamburger__landing-button {
        text-align: center;
        margin: 0 24px;
        img {
            margin: 0 auto;
            height: 45px;
            width: 45px;
        }
        h5 {
            font-size: 12px;
            margin: 8px 0 0;
        }
    }
    @media (min-width: $large) {
        .hamburger__landing-buttons__container {
            display: none;
        }
    }

    .navigation-gap-1 {
        height: 10%;
    }

    .navigation-gap-2 {
        height: 30%;

        @media screen and (max-height: $medium) {
            height: 25%;
        }
    }

    .menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-top: 1rem;
        color: $primary-color;

        &--list {
            list-style: none;
        }
    }

    .menu-container-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        color: $primary-color;

        &--list {
            list-style: none;
        }

        &--list * {
            @include button();

            margin-top: 29px !important;

            @media screen and (max-height: $medium) {
                margin-top: 19px !important;
            }
        }

        &--list *:first-child {
            margin-top: 0 !important;
        }

        &--list:nth-child(2) {
            // margin-top: 0px;
        }

        &--dropdown {
            width: 327px;

            margin-top: 9px;

            cursor: pointer;

            transition: .2s;

            &--placeholder {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    align-self: center;
                }

                img:first-child {
                    width: 40px;
                    height: 40px;

                    margin-right: 7px !important;
                }

                p {
                    @include button();

                    margin-left: 7px !important;
                }

                img:last-child {
                    width: 24px;
                    height: 24px;

                    margin-left: 7px !important;
                }
            }
            &--active {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                gap: 32px;

                width: 327px;

                // padding: 13px 0px 38px;
                // margin: -4px 24px 25px;

                border-radius: 16px;
                background: $card-white-color;

                cursor: pointer;

                transition: .5s;
                z-index: 1;
            }
        }
    }

    .placeholder-text, .placeholder-icon {
        margin-top: 0 !important;
        transition: .2s;
    }

    .nav-illustration-v2 {
        &--1, &--2 {
            position: fixed;

            opacity: 0.34;
        }

        &--1 {
            width: 206px;
            height: 424px;

            right: -20%;
            bottom: -150px;
            transform: rotate(17.9deg);
        }

        &--2 {
            width: 131px;
            height: 454px;

            left: -13%;
            bottom: -110px;
        }
    }

    .bottom-nav {
        margin-top: 201px !important;
    }

    .mt-2 {
        margin-top: 0.5rem;
    }

    .mt-4 {
        margin-top: 1rem;
    }

    .close-container {
        height: 48px;
    }

    .close-btn, .close-btn-v2 {
        position: absolute;
    }

    .close-btn {
        right: 24px;
        top: 24px;
    }

    .close-btn-v2 {
        right: 20px;
        top: 10px;
    }

    .image-class {
        position: absolute;
        bottom: 0px;
    }
    .close-btn-bh {
        position: absolute;
        right: 24px;
        top: 75px;
    }
    .bondholder {
        background-color: $shade-light-color;
        height: 60px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-right: 23px;
    }
    .close-btn-bh {
        position: absolute;
        right: 24px;
        top: 75px;
    }
    .bondholder {
        background-color: $shade-light-color;
        height: 60px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-right: 23px;
    }
    .help {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
</style>
