import Vue from 'vue';
import { formatDate } from '@/helpers/DateHelpers';
import * as Dates from '@/constants/Dates';
import * as registrationConsts from '@/constants/Registration';
import CouponService from '@/services/coupons/CouponService';
import RegistrationService from '@/services/users/RegistrationService';
import TaxService from '@/services/users/TaxService';
import i18n from '@/i18n';
import { getDeliveryPrice } from '@/helpers/QueryHelpers';
import { formatCouponCode } from '@/helpers/CouponHelpers';
import IncompleteRegistrationService from '@/services/users/incompleteRegistrationService/IncompleteRegistrationService';
import ProductsService from '@/services/logistics/ProductsService.js';
import { GIFT_TYPE_SINGLE, GIFT_TYPE_MULTI, PRODUCT_FAMILY_STANDARD } from '@/constants/Gifting';

const getDefaultState = () => {
    return {
        sections: {
            [registrationConsts.SECTION_ACCOUNT]: false,
            [registrationConsts.SECTION_DELIVERY]: false,
            [registrationConsts.SECTION_PAYMENT]: false,
        },
        details: {
            incompleteRegistrantUuid: null,
            welcomeName: null,
            account: {
                firstName: null,
                lastName: null,
                email: null,
                password: null,
                optInToMarketing: process.env.VUE_APP_COUNTRY_ISO === 'GBR',
                optOutToMarketing: false,
                optInToTextCalls: true,
                optInToEmailsLetters: true,
                optInToThirdPartyMarketing: false,
                doubleOptInMarketing: false,
                doubleOptInSpoilerMarketing: false,
                telephone: null,
            },
            billingAddress: {
                firstName: null,
                lastName: null,
                companyName: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                country: process.env.VUE_APP_COUNTRY_ISO,
                postcode: null,
                state: null,
            },
            deliveryAddress: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                country: process.env.VUE_APP_COUNTRY_ISO,
                postcode: null,
                state: null,
            },
            couponCode: null,
            friendCode: null,
            couponProperties: null,
            deliveryAddressIsBilling: true,
            deliveryComment: null,
            deliveryInstruction: null,
            deliveryCommentFurtherInfo: null,
            deliveryCommentOther: null,
            propertyType: null,
            card: {},
            firstPossibleDeliveryDate: null,
            firstDeliveryDateDesired: null,
            subscriptionsHostedPage: null,
            subscriptionsHostedPageUrl: null,
            courierName: null,
            couponDetails: null,
            AddedAddonItem: false,
            firstBoxPrePay: false,
            orderSummary: null,
        },
        plans: [
            {
                plan_name: i18n.t('PLAN_NAME_WEEKLY'),
                plan_interval: 'weekly',
                icon: 'weekly-deliveries.png',
                display: true,
                popular: false,
            },
            {
                plan_name: i18n.t('PLAN_NAME_FORTNIGHTLY'),
                plan_interval: 'fortnightly',
                pre_heading: '',
                icon: 'fortnightly-deliveries.png',
                display: true,
                popular: true,
            },
            {
                plan_name: i18n.t('PLAN_NAME_THREE_WEEKLY'),
                plan_interval: 'three-weekly',
                icon: 'fortnightly-deliveries.png',
                display: true,
                popular: false,
            },
            // {
            //     plan_name: 'Monthly (Every 4 weeks)',
            //     plan_interval: 'monthly',
            //     icon: 'fortnightly-deliveries.png',
            //     display: false,
            // },
        ],
        primaryProducts: [
            {
                name: 'Flower Box - A',
                price: Number(process.env.VUE_APP_BOX_PRICE),
                product_family: 'Standard',
            },
            {
                name: 'Flower Box - BBA',
                price: Number(process.env.VUE_APP_BUMPER_BOX_PRICE),
                product_family: 'Bumper',
            },
        ],
        checkoutProducts: null,
        propertyTypes: [
            {
                name: i18n.t('HOUSE'),
                icon: 'house',
            },
            {
                name: i18n.t('FLAT'),
                icon: 'flat',
            },
            {
                name: i18n.t('OFFICE'),
                icon: 'office',
            },
        ],
        taxes: {},
        preferredDeliveryDay: null,
        selectedPlan: null,
        checkoutPrice: 0,
        selectedProductID: null,
        selectedProductName: null,
        defaultProductFamily: 'standard',
        selectedProductDetails: {
            icon: 'Standard-icon.png',
            image: 'Standard-image.jpg',
            name: 'Flower Box - A',
            plan_name: i18n.t('PLAN_NAME_CLASSIC'),
            display_name: 'Classic',
            post_heading: 'For small to medium spaces',
            pre_heading: '',
            price: Number(process.env.VUE_APP_BOX_PRICE),
            product_family: 'Standard',
        }, // for new checkout flow.
        boxPrice: Number(process.env.VUE_APP_BOX_PRICE),
        bumperBoxPrice: Number(process.env.VUE_APP_BUMPER_BOX_PRICE),
        deliveryPrice: Number(process.env.VUE_APP_DELIVERY_PRICE || 0),
        region: null,
        source: null,
        canvasser: null,
        hasFetchedProducts: false,
        checkoutTestEnabled: false, // property to keep track of showstopper test flow for navigaton
        POVTestEnabled: process.env.VUE_APP_ENABLE_PRICE_TEST === 'true', // Enable price override test for any produce family
        overridePrice: Number(process.env.VUE_APP_PRICE_TEST), // override price for the test
        seasonalColor: '#B0D688',
        zeroNetTotalPrice: false,
        builderAnnouncementBarContent: null,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        setFirstBoxPrePay: (state, name) => state.details.firstBoxPrePay = name || false,
        setOrderSummary: (state, name) => state.details.orderSummary = name,
        setWelcomeName: (state, name) => state.details.welcomeName = name,
        setFirstName: (state, name) => state.details.account.firstName = name,
        setLastName: (state, name) => state.details.account.lastName = name,
        setEmail: (state, email) => state.details.account.email = email,
        setPassword: (state, password) => state.details.account.password = password,
        setOptInMarketing: (state, value) => state.details.account.optInToMarketing = value,
        setOptInEmailsLetters: (state, value) => state.details.account.optInToEmailsLetters = value,
        setOptInTextCalls: (state, value) => state.details.account.optInToTextCalls = value,
        setSelectedProductDetails: (state, value) => state.selectedProductDetails = value,
        setOptInThirdPartyMarketing: (state, value) => state.details.account.optInToThirdPartyMarketing = value,
        setDoubleOptInMarketing: (state, value) => state.details.account.doubleOptInMarketing = value,
        setDoubleOptInSpoilerMarketing: (state, value) => state.details.account.doubleOptInSpoilerMarketing = value,
        setSectionComplete: (state, section) => state.sections[section] = true,
        setDeliveryAddress: (state, address) => {
            state.details.deliveryAddress = Object.assign(state.details.deliveryAddress, address);
        },
        setBillingAddress: (state, address) => {
            state.details.billingAddress = Object.assign(state.details.billingAddress, address);
        },
        setFirstPossibleDeliveryDate: (state, date) => state.details.firstPossibleDeliveryDate = date,
        setCourierName: (state, name) => state.details.courierName = name,
        setFirstDeliveryDateDesired: (state, date) => state.details.firstDeliveryDateDesired = date,
        setDeliveryComment: (state, comment) => state.details.deliveryComment = comment,
        setDeliveryInstruction: (state, instruction) => state.details.deliveryInstruction = instruction,
        setDeliveryCommentFurtherInfo: (state, furtherInfo) => state.details.deliveryCommentFurtherInfo = furtherInfo,
        setDeliveryCommentOther: (state, comment) => state.details.deliveryCommentOther = comment,
        setPropertyType: (state, type) => state.details.propertyType = type,
        setTelephone: (state, telephone) => state.details.account.telephone = telephone,
        setDeliveryAddressIsBilling: (state, bool) => state.details.deliveryAddressIsBilling = bool,
        setCouponCode: (state, code) => state.details.couponCode = code,
        setFriendCode: (state, code) => state.details.friendCode = code,
        setCouponProperties: (state, code) => state.details.couponProperties = code,
        setIncompleteRegistrantUuid: (state, uuid) => state.details.incompleteRegistrantUuid = uuid,
        setPreferredDeliveryDay: (state, value) => state.preferredDeliveryDay = value,
        setSubscriptionsHostedPage: (state, hostedPage) => state.details.subscriptionsHostedPage = hostedPage,
        setSubscriptionsHostedPageUrl: (state, url) => state.details.subscriptionsHostedPageUrl = url,
        setPaymentToken: (state, tokenId) => state.details.paymentToken = tokenId,
        setPaymentType: (state, type) => state.details.paymentType = type,
        setTaxes: (state, payload) => state.taxes = payload,
        setCouponDetails: (state, payload) => state.details.couponDetails = payload,
        setSelectedPlan: (state, value) => state.selectedPlan = value,
        setSelectedProductID: (state, value) => state.selectedProductID = value,
        setSelectedProductName: (state, value) => state.selectedProductName = value,
        setCheckoutPrice: (state, value) => state.checkoutPrice = value,
        setRegionID: (state, value) => state.region = value,
        setSourceID: (state, value) => state.source = value,
        setCanvasserID: (state, value) => state.canvasser = value,
        setPropertyTypes: (state, value) => state.primaryProducts = value,
        setCheckoutProducts: (state, value) => state.checkoutProducts = value,
        setHasFetchedProducts: (state, value) => state.hasFetchedProducts = value,
        setBillingFirstName: (state, name) => state.details.billingAddress.firstName = name,
        setBillingLastName: (state, name) => state.details.billingAddress.lastName = name,
        setBillingCompanyName: (state, name) => state.details.billingAddress.companyName = name,
        setAddedAddonItem: (state, bool) => state.details.AddedAddonItem = bool,
        setCheckoutTestEnabled: (state, value) => state.checkoutTestEnabled = value,
        setSeasonalColor: (state, value) => state.seasonalColor = value,
        setZeroNetTotalPrice: (state, value) => state.zeroNetTotalPrice = value,
        setBuilderAnnouncementBarContent: (state, value) => state.builderAnnouncementBarContent = value,
    },
    actions: {
        getCouponByCouponCode({ commit }, couponCode) {
            return new Promise((resolve, reject) => {
                CouponService.getCouponByCouponCode(couponCode)
                    .then(response => {
                        if (response.data.properties.product_type !== GIFT_TYPE_SINGLE && response.data.properties.product_type !== GIFT_TYPE_MULTI) commit('setCouponDetails', response.data.properties);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        fetchTaxes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                TaxService.getTaxes(payload)
                    .then((res) => {
                        commit('setTaxes', res.data);
                        resolve(res);
                    })
                    .catch(error => reject(error));
            });
        },

        registerCustomer({ commit }, paymentDetails) {
            return new Promise((resolve, reject) => {
                RegistrationService.registerCustomer(paymentDetails)
                    .then((res) => {
                        commit('setSectionComplete', registrationConsts.SECTION_PAYMENT);
                        resolve(res);
                    })
                    .catch(error => reject(error));
            });
        },

        registerCanvassedCustomer({ commit }, paymentDetails) {
            return new Promise((resolve, reject) => {
                RegistrationService.registerCanvassedCustomer(paymentDetails)
                    .then((res) => {
                        commit('setSectionComplete', registrationConsts.SECTION_PAYMENT);
                        resolve(res);
                    })
                    .catch(error => reject(error));
            });
        },
        async fetchIncompleteRegistrationData({ commit, state }, payload) {
            let service = new IncompleteRegistrationService();
            let response = await service.getIncompleteRegistrationUserData(payload);
            let data = response.data.properties;
            let selectedPlan = state.plans?.filter(item => item.plan_interval === data.plan_slug)[0] || state.plans[0];
            commit('setFirstName', data.first_name);
            commit('setLastName', data.last_name);
            commit('setEmail', data.email);
            commit('setPassword', data.password);
            commit('setOptInMarketing', data.opt_in);
            commit('setOptInThirdPartyMarketing', data.third_party);
            commit('setRegionID', data.region_id);
            commit('setSourceID', data.source_id);
            commit('setCanvasserID', data.canvasser_id);
            commit('setDeliveryAddress', data.delivery_address);
            commit('setCourierName', data.courier_name);
            commit('setDeliveryComment', data.delivery_comment);
            commit('setPropertyType', data.property_type);
            commit('setTelephone', data.telephone);
            commit('setPreferredDeliveryDay', data.first_possible_delivery_date);
            commit('setSectionComplete', registrationConsts.SECTION_ACCOUNT);
            commit('setSectionComplete', registrationConsts.SECTION_DELIVERY);
            commit('setFriendCode', formatCouponCode(data.referral_code));
            commit('setCouponCode', formatCouponCode(data.referral_code));
            commit('setSelectedPlan', selectedPlan);
            commit('setIncompleteRegistrantUuid', data.uuid);
            commit('setFirstBoxPrePay', data.first_box_pre_pay);
            commit('setOrderSummary', data.order_summary);
            commit('setFirstDeliveryDateDesired', data.first_delivery_date_desired);
        },
        getAllProducts({ commit, state }, payload = {}) {
            let service = new ProductsService();

            if (payload.forceReload || !state.selectedProductDetails?.illustration_url) {
                service.getAllProducts(payload.subscriptionId)
                    .then((response) => {
                        commit('setPropertyTypes', response.data.products);
                        commit('setCheckoutProducts', response.data.products);
                        commit('setHasFetchedProducts', true);
                        commit('setSelectedProductDetails', ((state.selectedProductDetails?.illustration_url) ? state.selectedProductDetails : null) || response.data.products.find(item => item.product_family.toLowerCase() === PRODUCT_FAMILY_STANDARD));
                    })
                    .catch((err) => {
                        commit('setSelectedProductDetails', state.selectedProductDetails);
                        console.log(err.message);
                    });
            }
        },
    },
    getters: {
        getWelcomeName: state => state.details.welcomeName,
        getFirstName: state => state.details.account.firstName,
        getLastName: state => state.details.account.lastName,
        getFullName: state => {
            return `${state.details.account.firstName} ${state.details.account.lastName}`;
        },
        getEmail: state => state.details.account.email,
        getPassword: state => state.details.account.password,
        getOptInMarketing: state => state.details.account.optInToMarketing,
        getOptInTextCalls: state => state.details.account.optInToTextCalls,
        getOptInEmailsLetters: state => state.details.account.optInToEmailsLetters,
        getOptInThirdPartyMarketing: state => state.details.account.optInToThirdPartyMarketing,
        getDoubleOptInMarketing: state => state.details.account.doubleOptInMarketing,
        getDoubleOptInSpoilerMarketing: state => state.details.account.doubleOptInSpoilerMarketing,
        getDeliveryAddress: state => state.details.deliveryAddress,
        getBillingAddress: state => state.details.billingAddress,
        getFirstPossibleDeliveryDate: state => state.details.firstPossibleDeliveryDate,
        getCourierName: state => state.details.courierName,
        getFirstDeliveryDay: state => {
            return state.details.firstPossibleDeliveryDate ? formatDate(state.details.firstPossibleDeliveryDate, Dates.FULL_DAY) : null;
        },
        getFirstDeliveryDateDesired: state => state.details.firstDeliveryDateDesired,
        getDeliveryComment: state => state.details.deliveryComment,
        getDeliveryInstruction: state => state.details.deliveryInstruction,
        getDeliveryCommentFurtherInfo: (state) => state.details.deliveryCommentFurtherInfo,
        getDeliveryCommentOther: state => state.details.deliveryCommentOther,
        getPropertyType: state => state.details.propertyType,
        getTelephone: state => state.details.account.telephone,
        getDeliveryAddressIsBilling: state => state.details.deliveryAddressIsBilling,
        getCouponCode: state => state.details.couponCode,
        getFriendCode: state => state.details.friendCode,
        getCouponProperties: state => state.details.couponProperties,
        getIncompleteRegistrantUuid: state => state.details.incompleteRegistrantUuid,
        getSectionCompleted: state => section => state.sections[section],
        getPreferredDeliveryDay: state => state.preferredDeliveryDay,
        getSubscriptionsHostedPage: state => state.details.subscriptionsHostedPage,
        getSubscriptionsHostedPageUrl: state => state.details.subscriptionsHostedPageUrl,
        getPaymentToken: state => state.details.paymentToken,
        getPaymentType: state => state.details.paymentType,
        getTaxes: state => state.taxes,
        getCouponDetails: state => state.details.couponDetails,
        getAllPlans: state => state.plans,
        getSelectedPlan: state => state.selectedPlan || state.plans[1],
        getCheckoutPrice: state => state.checkoutPrice,
        getSelectedProductID: state => state.selectedProductID,
        getDefaultProductFamily: state => state.defaultProductFamily,
        getSelectedProductName: state => state.selectedProductName,
        getBoxPrice: state => state.boxPrice,
        getBumperBoxPrice: state => state.bumperBoxPrice,
        getSelectedProductDetails: state => state.selectedProductDetails,
        getPrimaryProducts: state => state.primaryProducts,
        getCheckoutProducts: state => state.checkoutProducts || [],
        getPropertyTypes: state => state.propertyTypes,
        getDeliveryPrice: () => getDeliveryPrice(),
        getRegionId: (state) => state.region,
        getSourceId: (state) => state.source,
        getCanvasserId: (state) => state.canvasser ? parseInt(state.canvasser) : null,
        getHasFetchedProducts: (state) => state.hasFetchedProducts,
        getBillingFirstName: state => state.details.billingAddress.firstName,
        getBillingLastName: state => state.details.billingAddress.lastName,
        getBillingCompanyName: state => state.details.billingAddress.companyName,
        getAddedAddonItem: (state) => state.details.AddedAddonItem,
        getCheckoutTestEnabled: (state) => state.checkoutTestEnabled,
        getPOVTestEnabled: (state) => state.POVTestEnabled,
        getOverridePrice: (state) => state.overridePrice,
        getSeasonalColor: (state) => state.seasonalColor,
        getZeroNetTotalPrice: (state) => state.zeroNetTotalPrice,
        getSteps: () => currentRoute => [
            {
                number: 1,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_DELIVERY_DETAILS'),
                route: 'register.delivery-details',
                isActive: currentRoute === 'register.delivery-details',
            },
            {
                number: 2,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_YOUR_DETAILS'),
                route: 'register.your-details',
                isActive: currentRoute === 'register.your-details',
            },
            {
                number: 3,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_PAYMENT_DETAILS'),
                route: 'register.payment-details',
                isActive: currentRoute === 'register.payment-details',
            },
        ],
        getStepsForGifter: () => currentRoute => [
            {
                number: 1,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_SUBSCRIPTION_DETAILS'),
                route: 'gifting.register.subscription-details',
                isActive: currentRoute === 'gifting.register.subscription-details',
            },
            {
                number: 2,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_DELIVERY_DETAILS'),
                route: 'gifting.register.delivery-details',
                isActive: currentRoute === 'gifting.register.delivery-details',
            },
            {
                number: 3,
                label: i18n.t('REGISTER_CHECKOUT_PROGRESS_BAR_PAYMENT_DETAILS'),
                route: 'gifting.register.payment-details',
                isActive: currentRoute === 'gifting.register.payment-details',
            },
        ],
        getSubscriptionsSteps: () => currentRoute => [
            {
                number: 1,
                label: i18n.t('SUBSCRIPTIONS_CHECKOUT_PROGRESS_BAR_YOUR_DETAILS'),
                route: 'subscriptions.checkout.your-details',
                isActive: currentRoute === 'subscriptions.checkout.your-details',
            },
            {
                number: 2,
                label: i18n.t('SUBSCRIPTIONS_CHECKOUT_PROGRESS_BAR_DELIVERY_DETAILS'),
                route: 'subscriptions.checkout.delivery-details',
                isActive: currentRoute === 'subscriptions.checkout.delivery-details',
            },
            {
                number: 3,
                label: i18n.t('SUBSCRIPTIONS_CHECKOUT_PROGRESS_BAR_PAYMENT_DETAILS'),
                route: 'subscriptions.checkout.payment-details',
                isActive: currentRoute === 'subscriptions.checkout.payment-details',
            },
        ],
        getBuilderAnnouncementBarContent: (state) => state.builderAnnouncementBarContent,
        getFirstBoxPrePay: (state) => state.details.firstBoxPrePay,
        getOrderSummary: (state) => state.details.orderSummary,
    },
};
