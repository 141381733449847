module.exports = {
    'users.index': 'users/',
    'users.update': 'users',
    'users.incomplete-registrations.store': 'incomplete-registrations',
    'users.incomplete-registrations.update': 'incomplete-registrations/$1',
    'users.postcheckout-questions.index.gbr': 'users/postcheckout-questions',
    'users.postcheckout-questions.index': 'users/postcheckout-questions/$1',
    'users.postcheckout-questions.store': 'users/postcheckout-questions',
    'users.register.store': 'users/register',
    'users.referral.index': 'user/referrals',
    'users.referral.store': 'user/referrals',
    'users.referral.update': 'user/referrals/$1',
    'users.referral.optout': 'user/referrals/optout/$1',
    'users.validate.email': 'validate/email',
    'users.preferences.dislikes-lilies.store': 'users/$1/dislikes-lilies',
    'users.validate.telephone': 'validate/telephone',
    'users.preferences.weekly-email.store': 'users/$1/preferences/weekly-email',
    'users.validate.registration.introductions.store': 'users/validate/registration/introductions',
    'users.validate.registration.delivery.store': 'users/validate/registration/delivery',
    'couponsHistory': 'user/coupons-history',
    'users.get-skip-delivery-reasons': '/skipping-delivery-reasons',
    'users.getSpecialDates': 'users/$1/occasions',
    'users.createSpecialDates': 'occasions',
    'users.updateSpecialDates': 'occasions/$1',
    'users.deleteSpecialDates': 'occasions/$1',
    'users.getOccasionTypes': 'occasions/types',
    'users.preferences.double-optin-confirmation.store': 'users/$1/preferences/double-optin-confirmation',
    'users.preferences.double-optin-spoiler-confirmation.store': 'users/$1/preferences/double-optin-spoiler-confirmation',
    'users.outstanding-orders.index': 'users/$1/outstanding-orders',
    'users.outstanding-orders.update': 'users/$1/outstanding-orders',
    'users.terminal.connection': 'terminal-connection-token',
    'setupintents.users.store': 'setupintents/users',
    'payment-intent': 'paymentintents/users',
    'users.promotional-banners': 'promotional-banners',
    'users.update-feature.update': 'users/$1/update-feature',
    'gifter.register': 'gifter/subscriptions',
    'users.themes': 'dashboard-themes',
    'users.braintree.client-token': 'payments/client-token-braintree',
    'users.payment-method': 'payments/users/$1/payment-methods',
    'users.survey-form': 'nps/survey/$1/$2',
    'users.send-survey-data': 'nps/feedback',
};
