import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class DeleteAddresses {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(userId, addressId) {
        let apiUrl = prepareUrl(ApiEndpoints['addresses.delete'], [userId, addressId]);

        return this.apiService.delete(apiUrl);
    }
}
