<template>
    <div class="contact-us">
        <div class="contact-us--container" :style="USSpecificStyles">
            <div class="contact-us--ourFlowers">
                <p class="contact-us--title">{{$t('CONTACT_US_OUR_FLOWERS')}}</p>
                <div class="contact-us--links">
                    <router-link class="contact-us--link-container-1 contact-us--link" :to="{name: 'flowers'}">{{$t('LANDING_FLOWERS')}}</router-link>
                    <router-link class="contact-us--link-container-1 contact-us--link" :to="{name: 'arranging'}">{{$t('LANDING_ARRANGING')}}</router-link>
                    <router-link class="contact-us--link-container-1 contact-us--link" :to="{name: 'delivery'}">{{$t('LANDING_DELIVERY')}}</router-link>
                    <router-link v-if="isGBR" class="contact-us--link-container-1 contact-us--link" :to="{name: 'how-it-works'}">{{$t('LANDING_HOW_IT_WORKS')}}</router-link>
                    <router-link v-if="isGBR" class="contact-us--link-container-1 contact-us--link" :to="{name: 'freddies-story'}">{{$t('LANDING_MEET_FREDDIE')}}</router-link>
                    <router-link class="contact-us--link-container-1 contact-us--link" :to="{name: 'sustainability'}">{{$t('CONTACT_US_NAV_LINK_SUSTAINABILITY')}}</router-link>
                </div>
            </div>
            <div class="contact-us--company">
                <p class="contact-us--title">{{$t('CONTACT_US_COMPANY')}}</p>
                <div class="contact-us--links">
                    <router-link v-if="isGBR" :to="{ name: 'blog' }" class="contact-us--link-container-2 contact-us--link" target="_blank">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_BLOG') }}
                        </div>
                    </router-link>
                    <router-link v-if="!isDEU" :to="{ name: 'jobs' }" class="contact-us--link-container-2 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_JOBS') }}
                        </div>
                    </router-link>
                    <router-link v-if="isDEU" :to="{ name: 'impressum' }" class="contact-us--link-container-2 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_IMPRESSUM') }}
                        </div>
                    </router-link>
                    <router-link v-if="isGBR" :to="{ name: 'survey-entrants' }" target="_blank" class="contact-us--link-container-2 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_SURVEY_ENTRANTS') }}
                        </div>
                    </router-link>
                    <router-link v-if="isGBR" :to="{ name: 'tag-rugby-trust' }" class="contact-us--link-container-2 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_TRT_PAGE') }}
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="contact-us--needHelp">
                <p class="contact-us--title">{{$t('HAMBURGER_HELP')}}</p>
                <div class="contact-us--links">
                    <a class="contact-us--link-container-3 contact-us--link" :href="countryUtils.zendesk_hc_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                        {{ $t('CONTACT_US_VISIT_OUR_HELP_CENTER') }}
                        <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                    </a>
                    <a class="contact-us--link-container-3 contact-us--link" :href="countryUtils.zendesk_hc_my_account_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                        {{ $t('CONTACT_US_HELP_CENTER_MY_ACCOUNT') }}
                        <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                    </a>
                    <a class="contact-us--link-container-3 contact-us--link" :href="countryUtils.zendesk_hc_my_delivery_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                        {{ $t('CONTACT_US_HELP_CENTER_DELIVERIES') }}
                        <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                    </a>
                    <router-link class="contact-us--link-container-3 contact-us--link" :to="{name: 'business-enquiries'}">
                        {{ $t('CONTACT_US_BUSINESS') }}
                    </router-link>
                </div>
            </div>
            <div class="contact-us--legal">
                <p class="contact-us--title">{{$t('CONTACT_US_LEGAL')}}</p>
                <div class="contact-us--links">
                    <a href="#" class="contact-us--link-container-4 contact-us--link">
                        <div @click="showCookieBanner()">
                            {{ $t('CONTACT_US_COOKIE') }}
                        </div>
                    </a>
                    <router-link :to="{ name: 'terms-and-conditions' }" class="contact-us--link-container-4 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_TERMS') }}
                        </div>
                    </router-link>
                    <router-link v-if="isDEU" :to="{ name: 'payment-and-delivery' }" class="contact-us--link-container-4 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_PAYMENT_AND_DELIVERY') }}
                        </div>
                    </router-link>
                    <router-link v-if="isDEU" :to="{ name: 'right-to-cancel' }" class="contact-us--link-container-4 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_RIGHT_TO_CANCEL') }}
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'privacy-policy' }" class="contact-us--link-container-4 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_PRIVACY') }}
                        </div>
                    </router-link>
                    <router-link :to="{ name: 'accessibility' }" class="contact-us--link-container-4 contact-us--link">
                        <div @click="$emit('closed')">
                            {{ $t('CONTACT_US_ACCESSIBILITY') }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <hr class="contact-us--line-1" />

        <div class="contact-us--infoSection">
            <div class="contact-us--infoSecContainer-and-copyRights">
                <div class="contact-us--infoSection-container">
                    <div class="contact-us--socialLinks-container">
                        <p class="contact-us--socialLinks-title"> {{ $t('FOOTER_FOLLOW_US') }}: </p>
                        <div class="contact-us--socialLinks">
                            <a class="mx-2" :href="$t('FACEBOOK_URL')" target="_blank">
                                <img class="hamburger__socialIcon"
                                    src="@/assets/images/decoration/social-icon-facebook-secondary.svg"
                                    alt="Facebook"
                                    loading="lazy" />
                            </a>

                            <a class="mx-2" :href="$t('INSTAGRAM_URL')" target="_blank">
                                <img class="hamburger__socialIcon"
                                    src="@/assets/images/decoration/social-icon-instagram-secondary.svg"
                                    alt="Instagram"
                                    loading="lazy" />
                            </a>
                        </div>
                    </div>
                    <div class="contact-us--country-and-payments">
                        <div class="contact-us--countrySelector">
                            <country-switch-dropdown
                                :showFullName="true"
                                :showBorder="false"
                                selectedBackground="transparent"
                                optionsDirection="top"/>
                        </div>
                        <div class="contact-us--payment-methods-container">
                            <img
                                v-for="(paymentMethod, index) in paymentMethods"
                                :key="`payment-method-${index}`"
                                class="contact-us--payment-methods-container--payment-method"
                                :src="paymentMethod.image"
                                :alt="paymentMethod.imageAlt"
                                loading="lazy" />
                        </div>
                    </div>
                </div>

                <div class="contact-us--payment-methods-container-v2">
                    <img
                        v-for="(paymentMethod, index) in paymentMethods"
                        :key="`payment-method-${index}`"
                        class="contact-us--payment-methods-container-v2--payment-method"
                        :src="paymentMethod.image"
                        :alt="paymentMethod.imageAlt"
                        loading="lazy" />
                </div>

                <hr class="contact-us--line-2" />

                <div class="contact-us--copyRights-and-policies">
                    <div class="contact-us--copyRights">
                        <p>&copy; {{$t('CONTACT_US_COPYRIGHT')}}</p>
                    </div>
                </div>

            </div>

            <div class="contact-us--certification">
                <img class="contact-us--certification--image" :src="bCorpLogo" alt="B-Corp Certified logo" loading="lazy" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CountrySwitchDropdown from '@/components/structure/navigation/CountrySwitchDropdown';
import OpenInNewTabIcon from '@/assets/images/icons/open-in-new-tab.svg';
import countryUtils from '@/resources/countryUtils.json';

import MasterCardIcon from '@/assets/icons/footer/master-card.svg';
import VISAIcon from '@/assets/icons/footer/visa.svg';
import ApplePayIcon from '@/assets/icons/footer/apple-pay.svg';
import PayPalIcon from '@/assets/icons/footer/pay-pal.svg';
import BCorpLogo from '@/assets/icons/footer/b-corp-logo.svg';

export default {
    name: 'ContactUs',
    components: {
        CountrySwitchDropdown,
    },
    data() {
        return {
            email: process.env.VUE_APP_EMAIL,
            contactNumber: process.env.VUE_APP_TELEPHONE,
            width: null,
            openInNewTabIcon: OpenInNewTabIcon,
            countryUtils: countryUtils.filter(item => item.iso === process.env.VUE_APP_COUNTRY_ISO)[0],
            paymentMethods: [
                {
                    name: 'MasterCard',
                    image: MasterCardIcon,
                    imageAlt: 'MasterCard Icon',
                },
                {
                    name: 'VISA',
                    image: VISAIcon,
                    imageAlt: 'VISA card Icon',
                },
                {
                    name: 'ApplePay',
                    image: ApplePayIcon,
                    imageAlt: 'ApplePay Icon',
                },
                {
                    name: 'PayPal',
                    image: PayPalIcon,
                    imageAlt: 'PayPal Icon',
                },
            ],
            bCorpLogo: BCorpLogo,
        };
    },
    props: {
        faqsLink: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
    created() {
        window.addEventListener('resize', this.getWidth);
    },
    destroyed() {
        window.removeEventListener('resize', this.getWidth);
    },
    mounted() {
        this.getWidth();
    },
    methods: {
        showCookieBanner() {
            this.$store.commit('cookies/showCookieBanner');
        },
        getWidth() {
            this.width = window.innerWidth;
        },
    },
    computed: {
        ...mapGetters({
            isDEU: 'localisation/isDEU',
            isUSA: 'localisation/isUSA',
            isGBR: 'localisation/isGBR',
            isUserLoggedIn: 'auth/isUserLoggedIn',
        }),
        USSpecificStyles() {
            let styles = {};
            if (this.isUSA & this.width > 720) styles.gridTemplateColumns = '1fr 1fr 1fr';
            if (this.isUSA & this.width <= 720) styles.gridTemplateColumns = '1fr 1fr';
            return styles;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';
.contact-us {
    .contact-us--container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 80%;
        margin: 0px auto;
        margin-bottom: 30px;
        @media screen and (min-width: $medium) {
            width: 100%;
            padding: 0px 97px;
            grid-template-columns: repeat(4, 1fr);
            justify-content: space-around;
        }
    }
    &--company, &--needHelp, &--legal, &--ourFlowers {
        font-size: 16px;
    }
    &--links {
        overflow: hidden;
        p {
            font-size: 16px;
        }
    }
    &--title {
        @include label();
    }
    &--link-container-1, &--link-container-2, &--link-container-3, &--link-container-4 {
        margin-top: 16px;

        display: block;
    }
    &--link-container-1:first-child, &--link-container-2:first-child, &--link-container-3:first-child, &--link-container-4:first-child {
        margin-top: 6px;
    }
    &--link {
        @include body();

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &--line-1, &--line-2 {
        height: 1px;

        background: #214944;
        border-color: #214944;
    }

    &--icon {
        display: inline;
        width: 10px;
    }
    &--socialLinks {
        margin: 20px 10px;
        display: flex;
    }
    &--country-and-payments {
        display: flex;
    }
    &--countrySelector {
        margin: 10px;
    }
    &--infoSection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 15px 28px 0 0;
    }

    &--infoSecContainer-and-copyRights {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &--infoSection-container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        padding-left: 97px;
        margin-bottom: 15px;
    }
    &--socialLinks-container {
        display: flex;
        align-items: center;
    }

    &--payment-methods-container {
        display: flex;

        @media (max-width: $medium) {
            display: none;
        }

        &--payment-method {
            margin-left: 16px;
        }
    }

    &--payment-methods-container-v2 {
        display: flex;
        justify-content: center;

        padding-bottom: 16px;

        @media (min-width: $medium) {
            display: none;
        }

        &--payment-method {
            margin-left: 16px;
        }
    }

    &--copyRights-and-policies {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 16px;
        padding-left: 97px;
    }

    &--copyRights p {
        @include body();
    }

    &--certification {
        margin-left: 29px;
    }
}
</style>
