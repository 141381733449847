<template>
    <transition name="transitionSlideHamburgerFromLeft">
        <div v-if="show" class="overlay" @click="$emit('closed')">
            <div :class="'hamburger__container-v2'" @click.stop="() => null" style="width: 100%">
                <div class="close-container">
                    <button class="hamburgerHeader__left__button close-btn-v2" @click="toggleShow">
                        <span class="hamburger__close-v2"></span>
                    </button>
                </div>

                <div class="hamburger-body">
                    <router-link class="hamburger-body--logo-section" :to="{ name: 'home'}">
                        <img
                            @click="toggleShow"
                            width="239"
                            height="34"
                            :src="fFLogoHorizontalGreen"
                            alt="Freddie's Flowers' logo"/>
                    </router-link>

                    <div class="navigation-gap-1"></div>

                    <nav v-if="!isPage(['account'])" class="menu-container-v2">
                        <ul class="menu-container-v2--list">
                            <li :key="`item-${index}`" v-for="(item, index) in filteredButtons1" @click="go(item.route)" class="menu-container-v2--list--item">
                                <router-link
                                    :to="{ name: item.route }"
                                    :class="{ 'selected-route': isActiveRoute(item) }">
                                    <img :src="item.src" v-if="item.showImage" class="icon-image-text"/>
                                    <span v-text="item.label"></span>
                                </router-link>
                            </li>

                            <li class="menu-container-v2--list--item" v-click-outside="hideLearnMoreDropdown">
                                <div id="learnMoreDropdown" :class="showLearnMoreDropdown ? 'menu-container-v2--dropdown--active' : 'menu-container-v2--dropdown'">
                                    <div class="menu-container-v2--dropdown--placeholder" @click="toggleShowLearnMoreDropdown">
                                        <p>{{ $t('FREDDIES_LEARN') }}</p>
                                        <img class="menu-container-v2--dropdown--placeholder--arrow-icon" :style="learnMoreArrowStyle" :src="greenArrowDownIcon" alt="Down-Arrow icon in Green">
                                    </div>

                                    <hr v-if="showLearnMoreDropdown" class="menu-container-v2--dropdown--active--hr-line" />

                                    <ul v-if="showLearnMoreDropdown" class="menu-container-v2--list">
                                        <li :key="`item-${index}`" v-for="(item, index) in routesUnderLearnMoreDropdown" @click="go(item.route)" class="menu-container-v2--list--item">
                                            <div class="menu-container-v2--list--item--container">
                                                <div class="menu-container-v2--list--item--container--image-section">
                                                    <img class="menu-container-v2--list--item--container--image-section--image" :src="item.image" :alt="item.imageAlt">
                                                </div>
                                                <div class="menu-container-v2--list--item--container--text-section">
                                                    <p class="menu-container-v2--list--item--container--text-section--heading"> {{ item.heading }} </p>
                                                    <p class="menu-container-v2--list--item--container--text-section--description"> {{ item.description }} </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li class="menu-container-v2--list--item signin-link-item">
                                <img class="menu-container-v2--list--item--image my-account-icon" :src="myAccountIcon" alt="My Account icon in Green">
                                <span class="menu-container-v2--list--item--link" @click="toggleShow()">
                                    <login-link class="login-link"></login-link>
                                </span>
                            </li>

                            <li class="menu-container-v2--list--item item-btn btn--primary-black btn--half signup-link-item"
                                @click="goToSignUp()">
                                {{ $t('SUBSCRIBE_NOW') }}
                            </li>
                        </ul>
                    </nav>
                </div>

                <img class="nav-illustration-v2--1" src="@/assets/images/decoration/flower-illustration-nav-v2-1.svg" alt="Flower illustration">
                <img class="nav-illustration-v2--2" src="@/assets/images/decoration/flower-illustration-nav-v2-2.svg" alt="Flower illustration">
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginLink from '@/components/structure/navigation/navigation/LogInLink';

import FFLogoStacked from '@/assets/images/logos/freddies_flowers_stacked.svg';
import FFLogoHorizontalGreen from '@/assets/images/logos/freddies_flowers_logo_horizontal_green.svg';
import GreenArrowDownIcon from '@/assets/images/decoration/arrow-down-green.svg';
import MyAccountIcon from '@/assets/images/decoration/my-account-icon.svg';

export default {
    name: 'HamburgerV2',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fFLogoStacked: FFLogoStacked,
            fFLogoHorizontalGreen: FFLogoHorizontalGreen,
            greenArrowDownIcon: GreenArrowDownIcon,
            myAccountIcon: MyAccountIcon,
            showLearnMoreDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            buttons: 'nav/getButtons',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            routesUnderLearnMoreDropdown: 'nav/getRoutesUnderLearnMoreDropdown',
        }),
        filteredButtons() {
            const status = (this.isUserLoggedIn ? 'loggedIn' : 'loggedOut');
            if (this.buttons.filter(button => button.label.toLowerCase() === 'gifting' && !this.isUserLoggedIn)[0]) this.buttons.filter(button => button.label.toLowerCase() === 'gifting' && !this.isUserLoggedIn)[0].route = 'guest-gifting';
            return this.buttons.filter(button => (button[status] || button['isMobile'] || button.label.toLowerCase() === 'subscriptions') && button.label.toLowerCase() !== 'home');
        },
        filteredButtons1() {
            return this.filteredButtons?.slice(0, 2);
        },
        filteredButtons2() {
            return this.routesUnderLearnMoreDropdown.map(route => this.filteredButtons.filter(filteredButton => filteredButton.route === route.route)[0]);
        },
        learnMoreArrowStyle() {
            return this.showLearnMoreDropdown ? { transform: 'rotate(-180deg)' } : { transform: 'rotate(0deg)' };
        },
    },
    methods: {
        go(route) {
            this.$router.push({ name: route });
            this.toggleShow();
        },
        toggleShow() {
            this.$emit('closed');
        },
        toggleShowLearnMoreDropdown() {
            this.showLearnMoreDropdown = !this.showLearnMoreDropdown;
            // this.$scrollTo('#learnMoreDropdown');
        },
        isActiveRoute(item) {
            return this.$route.name === item.route;
        },
        isPage(pageArr) {
            const pageRoot = this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        goToSignUp() {
            this.toggleShow();
            this.$router.push({ name: 'register' });
        },
        hideLearnMoreDropdown() {
            if (this.showLearnMoreDropdown) this.showLearnMoreDropdown = false;
        },
    },
    components: {
        LoginLink,
    },
};
</script>

<style lang="scss">
@import '@/assets/sass/brand/theme.scss';

    .close-btn, .close-btn-v2 {
        position: absolute;
    }

    .close-btn {
        right: 24px;
        top: 24px;
    }

    .close-btn-v2 {
        right: 20px;
        top: 10px;
    }

    .hamburger-body {
        height: 100%;

        &--logo-section {
            display: flex;
            justify-content: center;
        }
    }

    .navigation-gap-1 {
        height: 10%;
    }

    .navigation-gap-2 {
        height: 30%;

        @media screen and (max-height: $medium) {
            height: 25%;
        }
    }

    .menu-container-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        color: $primary-color;

        &--list * {
            @include button();

            margin-top: 29px !important;

            @media screen and (max-height: $medium) {
                margin-top: 19px !important;
            }
        }

        &--list img, p {
            margin: 0 !important;
        }

        &--list *:first-child {
            margin-top: 0 !important;
        }

        &--list {
            list-style: none;

            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &--item:first-child {
                margin-top: 0;
            }

            .item-btn {
                max-width: 200px;

                padding-left: 0.5rem;
                padding-right: 0.5rem;

                border-radius: $radius-small;
            }

            &--item {
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;

                margin-top: 27px;

                .dropdown-link {
                    display: flex;
                    justify-content: space-evenly;
                }

                &--link {
                    margin-top: 0 !important;
                }

                &--container {
                    width: 278px;
                    height: 80px;
                    flex-shrink: 0;

                    display: flex;
                    justify-content: space-between;

                    &--image-section {
                        &--image {
                            width: 80px;
                            height: 80px;

                            max-width: none;

                            border-radius: 8px;

                            @include small();
                            overflow: scroll;
                        }
                    }

                    &--text-section {
                        margin-top: 0 !important;

                        text-align: left;

                        width: 180px;

                        align-self: center;

                        &--description {
                            @include small();
                        }
                    }
                }
            }
        }

        .selected-route, .selected-route-dropdown-item {
            border-radius: 20px;

            padding: 7px 18px 5px;

            width: fit-content;
        }

        .selected-route {
            background-color: $card-white-color;
        }

        .selected-route-dropdown-item {
            background-color: $secondary-color;
        }

        &--dropdown {
            width: 327px;

            margin-top: 9px;

            cursor: pointer;

            transition: .5s;

            &--placeholder {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                p {
                    @include button();

                    margin-right: 5px !important;
                }

                img {
                    width: 24px;
                    height: 24px;

                    margin-left: 5px !important;
                    align-self: center;
                }

                &--arrow-icon {
                    transform: rotate(0deg);
                    transition: .5s;
                }
            }
            &--active {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 32px;

                width: 327px;
                max-width: 340px;

                padding: 16px 16px 16px;
                margin: -4px 24px 25px;

                border-radius: 16px;
                background: $card-white-color;

                position: relative;

                transition: .5s;
                z-index: 1;

                -webkit-tap-highlight-color: transparent;

                &--hr-line {
                    width: 226px;
                    height: 1px;

                    opacity: 0.25;
                    background: $shade-card-color;

                    margin-top: 0 !important;
                }

                .menu-container-v2--list {
                    margin-top: 0 !important;
                }

                .menu-container-v2--list--item {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .signin-link-item {
        margin-top: 80% !important;

        @media screen and (max-height: $medium) {
            margin-top: 50% !important;
        }
    }

    .signup-link-item {
        width: 200px;
        height: 48px;
    }

    .my-account-icon {
        width: 40px;
        height: 40px;

        margin-right: 6px;
    }

    .login-link {
        margin-left: 6px;
    }

    .nav-illustration-v2 {
        &--1, &--2 {
            position: fixed;

            opacity: 0.34;
        }

        &--1 {
            width: 206px;
            height: 424px;

            right: -20%;
            bottom: -150px;
            transform: rotate(17.9deg);
        }

        &--2 {
            width: 131px;
            height: 454px;

            left: -13%;
            bottom: -110px;
        }
    }

    .hamburger__landing-buttons__container {
        width: 100%;
        text-align: center;
    }
    .hamburger__landing-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 16px 10px;
        color: $primary-color;
    }
    .hamburger__landing-button {
        text-align: center;
        margin: 0 24px;
        img {
            margin: 0 auto;
            height: 45px;
            width: 45px;
        }
        h5 {
            font-size: 12px;
            margin: 8px 0 0;
        }
    }
    @media (min-width: 1024px) {
        .hamburger__landing-buttons__container {
            display: none;
        }
    }

    .bottom-menu-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $link-color;
    }

    .overlay {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100%;
        height: 120%;
        background: transparent;
        animation: fade .5s ease forwards;
    }

    @keyframes fade {
        from {
            background: transparent;
        }

        to {
            background:  rgba(1, 1, 1, 0.5);
        }
    }

    .flower-decoration {
        position: absolute;
        bottom: 0px;
    }
    .icon-text {
        position: relative;
    }
    .icon-image-text {
        position: absolute;
        top: -15px;
        left: 40px;
    }
</style>
