import isString from 'lodash/isString';
import { exception } from '@/exceptions/Exceptions';

export const capitalizeFirstLetter = (string) => {
    if (!isString(string)) exception('String must be a valid string.');

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const convertNumberToWords = (number, joinCharacter = 'and') => {
    let numberString = number.toString();

    // Is number zero?
    if (parseInt(numberString) === 0) {
        return 'zero';
    }

    // Array of units as words
    let unitWords = [
        '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve',
        'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen',
    ];

    // Array of tens as words
    let tenWords = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    // Array of scales as words
    let scaleWords = [
        '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion',
        'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion',
        'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion',
        'centillion',
    ];

    let hundredStarts = numberString.length;
    let chunkByHundreds = [];

    // Split user arguemnt into 3 digit chunks from right to left
    while (hundredStarts > 0) {
        let hundredEnds = hundredStarts;
        hundredStarts = Math.max(0, hundredStarts - 3);
        chunkByHundreds.push(numberString.slice(hundredStarts, hundredEnds));
    }

    let numberWords = [];

    // Stringify each integer in each chunk
    for (let i = 0; i < chunkByHundreds.length; i++) {
        // Split chunk into array of individual integers
        let integers = chunkByHundreds[i].split('').reverse().map(parseFloat);

        // If tens integer is 1, i.e. 10, then add 10 to units integer
        if (integers[1] === 1) integers[0] += 10;

        let scaleWord = scaleWords[i];

        // Add scale word if chunk is not zero and array item exists
        if (scaleWord) numberWords.push(scaleWord);

        let unitWord = unitWords[integers[0]];

        // Add unit word if array item exists
        if (unitWord) numberWords.push(unitWord);

        let tenWord = tenWords[integers[1]];

        // Add tens word if array item exists
        if (tenWord) numberWords.push(tenWord);

        // Add 'and' string after units or tens integer if
        if (integers[0] || integers[1]) {
            // Chunk has a hundreds integer or chunk is the first of multiple chunks
            if (integers[2] || !i) numberWords.push(joinCharacter);
        }

        let hundredWord = unitWords[integers[2]];

        // Add hundreds word if array item exists
        if (hundredWord) numberWords.push(hundredWord + ' hundred');
    }

    numberWords.reverse();

    // If the first word is the joinCharacter, remove from word list.
    if (numberWords[0] === joinCharacter) numberWords.shift();

    return numberWords.join(' ');
};

export const convertToCamelCase = (string) => {
    if (!isString(string)) exception('String must be a valid string.');

    let camelCasedString = string.replace(/([-_ ][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '')
            .replace(/ /g, '');
    });

    return camelCasedString.charAt(0).toLowerCase() + camelCasedString.slice(1);
};

export const convertToSnakeCase = (string) => {
    if (!isString(string)) exception('String must be a valid string.');

    let snakeCaseString = string.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase();

    return snakeCaseString.replace(/([-_ ][a-z])/ig, ($1) => {
        return $1.replace('-', '_').replace(/ /g, '_');
    });
};

export const removeWhiteSpaces = (string) => {
    if (!isString(string)) exception('String must be a valid string.');

    return string.replace(/ /g, '');
};

export const replaceWhiteSpaces = (string) => {
    if (!isString(string)) exception('String must be a valid string.');

    return string.replace(/ /g, '%20');
};

export const trimString = (string, length, ellipses = false) => {
    let maxLength = ellipses ? (length - 4) : length;
    let trimmedString = string.substr(0, maxLength);

    return ellipses ? `${trimmedString} ...` : trimmedString;
};

export const notEmpty = (string) => {
    return (string || '').length > 0;
};

export const reduceNumbers = (str) => {
    const regex = /\d+/g;
    let match;
    while ((match = regex.exec(str)) !== null) {
        const num = parseInt(match[0], 10);
        const newNum = num - 1;
        str = str.replace(match[0], newNum.toString());
    }
    return str;
};
