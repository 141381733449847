export default [
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        components: {
            default: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/content/PrivacyPolicy'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        components: {
            default: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/content/TermsAndConditions'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
    },
    {
        path: '/impressum',
        name: 'impressum',
        components: {
            default: () => import(/* webpackChunkName: "impressum" */ '@/views/content/Impressum'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
    },
];
