import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import store from '@/store';
import { url } from '@/helpers/UrlHelpers';

export default class GetGiftingDeliveryCaps {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = url(ApiEndpoints['gifting.delivery-caps'], null, { sku: payload.sku || null, gift_Date: payload.gift_date || null });

        return this.apiService.get(apiUrl);
    }
}
