<template>
    <div
        v-if="isOpen"
        :class="modalClass"
        @click="allowEscape ? closeModal() : null">
        <div :class="containerClass" :style="customStyle" @click.stop>
            <button v-if="enableCloseButton" class="modal__upper-close-btn" @click="closeModal()"><img src="@/assets/images/decoration/cross-shop.svg"></button>
            <div :class="contentClass"  :style="contentStyles">
                <slot></slot>
                <img v-if="showMoreContentIcon && moreContent" class="modal__content__more" src="@/assets/images/decoration/SCROLL-ARROW.png" alt="Scroll down for more content">
            </div>
            <div class="modal__actions" :style="actionsStyle" v-if="!hideActions">
                <button
                    v-if="allowCancel"
                    @click="closeModal"
                    v-text="cancelButtonText"
                    class="btn btn--primary">
                </button>
                <slot name="actions-prefix"></slot>
                <slot name="actions-suffix"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {
            isOpen: true,
            moreContent: false,
        };
    },
    props: {
        allowCancel: {
            default: true,
            type: Boolean,
        },
        showMoreContentIcon: {
            default: true,
            type: Boolean,
        },
        cancelButtonText: {
            default: 'Cancel',
            type: String,
        },
        enableFullScreen: {
            default: false,
            type: Boolean,
        },
        enableCloseButton: {
            default: true,
            type: Boolean,
        },
        allowEscape: {
            default: true,
            type: Boolean,
        },
        customClass: {
            default: '',
            type: String,
        },
        applyMinHeight: {
            default: true,
            type: Boolean,
        },
        customStyle: {
            default: '',
            type: [Object, String],
        },
        contentStyles: {
            default: '',
            type: [Object, String],
        },
        contentOverflow: {
            default: true,
            type: Boolean,
        },
        hideActions: {
            default: false,
            type: Boolean,
        },
        actionsStyle: {
            default: '',
            type: [Object, String],
        },
    },
    methods: {
        closeModal() {
            this.isOpen = false;
            document.querySelectorAll('.modal__content')[0].onscroll = null;
            // Emit the closed event so the modal can be destroyed by the parent component
            this.$emit('closed');
        },
        showScrollIndicator() {
            const obj = document.querySelectorAll('.modal__content')[0];
            if (obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)) this.moreContent = false;
            else this.moreContent = true;
        },
    },
    computed: {
        modalClass() {
            return this.enableFullScreen ? 'modal modal--full-screen' : 'modal';
        },
        containerClass() {
            return `modal__container container ${this.customClass}`;
        },
        contentClass() {
            const overflow = this.contentOverflow && 'overflow-content';
            return `modal__content ${this.applyMinHeight && 'modal__content__min-height'} ${overflow}`;
        },
    },
    mounted() {
        const obj = document.querySelectorAll('.modal__content')[0];
        obj.onscroll = this.showScrollIndicator;
        this.showScrollIndicator();
    },
    onDestroy() {
        this.isOpen = false;
        this.observer.disconnect();
        document.querySelectorAll('.modal__content')[0].onscroll = null;
    },
    watch: {
        isOpen(newVal, oldVal) {
            if (newVal) {
                this.showScrollIndicator();
                document.querySelectorAll('.modal__content')[0].onscroll = this.showScrollIndicator;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.modal {
    background: rgba(1, 1, 1, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    min-width: 100%;
    top: 0;
    left: 0;
    z-index: 999;

    &__container {
        background: $light-color;
        padding: 1rem;
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &__content {
        padding: 0.75rem;
        max-height: 50vh;
        position: relative;
        &__min-height {
            min-height: 200px;
        }
        &__more {
            width: 30px;
            position: sticky;
            bottom: 0px;
            margin-left: auto;
            margin-right: -10px;
            z-index: 100;
        }
    }

    &__actions {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 0.25rem;
        & .btn {
            min-width: 100px;
            @media (max-width: 768px) {
                padding: 10px;
            }
        }
    }

    &__upper-close-btn {
        width: 18px;
        height: 18px;
        line-height: 29px;
        top: 20px;
        right: 18px;
        color: $primary-light-color;
        text-align: center;
        position: absolute;
        z-index: 1;
        &:hover {
            color: $primary-dark-color;
        }
    }

    &--full-screen {
        .modal {
            &__container {
                margin: 1rem;
                height: 90vh;
            }
        }
    }

    .content_medium{
        min-height: 450px;
    }
}

.overflow-content {
    overflow-y: scroll;
}

.alert {
    max-width: $container-medium;
    min-height: 277px;
    border-radius: 8px;
}

.share {
    margin: 0;
    padding: 0;
    border-radius: 8px;
    border: 1px solid $shade-card-color;
    background-color: $shade-light-color;
    max-width: 380px;
}
</style>
