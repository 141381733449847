import union from 'lodash/union';
import forOwn from 'lodash/forOwn';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';

let FlowerQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/flower.json`);
let AccountQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/account.json`);
let GeneralQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/general.json`);
let GiftingQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/gifting.json`);
let DeliveryQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/delivery.json`);
let FreeFlowerQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/freeFlower.json`);
let ShopQuestions = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/answers/freeFlower.json`);

export default class FaqAnswers {
    questionsByCategory = {}

    allQuestions = []

    constructor() {
        this.questionsByCategory = {
            '/faq/general': GeneralQuestions.items,
            '/faq/account': AccountQuestions.items,
            '/faq/flower': FlowerQuestions.items,
            '/faq/gifting': GiftingQuestions.items,
            '/faq/delivery': DeliveryQuestions.items,
            '/faq/free-flower': FreeFlowerQuestions.items,
            '/faq/shop': ShopQuestions.items,
        };

        forOwn(this.questionsByCategory, (faqArray) => (this.allQuestions = union(this.allQuestions, faqArray)));
    }

    handle() {
        forEach(this.allQuestions, (question, key) => {
            let answer = '';

            if (isArray(question.answer)) {
                answer = question.answer.join('</p><p>');
            } else {
                answer = question.answer;
            }

            this.allQuestions[key].answer = `<p>${answer}</p>`;
        });

        return new Promise((resolve) => resolve(this.allQuestions));
    }

    findAnswersByPath(path) {
        path = path.replace(/(\/faq\/[^/]+).*/, '$1');

        return new Promise((resolve) => resolve(this.questionsByCategory[path]));
    }
}
