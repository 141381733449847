export const turnScrollOn = (document) => {
    document.body.style.height = '100%';
    document.body.style.overflow = 'scroll';
};

export const turnScrollOff = (document) => {
    document.body.style.margin = '0px';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
};
