import Footer from '@/components/structure/Footer';
import TopNavigation from '@/components/structure/navigation/TopNavigation';
import TopNavigationV2 from '@/components/structure/navigation/TopNavigationV2';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].faq,
        name: 'faq',
        redirect: { name: 'faq.categories' },
        components: {
            // route level code-splitting. This generates a separate chunk (faq.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "faq" */ '@/views/Faq'),
            footer: Footer,
            topNavigation: TopNavigation,
            topNavigationV2: TopNavigationV2,
            orderSummaryModal: () => import(/* webpackChunkName: "order-modal" */ '@/components/modals/shop/OrderSummaryModal'),
        },
        children: [
            {
                path: routesMap[country].categories,
                name: 'faq.categories',
                component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/FaqCategories'),
            },
            {
                path: routesMap[country].answers,
                name: 'faq.answers',
                component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/FaqAnswers'),
            },
        ],
    },
];
