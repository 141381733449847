module.exports = {
    'subscriptions': 'users/$1/subscriptions',
    'subscriptions.store': 'subscriptions',
    'subscriptions.create-from-lapsed.store': 'subscriptions/create-from-lapsed',
    'subscriptions.delete': 'subscriptions/$1',
    'subscriptions.quantity.update': 'subscriptions/$1/quantity',
    'subscriptions.plan.update': 'subscriptions/$1/plan',
    'subscriptions.continue': 'subscriptions/$1/continue',
    'subscriptions.pause': 'subscriptions/$1/pause',
    'subscriptions.paused-until': 'subscriptions/$1/paused-until',
    'subscriptions.scheduled-orders': 'subscriptions/$1/predict-deliveries',
    'subscriptions.holiday.store': 'subscriptions/$1/holidays',
    'subscriptions.holiday.destroy': 'subscriptions/$1/holidays/$2',
    'subscriptions.holidayBox.destroy': '/holiday-gift/$1',
    'subscriptions.opt-in.store': 'subscriptions/$1/scheduled-orders',
    'subscriptions.opt-in.destroy': 'subscriptions/$1/scheduled-orders/$2',
    'subscriptions.update.box-type': 'subscriptions/$1/product/$2',
    'subscriptions.update.single-box-type': 'subscriptions/$1/swap-product',
    'subscriptions.Send-skip-reason': 'subscriptions/$1/holidays',
    'subscriptions.next-delivery': 'subscriptions/delivery/$1',
    'subscriptions.charity-price': 'subscriptions/$1/charity-price',
};
