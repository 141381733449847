import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class CancelSubscription {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, reasonId) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.delete'], [subscriptionId]);

        return this.apiService.delete(apiUrl);
    }
}
