export const SUBSCRIPTION_STATUS_ACTIVE = 'Active';
export const SUBSCRIPTION_STATUS_CANCELLED = 'Cancelled';
export const SUBSCRIPTION_STATUS_PAUSED = 'Paused';
export const SUBSCRIPTION_STATUS_PENDING = 'Pending';

export const SUBSCRIPTION_FREQUENCY_WEEKLY = 'weekly';
export const SUBSCRIPTION_FREQUENCY_FORTNIGHTLY = 'fortnightly';
export const SUBSCRIPTION_FREQUENCY_THREE_WEEKLY = 'three-weekly';
export const SUBSCRIPTION_FREQUENCY_MONTHLY = 'monthly';
export const SUBSCRIPTION_STATUS_PAUSED_PENDING = 'Paused_Pending';
