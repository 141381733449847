import Vue from 'vue';
import store from '@/store';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';
import CountryService from '@/services/logistics/CountryService';
import { getVariant, getDeliveryPrice } from '@/helpers/QueryHelpers';

export default class RegisterCustomer {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    // Handle registering a new WEB customer.
    handle(paymentDetails) {
        let payload = Object.assign(this.getPayload(), paymentDetails);
        return this.apiService
            .post(ApiEndpoints['gifter.register'], payload);
    }

    getPayload() {
        let payload = {};
        let deliveryAddress = store.getters['register/getDeliveryAddress'];
        let deliveryAddressIsBilling = store.getters['register/getDeliveryAddressIsBilling'];
        let billingAddress = store.getters['register/getBillingAddress'];
        billingAddress = deliveryAddressIsBilling ? deliveryAddress : billingAddress;

        // let billingIso = billingAddress.country instanceof Object ? billingAddress.country.iso : billingAddress.country;
        let billingIso = process.env.VUE_APP_COUNTRY_ISO;
        let billingAddressCountry = CountryService.findCountryByIso(billingIso).name;
        let deliveryAddressCountry = CountryService.findCountryByIso(deliveryAddress.country).name;
        const originURL = process.env.VUE_APP_ORIGIN_URL || '';
        const selectedPlan = store.getters['register/getSelectedPlan'];
        let queryParams = '';
        return {
            ...payload,
            billing_address_first_name: store.getters['account/getFirstName'],
            billing_address_last_name: store.getters['account/getLastName'],
            billing_address_house_number: billingAddress.houseNumber || null,
            billing_address_line_1: billingAddress.addressLine1,
            billing_address_line_2: billingAddress.addressLine2,
            billing_address_city: billingAddress.city,
            billing_address_postcode: billingAddress.postcode,
            billing_address_country: billingAddressCountry,
            coupon_code: store.getters['register/getFriendCode'],
            delivery_address_house_number: deliveryAddress.houseNumber || null,
            delivery_address_line_1: deliveryAddress.addressLine1,
            delivery_address_line_2: deliveryAddress.addressLine2,
            delivery_address_city: deliveryAddress.city,
            delivery_address_postcode: deliveryAddress.postcode,
            delivery_address_state: deliveryAddress.state || null,
            delivery_address_country: deliveryAddressCountry,
            delivery_address_property_type: store.getters['register/getPropertyType'],
            delivery_instructions: store.getters['register/getDeliveryComment'],
            delivery_amount: getDeliveryPrice(),
            first_name: store.getters['account/getFirstName'],
            free_first_delivery: false,
            gateway: store.getters['payment/getStripeGateway'],
            last_name: store.getters['account/getLastName'],
            mode: getVariant(),
            originURL: originURL,
            password: store.getters['register/getPassword'],
            plan_slug: selectedPlan.plan_interval,
            telephone: store.getters['register/getTelephone'],
            paramsPayload: {
                queryParams,
            },
        };
    }
}
