<template>
    <div v-click-outside="closeMenu">
        <div class="burger-menu" :class="{ 'burger-opened': !isClosed }">
            <button aria-label="Menu" @click="toggleShow">
                <span class="hamburger__btn hamburger-btn"></span>
            </button>
        </div>

        <nav>
            <ul v-show="!isClosed" class="hamburger--menu">
                <li class="space-route" v-for="(item, index) in filteredButtons" :key="index">
                    <router-link
                        :to="{ name: isSelectedGifter(item) }"
                        v-on:click.native="toggleShow"
                        :class="{ 'selected-route': isActiveRoute(item) }"
                        class="my-account-route">
                        <span v-text="item.label"></span>
                    </router-link>
                </li>
                <li v-if="isUserLoggedIn && !hasNoActiveSubscription">
                    <a
                        :href="zendeskHcUrl"
                        class="my-account-route">
                        {{ $t('TOP_NAVIGATION_HELP') }}
                    </a>
                </li>
            </ul>
        </nav>

        <hamburger-menu
            :show="showHamburgerMenu"
            @closed="toggleShow">
        </hamburger-menu>

        <user-menu
            :show="showUserMenu"
            @menu-clicked="toggleShow"
            @closed="toggleShow">
        </user-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMenu from '@/components/structure/navigation/UserMenu';
import HamburgerMenu from '@/components/structure/navigation/Hamburger';
import { turnScrollOn, turnScrollOff } from '@/helpers/ScrollHelpers.js';
import countryUtils from '@/resources/countryUtils.json';

export default {
    name: 'HelpButton',
    data() {
        return {
            countryUtils: countryUtils.filter(item => item.iso.toLowerCase() === process.env.VUE_APP_COUNTRY_ISO.toLowerCase())[0],
            showHamburger: false,
            isClosed: true,
            window: {
                width: 0,
                mobileBreakpoint: 768,
                tabletBreakpoint: 1024,
            },
        };
    },
    computed: {
        ...mapGetters({
            buttons: 'nav/getButtons',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
            isNewGiftingFlow: 'account/isNewGiftingFlow',
            isGifter: 'account/isGifterAccount',
        }),
        zendeskHcUrl() {
            return this.countryUtils?.zendesk_hc_url;
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
        filteredButtons() {
            const status = (this.isUserLoggedIn ? 'loggedIn' : 'loggedOut');
            return this.buttons.filter(button => this.hasNoActiveSubscription ? button[status] && button.label.toLowerCase() !== 'shop' : button[status] && button.route !== 'faq.categories' && button.enabled);
        },
        isMobile() {
            return this.window.width <= this.window.mobileBreakpoint;
        },
        showHamburgerMenu() {
            return this.showHamburger && !this.isUserLoggedIn;
        },
        showUserMenu() {
            return this.showHamburger && this.isUserLoggedIn;
        },
        newGiftingFlow() {
            return this.isNewGiftingFlow ? 'guest-gifting' : 'account.gifting.choose-your-gift';
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        toggleShow() {
            let isOrderSummaryOpened = this.$store.getters['shop/getOrderSummaryModalState'];
            isOrderSummaryOpened && this.$store.commit('shop/closeOrderSummary');
            this.isMobile
                ? this.showHamburger = !this.showHamburger
                : this.isClosed = !this.isClosed;
        },
        closeMenu() {
            this.isMobile
                ? this.showHamburger = false
                : this.isClosed = true;
        },
        handleResize() {
            this.window.width = window.innerWidth;
        },
        isActiveRoute(item) {
            return this.$route.name === item.route;
        },
        isSelectedGifter(item) {
            let routeName = item.route;
            if (routeName === 'account.gifting.choose-your-gift') {
                return this.newGiftingFlow;
            }
            return routeName;
        },
    },
    components: {
        UserMenu,
        HamburgerMenu,
    },
    watch: {
        showHamburger: {
            handler(newValue) {
                return newValue ? turnScrollOff(document) : turnScrollOn(document);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';
.hamburger {
    &--menu {
        width: 260px;
        background-color: $light-color;
        position: absolute;
        z-index: 9999;
        padding: 20px;
        left: 20px;
        text-align: left;
        box-shadow: 0px 8px 40px 0px #00000033;
        &-btn {
            margin-top: 0;
        }
    }
}

.burger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    position: relative;
    background-color: $secondary-color;
    color: $primary-color;
    border-radius: 50%;
    margin-left: -8px;
    transition: background-color .25s linear;
    -moz-transition: background-color .25s linear;
    -webkit-transition: background-color .25s linear;
}

.burger-menu:hover {
    transition: background-color .25s linear;
    -moz-transition: background-color .25s linear;
    -webkit-transition: background-color .25s linear;
    opacity: 0.8;
}

.burger-opened {
    opacity: 0.8;
}
.my-account-route {
    color: $primary-color;
}
.space-route {
    margin-bottom: 25px;
}
</style>
