<template>
    <swipeable-bottom-sheet class="bottom-sheet--container">
        <slot></slot>
    </swipeable-bottom-sheet>
</template>

<script>
import SwipeableBottomSheet from '@/components/structure/SwipeableBottomSheet.vue';

export default {
    name: 'BottomSheet',
    components: {
        SwipeableBottomSheet,
    },
};
</script>

<style lang="scss" scoped>
    .bottom-sheet {
        &--container {
            display: unset;
            position: relative;
            z-index: 999;
            @media (min-width: 640px) {
                display: none;
            }
        }
    }
</style>
