<template>
    <div class="loader">

        <div :class="loadingContainerClass">
            <slot v-if="loaded">
            </slot>
        </div>

        <div :class="loadingIconContainerClass">
            <div v-if="false === loaded" :class="loadingIconClass" :style="loaderIconStyle">&nbsp;</div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        loaded: {
            default: false,
            type: Boolean,
        },
        fullScreen: {
            default: false,
            type: Boolean,
        },
        loaderIconStyle: {
            type: Object,
            default: () => {},
        },
        usePrimaryColor: Boolean,
    },
    computed: {
        loadingIconContainerClass() {
            return this.fullScreen ? 'w-full h-screen flex items-center justify-center' : 'w-full flex items-center justify-center';
        },

        loadingIconClass() {
            if (this.loaded) {
                return 'loader__loading-icon--loaded fa-spin';
            } else {
                return this.usePrimaryColor ? 'loader__loading-icon-primary-color fa-spin' : 'loader__loading-icon fa-spin';
            }
        },

        loadingContainerClass() {
            return this.loaded ? 'loader__container--loaded' : 'loader__container';
        },
    },
};
</script>
