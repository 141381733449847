import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class CreateOptInOrder {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.opt-in.store'], [payload.subscriptionId]);
        let parameters = {
            delivery_date: payload.deliveryDate,
            source: payload.source || null,
        };

        return this.apiService.post(apiUrl, parameters);
    }
}
