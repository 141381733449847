import GetCountries from '@/services/logistics/countryService/GetCountries';
import FindCountryByIso from '@/services/logistics/countryService/findCountryByIso';
import GetStripeGatewayByISO from '@/services/logistics/countryService/getStripeGatewayByISO';

export default class CountryService {
    static findCountryByIso(iso) {
        return (new FindCountryByIso()).handle(iso);
    }

    static getCountries() {
        return (new GetCountries()).handle();
    }

    static getStripeGatewayByISO(iso) {
        return (new GetStripeGatewayByISO().handle(iso));
    }
}
