import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class GetAvailableDeliveryDay {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(postcode) {
        const encodedPostcode = encodeURIComponent(postcode);
        let apiUrl = url(ApiEndpoints['postcodes.delivery-day'], [encodedPostcode]);

        return this.apiService.get(apiUrl);
    }
}
