export default [
    {
        path: '/konto/bestatigung-der-doppeloption',
        name: 'doubleOptin.double-optin-confirmation',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/marketingConfirmation/DoubleOptInConfirmation'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
    {
        path: '/konto/bestatigung-der-spoiler-doppeloption',
        name: 'doubleOptin.double-optin-spoiler-confirmation',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/marketingConfirmation/DoubleOptInSpoilerConfirmation'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
    },
];
