import Vue from 'vue';

let deliveryPrice = Number(process.env.VUE_APP_DELIVERY_PRICE || 0);
let deliveryPrice1 = Number(process.env.VUE_APP_DELIVERY_PRICE_NP_3 || 0);
let deliveryPrice2 = Number(process.env.VUE_APP_DELIVERY_PRICE_NP_4 || 0);

export const getPriceOverrideParams = () => {
    let globalParams = Vue.prototype.$globalQueryParams;
    if (process.env.VUE_APP_ENABLE_PRICE_TEST === 'true' && globalParams.fam && globalParams.pov === 'true') {
        return { fam: globalParams.fam, pov: process.env.VUE_APP_PRICE_TEST };
    }
    return null;
};

export const getBoxPrice = (boxPrice) => {
    let globalParams = Vue.prototype.$globalQueryParams;
    if (globalParams.np === '1') return boxPrice + deliveryPrice;
    if (globalParams.np === '2') return boxPrice + deliveryPrice2;
    return boxPrice;
};

export const getVariant = () => {
    let globalParams = Vue.prototype.$globalQueryParams;
    if (globalParams.dci === '1' || globalParams.dci === '2' || globalParams.dci === '3') return 'dci';
    if (globalParams.np === '1') return 'np';
    if (globalParams.np === '2') return 'np2';
    return 'dci';
};

export const getDeliveryPrice = () => {
    let globalParams = Vue.prototype.$globalQueryParams;
    if (globalParams.dci === '1') return deliveryPrice;
    if (globalParams.dci === '2') return deliveryPrice1;
    if (globalParams.dci === '3') return deliveryPrice2;
    return deliveryPrice2;
};

export const isFirstDeliveryFree = () => {
    return Vue.prototype.$globalQueryParams?.fdf !== '0';
};

export const setCookie = (cookieName, cookieValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + '; ' + expires + '; path=/';
};
