import RedirectMiddleware from '@/middleware/RedirectMiddleware';
import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].register,
        name: 'register',
        beforeEnter: RedirectMiddleware,
        redirect: { name: 'register.email' },
        components: {
            // route level code-splitting. This generates a separate chunk (register.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "register" */ '@/views/Register'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        children: [
            {
                path: routesMap[country].regAccount,
                name: 'register.account',
                beforeEnter: RedirectMiddleware,
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/Account'),
            },
            {
                path: routesMap[country].delivery,
                name: 'register.delivery',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/Delivery'),
            },
            {
                path: routesMap[country].regCardDetails,
                name: 'register.card-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/CardDetails'),
            },
            {
                path: routesMap[country].email,
                name: 'register.email',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/GetEmail'),
            },
            {
                path: routesMap[country].subscriptionDetails,
                name: 'register.subscription-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/SubscriptionDetails'),
            },
            {
                path: routesMap[country].chooseSubscription,
                name: 'register.choose-subscription',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/ChooseSubscription'),
            },
            {
                path: `${routesMap[country].chooseSubscription}/:id/:name`,
                name: 'register.subscription-product-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/SubscriptionProductDetails'),
            },
            {
                path: routesMap[country].deliveryDetails,
                name: 'register.delivery-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/DeliveryDetails'),
            },
            {
                path: routesMap[country].yourDetails,
                name: 'register.your-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/YourDetails'),
            },
            {
                path: routesMap[country].paymentDetails,
                name: 'register.payment-details',
                component: () => import(/* webpackChunkName: "register" */ '@/views/register/PaymentDetails'),
                // component: () => import(/* webpackChunkName: "register" */ '@/components/forms/register/PaymentScreen'),
            },
        ],
    },
];
