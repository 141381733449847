import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import store from '@/store';
import { url } from '@/helpers/UrlHelpers';

export default class SendGuestGiftingData {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload, uid) {
        let apiUrl = null;
        if (uid) apiUrl = url(ApiEndpoints['guest.gifting.update'], [uid]);
        else apiUrl = ApiEndpoints['guest.gifting.signup'];
        return this.apiService.post(apiUrl, payload);
    }
}
