<template>
    <div>
        <div class="my-account--container" :class="{'drop-down-open': !isClosed}" v-click-outside="closeMenu" @click="toggleMenu">
            <img height="40" width="40" src="@/assets/images/decoration/my-account.svg"/>
            <div class="my-account--dropdown">
                <p v-if="isDesktop">{{ $t('DASHBOARD_MY_ACCOUNT') }}</p>
                <img v-if="isClosed" class="arrow-margin" src="@/assets/images/decoration/my-account-arrow-down.svg">
                <img v-else class="arrow-margin" src="@/assets/images/decoration/my-account-arrow-up.svg">
            </div>
        </div>
        <nav v-show="!isClosed" class="my-account--menu">
            <ul class="my-account--menu-style">
                <li class="space-route" v-for="(item, index) in menuItems" :key="index">
                    <router-link
                        :to="{ name: item.route }"
                        v-on:click.native="$emit('menu-clicked')"
                        :class="{ 'selected-route': isActiveRoute(item) }"
                        class="my-account-route">
                        <span v-text="item.label"></span>
                    </router-link>
                </li>
            </ul>
            <div  v-if="isFlowerBondHolder" class="my-account--menu-bondholder">
            <router-link target="_blank" :to="{ name: 'flowerbond'}">
                <div class="bondHolderButton">
                    <img class="bondHolderButton__icon" :src="flowerBondIcon" alt="Flower Bond Holder"/>
                    <p class="bondHolderButton__text" v-text="$t('NAV_BOND_HOLDER')"></p>
                </div>
            </router-link>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationLinks from '@/config/NavigationLinks';

export default {
    name: 'MyAccount',
    data() {
        return {
            flowerBondIcon: require('@/assets/images/decoration/icon-bondholder-nav.svg'),
            isClosed: true,
            window: {
                width: 0,
                desktopLowerBreakpoint: 1024,
                mobileBreakpoint: 768,
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    computed: {
        ...mapGetters({
            isUserLoggedIn: 'auth/isUserLoggedIn',
            isFlowerBondHolder: 'account/getFlowerBondStatus',
            isGifterAccount: 'account/isGifterAccount',
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
        }),
        menuItems() {
            const allMenuItems = NavigationLinks.items;
            const enabled = allMenuItems.filter((item) => {
                if (this.isGifterAccount) {
                    return typeof item.myAccount !== 'undefined' && item.myAccount.enabled && item.myAccount.gifterDashboard;
                } else if (this.hasNoActiveSubscription) {
                    return typeof item.myAccount !== 'undefined' && item.myAccount.isLapser;
                } else {
                    return typeof item.myAccount !== 'undefined' && item.myAccount.enabled;
                }
            });
            const myAccountItems = enabled.map((item) => Object.assign(item.myAccount, item));
            const sortedItems = myAccountItems.sort((prevItem, nextItem) => prevItem.order - nextItem.order);
            return sortedItems;
        },
        isDesktop() {
            const isDesktop = this.window.width >= this.window.desktopLowerBreakpoint;
            return isDesktop;
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
        },
        isPage(pageArr) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        toggleMenu() {
            this.isClosed = !this.isClosed;
        },
        closeMenu() {
            this.isClosed = true;
        },
        isActiveRoute(item) {
            return this.$route.name === item.route;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

    .my-account {
        &--container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            margin-left: 8px;
            border-radius: 136px;
            padding: 0px 12px;
            cursor: pointer;
            background-color: $secondary-color;
            color: $primary-color;
            transition: background-color .1s linear;
            -moz-transition: background-color .1s linear;
            -webkit-transition: background-color .1s linear;
        }
        &--container:hover {
            transition: background-color .1s linear;
            -moz-transition: background-color .1s linear;
            -webkit-transition: background-color .1s linear;
            background-color: $secondary-color;
            opacity: 0.8;
        }
        &--dropdown {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
        }
        &--menu {
            width: 290px;
            background-color: $light-color;
            color: $primary-color;
            position: absolute;
            z-index: 9999;
            right: 20px;
            text-align: left;
            box-shadow: 0px 8px 40px 0px #00000033;
        }
          &--menu-style {
            padding: 5px;
        }
        &--menu-bondholder {
            background-color: $secondary-color;
            color: $primary-color;
            height: 60px;
            padding-left: 20px;
            display: flex;
            align-items: center;
        }
    }

    .drop-down-open {
        background-color: $secondary-light-color;
    }

    .my-account-route {
        padding: 10px 20px;
        border-radius: 136px;
        cursor: pointer;
        background-color: $light-color;
        transition: background-color .1s linear;
        -moz-transition: background-color .1s linear;
        -webkit-transition: background-color .1s linear;
    }

    .my-account-route:hover {
        transition: background-color .1s linear;
        -moz-transition: background-color .1s linear;
        -webkit-transition: background-color .1s linear;
        background-color: $secondary-color;
    }

    .selected-route {
        background-color: $secondary-light-color;
    }

    .space-route {
        list-style: none;
        margin-top: 20px;
        width: 100%;
    }

    @media (min-width: $medium) {
        .my-account {
            &--dropdown {
                margin-left: -10px;
            }
        }
    }
    @media (min-width: $large) {
        .my-account {
            &--container {
                padding: 0px 16px 0px 12px;
            }
            &--dropdown {
                margin-left: 2px;
            }
        }
    }

    .arrow-margin {
        margin-left: 4px;
    }

    .bondHolderButton {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (min-width: $large) {
        margin: 20px;
    }
    @media (min-width: $medium) {
        margin: 20px;
    }
    &:hover {
        cursor: pointer;
    }
    &__icon {
        margin: 0 auto;
        padding: 0;
        width: 22px;
        height: 21px;
        &__container {
            display: flex;
            justify-content: center;
        }
    }
    &__text {
        @include body();
        font-size: 20px;
        line-height: 24px;
        padding-top: 0;
        width: 99px;
        padding-left: 8.22px;
        margin-top: 3.3px;
        margin-bottom: 0px;
         color: #285a53;
    }
}
</style>
