import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class getOccasionTypes {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    async handle() {
        let apiUrl = url(ApiEndpoints['users.getOccasionTypes']);
        let request = await this.apiService
            .get(apiUrl)
            .catch(() => false);

        return request ? request.data.data : [];
    }
}
