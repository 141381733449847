import UpdateUser from '@/services/users/UpdateUser';
import FetchUserData from '@/services/users/userService/FetchUserData';
import GetOutstandingOrders from '@/services/users/userService/GetOutstandingOrders';
import PayOutstandingOrders from '@/services/users/userService/PayOutstandingOrders';
import FetchStripeTerminalToken from '@/services/users/userService/FetchStripeTerminalToken';
import FetchPromotionalBanners from '@/services/users/userService/FetchPromotionalBanners';
import FetchClientToken from '@/services/users/userService/FetchClientToken';
import FetchStripePaymentIntent from '@/services/users/userService/FetchStripePaymentIntent';
import FetchSkipDeliveryReasons from '@/services/users/userService/FetchSkipDeliveryReasons';
import FetchUsersPaymentMethods from '@/services/users/userService/FetchUsersPaymentMethods';
import SendSkipDeliveryReasons from '@/services/users/userService/SendSkipDeliveryReasons';
import UpdateFeature from '@/services/users/userService/UpdateFeature';
import FetchThemes from '@/services/users/userService/FetchThemes';
import FetchSurveyForm from '@/services/users/userService/FetchSurveyForm';
import SendSurveyData from '@/services/users/userService/SendSurveyData';

export default class UserService {
    fetchUserData(isCanvasser) {
        return (new FetchUserData()).handle(isCanvasser);
    }

    updateUser(payload) {
        return (new UpdateUser()).handle(payload);
    }
    getOutstandingOrders(userId) {
        return (new GetOutstandingOrders()).handle(userId);
    }

    payOutstandingOrders(userId, payload) {
        return (new PayOutstandingOrders()).handle(userId, payload);
    }

    updateFeature(userId, payload) {
        return (new UpdateFeature()).handle(userId, payload);
    }

    fetchStripeTerminalToken() {
        return (new FetchStripeTerminalToken()).handle();
    }

    fetchClientToken(payload) {
        return (new FetchClientToken()).handle(payload);
    }

    fetchStripePaymentIntent(payload) {
        return (new FetchStripePaymentIntent()).handle(payload);
    }

    fetchSkipDeliveryReasons() {
        return (new FetchSkipDeliveryReasons()).handle();
    }

    sendSkipDeliveryReasons(subscriptionId, payload) {
        return (new SendSkipDeliveryReasons()).handle(subscriptionId, payload);
    }

    fetchPromotionalBanners(payload) {
        return (new FetchPromotionalBanners()).handle(payload);
    }

    fetchThemes() {
        return (new FetchThemes()).handle();
    }

    fetchUsersPaymentMethods(userId) {
        return (new FetchUsersPaymentMethods()).handle(userId);
    }

    fetchSurveyForm(surveyId) {
        return (new FetchSurveyForm()).handle(surveyId);
    }

    sendSurveyData(payload) {
        return (new SendSurveyData()).handle(payload);
    }
}
