export const stripeElementsClasses = {
    base: 'stripe-element',
    complete: 'stripe-element--complete',
    empty: 'stripe-element--empty',
    focus: 'stripe-element--focus',
    invalid: 'stripe-element--invalid',
};

export const stripeElementsStyle = {
    base: {
        color: '#285A53',
        fonts: [{
            cssSrc: 'https://use.typekit.net/fgq1mov.css',
        }],
        family: 'new-atten',
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        fontWeight: '200',
        lineHeight: '32.5px',
        '::placeholder': {
            fonts: [{
                cssSrc: 'https://use.typekit.net/fgq1mov.css',
            }],
            family: 'new-atten',
            fontSize: '20px',
            fontWeight: '200',
            fontStyle: 'italic',
        },
    },
    invalid: {
        color: '#A45B56',
    },
};

export const stripeElementsFonts = {
    fonts: [{
        cssSrc: 'https://use.typekit.net/fgq1mov.css',
    }],
    family: 'new-atten',
    style: 'normal',
    weight: '200',
};

export const appearance = {
    theme: 'stripe',
    base: {
        color: '#285A53',
        fonts: [{
            cssSrc: 'https://use.typekit.net/fgq1mov.css',
        }],
        family: 'new-atten',
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        fontWeight: '200',
        lineHeight: '32.5px',
        '::placeholder': {
            fonts: [{
                cssSrc: 'https://use.typekit.net/fgq1mov.css',
            }],
            family: 'new-atten',
            fontSize: '20px',
            fontWeight: '200',
            fontStyle: 'italic',
        },
    },
    variables: {
        colorPrimary: '#285A53',
        colorText: '#285A53',
        colorTextPlaceholder: '#8BA5A2',
    },
};

export const AMEX_CARD_BRAND = 'American Express';
