<template>
    <div class="footer footer--background divider--container">
        <div class="contact">
            <contact-us
                class="desktop-footer"
                v-if="footerType.contactUs"
                :faqsLink="footerType.faqsLink"/>
            <contact-us-mobile class="mobile-footer" />
        </div>
    </div>
</template>
<script>
import ContactUs from '@/components/structure/navigation/navigation/ContactUs';
import ContactUsMobile from '@/components/structure/navigation/navigation/ContactUsMobile';
import FaqService from '@/services/faq/FaqService';

export default {
    name: 'Footer',
    components: {
        ContactUs,
        ContactUsMobile,
    },
    props: {
        pageName: {
            type: String,
            required: true,
            default: 'login',
        },
    },
    data() {
        return {
            help: {
                title: this.$t(''), // HAMBURGER_HELP is removed for new footer. Title can be added here if needed in future.
                text: '',
                faqsLink: true,
                contactUs: true,
            },
            esoteric: {
                title: this.$t('FOOTER_GOT_SOMETHING'),
                text: this.$t('FOOTER_GOT_HOWEVER'),
                contactUs: true,
            },
            faqSearch: {
                searchBar: true,
                contactUs: true,
            },
            signUp: {
                page: null,
                gotMoreQuestions: true,
                faqItems: [],
            },
        };
    },
    watch: {
        'signUp.page'() {
            this.faqItems();
        },
    },
    computed: {
        hideBadge() {
            return this.$route.name.includes('register.account') || this.$route.name.includes('register.delivery') || this.$route.name.includes('register.card-details') || this.isNLD;
        },

        footerType() {
            const map = new Map([
                ['login', this.help],
                ['faq.categories', this.esoteric],
                ['faq.answers', this.faqSearch],
                ['register.account', this.signUp, this.signUp.page = this.pageName],
                ['register.delivery', this.signUp, this.signUp.page = this.pageName],
                ['register.card-details', this.signUp, this.signUp.page = this.pageName],
            ]);

            return map.get(this.pageName) || map.get('login');
        },
        isGermany() {
            return this.$store.getters['localisation/isDEU'];
        },
        isNLD() {
            return this.$store.getters['localisation/isNLD'];
        },
    },
    methods: {
        faqItems() {
            if (this.pageName.includes('register')) {
                const faqService = new FaqService();
                const map = new Map([
                    ['register.account', faqService.getIntroductionsQuestions()],
                    ['register.delivery', faqService.getDeliveryQuestions()],
                    ['register.card-details', faqService.getPaymentQuestions()],
                ]);
                map.get(this.pageName)?.then(response => {
                    this.signUp.faqItems = response;
                    return response;
                });
            };
        },
    },
    mounted() {
        this.$store.dispatch('cookies/mountTrustedShops');
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

$trustbadge-mobile-bp: 640px;

.footer {
    z-index: 100;
    color: $secondary-color;
}

.mobile-footer {
    @media (min-width: $small) {
        display: none;
    }
}

.desktop-footer {
    display: none;
    @media (min-width: $small) {
        display: block;
    }
}

.footer--trustbadge {
    height: 80px;
    width: 100%;
    justify-content: center;
    display: none;
    @media(min-width: $trustbadge-mobile-bp) {
        display: flex;
    }
}
.footer--mobile-trustbadge {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media(min-width: $trustbadge-mobile-bp) {
        display: none;
    }
}
.footer-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.content {
    width: 100%;
    text-align: center;
    @media(min-width: $medium) {
        width: 50%;
    }
}
.title {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
}
.text {
    padding-top: 0.25rem;
    padding-bottom: 1rem;
}
.contact {
    text-align: left;

    @media (min-width: $small) {
        padding-top: 32px;
        padding-bottom: 23px;
    }
}
</style>
