import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class GetDeliveryDatesForPostcode {
    apiService;

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    async handle(postcode, numberOfDeliveryDays) {
        let apiUrl = url(ApiEndpoints['postcodes.delivery-days'], [postcode, numberOfDeliveryDays]);

        return this.apiService.get(apiUrl);
    }
}
