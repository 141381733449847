const butter = require('buttercms')('6a1c0e28aeba5c9e46b9f4b707b4d42b3b2592a1');

export const getButterCmsPage = (pageName, locale = null) => {
    let defaultLocale = `${process.env.VUE_APP_COUNTRY_ISO}-${process.env.VUE_APP_I18N_LOCALE}`.toLowerCase();
    if (process.env.VUE_APP_COUNTRY_ISO === 'GBR') defaultLocale = 'en';
    return butter.page.retrieve('*',
        pageName,
        {
            'preview': process.env.VUE_APP_ENV === 'Production' ? 0 : 1,
            'locale': locale || defaultLocale,
        },
    ).then((resp) => resp?.data?.data?.fields?.section)
        .catch(error => console.error(error));
};

export const getButterCmsPageData = (pageName, locale = null) => {
    let defaultLocale = `${process.env.VUE_APP_COUNTRY_ISO}-${process.env.VUE_APP_I18N_LOCALE}`.toLowerCase();
    if (process.env.VUE_APP_COUNTRY_ISO === 'GBR') defaultLocale = 'en';
    return butter.page.retrieve('*',
        pageName,
        {
            'preview': process.env.VUE_APP_ENV === 'Production' ? 0 : 1,
            'locale': locale || defaultLocale,
        },
    ).then((resp) => {
        return {
            data: resp?.data?.data?.fields?.section,
            pageType: resp.data.data.page_type,
        };
    })
        .catch(error => console.error(error));
};

export const getCollection = (collection, id = null, locale = null) => {
    let defaultLocale = `${process.env.VUE_APP_COUNTRY_ISO}-${process.env.VUE_APP_I18N_LOCALE}`.toLowerCase();
    if (process.env.VUE_APP_COUNTRY_ISO === 'GBR') defaultLocale = 'en';
    let params = {
        locale: locale || defaultLocale,
    };
    return butter.content.retrieve([collection], params)
        .then(function(resp) {
            if (id) return resp.data.data[collection].find(item => item.id === id) || [];
            return resp.data.data[collection];
        })
        .catch(resp => console.error(resp));
};
