import Vue from 'vue';
import store from '@/store';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';
import { url } from '@/helpers/UrlHelpers';
import * as registrationConsts from '@/constants/Registration';
import { CANVASSER_TYPE } from '@/constants/Account';

export default class IncompleteRegistrationService {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    updateOrCreate() {
        const uuid = store.getters['register/getIncompleteRegistrantUuid'];

        if (uuid === null) {
            this.createIncompleteRegistration()
                .then(response => this.setUuid(response.data.properties.uuid))
                .catch(() => this.resetUuid());
        } else {
            this.updateIncompleteRegistration(uuid)
                .catch(() => this.resetUuid());
        }
    }

    getPayload() {
        let payload = {};
        const accountCompleted = store.getters['register/getSectionCompleted'](registrationConsts.SECTION_ACCOUNT);
        const deliveryCompleted = store.getters['register/getSectionCompleted'](registrationConsts.SECTION_DELIVERY);
        const deliveryAddress = store.getters['register/getDeliveryAddress'];
        const optInMarketing = store.getters['register/getOptInMarketing'];
        const originURL = process.env.VUE_APP_ORIGIN_URL;
        const productId = store.getters['register/getSelectedProductID'];
        const selectedPlan = store.getters['register/getSelectedPlan'];
        let appliedVouchers = store.getters['giftVoucher/getAppliedVouchers'];

        let voucherCodes = [];
        appliedVouchers.forEach(voucher => {
            voucherCodes.push(voucher.voucher_code);
        });

        let queryParams = '';
        if (!/(%3C*|<)[^*]?script/gi.test(Vue.prototype.$globalQueryParams.queryString)) {
            queryParams = Vue.prototype.$globalQueryParams.queryString;
        }
        const selectedProduct = store.getters['register/getSelectedProductDetails'];

        if (productId) payload.primary_product_id = productId;
        if (selectedProduct?.product_family) payload.product_family = selectedProduct?.product_family;

        if (process.env.VUE_APP_MODE === CANVASSER_TYPE) {
            payload.source_id = store.getters['canvasser/getSourceId'] || store.getters['canvasser/getParentSourceId'];
            payload.region_id = store.getters['canvasser/getRegionId'];
            payload.canvasser_id = store.getters['account/getUserId'];
            payload.password = store.getters['register/getPassword'];
            payload.start_date = store.getters['register/getFirstDeliveryDateDesired']?.value;
        }

        return {
            ...payload,
            account_completed: accountCompleted,
            delivery_completed: deliveryCompleted,
            coupon_code: store.getters['register/getFriendCode'],
            voucher_codes: voucherCodes,
            delivery_address_house_number: deliveryAddress.houseNumber || null,
            delivery_address_line_1: deliveryAddress.addressLine1,
            delivery_address_line_2: deliveryAddress.addressLine2,
            delivery_address_city: deliveryAddress.city,
            delivery_address_country: deliveryAddress.country,
            delivery_address_postcode: deliveryAddress.postcode,
            delivery_instructions: store.getters['register/getDeliveryComment'],
            delivery_property_type: store.getters['register/getPropertyType'],
            email: store.getters['register/getEmail'],
            first_name: store.getters['register/getFirstName'],
            last_name: store.getters['register/getLastName'],
            opt_in_marketing: optInMarketing,
            opt_in_third_party_marketing: store.getters['register/getOptInThirdPartyMarketing'],
            originURL: originURL,
            telephone: store.getters['register/getTelephone'],
            plan_slug: selectedPlan.plan_interval,
            order_summary: store.getters['register/getOrderSummary'],
            paramsPayload: {
                queryParams,
            },
        };
    }

    createIncompleteRegistration() {
        return this.apiService.post(
            ApiEndpoints['users.incomplete-registrations.store'],
            this.getPayload(),
        );
    };

    updateIncompleteRegistration(uuid, secret) {
        const apiURL = url(ApiEndpoints['users.incomplete-registrations.update'], [uuid, secret]);
        return this.apiService.patch(apiURL, this.getPayload());
    };

    setUuid(uuid) {
        store.commit('register/setIncompleteRegistrantUuid', uuid);
    };

    resetUuid() {
        store.commit('register/setIncompleteRegistrantUuid', null);
    };

    getIncompleteRegistrationUserData(uuid) {
        const apiURL = url(ApiEndpoints['users.incomplete-registrations.update'], [uuid]);
        return this.apiService.get(apiURL);
    }
}
