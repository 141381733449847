<template>
    <div @click="loginLinkClicked" class="login-link">
        <p class="text" v-html="label" style="textStyle"></p>
    </div>
</template>

<script>
import { CSPageEvents } from '@/helpers/GAHelpers';

export default {
    name: 'LogInLink',
    props: {
        customStyle: {
            type: Object,
        },
        textWeight: {
            type: [String, Number],
            default: '300',
        },
    },
    computed: {
        isUserLoggedIn: {
            get() {
                return this.$store.getters['auth/isUserLoggedIn'];
            },
        },
        label() {
            return this.isUserLoggedIn ? this.$t('LOGIN_LINK_LOG_OUT') : this.$t('LOGIN_LINK_LOG_IN');
        },
        url() {
            return this.isUserLoggedIn ? { name: 'logout' } : { name: 'login' };
        },
        textStyle() {
            return {
                fontWeight: this.textWeight,
            };
        },
    },
    methods: {
        loginLinkClicked() {
            if (this.isUserLoggedIn) {
                this.$router.push({ name: 'logout' });
                CSPageEvents(undefined, { userLogged: 'Logged-out' });
            } else {
                this.$router.push({ name: 'login' });
            }
        },
    },
};
</script>

<style scoped>
    .login-link {
        cursor: pointer;
    }
    .login-link:hover {
        opacity: 0.5;
    }
    text {
        display: block;
        margin: 0;
        line-height: 1;
    }
</style>
