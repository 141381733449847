<template>
    <div :class="cardNumberInputClass">
        <label class="input__label"
            :class="{ 'required-input': mandatory }"
            v-if="showLabel"
            v-text="$t('STRIPE_ELEMENTS_CARD_NUMBER')"/>
        <div ref="input" :id="`${_uid}-input`" class="stripe_card_number"/>

        <!-- Validation -->
        <p class="input__message"
           v-show="errorMessage"
           v-text="errorMessage"/>
    </div>
</template>

<script>
import { AMEX_CARD_BRAND, stripeElementsClasses, stripeElementsStyle } from '@/config/StripeElements';

export default {
    name: 'StripeCardNumberInput',
    data: () => {
        return {
            cardNumber: null,
        };
    },
    props: {
        stripeElements: {
            required: true,
        },
        hasBeenRequested: {
            required: true,
            default: false,
        },
        brand: {
            required: false,
            default: null,
        },
        mandatory: {
            default: false,
            type: Boolean,
        },
        noPadding: {
            default: false,
            type: Boolean,
        },
        showLabel: {
            required: false,
            default: true,
        },
    },
    mounted: function() {
        this.mountCardNumberField();
    },
    destroy: function() {
        this.cardNumber.unmount();
        this.cardNumber.destroy();
    },
    methods: {
        /**
         * Handle mounting the card number field.
         *
         * @returns {void}
         */
        mountCardNumberField() {
            this.cardNumber = this.stripeElements.create('cardNumber', {
                classes: stripeElementsClasses,
                style: stripeElementsStyle,
                placeholder: '––––  ––––  ––––  ––––',
                showIcon: true,
            });

            this.cardNumber.mount(this.$refs.input);
        },
    },
    computed: {
        isValid() {
            return this.cardNumberValid;
        },
        cardNumberInputClass() {
            return !(this.cardNumberEmpty && this.hasBeenRequested) && this.cardNumberValid && !this.errorMessage
                ? `input ${this.noPadding ? 'noPadding' : ''}`
                : `input input--invalid ${this.noPadding ? 'noPadding' : ''}`;
        },
        cardNumberValid() {
            return this.cardNumber && !(this.cardNumber._implementation?._invalid && !this.cardNumber._implementation?._empty);
        },
        cardNumberEmpty() {
            return this.cardNumber && this.cardNumber._implementation?._empty;
        },
        isAmex() {
            return this.brand === AMEX_CARD_BRAND;
        },
        errorMessage() {
            if (this.hasBeenRequested) {
                if (this.cardNumberEmpty) {
                    return this.$t('STRIPE_ELEMENTS_YOUR_CARD_NUMBER');
                }

                if (!this.cardNumberValid) {
                    return this.$t('STRIPE_ELEMENTS_SORRY');
                }

                if (this.isAmex) {
                    return this.$t('STRIPE_ELEMENTS_AMERICAN_EXPRESS');
                }
            }

            return null;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/sass/brand/theme.scss';

.stripe_card_number {
    @include border-thin();
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: calc(20 + (25 / 2)) + px;
    background-color: $light-color;
}
.noPadding {
    padding: 0;
}
</style>
