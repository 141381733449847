export default class FaqCategories {
    categories = []

    constructor() {
        this.categories = require(`@/content/${process.env.VUE_APP_COUNTRY_ISO}/${process.env.VUE_APP_I18N_LOCALE}/faqs/categories.json`);
    }

    handle() {
        return new Promise((resolve) => resolve(this.categories));
    }

    findCategoryByPath(path) {
        path = path.replace(/(\/faq\/[^/]+).*/, '$1');

        return new Promise((resolve) => {
            resolve(this.categories.items
                .filter((current) => path === current.path)[0],
            );
        });
    }
}
