import { SUBSCRIPTION_STATUS_CANCELLED } from '@/constants/Subscription';
import ScheduledOrderService from '@/services/subscriptions/Subscriptions/ScheduledOrderService';
import { formatDate } from '@/helpers/DateHelpers';
import * as Dates from '@/constants/Dates';
import {
    filterDeliverableDays,
    filterHolidays,
    filterOptInDeliveryDays,
    filterUndeliverableDays,
} from '@/helpers/ScheduledOrderHelpers';

const getDefaultState = () => {
    return {
        deliveryDayName: null,
        cutOffDayName: null,
        firstCutOffDay: null,
        today: null,
        deliveryDays: [],
        holidays: [],
        optInDeliveryDays: [],
        haveFetched: false,
        selectedDelivery: null,
        undeliverableDays: [],
        deliverableDays: [],
        deliveries: [],
        otherDeliveries: [],
        otherMonthsDeliveries: {},
        shiftedDeliveries: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetSelectedDeliveryDays: (state) => state.deliveryDays.map(value => { value.isSelected = false; }),

        resetState: (state) => Object.assign(state, getDefaultState()),

        selectDelivery: (state, date) => {
            const deliveries = [...state.deliveries, ...state.otherDeliveries];

            let shiftedDate = state.shiftedDeliveries.find((item) => formatDate(item.after_date, Dates.DASHED_YEAR_MONTH_DAY) === date);
            let newSelectedDate = deliveries.find((value) => {
                return shiftedDate
                    ? formatDate(shiftedDate.before_date, Dates.DASHED_YEAR_MONTH_DAY) === value.datetime
                    : value.datetime === date;
            });

            newSelectedDate.newDate = shiftedDate ? formatDate(shiftedDate.after_date, Dates.DASHED_YEAR_MONTH_DAY) : null;
            state.selectedDelivery = newSelectedDate;
        },

        updateOtherDeliveries: (state, payload) => {
            state.otherMonthsDeliveries[`${payload.from}:${payload.to}`] = payload.deliveries;
            state.otherDeliveries = state.otherDeliveries.concat(payload.deliveries);
        },

        updateDeliveryDayName: (state, payload) => state.deliveryDayName = payload,

        updateCutOffDayName: (state, payload) => state.cutOffDayName = payload,

        updateToday: (state, payload) => state.today = payload,

        updateDeliveryDays: (state, payload) => state.deliveryDays = payload,

        updateFirstCutOffDay: (state, payload) => state.firstCutOffDay = payload,

        updateShiftedDeliveries: (state, payload) => {
            payload.shiftedDates ? state.shiftedDeliveries = payload.shiftedDates : [];
        },

        updateHolidays: (state, payload) => state.holidays = payload,

        updateOptInDeliveryDays: (state, payload) => state.optInDeliveryDays = payload,

        updateUndeliverableDays: (state, payload) => state.undeliverableDays = payload,

        updateDeliverableDays: (state, payload) => state.deliverableDays = payload,

        setAllDeliveries: (state, payload) => {
            payload.forEach(item => {
                if (!item.product_family) item.product_family = 'standard';
                if (!item.is_locked) item.is_locked = item.cut_off_expired;
            });
            state.deliveries = payload;
        },
    },
    actions: {
        async fetchAllDeliveries({ commit, state, rootGetters, dispatch }, forceReload = false) {
            if (state.haveFetched === false || forceReload === true) {
                let service = new ScheduledOrderService();
                let subscription = rootGetters['subscription/getActiveSubscription'];
                let selectedDelivery = rootGetters['scheduledOrder/getSelectedDelivery'];

                if (subscription && subscription.status !== SUBSCRIPTION_STATUS_CANCELLED) {
                    state.haveFetched = true;

                    return await new Promise(function(resolve, reject) {
                        return service.fetchAllDeliveries(subscription.id)
                            .then(response => {
                                commit('setAllDeliveries', response.data.data.deliveries);
                                commit('updateDeliveryDayName', response.data.data.deliveryDayName);
                                commit('updateToday', response.data.data.today);
                                commit('updateCutOffDayName', response.data.data.cutOffDayName);
                                commit('updateShiftedDeliveries', response.data.data);

                                if (selectedDelivery) {
                                    commit('selectDelivery', selectedDelivery.datetime);
                                }

                                return resolve(response.data.data);
                            });
                    });
                }
            }
        },

        async fetchAllScheduledOrders({ commit, state, rootGetters, dispatch }, forceReload = false) {
            if (state.haveFetched === false || forceReload === true) {
                let service = new ScheduledOrderService();
                let subscription = rootGetters['subscription/getActiveSubscription'];

                if (subscription && subscription.status !== SUBSCRIPTION_STATUS_CANCELLED) {
                    state.haveFetched = true;

                    await new Promise(function(resolve, reject) {
                        service.fetchAllScheduledOrders(subscription.id)
                            .then(response => {
                                commit('setAllDeliveries', response.data.data.deliveries);
                                commit('updateHolidays', filterHolidays(response.data.data.deliveries));
                                commit('updateOptInDeliveryDays', filterOptInDeliveryDays(response.data.data.deliveries));
                                commit('updateUndeliverableDays', filterUndeliverableDays(response.data.data.deliveries));
                                commit('updateDeliverableDays', filterDeliverableDays(response.data.data.deliveries));
                                commit('updateDeliveryDayName', response.data.data.deliveryDayName);
                                commit('updateToday', response.data.data.today);
                                commit('updateCutOffDayName', response.data.data.cutOffDayName);
                            });
                    });
                }
            }
        },

        async fetchDeliveriesForDateRange({ commit, state, rootGetters, dispatch }, payload) {
            let service = new ScheduledOrderService();
            let subscription = rootGetters['subscription/getActiveSubscription'];
            await new Promise(function(resolve, reject) {
                if (state.otherMonthsDeliveries[`${payload.from}:${payload.to}`]) {
                    return resolve([]);
                }
                service.fetchAllDeliveries(subscription.id, payload.to, payload.from)
                    .then(response => {
                        commit('updateOtherDeliveries', { to: payload.to, from: payload.from, deliveries: response.data.data.deliveries });
                        resolve(response.data.data.deliveries);
                    });
            });
        },

        resetScheduledOrders({ commit }) {
            commit('resetState');
        },

        selectDeliveryDay({ commit }, selectedDay) {
            commit('selectDelivery', selectedDay);
        },
    },
    getters: {
        getDeliveryDayName: state => state.deliveryDayName,

        getCutOffDayName: state => state.cutOffDayName,

        getSelectedDelivery: state => state.selectedDelivery,

        getShiftedDeliveries: state => state.shiftedDeliveries,

        getToday: state => state.today ? new Date(state.today) : null,

        getDeliveryDays: state => state.deliveryDays,

        getDeliveries: state => state.deliveries,

        getAllDeliveries(state) {
            const deliveries = !process.env.VUE_APP_ENABLE_MONTH_SWITCHING ? state.deliveries : [...state.deliveries, ...state.otherDeliveries];
            return deliveries.sort((a, b) => parseFloat(a.datetime.replace('-', '')) - parseFloat(b.datetime.replace('-', '')));
        },

        getFirstCutOffDay: state => state.firstCutOffDay,

        getHolidays: state => state.holidays,

        getOptInDeliveryDays: state => state.optInDeliveryDays,

        getUndeliverableDays: state => state.undeliverableDays,

        getDeliveryForDay(state) {
            return date => state.deliveries.find(delivery => delivery.datetime === date);
        },

        getNextAvailableDeliveryDatePredicted: (state, getters) => {
            const deliveries = getters.getAllDeliveries;
            const getNextAvailableDelivery = deliveries.filter(item => item.delivery_scheduled && !item.is_locked);
            return getNextAvailableDelivery[0]?.datetime;
        },
    },
};
