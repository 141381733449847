import routesMap from '@/routes/routeMap';
import GuestGiftingMiddleware from '@/middleware/GuestGiftingMiddleware';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: routesMap[country].guestGifting,
        name: 'guest-gifting',
        beforeEnter: GuestGiftingMiddleware,
        redirect: { name: 'guest-gifting.home' },
        components: {
            // route level code-splitting. This generates a separate chunk (gifting.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "guestGifting" */ '@/views/GuestGifting'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
        },
        children: [
            {
                path: routesMap[country].guestGiftingHome,
                name: 'guest-gifting.home',
                component: () => import(/* webpackChunkName: "GiftingHome" */ '@/views/GuestGifting/GiftingHome'),
            },
            {
                path: routesMap[country].giftVouchers,
                name: 'guest-gifting.vouchers',
                component: () => import(/* webpackChunkName: "GiftingMessage" */ '@/views/GuestGifting/GiftVoucherList'),
            },
            {
                path: routesMap[country].guestGiftingProductOneOff,
                name: 'guest-gifting.one-off-gifts',
                component: () => import(/* webpackChunkName: "GiftingOneOff" */ '@/views/GuestGifting/GiftingProducts'),
            },
            {
                path: routesMap[country].guestGiftingProductSubscription,
                name: 'guest-gifting.subscription-gifts',
                component: () => import(/* webpackChunkName: "GiftingSubs" */ '@/views/GuestGifting/GiftingProducts'),
            },
            {
                path: `${routesMap[country].guestGiftingProductDetailsPage}/:id/:name`,
                name: 'guest-gifting.arrangements',
                component: () => import(/* webpackChunkName: "GiftingArrangement" */ '@/views/GuestGifting/GiftingProductDetailsPage'),
            },
            {
                path: routesMap[country].guestGiftingDeliveryDate,
                name: 'guest-gifting.delivery-date',
                component: () => import(/* webpackChunkName: "GiftingDelivreryDate" */ '@/views/GuestGifting/DeliveryDate'),
            },
            {
                path: routesMap[country].guestGiftingArrangementUnavailable,
                name: 'guest-gifting.arrangement-unavailable',
                component: () => import(/* webpackChunkName: "GiftingUnavailable" */ '@/views/GuestGifting/ArrangementUnavailable'),
            },
            {
                path: routesMap[country].guestGiftingGiftNoteDesign,
                name: 'guest-gifting.gift-note-design',
                component: () => import(/* webpackChunkName: "GiftingGiftNoteDesign" */ '@/views/GuestGifting/GiftNoteDesign'),
            },
            {
                path: routesMap[country].guestGiftingGiftNoteMessage,
                name: 'guest-gifting.gift-note-message',
                component: () => import(/* webpackChunkName: "GiftingGiftNoteDesign" */ '@/views/GuestGifting/GiftNoteMessage'),
            },
            {
                path: routesMap[country].guestGiftingGiftingSummary,
                name: 'guest-gifting.gift-summary',
                component: () => import(/* webpackChunkName: "GiftingCheckoutDeliveryAddress" */ '@/views/GuestGifting/GiftingSummary'),
            },
            {
                path: routesMap[country].guestGiftingGiftingCheckoutEmail,
                name: 'guest-gifting.checkout.email',
                component: () => import(/* webpackChunkName: "GiftingCheckoutEmail" */ '@/views/GuestGifting/GiftingCheckoutEmail'),
            },
            {
                path: routesMap[country].guestGiftingGiftingSignIn,
                name: 'guest-gifting.sign-in',
                component: () => import(/* webpackChunkName: "GiftingCheckoutEmail" */ '@/views/GuestGifting/GiftingSignIn'),
            },
            {
                path: routesMap[country].guestGiftingGiftingCheckoutDeliveryAddress,
                name: 'guest-gifting.checkout.delivery-address',
                component: () => import(/* webpackChunkName: "GiftingCheckoutDeliveryAddress" */ '@/views/GuestGifting/GiftingCheckoutDeliveryAddress'),
            },
            {
                path: routesMap[country].guestGiftingGiftingCheckoutPayment,
                name: 'guest-gifting.checkout.payment',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingCheckoutPayment'),
            },
            {
                path: routesMap[country].guestGiftingGiftingOrderConfirmation,
                name: 'guest-gifting.order-confirmation',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingOrderConfirmation'),
            },
            {
                path: routesMap[country].guestGiftingGiftingChristmasShop,
                name: 'guest-gifting.christmas',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingSpecialShop'),
                meta: {
                    params: {
                        keyPrefix: 'CHRISTMAS',
                        faqCollection: 'christmas',
                        category: 'Christmas',
                        selfGiftEmable: true,
                        customDeliveryProducts: [],
                        customStartDate: '',
                        customEndDate: '',
                    },
                },
            },
            {
                path: routesMap[country].guestGiftingGiftingValentinesShop,
                name: 'guest-gifting.valentines',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingSpecialShop'),
                meta: {
                    params: {
                        keyPrefix: 'VALENTINES',
                        faqCollection: 'valentines-day',
                        category: 'Valentines',
                        selfGiftEmable: false,
                        customDeliveryProducts: ['Love Supreme'],
                        customStartDate: '2024-02-12',
                        customEndDate: '2024-02-14',
                    },
                },
            },
            {
                path: routesMap[country].guestGiftingGiftingMothersDayShop,
                name: 'guest-gifting.mothers-day',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingSpecialShop'),
                meta: {
                    params: {
                        keyPrefix: 'MOTHERS_DAY',
                        faqCollection: 'mothers-day',
                        category: 'Mothers_Day',
                        selfGiftEmable: true,
                        customDeliveryProducts: ['The Mothership', 'The Mothership & Sectaeurs', 'The Mothership & Candle'],
                        customStartDate: '2024-03-06',
                        customEndDate: '2024-03-10',
                    },
                },
            },
            {
                path: routesMap[country].guestGiftingGiftingPeonyShop,
                name: 'guest-gifting.peony-shop',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingSpecialShop'),
                meta: {
                    params: {
                        keyPrefix: 'PEONY_DAY',
                        faqCollection: 'peony-day',
                        category: 'Peony_Day',
                        selfGiftEmable: true,
                        customDeliveryProducts: ['The Mothership', 'The Mothership & Sectaeurs', 'The Mothership & Candle'],
                        customStartDate: '2024-03-06',
                        customEndDate: '2024-03-10',
                    },
                },
            },
            {
                path: routesMap[country].guestGiftingGiftingEasterShop,
                name: 'guest-gifting.easter',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingSpecialShop'),
                meta: {
                    params: {
                        keyPrefix: 'EASTER',
                        faqCollection: 'easter',
                        category: 'Easter',
                        selfGiftEmable: true,
                        customDeliveryProducts: ['The Egg-stra Special!'],
                        customStartDate: '2024-03-25',
                        customEndDate: '',
                    },
                },
            },
            {
                path: routesMap[country].guestGiftingGiftingChristmasShopAmaryllis,
                name: 'guest-gifting.arrangements.amaryllis',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingChristmasShopAmaryllis'),
            },
            {
                path: routesMap[country].guestGiftingGiftingChristmasShopWreath,
                name: 'guest-gifting.arrangements.wreath',
                component: () => import(/* webpackChunkName: "GiftingCheckoutPayment" */ '@/views/GuestGifting/GiftingChristmasShopWreath'),
            },
        ],
    },
    {
        path: `${routesMap[country].guestGiftingMessageLandingPage}/:giftRef`,
        name: 'guest-gifting.message',
        component: () => import(/* webpackChunkName: "GiftingMessage" */ '@/views/GuestGifting/GiftingMessageLandingPage'),
    },
];
