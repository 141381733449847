import RedirectToLoginPage from '@/services/auth/redirectService/RedirectToLoginPage';
import RedirectAfterAccessTokenExpiration from '@/services/auth/redirectService/RedirectAfterAccessTokenExpiration';

export default class AuthRedirectService {
    static redirectAfterAccessTokenExpiration() {
        (new RedirectAfterAccessTokenExpiration()).handle();
    }

    static redirectToLoginPage(redirectRouteName = 'login') {
        (new RedirectToLoginPage()).handle(redirectRouteName);
    }
}
