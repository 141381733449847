import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class CharityPrice {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, price, source) {
        const apiUrl = prepareUrl(ApiEndpoints['subscriptions.charity-price'], [subscriptionId]);
        return this.apiService.patch(apiUrl, {
            charity_price: price,
            source,
        });
    }
}
