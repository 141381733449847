export default [
    {
        name: 'blog',
        path: process.env.VUE_APP_BLOG_URL,
    },
    {
        name: 'flowerbond',
        path: 'https://platform.lygocapital.com/wp-login.php',
    },
    {
        name: 'survey-entrants',
        path: 'https://freddiesflowers.blog/survey',
    },
];
