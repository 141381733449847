import isString from 'lodash/isString';
import { exception } from '@/exceptions/Exceptions';
import { removeWhiteSpaces } from '@/helpers/StringHelpers';

const i18n = require('@/i18n').default;

export const formatCouponCode = (couponCode) => {
    if (!isString(couponCode)) exception(i18n.t('INVALID_COUPON_STRING'));

    return removeWhiteSpaces(couponCode.toUpperCase());
};
