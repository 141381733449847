export default {
    GBR: {
        // Account Routes
        account: '/account',
        confirmation: 'confirmation',
        accountConfirmation: '/account-confirmation',
        dashboard: 'dashboard',
        restart: 'restart',
        restartV2Subscription: '/activate-subscription',
        confirmSubscription: '/confirm-subscription',
        restartV2Gifting: '/activate-gifting',
        subscriptionSettings: '/subscription-settings',
        gifterDashboard: 'gifter-dashboard',
        rateMyFlowers: 'rate-my-flowers',
        freeFlowers: 'free-flowers',
        gifterFreeFlowers: 'gifter-free-flowers',
        yourBoxesRedirect: 'your-boxes',
        yourBoxes: 'your-boxes',
        deliverySchedule: 'delivery-schedule',
        subscriptionManagement: 'subscription-management',
        orderAVase: 'order-a-vase',
        updateYourPlan: 'update-your-plan',
        pauseOrCancel: 'pause-or-cancel',
        shop: 'shop',
        product: 'shop',
        deliveryAddresses: 'addresses',
        addGiftAddresses: 'add-gift-addresses',
        editGiftAddresses: 'edit-gift-addresses',
        nextWeekDelivery: 'next-week-delivery',
        cardDetails: 'card-details',
        manageAccount: 'manage-account',
        gifting: 'gifting',
        restartConfirmationPage: 'restart-confirmation-page',
        doubleOptinConfirmation: 'double-opt-in-confirmation',
        doubleOptinSpoilerConfirmation: 'double-opt-in-soiker-confirmation',
        // Auth Routes
        login: '/login',
        logout: '/logout',
        forgotPassword: '/password/reset',
        resetPasswordNotification: '/password/notification',
        resetPassword: '/password/reset',
        // Content Routes
        jobs: '/jobs',
        privacyPolicy: '/privacy-policy',
        termsAndConditions: '/terms-and-conditions',
        impressum: '/impressum',
        paymentAndDelivery: 'payment-and-delivery',
        rightToCancel: '/right-to-cancel',
        // Coupon Routes
        coupon: '/c',
        // Development Routes
        styleGuide: '/style',
        // FAQ Routes
        faq: '/faq',
        categories: 'categories',
        answers: '*',
        // Gifting Routes
        chooseYourGift: 'choose-your-gift',
        numberOfDeliveries: 'number-of-deliveries',
        deliveryAddress: 'delivery-address',
        freddiesAddons: 'freddies-addons',
        deliveryDay: 'delivery-day',
        giftCard: 'gift-card',
        payment: 'payment',
        thankYou: 'thank-you',
        freddiesshop: 'freddies-shops',

        // Guest Gifting Routes
        guestGifting: '/gifting',
        guestGiftingHome: '/',
        guestGiftingProductOneOff: 'one-off-gifts',
        guestGiftingProductSubscription: 'subscription-gifts',
        guestGiftingProductDetailsPage: 'arrangements',
        guestGiftingDeliveryDate: 'delivery-date',
        guestGiftingArrangementUnavailable: 'arrangement-unavailable',
        guestGiftingGiftNoteDesign: 'gift-note-design',
        guestGiftingGiftNoteMessage: 'gift-note-message',
        guestGiftingGiftingSummary: 'gift-summary',
        guestGiftingGiftingCheckoutEmail: 'checkout/email',
        guestGiftingGiftingSignIn: '/gifting/signin',
        guestGiftingGiftingCheckoutDeliveryAddress: 'checkout/delivery-address',
        guestGiftingGiftingCheckoutPayment: 'checkout/payment',
        guestGiftingGiftingOrderConfirmation: 'order-confirmation',
        guestGiftingGiftingChristmasShop: 'christmas',
        guestGiftingGiftingMothersDayShop: 'mothers-day-flowers',
        guestGiftingGiftingEasterShop: 'easter-flowers',
        guestGiftingGiftingPeonyShop: 'peony-flower-shop',
        guestGiftingGiftingValentinesShop: 'valentines',
        guestGiftingGiftingChristmasShopAmaryllis: 'arrangements/amaryllis',
        guestGiftingGiftingChristmasShopWreath: 'arrangements/freddies-christmas-wreath',
        guestGiftingMessageLandingPage: '/gifting/gift-message',
        giftVouchers: 'vouchers',

        // Home Routes
        home: '/',
        theFlowers: '/the-flowers',
        theDelivery: '/the-delivery',
        howItworks: '/how-it-works',
        meetFreddie: '/freddies-story',
        brightenYourHome: '/brighten-your-home',
        theArranging: '/the-arranging',
        theIntroductions: '/the-introductions',
        // Recomment a Friend Routes
        referenceFromAFriend: '/r',
        referenceFromWeb: '/r',
        // Register Routes
        register: '/register',
        regAccount: 'account',
        delivery: 'delivery',
        regCardDetails: 'card-details',
        outstandingOrders: 'outstanding-orders',
        email: 'email',
        subscriptionDetails: 'subscription-details',
        chooseSubscription: 'choose-subscription',
        deliveryDetails: 'delivery-details',
        yourDetails: 'your-details',
        paymentDetails: 'payment-details',
        // Subscription Routes
        subscriptions: '/subscriptions',
        checkout: 'checkout',
        size: 'size',
        frequency: 'frequency',
        // Others
        specialDates: 'special-dates',
        bumperBox: 'bumper-box',
        trt: '/tag-rugby-trust',
        accessibility: '/accessibility',
        sustainability: '/sustainability',
        growers: '/growers',
        chooseBoxType: 'choose-your-box-type',
        survey: '/survey',
        business: '/business',
        pennies: '/pennies',
    },

    DEU: {
        // Account Routes
        account: '/konto',
        confirmation: 'bestatigung',
        accountConfirmation: '/kontobestätigung',
        dashboard: 'instrumententafel',
        restart: 'neustart',
        restartV2Subscription: '/Abo-aktivieren',
        confirmSubscription: '/confirm-subscription',
        restartV2Gifting: '/activate-gifting',
        subscriptionSettings: '/subscription-settings',
        gifterDashboard: 'gifter-dashboard',
        rateMyFlowers: 'meine-blumen-bewerten',
        freeFlowers: 'gratis-blumen',
        gifterFreeFlowers: 'gifter-gratis-blumen',
        yourBoxesRedirect: 'mein-boxen',
        yourBoxes: 'mein-boxen',
        deliverySchedule: 'lieferplan',
        subscriptionManagement: 'subscription-management',
        orderAVase: 'vase-bestellen',
        updateYourPlan: 'update-your-plan',
        pauseOrCancel: 'pause-or-cancel',
        shop: 'shop',
        product: 'shop/:productId',
        deliveryAddresses: 'lieferadressen',
        addGiftAddresses: 'add-gift-addresses',
        editGiftAddresses: 'edit-gift-addresses',
        nextWeekDelivery: 'nachste-lieferung',
        cardDetails: 'kreditkartendetails',
        manageAccount: 'mein-konto-verwalten',
        gifting: 'strausse-verschenken',
        restartConfirmationPage: 'neustart-bestatigung',
        doubleOptinConfirmation: 'bestatigung-der-doppeloption',
        doubleOptinSpoilerConfirmation: 'bestatigung-der-spoiler-doppeloption',
        // Auth Routes
        login: '/einloggen',
        logout: '/ausloggen',
        forgotPassword: '/passwort/zurucksetzen',
        resetPasswordNotification: '/passwort/benachrichtigung',
        resetPassword: '/passwort/zurucksetzen',
        // Content Routes
        jobs: '/arbeitsplatze',
        privacyPolicy: '/datenschutz',
        termsAndConditions: '/agb',
        accessibility: '/zuganglichkeit',
        impressum: '/impressum',
        paymentAndDelivery: '/zahlung-und-versand', // linked by path in t&c json line 13
        rightToCancel: '/widerrufsbelehrung', // linked by path in t&c json line 13
        // Coupon Routes
        coupon: '/c',
        // Development Routes
        styleGuide: '/style', // ?????
        // FAQ Routes
        faq: '/faq',
        categories: 'kategorien',
        answers: '*',
        // Gifting Routes
        chooseYourGift: 'wahle-dein-geschenk',
        numberOfDeliveries: 'anzahl-der-lieferungen',
        deliveryAddress: 'lieferadresse',
        freddiesAddons: 'freddies-extras',
        deliveryDay: 'lieferdatum',
        giftCard: 'personalisierte-karte',
        payment: 'zahlung',
        thankYou: 'danke',
        freddiesshop: 'freddies-shops',

        // Guest Gifting Routes
        guestGifting: '/blumen-schenken',
        guestGiftingHome: '/',
        guestGiftingProductOneOff: 'einmalige-blumen-geschenke',
        guestGiftingProductSubscription: 'abonnement',
        guestGiftingProductDetailsPage: 'arrangements',
        guestGiftingDeliveryDate: 'liefertermin',
        guestGiftingArrangementUnavailable: 'arrangement-nicht-verfuegbar',
        guestGiftingGiftNoteDesign: 'geschenk-notiz-gestaltung',
        guestGiftingGiftNoteMessage: 'geschenk-notiz-nachricht',
        guestGiftingGiftingSummary: 'geschenk-zusammenfassung',
        guestGiftingGiftingCheckoutEmail: 'kasse/email',
        guestGiftingGiftingSignIn: '/geschenk/anmeldung',
        guestGiftingGiftingCheckoutDeliveryAddress: 'kasse/lieferanschrift',
        guestGiftingGiftingCheckoutPayment: 'kasse/zahlung',
        guestGiftingGiftingOrderConfirmation: 'bestell-bestaetigung',
        guestGiftingGiftingChristmasShop: 'weihnachten',
        guestGiftingGiftingMothersDayShop: 'muttertag',
        guestGiftingGiftingEasterShop: 'easter-flowers',
        guestGiftingGiftingPeonyShop: 'pfingstrosen-strauss-kaufen',
        guestGiftingGiftingValentinesShop: 'valentinstag',
        guestGiftingGiftingChristmasShopAmaryllis: 'gestecke/amaryllis',
        guestGiftingGiftingChristmasShopWreath: 'gestecke/freddies-weihnachtskranz',
        guestGiftingMessageLandingPage: '/gestecke/geschenknachricht',
        giftVouchers: 'vouchers',

        // Home Routes
        home: '/',
        theFlowers: '/frische-blumen-der-saison',
        theDelivery: '/frische-blumen-liefern-lassen',
        theArranging: '/blumen-arrangieren',
        theIntroductions: '/die-einfuhrungen',
        // Recomment a Friend Routes
        referenceFromAFriend: '/r',
        referenceFromWeb: '/r',
        // Register Routes
        register: '/register',
        regAccount: 'konto',
        delivery: 'lieferanten',
        regCardDetails: 'kartendetails',
        outstandingOrders: 'outstanding-orders',
        email: 'email',
        subscriptionDetails: 'abonnement-details',
        chooseSubscription: 'choose-subscription',
        deliveryDetails: 'lieferdetails',
        yourDetails: 'deine-details',
        paymentDetails: 'zahlungsdetails',
        // Subscription Routes
        subscriptions: '/subscriptions',
        checkout: 'checkout',
        size: 'size',
        frequency: 'frequency',
        // Others
        specialDates: 'special-dates',
        bumperBox: 'bumper-box',
        trt: '/tag-rugby-trust',
        sustainability: '/nachhaltigkeit-der-blumen',
        growers: '/growers',
        chooseBoxType: 'choose-your-box-type',
        survey: '/survey',
        business: '/firmenkunden',
        pennies: '/pennies',
    },

    AUT: {
        // Account Routes
        account: '/konto',
        confirmation: 'bestatigung',
        accountConfirmation: '/account-confirmation',
        dashboard: 'instrumententafel',
        rateMyFlowers: 'meine-blumen-bewerten',
        freeFlowers: 'gratis-blumen',
        yourBoxesRedirect: 'mein-boxen',
        yourBoxes: 'mein-boxen',
        deliverySchedule: 'lieferplan',
        subscriptionManagement: 'subscription-management',
        orderAVase: 'vase-bestellen',
        shop: 'shop',
        product: 'shop',
        deliveryAddresses: 'lieferadressen',
        nextWeekDelivery: 'nachste-lieferung',
        cardDetails: 'kreditkartendetails',
        manageAccount: 'mein-konto-verwalten',
        gifting: 'strausse-verschenken',
        restartConfirmationPage: 'neustart-bestatigung',
        doubleOptinConfirmation: 'bestatigung-der-doppeloption',
        doubleOptinSpoilerConfirmation: 'bestatigung-der-spoiler-doppeloption',
        // Auth Routes
        login: '/einloggen',
        logout: '/logout',
        forgotPassword: '/passwort/zurucksetzen',
        resetPasswordNotification: '/passwort/benachrichtigung',
        resetPassword: '/passwort/zurucksetzen',
        // Content Routes
        jobs: '/jobs',
        privacyPolicy: '/datenschutz',
        termsAndConditions: '/agb',
        impressum: '/impressum',
        paymentAndDelivery: '/zahlung-und-versand', // linked by path in t&c json line 13
        rightToCancel: '/widerrufsbelehrung', // linked by path in t&c json line 13
        // Coupon Routes
        coupon: '/c',
        // Development Routes
        styleGuide: '/style', // ?????
        // FAQ Routes
        faq: '/faq',
        categories: 'kategorien',
        answers: '*',
        // Gifting Routes
        chooseYourGift: 'wahle-dein-geschenk',
        numberOfDeliveries: 'anzahl-der-lieferungen',
        deliveryAddress: 'lieferadresse',
        freddiesAddons: 'freddies-extras',
        deliveryDay: 'lieferdatum',
        giftCard: 'personalisierte-karte',
        payment: 'zahlung',
        thankYou: 'danke',
        freddiesshop: 'freddies-shops',
        // Home Routes
        home: '/',
        theFlowers: '/die-blumen',
        theDelivery: '/die-lieferung',
        theArranging: '/das-arrangieren',
        theIntroductions: '/die-einfuhrungen',
        // Recomment a Friend Routes
        referenceFromAFriend: '/r',
        referenceFromWeb: '/r',
        // Register Routes
        register: '/register',
        regAccount: 'konto',
        delivery: 'lieferanten',
        regCardDetails: 'Kartendetails',
        outstandingOrders: 'outstanding-orders',
        email: '/email',
        subscriptionDetails: 'subscription-details',
        deliveryDetails: 'delivery-details',
        yourDetails: 'your-details',
        paymentDetails: 'payment-details',
        // Others
        specialDates: 'special-dates',
        bumperBox: 'bumper-box',
        trt: '/tag-rugby-trust',
        accessibility: '/accessibility',
        sustainability: '/sustainability',
        growers: '/growers',
        chooseBoxType: 'choose-your-box-type',
    },

    NLD: {
        // Account Routes
        account: '/account',
        confirmation: 'bevestiging',
        accountConfirmation: '/account-confirmation',
        dashboard: 'dashboard',
        rateMyFlowers: 'beoordeel-mijn-bloemen',
        freeFlowers: 'gratis-bloemen',
        yourBoxesRedirect: 'jouw-dozen',
        yourBoxes: 'jouw-dozen',
        deliverySchedule: 'leveringsschema',
        subscriptionManagement: 'subscription-management',
        orderAVase: 'bestel-een-vaas',
        shop: 'shop',
        product: 'shop',
        deliveryAddresses: 'leveringsadres',
        nextWeekDelivery: 'levering-volgende-week',
        cardDetails: 'kaartgegevens',
        manageAccount: 'beheer-account',
        gifting: 'cadeaus',
        restartConfirmationPage: 'herstart-bevestigingspagina',
        doubleOptinConfirmation: 'dubbele-optin-bevestiging',
        doubleOptinSpoilerConfirmation: 'dubbele-optin-spoiler-bevestiging',
        // Auth Routes
        login: '/inloggen',
        logout: '/uitloggen',
        forgotPassword: '/wachtwoord/reset',
        resetPasswordNotification: '/wachtwoord/notificatie',
        resetPassword: '/wachtwoord/reset',
        // Content Routes
        jobs: '/null',
        privacyPolicy: '/privacybeleid',
        termsAndConditions: '/algemene voorwaarden',
        impressum: '/impressum',
        paymentAndDelivery: '/payment-and-delivery',
        rightToCancel: '/recht-te-annuleren',
        // Coupon Routes
        coupon: '/c',
        // Development Routes
        styleGuide: '/style', // ?????
        // FAQ Routes
        faq: '/faq',
        categories: 'categories',
        answers: '*',
        // Gifting Routes
        chooseYourGift: 'kies-je-cadeau',
        numberOfDeliveries: 'aantal-leveringen',
        deliveryAddress: 'leveringsadres',
        freddiesAddons: 'freddies-addons',
        deliveryDay: 'afleverdag',
        giftCard: 'gift-card',
        payment: 'betaling',
        thankYou: 'bedankt',
        freddiesshop: 'freddies-shops',
        // Home Routes
        home: '/',
        theFlowers: '/de-bloemen',
        theDelivery: '/de-levering',
        theArranging: '/de-arranging',
        theIntroductions: '/de-introducties',
        // Recomment a Friend Routes
        referenceFromAFriend: '/r',
        referenceFromWeb: '/r',
        // Register Routes
        register: '/registratie',
        regAccount: 'account',
        delivery: 'levering',
        regCardDetails: 'kaartgegevens',
        outstandingOrders: 'outstanding-orders',
        email: '/email',
        subscriptionDetails: 'subscription-details',
        deliveryDetails: 'delivery-details',
        yourDetails: 'your-details',
        paymentDetails: 'payment-details',
        // Others
        specialDates: 'special-dates',
        bumperBox: 'bumper-box',
        trt: '/tag-rugby-trust',
        accessibility: '/accessibility',
        sustainability: '/sustainability',
        growers: '/growers',
        chooseBoxType: 'choose-your-box-type',
    },

    USA: {
        // Account Routes
        account: '/account',
        confirmation: 'confirmation',
        accountConfirmation: '/account-confirmation',
        dashboard: 'dashboard',
        rateMyFlowers: 'rate-my-flowers',
        freeFlowers: 'free-flowers',
        yourBoxesRedirect: 'your-boxes',
        yourBoxes: 'your-boxes',
        deliverySchedule: 'delivery-schedule',
        subscriptionManagement: 'subscription-management',
        orderAVase: 'order-a-vase',
        shop: 'shop',
        product: 'shop',
        deliveryAddresses: 'addresses',
        nextWeekDelivery: 'next-week-delivery',
        cardDetails: 'card-details',
        manageAccount: 'manage-account',
        gifting: 'gifting',
        restartConfirmationPage: 'restart-confirmation-page',
        doubleOptinConfirmation: 'double-opt-in-confirmation',
        doubleOptinSpoilerConfirmation: 'double-opt-in-soiker-confirmation',
        // Auth Routes
        login: '/login',
        logout: '/logout',
        forgotPassword: '/password/reset',
        resetPasswordNotification: '/password/notification',
        resetPassword: '/password/reset',
        // Content Routes
        jobs: '/jobs',
        privacyPolicy: '/privacy-policy',
        termsAndConditions: '/terms-and-conditions',
        impressum: '/impressum',
        paymentAndDelivery: '/payment-and-delivery',
        rightToCancel: '/right-to-cancel',
        // Coupon Routes
        coupon: '/c',
        // Development Routes
        styleGuide: '/style',
        // FAQ Routes
        faq: '/faq',
        categories: 'categories',
        answers: '*',
        // Gifting Routes
        chooseYourGift: 'choose-your-gift',
        numberOfDeliveries: 'number-of-deliveries',
        deliveryAddress: 'delivery-address',
        freddiesAddons: 'freddies-addons',
        deliveryDay: 'delivery-day',
        giftCard: 'gift-card',
        payment: 'payment',
        thankYou: 'thank-you',
        freddiesshop: 'freddies-shops',
        // Home Routes
        home: '/',
        theFlowers: '/the-flowers',
        theDelivery: '/the-delivery',
        theArranging: '/the-arranging',
        theIntroductions: '/the-introductions',
        // Recomment a Friend Routes
        referenceFromAFriend: '/r',
        referenceFromWeb: '/r',
        // Register Routes
        register: '/register',
        regAccount: 'account',
        delivery: 'delivery',
        regCardDetails: 'card-details',
        outstandingOrders: 'outstanding-orders',
        email: '/email',
        subscriptionDetails: 'subscription-details',
        deliveryDetails: 'delivery-details',
        yourDetails: 'your-details',
        paymentDetails: 'payment-details',
        // Others
        specialDates: 'special-dates',
        bumperBox: 'bumper-box',
        trt: '/tag-rugby-trust',
        accessibility: '/accessibility',
        sustainability: '/sustainability',
        growers: '/growers',
        chooseBoxType: 'choose-your-box-type',
    },
};
