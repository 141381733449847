import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { exception } from '@/exceptions/Exceptions';
import { convertToCamelCase } from '@/helpers/StringHelpers';

export const convertObjectKeysToCamelCase = (object) => {
    if (!isObject(object) && !isArray(object)) return object;

    if (isArray(object)) {
        return object.map((value) => {
            return (isString(value)) ? convertToCamelCase(value) : value;
        });
    }

    const newObject = {};

    Object.keys(object).forEach((key) => {
        newObject[convertToCamelCase(key)] = convertObjectKeysToCamelCase(object[key]);
    });

    return newObject;
};

export const isObjectEmpty = (object) => {
    if (!isObject(object)) exception('Invalid object');
    if (isEmpty(object)) return true;

    return Object.values(object).every(property => (property === null || property === ''));
};

export const mergeObjects = (objects) => {
    if (!isArray(objects) || isEmpty(objects)) exception('Invalid Array');

    let mergedObjects = {};

    objects.forEach(function(object) {
        if (!isObject(object)) exception('Invalid object');

        mergedObjects = merge(mergedObjects, object);
    });

    return mergedObjects;
};
