import AuthCanvasserMiddleware from '@/middleware/AuthCanvasserMiddleware';
import CanvasserLoginMiddleware from '@/middleware/CanvasserLoginMiddleware';

export default [
    {
        path: '/',
        name: 'home',
        beforeEnter: AuthCanvasserMiddleware,
        redirect: { name: 'login' },
        components: {
            // route level code-splitting. This generates a separate chunk (canvasser.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "canvasser" */ '@/views/Canvasser'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
        children: [
            {
                path: 'dashboard',
                name: 'account.dashboard',
                component: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/Dashboard'),
            },
            {
                path: 'register',
                name: 'canvasser-register',
                beforeEnter: CanvasserLoginMiddleware,
                redirect: { name: 'canvasser-register.account' },
                components: {
                    default: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/Register'),
                    registerSubNavigation: () => import(/* webpackChunkName: "register-submenu" */ '@/components/structure/navigation/RegisterSubNavigation'),
                },
                children: [
                    {
                        path: 'account',
                        name: 'canvasser-register.account',
                        component: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/register/Account'),
                    },
                    {
                        path: 'delivery',
                        name: 'canvasser-register.delivery',
                        component: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/register/Delivery'),
                    },
                    {
                        path: 'card-details',
                        name: 'canvasser-register.card-details',
                        component: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/register/CardDetails'),
                    },
                ],
            },
            {
                path: 'confirmation',
                name: 'canvasser-register.confirmation',
                component: () => import(/* webpackChunkName: "canvasser" */ '@/views/canvasser/Confirmation'),
            },
        ],
    },
];
