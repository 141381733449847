<template>
    <div id="app">
        <div class="header-fixed">
            <transition name="transitionFade">
                <component :is="`cookie-banner-${country}`" v-if="!disableCookieBanner && !hasAcceptedCookies && !isCanvasser" />
            </transition>

            <transition-group name="transitionFade" v-if="!hideNavAndFooter">
                <router-view v-for="(item, index) in ['topNavigation', 'topNavigationV2']" :key="`top-nav-${index}`" :name="item"></router-view>
            </transition-group>
        </div>

        <div class="announcement-bar" :class="{'no-padding' : !hideAnnouncementBar && hideNavAndFooter}" v-if="!hideAnnouncementBar">
            <builder-render-content v-if="canShowContent" model="announcement-bar" :content="content" :api-key="apiKey"/>
        </div>

        <div class="router__container container-route" :key="$route.fullPath">
            <transition :name="transitionName">
                <router-view :key="$route.fullPath"></router-view>
            </transition>
        </div>

        <bottom-sheet v-if="showBottomPage">
            <component :is="bottomSheetSlotComponent"/>
        </bottom-sheet>

        <sticky-banner v-if="showStickyBanner">
            <component :is="stickyBannerSlotComponent"/>
        </sticky-banner>

        <router-view name="orderSummaryModal"></router-view>

        <transition name="transitionFade">
            <tool-tip-modal :showToolTipModal="showToolTipMdal" :fields="toolTipData"></tool-tip-modal>
        </transition>

        <transition name="transitionFade">
            <router-view v-if="!hideNavAndFooter" :name="footer" :pageName="$route.name"></router-view>
        </transition>

        <country-switch-modal v-show="showCountryModal" :appLocation="appLocation" :userLocation="userLocation"/>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { fetchOneEntry, RenderContent } from '@builder.io/sdk-vue/vue2';
import { addDays } from '@/helpers/DateHelpers';
import BottomSheet from '@/components/partials/BottomSheet.vue';
import CountrySwitchModal from '@/components/modals/CountrySwitchModal';
import Countries from '@/resources/countrySwitcher.json';
import CookieBanner from '@/components/structure/CookieBanner';
import ToolTipModal from '@/components/modals/ToolTipModal.vue';
import { turnScrollOff, turnScrollOn } from '@/helpers/ScrollHelpers.js';
import OptInUpcomingDelivery from './views/account/OptInUpcomingDelivery.vue';
import OptInUpcomingDeliveryBanner from './views/account/OptInUpcomingDeliveryBanner.vue';
import StickyBanner from '@/components/partials/StickyBanner.vue';
import { CSPageEvents, removeScript } from '@/helpers/GAHelpers.js';
import { prefillZDForm } from '@/helpers/ZendeskHelpers';
import { getCollection } from '@/helpers/ButterCmsHelpers';
import ShowstopperUpgrade from '@/views/account/ShowstopperUpgrade';
import ShowstopperUpgradeMobile from '@/views/account/ShowstopperUpgradeMobile';
import '@builder.io/sdk-vue/vue2/css';

const i18n = require('@/i18n').default;

export default {
    name: 'FrontEndWebsite',
    metaInfo() {
        let metaTags = [];
        metaTags.push({ vmid: 'title', property: 'og:title', content: this.$t('DEFAULT_META_TITLE') });
        metaTags.push({ vmid: 'description_meta', name: 'description', content: this.$t('DEFAULT_META_DESCRIPTION') });
        metaTags.push({ vmid: 'description', property: 'og:description', content: this.$t('DEFAULT_META_DESCRIPTION') });
        metaTags.push({ vmid: 'keywords', name: 'keywords', content: this.$t('DEFAULT_META_KEY_WORDS') });
        metaTags.push({ vmid: 'image', property: 'og:image', content: this.$t('DEFAULT_META_IMAGE') });
        metaTags.push({ vmid: 'site_name', property: 'og:site_name', content: this.$t('DEFAULT_META_SITE_NAME') });
        metaTags.push({ vmid: 'twitter_card', name: 'twitter:card', content: this.$t('DEFAULT_META_TWITTER_CARD') });
        metaTags.push({ vmid: 'twitter_image_alt', name: 'twitter:image:alt', content: this.$t('DEFAULT_META_TWITTER_IMAGE_ALE') });
        metaTags.push({ vmid: 'image_alt', property: 'og:image:alt', content: this.$t('DEFAULT_META_IMAGE_ALT') });
        return { meta: metaTags };
    },
    data() {
        return {
            transitionName: '',
            userInDifferentLocation: false,
            appLocation: null,
            userLocation: null,
            showToolTipMdal: false,
            toolTipData: null,
            showBottomPage: false,
            showStickyBanner: false,
            bottomSheetSlotComponent: null,
            stickyBannerSlotComponent: null,
            content: null,
            apiKey: process.env.VUE_APP_BUILDER_IO_KEY,
            canShowContent: null,
            hideNavAndFooter: false,
            hideAnnouncementBar: false,
        };
    },
    components: {
        BottomSheet,
        CookieBanner,
        CountrySwitchModal,
        ToolTipModal,
        OptInUpcomingDelivery,
        OptInUpcomingDeliveryBanner,
        ShowstopperUpgrade,
        ShowstopperUpgradeMobile,
        StickyBanner,
        'builder-render-content': RenderContent,
    },
    computed: {
        ...mapGetters({
            hasAnsweredCountrySwitch: 'account/getHasAnsweredCountrySwitch',
            hasAgreedToCookies: 'cookies/getHasAgreedToCookies',
            hasAcceptedCookies: 'cookies/getHasAnsweredCookieForm',
            scrollbarEnabled: 'account/getScrollbarEnabled',
            isCanvasser: 'auth/isCanvasser',
            butterCMSDataAvailable: 'cms/getButterCMSDataAvailable',
            isZendeskMounted: 'cookies/isZendeskMounted',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            customerFullName: 'account/getFullName',
            customerEmail: 'account/getEmail',
            country: 'localisation/country',
            couponCode: 'register/getCouponCode',
            gifterToSubscriptionUpgradeCoupon: 'account/getGifterToSubscriberUpgradeCoupon',
            isGBR: 'localisation/isGBR',
            isDEU: 'localisation/isDEU',
            isZendeskEnabled: 'account/isZendeskEnabled',
        }),
        showCountryModal() {
            return this.userInDifferentLocation && !this.hasAnsweredCountrySwitch;
        },
        footer() {
            if (this.$route.name?.includes('register.')) return null;
            if (!this.isUserLoggedIn && this.$route?.name?.includes('guest-gifting')) return 'footer';
            if (this.$route.name === 'home') return this.butterCMSDataAvailable ? 'footer' : null;
            if (this.$route.name === 'home') return this.butterCMSDataAvailable ? 'footer' : null;
            return 'footer';
        },
        language() {
            return this.$root.$i18n.locale;
        },
        disableCookieBanner() {
            return process.env.VUE_APP_DISABLE_COOKIE_BANNER === 'true';
        },
        couponCookie() {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; couponCode=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        },
    },
    mounted() {
        window.addEventListener('CookiebotOnConsentReady', this.mountScriptBasedConsent);
        this.$root.$on('show-tool-tip-modal', this.showToolTip);
        this.$root.$on('hide-tool-tip-modal', this.hideToolTip);
        this.$root.$on('show-bottom-sheet', this.showBottomSheet);
        this.$root.$on('hide-bottom-sheet', this.hideBottomSheet);
        this.$root.$on('show-sticky-banner', this.showBanner);
        this.$root.$on('hide-sticky-banner', this.hideBanner);
        this.$root.$on('hide-announcement-bar', this.onHideAnnouncementBar);
        this.$root.$on('hide-footer-nav', this.onHideNavAndFooter);

        fetchOneEntry({
            model: 'announcement-bar',
            apiKey: this.apiKey,
            userAttributes: {
                urlPath: window.location.pathname,
                country: this.isGBR ? 'UK' : 'DE',
                isLoggedIn: this.isUserLoggedIn,
            },
        }).then(res => {
            this.content = res;
            this.$store.commit('register/setBuilderAnnouncementBarContent', this.content);
            this.canShowContent = this.content;
        });

        getCollection('seasonal_color', null, 'en')
            .then(data => {
                document.querySelector(':root').style.setProperty('--seasonal-color', `#${data[0].color}`);
                this.$store.commit('register/setSeasonalColor', `#${data[0].color}`);
            });

        if (this.$globalQueryParams?.accessibility) {
            this.$store.commit('account/setScrollbarEnabled', true);
        }
        if (!this.scrollbarEnabled) {
            this.$store.commit('account/setScrollbarEnabled', false);
        }
        this.$store.commit('cookies/setAwcValue', this.$route.query.awc);
        this.$store.dispatch('cookies/mountScripts');
        setTimeout(() => {
            const location = JSON.parse(sessionStorage.getItem('ABTastyGeoloc'));
            const appIso = process.env.VUE_APP_COUNTRY_ISO;
            this.appLocation = Countries.find(country => country.geocode === appIso || country.iso === appIso);
            this.userLocation = Countries.find(country => (location && location.country_iso_code === country.geocode) || (location && location.country_iso_code === country.iso));

            // if the users location is available in the country list, then we can show the modal if its a different location from the app location
            this.userInDifferentLocation = this.appLocation && this.userLocation && this.userLocation.iso !== this.appLocation.iso;
        }, 2000);

        const params = this.$globalQueryParams?.c;
        if (this.couponCookie === params?.toLowerCase()) this.$store.commit('register/setCouponCode', this.couponCookie);
        else if (params) {
            this.$store.commit('register/setCouponCode', params);
            document.cookie = `couponCode=${params.toLowerCase()}; expires=${addDays(new Date(), 30)}; path=/`;
        }
    },
    updated() {
        if (this.isZendeskEnabled) {
            setTimeout(() => {
                prefillZDForm(this.customerFullName, this.customerEmail, this.isUserLoggedIn);
            }, 500);
        }
        CSPageEvents('ContentSquare-Trigger-on-every-page');
    },
    destroyed() {
        window.removeEventListener('CookiebotOnConsentReady', this.mountScriptBasedConsent);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            if (this.isDixaMounted && this.isMobile) _dixa_.invoke('shutdown');
            else if (this.isDixaMounted && !this.isMobile) _dixa_.invoke('init', { messengerToken: process.env.VUE_APP_DIXA_MESSENGER_TOKEN });
        },
        onHideNavAndFooter(payload) {
            this.hideNavAndFooter = payload;
        },
        onHideAnnouncementBar(payload) {
            this.hideAnnouncementBar = payload;
        },
        showToolTip(payload) {
            turnScrollOff(document);
            this.toolTipData = payload;
            this.showToolTipMdal = true;
        },
        hideToolTip() {
            turnScrollOn(document);
            this.showToolTipMdal = false;
        },
        showBottomSheet(mobileComponent) {
            this.showBottomPage = true;
            this.bottomSheetSlotComponent = mobileComponent;
        },
        hideBottomSheet() {
            this.showBottomPage = false;
        },
        showBanner(desktopComponent) {
            this.showStickyBanner = true;
            this.stickyBannerSlotComponent = desktopComponent;
        },
        hideBanner() {
            this.showStickyBanner = false;
        },
        updateFacebookPixel() {
            if (this.$route.name?.includes('register.' && 'account.account-confirmation')) {
                fbq('init', process.env.VUE_APP_FB_PIXEL_KEY);
                fbq('track', 'PageView');
            } else if (this.$route.name?.includes('guest-gifting.')) {
                fbq('init', process.env.VUE_APP_FB_PIXEL_GIFTING_KEY);
                fbq('track', 'PageView');
            } else {
                fbq('init', process.env.VUE_APP_FB_PIXEL_KEY);
                fbq('init', process.env.VUE_APP_FB_PIXEL_GIFTING_KEY);
                fbq('track', 'PageView');
            }
        },
        mountScriptBasedConsent() {
            const cookieConsent = window.Cookiebot?.consent;
            if (cookieConsent?.marketing) {
                this.$store.dispatch('cookies/mountScripts');
                setTimeout(() => {
                    this.updateFacebookPixel();
                }, 2000);
            }
        },
    },
    watch: {
        '$route'(to, from) {
            if (to.meta?.order && from.meta?.order) {
                this.transitionName = to.meta.order > from.meta.order ? 'transitionSlideNext' : 'transitionSlidePrevious';
            } else {
                this.transitionName = 'transitionFade';
            }
        },
    },
};
</script>
<style lang="scss">
    @import "@/assets/sass/app.scss";
    #app{
        min-height: 100vh;
    }

    .header-fixed {
        position: fixed;
        z-index: 1000;
        width: 100%;
    }

    .cookie-banner-fixed {
        width: 100%;

        display: flex;
        justify-content: center;

        position: fixed;
        z-index: 999999999;

        bottom: 13px;
    }
    .container-route {
        overflow-x: hidden;
    }
    .announcement-bar {
        padding-top: $padding-top-mobile;
        @media (min-width: 1024px) {
            padding-top: $padding-top-desktop;
        }
    }
    .no-padding {
        padding-top: 0;
    }
</style>
