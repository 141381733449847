<template>
    <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
        <div class="bg"></div>
        <div ref="card" class="card" :data-state="isMove ? 'move' : state" :style="{ top: `${isMove ? y : calcY()}px` }">
            <div class="pan-area" ref="pan">
                <div class="bar" ref="bar"/>
            </div>
            <div class="contents">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
    name: 'SwipeableBottomSheet',
    props: {
        openY: {
            type: Number,
            default: 0.1,
        },
        halfY: {
            type: Number,
            default: 0.75,
        },
        defaultState: {
            type: String,
            default: 'half',
        },
        barColor: {
            type: String,
            default: 'rgba(0, 0, 0, .3)',
        },
    },
    data() {
        return {
            mc: null,
            y: 0,
            startY: 0,
            isMove: false,
            state: this.defaultState,
            rect: {},
        };
    },
    mounted() {
        this.$root.$on('open-bottom-sheet', this.openBottomSheet);
        this.$root.$on('close-bottom-sheet', this.closeBottomSheet);
        window.onresize = () => {
            this.rect = this.$refs.card.getBoundingClientRect();
        };
        this.rect = this.$refs.card.getBoundingClientRect();
        this.mc = new Hammer(this.$refs.pan);
        this.mc.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL });
        this.mc.on('panup pandown', (evt) => {
            this.y = evt.center.y - 16;
        });
        this.mc.on('panstart', (evt) => {
            this.startY = evt.center.y;
            this.isMove = true;
        });
        this.mc.on('panend', (evt) => {
            this.isMove = false;
            switch (this.state) {
            case 'close': // Added a close state on the condition to be able to swipe from closed to half/closed state.
            case 'half':
                if (this.state === 'close') {
                    if (this.startY - evt.center.y > 120) {
                        this.state = 'half';
                    }
                    if (this.startY - evt.center.y > 320) {
                        this.state = 'open';
                    }
                    this.$root.$emit('bottom-sheet-state', this.state);
                    break;
                }
                if (this.startY - evt.center.y > 120) {
                    this.state = 'open';
                }
                if (this.startY - evt.center.y < -50) {
                    this.state = 'close';
                }
                this.$root.$emit('bottom-sheet-state', this.state);
                break;
            case 'open':
                if (this.startY - evt.center.y < -120) {
                    this.state = 'half';
                }
                this.$root.$emit('bottom-sheet-state', this.state);
                break;
            }
        });
    },
    beforeDestroy() {
        this.mc.destroy();
        window.onresize = null;
    },
    methods: {
        calcY() {
            switch (this.state) {
            case 'close':
                return this.rect.height;
            case 'open':
                return this.rect.height * this.openY;
            case 'half':
                return this.rect.height * this.halfY;
            default:
                return this.y;
            }
        },
        setState(state) {
            this.state = state;
        },
        openBottomSheet() {
            this.setState('open');
        },
        closeBottomSheet() {
            this.setState('half');
        },
    },
};
</script>

<style lang="scss" scoped>
    .wrapper[data-open="1"] {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
    }
    .wrapper[data-open="1"] .bg {
        display: block;
        transition: all .3s;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
    }
    .card {
        width: 100%;
        height: 100vh;
        position: fixed;
        background: white;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 -3px 4px rgba(0, 0, 0, .1);
        left: 0;
        padding-top: 25px;
    }
    .card[data-state="half"], .card[data-state="open"], .card[data-state="close"] {
        transition: top .3s ease-out;
    }
    .card[data-state="close"] {
        box-shadow: none;
    }
    .bar {
        width: 60px;
        height: 8px;
        border-radius: 14px;
        margin: 0 auto;
        cursor: pointer;
        background: #C6D6D4;
    }
    .pan-area {
        top: 0;
        right: 0; left: 0;
        position: absolute;
        padding: 12px 0;
        .bar {
            &:hover {
                cursor: grab;
            }
            &:active {
                cursor: grabbing;
            }
        }
    }
    // Moved the pan area above the card content to be ale to swipe from closed state to half/open
    .card[data-state="close"] .pan-area {
        top: -28px;
    }
    .contents {
        overflow-y: scroll;
        max-height: 100%;
        padding-bottom: calc(100vh * 0.2);
        box-sizing: border-box;
    }
</style>
