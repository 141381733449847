import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchAllDeliveries {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(subscriptionId, to, from) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.scheduled-orders'], [subscriptionId]);

        if (to && from) {
            apiUrl = `${apiUrl}?from=${from}&to=${to}`;
        }

        return this.apiService.get(apiUrl);
    }
}
