import ApiEndpoints from '@/config/ApiEndpoints';
import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';

export default class UpdateGiftAddress {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(addressId, payload) {
        let apiUrl = prepareUrl(ApiEndpoints['gift.addresses.update'], [addressId]);

        let updatedAddress = {
            first_name: payload.firstName,
            last_name: payload.lastName,
            house: payload.houseNumber || null,
            address1: payload.address1,
            address2: payload.address2 || null,
            city: payload.city,
            country: payload.country,
            postcode: payload.postcode,
            delivery_instructions: payload.deliveryComment,
            property_type: payload.propertyType,
        };

        return this.apiService.patch(apiUrl, updatedAddress);
    }
}
