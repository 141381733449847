import CountryService from '@/services/logistics/CountryService';

export default class GetStripeGatewayByISO {
    handle(iso) {
        let countries = CountryService.getCountries();
        let country = countries.find(country => country.iso === iso);
        let countryCurrencyCode = country.currency.code;
        return `Stripe${countryCurrencyCode}`;
    }
}
