<template>
    <transition name="transitionFade">
        <div v-if="show" id="learnMoreDropdown" class="nav-dropdown" :style="navDropdownStyle">
            <div class="nav-dropdown--menu-container">
                <hr class="nav-dropdown--menu-container--hr-line" />

                <ul class="nav-dropdown--menu-container--list">
                    <li :key="`item-${index}`" v-for="(item, index) in routesUnderDropdown" @click="go(item.route)" class="nav-dropdown--menu-container--list--item">
                        <div class="nav-dropdown--menu-container--list--item--container">
                            <div class="nav-dropdown--menu-container--list--item--container--image-section">
                                <img class="nav-dropdown--menu-container--list--item--container--image-section--image" :src="item.image" :alt="item.imageAlt">
                            </div>
                            <div class="nav-dropdown--menu-container--list--item--container--text-section">
                                <p class="nav-dropdown--menu-container--list--item--container--text-section--heading"> {{ item.heading }} </p>
                                <p class="nav-dropdown--menu-container--list--item--container--text-section--description"> {{ item.description }} </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'NavDropdown',
    props: {
        show: Boolean,
        routesUnderDropdown: {
            type: Array,
            default: () => [],
        },
        width: {
            type: String,
            default: '327px',
        },
    },
    computed: {
        navDropdownStyle() {
            return { width: this.width } || {};
        },
    },
    methods: {
        go(route) {
            this.$router.push({ name: route });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.nav-dropdown {
    position: absolute;
    left: -9px;

    padding: 16px 16px 16px;

    border-radius: 0 16px 16px 16px;
    background: $card-white-color;

    box-shadow: 0px 8px 10px 0px transparentize($color: $dark-color, $amount: 0.9);

    transition: .2s;

    &--menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        color: $primary-color;

        &--hr-line {
            display: none;
        }

        &--list * {
            @include button();

            margin-top: 29px !important;

            @media screen and (max-height: $medium) {
                margin-top: 19px !important;
            }
        }

        &--list img, p {
            margin: 0 !important;
        }

        &--list *:first-child {
            margin-top: 0 !important;
        }

        &--list {
            display: flex;
            flex-direction: column;

            list-style: none;

            width: 100%;

            &--item {
                transition: .2s;
                &--container {
                    display: flex;
                    justify-content: space-between;
                    flex-shrink: 0;

                    &--image-section {
                        &--image {
                            width: 80px;
                            height: 80px;

                            max-width: none;

                            border-radius: 8px;

                            @include small();
                            overflow: scroll;
                        }
                    }

                    &--text-section {
                        margin-top: 0 !important;

                        text-align: left;

                        width: 180px;

                        align-self: center;

                        &--heading {

                        }

                        &--description {
                            @include small();
                        }
                    }
                }
            }

            &--item:hover {
                opacity: 0.8;
                transition: .2s;

                cursor: pointer;
            }
        }
    }
}
</style>
