import store from '@/store';
import router from '@/router';

export default class RedirectToLoginPage {
    handle(redirectRouteName) {
        let refreshTokenAttempts = store.getters['auth/getRefreshTokenAttempts'];

        store.commit('auth/updateUserLoggedIn', false);
        store.commit('auth/updateIntendedUrl', '/');
        store.commit('auth/updateRefreshTokenAttempts', ++refreshTokenAttempts);

        router.push({ name: redirectRouteName }, () => {});
    }
}
