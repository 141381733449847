import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class CancelHolidayBox {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        const apiUrl = prepareUrl(ApiEndpoints['subscriptions.holidayBox.destroy'], [payload.holiday_box_id]);

        return this.apiService.delete(apiUrl);
    }
}
