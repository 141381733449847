import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class FetchGiftDeliveryOptions {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle() {
        let apiUrl = ApiEndpoints['delivery-options'];

        return this.apiService.get(apiUrl);
    }
}
