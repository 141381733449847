import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchUserData {
    apiService
    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(isCanvasser) {
        const apiUrl = isCanvasser ? ApiEndpoints['canvassers.index'] : ApiEndpoints['users.index'];

        return this.apiService.get(apiUrl);
    }
}
