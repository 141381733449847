import GetAllSchedules from '@/services/cms/GetAllSchedules';
import GetRateableArrangements from '@/services/cms/GetRateableArrangements';
import GetRecentArrangements from '@/services/cms/GetRecentArrangements';
import RateArrangementForOrder from '@/services/cms/RateArrangementForOrder';
import GetPlans from '@/services/cms/GetPlans';
import GetMissedArrangements from '@/services/cms/GetMissedArrangements';
import GetLandingPageMessages from '@/services/cms/GetLandingPageMessages.js';

export default class CmsService {
    getAllSchedules(subscriptionId) {
        return (new GetAllSchedules()).handle(subscriptionId);
    }

    getRecentArrangements() {
        return (new GetRecentArrangements()).handle();
    }

    getMissedArrangements(subscriptionId) {
        return (new GetMissedArrangements()).handle(subscriptionId);
    }

    getRateableArrangements() {
        return (new GetRateableArrangements()).handle();
    }

    rateArrangementForOrder(orderId, payload) {
        return (new RateArrangementForOrder()).handle(orderId, payload);
    }

    getPlans() {
        return (new GetPlans()).handle();
    }

    getLandingPageMessages() {
        return (new GetLandingPageMessages().handle());
    }
}
