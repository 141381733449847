import store from '@/store';

const i18n = require('@/i18n').default;

const isGifter = store.getters['account/isGifterAccount'];
const isNewGiftingFlow = store.getters['account/isNewGiftingFlow'];
const isSpecialDatesEnabled = store.getters['account/getSpecialDatesEnabled'];

const addressesKey = isGifter ? 'GIFTER_NAVIGATION_LINK_ADDRESSES' : 'DASHBOARD_YOUR_ADDRESSES';
const accountDetailsKey = isGifter ? 'DASHBOARD_ACCOUNT_DETAILS' : 'DASHBOARD_YOUR_ACCOUNT';
const giftingKey = isGifter ? 'GIFTER_NAVIGATION_LINK_GIFTING' : 'DASHBOARD_SEND_A_GIFT';
const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';
const isDEU = process.env.VUE_APP_COUNTRY_ISO === 'DEU';

const gbrOrder = {
    items: [
        {
            route: 'account.dashboard',
            hamburgerMenu: {
                icon: 'dashboard.png',
                label: i18n.t('DASHBOARD_DASHBOARD'),
                labelTranslateKey: 'DASHBOARD_DASHBOARD',
                order: 1,
                enabled: true,
                enabledForInactiveUser: true,
            },
        },
        {
            route: 'account.free-flowers',
            hamburgerMenu: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_FREE_BOXES'),
                labelTranslateKey: 'DASHBOARD_FREE_BOXES',
                order: 8,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'raf-icon',
                label: i18n.t('DASHBOARD_SEND_A_FREE_BOX'),
                labelTranslateKey: 'DASHBOARD_SEND_A_FREE_BOX',
                counterGetterName: 'account/getUserInvitesRemaining',
                order: 1,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 1,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 1,
            },
            myAccount: {
                icon: 'free-boxes.svg',
                label: i18n.t('NAVIGATION_LINK_FREE_BOXES'),
                labelTranslateKey: 'NAVIGATION_LINK_FREE_BOXES',
                order: 4,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.rate-my-flowers',
            hamburgerMenu: {
                icon: 'rate-my-flowers.svg',
                label: i18n.t('DASHBOARD_RATE_MY_FLOWERS'),
                labelTranslateKey: 'DASHBOARD_RATE_MY_FLOWERS',
                order: 7,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'rate-my-flowers',
                label: i18n.t('DASHBOARD_RATE_YOUR_FLOWERS'),
                labelTranslateKey: 'DASHBOARD_RATE_YOUR_FLOWERS',
                order: 4,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'rate-my-flowers.svg',
                label: i18n.t('NAVIGATION_LINK_RETE_MY_FLOWERS'),
                labelTranslateKey: 'NAVIGATION_LINK_RETE_MY_FLOWERS',
                order: 3,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.your-boxes',
            hamburgerMenu: {
                icon: 'your-boxes.svg',
                label: i18n.t('DASHBOARD_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'DASHBOARD_WHATS_IN_THE_BOX',
                order: 6,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'your-boxes',
                label: i18n.t('DASHBOARD_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'DASHBOARD_WHATS_IN_THE_BOX',
                order: 6,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'your-boxes.svg',
                label: i18n.t('NAVIGATION_LINK_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'NAVIGATION_LINK_WHATS_IN_THE_BOX',
                order: 2,
                enabled: !isGifter,
            },
        },
        {
            route: 'guest-gifting.home',
            hamburgerMenu: {
                image: 'gift',
                icon: 'send-a-gift.svg',
                label: i18n.t(giftingKey),
                labelTranslateKey: giftingKey,
                order: 2,
                enabled: isGifter || process.env.VUE_APP_GIFTING_ENABLED === 'true',
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'gift',
                label: i18n.t('DASHBOARD_SEND_A_GIFT'),
                labelTranslateKey: 'DASHBOARD_SEND_A_GIFT',
                order: 2,
                enabled: process.env.VUE_APP_GIFTING_ENABLED === 'true',
                newDashboardSection: 1,
                newDashboardMobileSection: 1,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 1,
            },
        },
        {
            route: 'guest-gifting.vouchers',
            hamburgerMenu: {
                image: 'gift',
                icon: 'send-a-gift.svg',
                label: i18n.t('NAV_GIFT_VOUCHERS'),
                labelTranslateKey: 'NAV_GIFT_VOUCHERS',
                order: 2,
                enabled: process.env.VUE_APP_GIFTI_VOUCHER_ENABLED === 'true',
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'gift',
                label: i18n.t('NAV_GIFT_VOUCHERS'),
                labelTranslateKey: 'NAV_GIFT_VOUCHERS',
                order: 2,
                enabled: false,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
        },
        {
            route: 'account.delivery-schedule',
            hamburgerMenu: {
                icon: 'delivery-schedule.svg',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 5,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'upcoming-deliveries',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 4,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'delivery-schedule.svg',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 1,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.shop',
            hamburgerMenu: {
                icon: 'shop.svg',
                label: i18n.t('DASHBOARD_FREDDIES_SHOP'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_SHOP',
                order: 3,
                enabled: process.env.VUE_APP_SHOP_ENABLED === 'true' && !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'shop',
                label: i18n.t('DASHBOARD_FREDDIES_SHOP'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_SHOP',
                order: 3,
                enabled: process.env.VUE_APP_SHOP_ENABLED === 'true' && !isGifter,
                enabledForInactiveUser: false,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 2,
            },
        },
        {
            route: 'account.order-a-vase',
            dashboard: {
                image: 'order-a-vase',
                label: i18n.t('DASHBOARD_FREDDIES_VASE'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_VASE',
                order: 7,
                enabled: !(process.env.VUE_APP_SHOP_ENABLED === 'true'),
                enabledForInactiveUser: true,
            },
        },
        {
            route: 'account.delivery-addresses',
            hamburgerMenu: {
                icon: 'delivery-addresses.svg',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 9,
                enabled: true,
                enabledForInactiveUser: true,
                gifterDashboard: true,
                isLapser: true,
            },
            dashboard: {
                image: 'address',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 7,
                enabled: true,
                enabledForInactiveUser: true,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: true,
                gifterOrder: 4,
                gifterDashboardSection: 2,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'delivery-addresses.svg',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 6,
                enabled: true,
                gifterDashboard: true,
                isLapser: true,
            },
        },
        {
            route: 'blog',
            target: '_blank',
            dashboard: {
                image: 'blog',
                label: i18n.t('DASHBOARD_FLOWER_BLOG'),
                labelTranslateKey: 'DASHBOARD_FLOWER_BLOG',
                order: 9,
                enabled: process.env.VUE_APP_COUNTRY_ISO === 'GBR',
            },
        },
        {
            route: 'account.manage-account',
            hamburgerMenu: {
                icon: 'account.svg',
                label: i18n.t(accountDetailsKey),
                labelTranslateKey: accountDetailsKey,
                order: 10,
                enabled: true,
                enabledForInactiveUser: true,
                gifterDashboard: true,
                isLapser: true,
            },
            dashboard: {
                image: 'manage-account',
                label: i18n.t('DASHBOARD_YOUR_ACCOUNT_DETAILS'),
                labelTranslateKey: isGifter ? 'DASHBOARD_ACCOUNT_DETAILS' : 'DASHBOARD_YOUR_ACCOUNT_DETAILS',
                order: 8,
                enabled: true,
                enabledForInactiveUser: true,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: true,
                gifterOrder: 5,
                gifterDashboardSection: 2,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'account.svg',
                label: i18n.t(accountDetailsKey),
                labelTranslateKey: accountDetailsKey,
                order: 7,
                enabled: true,
                gifterDashboard: true,
                isLapser: true,
            },
        },
        // {
        //     route: 'faq.categories',
        //     hamburgerMenu: {
        //         icon: 'dashboard.png',
        //         label: i18n.t('NAVIGATION_LINK_HELP'),
        //         labelTranslateKey: 'NAVIGATION_LINK_HELP',
        //         order: 4,
        //         enabled: true,
        //     },
        // },
        {
            route: 'logout',
            hamburgerMenu: {
                icon: 'account.svg',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 12,
                enabled: true,
                gifterDashboard: true,
                isLapser: true,
            },
            dashboard: {
                image: 'manage-account',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 8,
                enabled: false,
                gifterDashboard: false,
            },
            myAccount: {
                icon: 'account.svg',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 8,
                enabled: true,
                gifterDashboard: true,
                isLapser: true,
            },
        },
        {
            route: 'account.gifting-reminders',
            hamburgerMenu: {
                icon: 'Gifting-Dates-Icon.png',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                gifterDashboard: true,
            },
            dashboard: {
                image: 'Gifting-Dates-Icon',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: true,
                gifterOrder: 2,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 1,
            },
            myAccount: {
                icon: 'Gifting-Dates-Icon.png',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                gifterDashboard: true,
            },
        },
        {
            route: 'account.order-history',
            hamburgerMenu: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 11,
                enabled: isGifter,
                enabledForInactiveUser: false,
                gifterDashboard: true,
                isLapser: true,
            },
            dashboard: {
                image: 'order-history',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 5,
                enabled: false,
                enabledForInactiveUser: false,
                gifterDashboard: true,
                gifterOrder: 3,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 7,
                enabled: () => isGifter,
                gifterDashboard: true,
                isLapser: true,
            },
        },
        {
            route: 'account.gifter-free-flowers',
            dashboard: {
                image: 'raf-icon',
                label: i18n.t('GIFTER_DASHBOARD_SEND_A_FREE_BOX'),
                labelTranslateKey: 'GIFTER_DASHBOARD_SEND_A_FREE_BOX',
                counterGetterName: 'account/getUserInvitesRemaining',
                order: 1,
                enabled: isGifter,
                enabledForInactiveUser: false,
                gifterDashboard: true,
                gifterOrder: 1,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 1,
            },
        },
        {
            route: 'account.subscription-management',
            hamburgerMenu: {
                icon: 'order-history',
                label: i18n.t('NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS'),
                labelTranslateKey: 'NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS',
                order: 5,
                enabled: store.getters['account/isSubscriptionManagementPageEnabled'],
                gifterDashboard: false,
            },
            dashboard: {
                image: 'order-history',
                label: i18n.t('NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS'),
                labelTranslateKey: 'NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS',
                order: 5,
                enabled: store.getters['account/isSubscriptionManagementPageEnabled'],
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: false,
                gifterOrder: 2,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 1,
            },
            myAccount: {
                icon: 'order-history',
                label: i18n.t('NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS'),
                labelTranslateKey: 'NAVIGATION_LINK_MANAGE_SUBSCRIPTIONS',
                order: 5,
                enabled: store.getters['account/isSubscriptionManagementPageEnabled'],
                gifterDashboard: false,
            },
        },
    ],

};

const deuOrder = {
    items: [
        {
            route: 'account.dashboard',
            hamburgerMenu: {
                icon: 'dashboard.png',
                label: i18n.t('DASHBOARD_DASHBOARD'),
                labelTranslateKey: 'DASHBOARD_DASHBOARD',
                order: 1,
                enabled: true,
                enabledForInactiveUser: true,
            },
        },
        {
            route: 'account.free-flowers',
            hamburgerMenu: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_FREE_BOXES'),
                labelTranslateKey: 'DASHBOARD_FREE_BOXES',
                order: 8,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'raf-icon',
                label: i18n.t('DASHBOARD_SEND_A_FREE_BOX'),
                labelTranslateKey: 'DASHBOARD_SEND_A_FREE_BOX',
                counterGetterName: 'account/getUserInvitesRemaining',
                order: 8,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 1,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 1,
            },
            myAccount: {
                icon: 'free-boxes.svg',
                label: i18n.t('NAVIGATION_LINK_FREE_BOXES'),
                labelTranslateKey: 'NAVIGATION_LINK_FREE_BOXES',
                order: 4,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.rate-my-flowers',
            hamburgerMenu: {
                icon: 'rate-my-flowers.svg',
                label: i18n.t('DASHBOARD_RATE_MY_FLOWERS'),
                labelTranslateKey: 'DASHBOARD_RATE_MY_FLOWERS',
                order: 7,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'rate-my-flowers',
                label: i18n.t('DASHBOARD_RATE_YOUR_FLOWERS'),
                labelTranslateKey: 'DASHBOARD_RATE_YOUR_FLOWERS',
                order: 2,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'rate-my-flowers.svg',
                label: i18n.t('NAVIGATION_LINK_RETE_MY_FLOWERS'),
                labelTranslateKey: 'NAVIGATION_LINK_RETE_MY_FLOWERS',
                order: 3,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.your-boxes',
            hamburgerMenu: {
                icon: 'your-boxes.svg',
                label: i18n.t('DASHBOARD_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'DASHBOARD_WHATS_IN_THE_BOX',
                order: 6,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'your-boxes',
                label: i18n.t('DASHBOARD_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'DASHBOARD_WHATS_IN_THE_BOX',
                order: 6,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'your-boxes.svg',
                label: i18n.t('NAVIGATION_LINK_WHATS_IN_THE_BOX'),
                labelTranslateKey: 'NAVIGATION_LINK_WHATS_IN_THE_BOX',
                order: 2,
                enabled: !isGifter,
            },
        },
        {
            route: (process.env.VUE_APP_GUEST_GIFTING_ENABLED === 'true') ? 'guest-gifting.home' : 'account.gifting.choose-your-gift',
            hamburgerMenu: {
                image: 'gift',
                icon: 'send-a-gift.svg',
                label: i18n.t(giftingKey),
                labelTranslateKey: giftingKey,
                order: 2,
                enabled: isGifter || process.env.VUE_APP_GIFTING_ENABLED === 'true',
                enabledForInactiveUser: false,
                isLapser: true,
            },
            dashboard: {
                image: 'gift',
                label: i18n.t('DASHBOARD_SEND_A_GIFT'),
                labelTranslateKey: 'DASHBOARD_SEND_A_GIFT',
                order: 3,
                enabled: isGifter || process.env.VUE_APP_GIFTING_ENABLED === 'true',
                newDashboardSection: 1,
                newDashboardMobileSection: 1,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 1,
            },
        },
        {
            route: 'account.delivery-schedule',
            hamburgerMenu: {
                icon: 'delivery-schedule.svg',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 5,
                enabled: !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'upcoming-deliveries',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 1,
                enabled: true,
                enabledForInactiveUser: false,
                newDashboardSection: 1,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'delivery-schedule.svg',
                label: i18n.t('DASHBOARD_CALENDAR'),
                labelTranslateKey: 'DASHBOARD_CALENDAR',
                order: 1,
                enabled: !isGifter,
            },
        },
        {
            route: 'account.shop',
            hamburgerMenu: {
                icon: 'shop.svg',
                label: i18n.t('DASHBOARD_FREDDIES_SHOP'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_SHOP',
                order: 3,
                enabled: process.env.VUE_APP_SHOP_ENABLED === 'true' && !isGifter,
                enabledForInactiveUser: false,
            },
            dashboard: {
                image: 'shop',
                label: i18n.t('DASHBOARD_FREDDIES_SHOP'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_SHOP',
                order: 2,
                enabled: process.env.VUE_APP_SHOP_ENABLED === 'true' && !isGifter,
                enabledForInactiveUser: false,
                oldDashboardSection: 1,
                oldDashboardMobileSection: 2,
            },
        },
        {
            route: 'account.order-a-vase',
            dashboard: {
                image: 'order-a-vase',
                label: i18n.t('DASHBOARD_FREDDIES_VASE'),
                labelTranslateKey: 'DASHBOARD_FREDDIES_VASE',
                order: 7,
                enabled: !(process.env.VUE_APP_SHOP_ENABLED === 'true'),
                enabledForInactiveUser: true,
            },
        },
        {
            route: 'account.delivery-addresses',
            hamburgerMenu: {
                icon: 'delivery-addresses.svg',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 9,
                enabled: true,
                enabledForInactiveUser: true,
                isLapser: true,
                gifterDashboard: true,
            },
            dashboard: {
                image: 'address',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 3,
                enabled: true,
                enabledForInactiveUser: true,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: true,
                gifterOrder: 4,
                gifterDashboardSection: 2,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'delivery-addresses.svg',
                label: i18n.t(addressesKey),
                labelTranslateKey: addressesKey,
                order: 6,
                enabled: true,
                isLapser: true,
                gifterDashboard: true,
            },
        },
        {
            route: 'blog',
            target: '_blank',
            dashboard: {
                image: 'blog',
                label: i18n.t('DASHBOARD_FLOWER_BLOG'),
                labelTranslateKey: 'DASHBOARD_FLOWER_BLOG',
                order: 9,
                enabled: process.env.VUE_APP_COUNTRY_ISO === 'GBR',
            },
        },
        {
            route: 'account.manage-account',
            hamburgerMenu: {
                icon: 'account.svg',
                label: i18n.t(accountDetailsKey),
                labelTranslateKey: accountDetailsKey,
                order: 10,
                enabled: true,
                enabledForInactiveUser: true,
                isLapser: true,
                gifterDashboard: true,
            },
            dashboard: {
                image: 'manage-account',
                label: i18n.t('DASHBOARD_YOUR_ACCOUNT_DETAILS'),
                labelTranslateKey: isGifter ? 'DASHBOARD_ACCOUNT_DETAILS' : 'DASHBOARD_YOUR_ACCOUNT_DETAILS',
                order: 5,
                enabled: true,
                enabledForInactiveUser: true,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: true,
                gifterOrder: 5,
                gifterDashboardSection: 2,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'account.svg',
                label: i18n.t(accountDetailsKey),
                labelTranslateKey: accountDetailsKey,
                order: 7,
                enabled: true,
                isLapser: true,
                gifterDashboard: true,
            },
        },
        // {
        //     route: 'faq.categories',
        //     hamburgerMenu: {
        //         icon: 'dashboard.png',
        //         label: i18n.t('NAVIGATION_LINK_HELP'),
        //         labelTranslateKey: 'NAVIGATION_LINK_HELP',
        //         order: 4,
        //         enabled: true,
        //     },
        // },
        {
            route: 'logout',
            hamburgerMenu: {
                icon: 'account.svg',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 12,
                enabled: true,
                isLapser: true,
                gifterDashboard: true,
            },
            dashboard: {
                image: 'manage-account',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 8,
                enabled: false,
            },
            myAccount: {
                icon: 'account.svg',
                label: i18n.t('NAVIGATION_LINK_SIGN_OUT'),
                labelTranslateKey: 'NAVIGATION_LINK_SIGN_OUT',
                order: 8,
                enabled: true,
                isLapser: true,
                gifterDashboard: true,
            },
        },
        {
            route: 'account.gifting-reminders',
            hamburgerMenu: {
                icon: 'Gifting-Dates-Icon.png',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                gifterDashboard: isSpecialDatesEnabled,
            },
            dashboard: {
                image: 'Gifting-Dates-Icon',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                newDashboardSection: 2,
                newDashboardMobileSection: 2,
                oldDashboardSection: 2,
                oldDashboardMobileSection: 2,
                gifterDashboard: isSpecialDatesEnabled,
                gifterOrder: 2,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 1,
            },
            myAccount: {
                icon: 'Gifting-Dates-Icon.png',
                label: i18n.t('NAVIGATION_LINK_GIFTING_REMINDERS'),
                labelTranslateKey: 'NAVIGATION_LINK_GIFTING_REMINDERS',
                order: 5,
                enabled: isSpecialDatesEnabled,
                gifterDashboard: isSpecialDatesEnabled,
            },
        },
        {
            route: 'account.order-history',
            hamburgerMenu: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 11,
                enabled: isGifter,
                enabledForInactiveUser: false,
                gifterDashboard: true,
            },
            dashboard: {
                image: 'order-history',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 5,
                enabled: false,
                enabledForInactiveUser: false,
                gifterDashboard: true,
                gifterOrder: 3,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 2,
            },
            myAccount: {
                icon: 'free-boxes.svg',
                label: i18n.t('DASHBOARD_ORDER_HISTORY'),
                labelTranslateKey: 'DASHBOARD_ORDER_HISTORY',
                order: 7,
                enabled: isGifter,
                gifterDashboard: true,
            },
        },
        {
            route: 'account.gifter-free-flowers',
            dashboard: {
                image: 'raf-icon',
                label: i18n.t('DASHBOARD_SEND_A_FREE_BOX'),
                labelTranslateKey: 'DASHBOARD_SEND_A_FREE_BOX',
                counterGetterName: 'account/getUserInvitesRemaining',
                order: 1,
                enabled: isGifter,
                enabledForInactiveUser: false,
                gifterDashboard: true,
                gifterOrder: 1,
                gifterDashboardSection: 1,
                gifterDashboardMobileSection: 1,
            },
        },
    ],
};

let dashboardToShow;
if (isGBR) {
    dashboardToShow = gbrOrder;
} else {
    dashboardToShow = deuOrder;
};

export default dashboardToShow;
