module.exports = {
    'auth.login': 'login/',
    'auth.login.refresh': 'login/refresh',
    'auth.logout': 'logout',
    'auth.forgot-password.store': 'auth/forgot-password',
    'auth.reset-password.update': 'auth/reset-password',
    'auth.validate-token': 'auth/reset-password/$1/$2',
    'auth.login.canvasser': 'login/canvasser',
    'auth.login.canvasser.refresh': 'login/canvasser/refresh',
};
