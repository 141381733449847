import FaqService from '@/services/faq/FaqService';

export default {
    namespaced: true,
    state: {
        answers: {
            fetched: false,
            items: [],
        },
        categories: {
            fetched: false,
            items: [],
        },
    },

    mutations: {
        updateAnswers(state, payload) {
            state.answers.items = payload;
        },

        updateCategories(state, payload) {
            state.categories.items = payload;
        },
    },

    actions: {
        fetchAnswers({ commit, state }, forceReload = false) {
            if (state.answers.fetched === false || forceReload === true) {
                let service = new FaqService();
                state.answers.fetched = true;
                service.getQuestions().then((answers) => { commit('updateAnswers', answers); });
            }
        },

        fetchCategories({ commit, state }, forceReload = false) {
            if (state.categories.fetched === false || forceReload === true) {
                let service = new FaqService();
                state.categories.fetched = true;
                service.getCategories().then((categories) => { commit('updateCategories', categories); });
            }
        },
    },

    getters: {
        getAnswers: state => state.answers.items || [],

        getCategories: state => state.categories.items || [],

        getLoggedInCategories: state => state.categories.items.filter((category) => category.loggedIn === true),

        getNotLoggedInCategories: state => state.categories.items.filter((category) => category.loggedIn === false),
    },
};
