import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import { Vuelidate } from 'vuelidate/src';
import { sync } from 'vuex-router-sync';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueMeta from 'vue-meta';
import VueLazyload from 'vue-lazyload';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSocialSharing from 'vue-social-sharing';
import { builder } from '@builder.io/vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';

const loadimage = require('@/assets/images/guest-gifting/lazy.png');

Vue.component('font-awesome-icon', FontAwesomeIcon);

builder.init(process.env.VUE_APP_BUILDER_IO_KEY);

sync(store, router);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(VueMeta);
Vue.use(VueLazyload, {
    error: loadimage,
    loading: loadimage,
});
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_ENV,
        debug: process.env.VUE_APP_DEBUG,
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
                logErrors: true,
            }),
        ],
    });
}

if (location.protocol !== 'https:' && process.env.VUE_APP_ENV.toLowerCase() === 'production') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

let savedQueryParams = JSON.parse(localStorage.getItem('query-params'));
delete savedQueryParams?.cms;
let globalQueryParams = { ...savedQueryParams };

let queryString = document.URL.split('?')[1];

let searchParams = new URLSearchParams(queryString);
searchParams.forEach((value, key) => {
    globalQueryParams[key] = value;
});
localStorage.setItem('query-params', JSON.stringify(globalQueryParams));

if (queryString) {
    localStorage.setItem('query-string', queryString);
}
globalQueryParams.queryString = localStorage.getItem('query-string');

Vue.prototype.$globalQueryParams = globalQueryParams;

const data = {
    'country': {
    },
};

import('@/resources/countries.json').then(module => module.default).then(response => {
    data.country = response.find(country => country.iso === process.env.VUE_APP_COUNTRY_ISO);
});

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    i18n,
    data: data,
    render: h => h(App),
}).$mount('#app');
