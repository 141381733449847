import ShopService from '@/services/shop/ShopService';

const getDefaultState = () => {
    return {
        subscriptionId: null,
        products: {
            items: [],
            filtered: [],
            recommendations: [],
            fetched: false,
            sortMethod: { name: process.env.VUE_APP_COUNTRY_ISO === 'DEU' ? 'Sortieren' : 'Sort', key: 'default' },
            categories: null,
            checkedCategories: [],
        },
        orderSummary: {
            items: [],
            fetched: false,
            open: false,
        },
        nextAvailableDelivery: {
            date: null,
            fetched: false,
        },
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        updateProducts: (state, products) => {
            state.products.items = products;
        },
        updateFilteredProducts: (state, products) => {
            state.products.filtered = products;
        },
        updateRecommendedProducts: (state, products) => {
            state.products.recommendations = products.sort(() => Math.random() - 0.5);
        },
        clearFilteredProducts: (state) => {
            state.products.filtered = state.products.items;
        },
        updateCategories: (state, categories) => {
            state.products.categories = categories;
        },
        addCheckedCategory: (state, category) => {
            const alreadyChecked = state.products.checkedCategories.includes(category);
            if (!alreadyChecked) state.products.checkedCategories.push(category);
        },
        removeCheckedCategory: (state, option) => {
            state.products.checkedCategories = state.products.checkedCategories.filter(item => item !== option);
        },
        updateOrderSummary: (state, orders) => {
            state.orderSummary.items = orders;
        },
        updateNextDeliveryDate: (state, date) => {
            state.nextAvailableDelivery.date = date;
        },
        resetOrderSummary(state) {
            state.orderSummary.items = [];
        },
        resetNextDeliveryDate(state) {
            state.nextAvailableDelivery.date = null;
        },
        setSortMethod(state, value) {
            state.products.sortMethod = value;
        },
        openOrderSummary(state) {
            state.orderSummary.open = true;
        },
        closeOrderSummary(state) {
            state.orderSummary.open = false;
        },
    },
    actions: {
        async fetchProducts({ commit, state, dispatch }, payload = null) {
            let service = new ShopService();

            return await new Promise(function(resolve, reject) {
                service.fetchProducts(payload).then(response => {
                    commit('updateProducts', response.data.data);
                    commit('updateFilteredProducts', response.data.data);
                    commit('updateRecommendedProducts', response.data.data);
                    dispatch('findCategories', response.data.data);
                    state.products.fetched = true;
                    resolve(response);
                })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        async findCategories({ commit, state }, payload) {
            let categories = [];
            payload.filter(item => categories.push(item.category));
            categories = new Set(categories);
            commit('updateCategories', categories);
        },
        async fetchOrderSummary({ commit, state, rootGetters }, forceReload = false) {
            const activeSubscription = rootGetters['subscription/getActiveSubscription'];
            const subscriptionId = activeSubscription?.id;
            const notFetched = subscriptionId && !state.orderSummary.fetched;
            const suscriptionIdHasChanged = subscriptionId && subscriptionId !== state.subscriptionId;

            if (notFetched || suscriptionIdHasChanged || forceReload) {
                let service = new ShopService();
                commit('resetOrderSummary');

                return await new Promise(function(resolve, reject) {
                    service.fetchOrderSummary(subscriptionId).then(response => {
                        commit('updateOrderSummary', response.data.order_summary);
                        state.orderSummary.fetched = true;
                        state.subscriptionId = subscriptionId;
                        resolve(response);
                    })
                        .catch(error => {
                            reject(error);
                        });
                });
            }
        },
        async getNextDeliveryDate({ commit, state, rootGetters }, forceReload = false) {
            const activeSubscription = rootGetters['subscription/getActiveSubscription'];
            if (activeSubscription) {
                commit('updateNextDeliveryDate', activeSubscription.next_delivery_date);
            }
        },
        updateFilteredProducts({ commit, state }, filter) {
            const filteredProducts = state.products.items.filter(product => Object.values(filter).includes(product.category));
            commit('updateFilteredProducts', filteredProducts);
        },
        addCheckedCategory({ commit }, filter) {
            commit('addCheckedCategory', filter);
        },
        resetShop({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        getProductsHighToLow: (state) => {
            const compareHighToLow = (a, b) => {
                const comparison = b.price - a.price;
                return comparison;
            };

            const products = [...state.products.filtered];
            const productsHighToLow = products.sort(compareHighToLow);
            return productsHighToLow;
        },
        getProductsLowToHigh: (state) => {
            const compareLowToHigh = (a, b) => {
                const comparison = a.price - b.price;
                return comparison;
            };

            const products = [...state.products.filtered];
            const productsLowToHigh = products.sort(compareLowToHigh);
            return productsLowToHigh;
        },
        getProductsBySortOrder: (state) => {
            return state.products.filtered.sort((a, b) => a.sort_order - b.sort_order);
        },
        getProductsSortedByCategory: (state) => (category) => {
            const primaryProducts = state.products.filtered.filter((item) => item.category === category);
            const secondaryProducts = state.products.filtered.filter((item) => item.category !== category);

            return [...primaryProducts, ...secondaryProducts];
        },
        getProducts: (state, getters) => {
            const sortKey = state.products.sortMethod.key;

            if (sortKey === 'low-high') {
                return getters.getProductsLowToHigh;
            } else if (sortKey === 'high-low') {
                return getters.getProductsHighToLow;
            }

            return getters.getProductsBySortOrder;
        },
        getProductById: (state) => (id) => {
            const productDetails = state.products.items.find(product => product.productId === id);
            return productDetails;
        },
        getOrderSummary: (state) => state.orderSummary.items,
        getOrderSummaryModalState: (state) => state.orderSummary.open,
        getNextAvailableDeliveryDate: (state) => state.nextAvailableDelivery.date,
        getSortMethod: (state) => state.products.sortMethod,
        getCategories: (state) => state.products.categories,
        getCheckedCategories: (state) => state.products.checkedCategories,
        getRecommendations: (state) => state.products.recommendations,
    },
};
