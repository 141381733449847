import OrderService from '@/services/orders/OrderService';

const getDefaultState = () => {
    return {
        orders: {
            items: [],
            pagination: {
                totalItems: null,
                totalPages: null,
                currentPage: 0,
            },
        },
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        updateRateableArrangements: (state, payload) => {
            state.orders.items = state.orders.items.concat(payload.data);
            state.orders.pagination.currentPage = payload.meta.pagination.current_page;
            state.orders.pagination.totalItems = payload.meta.pagination.total;
            state.orders.pagination.totalPages = payload.meta.pagination.total_pages;
        },
    },
    actions: {
        async fetchOrders({ commit, state, rootGetters }, page = 1) {
            let service = new OrderService();
            let userId = rootGetters['account/getUserId'];

            if (userId && state.orders.pagination.currentPage < page) {
                service.fetchUserOrders(userId, page)
                    .then(response => commit('updateRateableArrangements', response.data));
            }
        },

        resetOrder({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        canFetchMoreOrders: (state) => state.orders.pagination.currentPage < (state.orders.pagination.totalPages || 1),

        currentOrdersPage: (state) => state.orders.pagination.currentPage,

        getAllOrders: (state) => state.orders.items,

        totalOrders: (state) => state.orders.pagination.totalItems,
    },
};
