<template>
    <li>
        <router-link
            :to="{ name: isSelectedGifter }"
            class="item"
            :class="{ 'selected-route': isActiveRoute(item) }"
            :target="target">
            <span @click="onClick()" v-text="item.label"></span>
        </router-link>
    </li>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MenuItem',
    props: {
        item: {
            required: true,
            type: Object,
        },
        disabled: {
            required: true,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters({
            isNewGiftingFlow: 'account/isNewGiftingFlow',
        }),
        iconUrl() {
            return require(`@/assets/images/user-menu/${this.item.icon}`);
        },
        target() {
            return this.item.target ? this.item.target : '_self';
        },
        newGiftingFlow() {
            return this.isNewGiftingFlow ? 'guest-gifting' : 'account.gifting.choose-your-gift';
        },
        isSelectedGifter() {
            let routeName = this.item.route;
            if (routeName === 'account.gifting.choose-your-gift') {
                return this.newGiftingFlow;
            }
            return routeName;
        },
    },
    methods: {
        isActiveRoute(item) {
            return this.$route.name === item.route;
        },
        onClick() {
            this.$emit('menu');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/brand/theme.scss';

    .selected-route {
        padding: 10px 24px;
        border-radius: 136px;
        background-color: $secondary-light-color;
    }
    .item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
</style>
