import RegisterCustomer from '@/services/users/registrationService/RegisterCustomer';
import ValidateDelivery from '@/services/users/registrationService/ValidateDelivery';
import ValidateIntroductions from '@/services/users/registrationService/ValidateIntroductions';
import RegisterCanvassedCustomer from '@/services/users/registrationService/RegisterCanvassedCustomer';
import RegisterGifterCustomer from '@/services/users/registrationService/RegisterGifterCustomer';

export default class RegistrationService {
    static registerCustomer(tokenId, cardId) {
        return (new RegisterCustomer()).handle(tokenId, cardId);
    }

    static registerCanvassedCustomer(tokenId = null, cardId = null, customer = null) {
        return (new RegisterCanvassedCustomer()).handle(tokenId, cardId, customer);
    }

    static registerGifterCustomer(tokenId, cardId) {
        return (new RegisterGifterCustomer()).handle(tokenId, cardId);
    }

    static validateIntroductions() {
        return (new ValidateIntroductions()).handle();
    }

    static validateDelivery() {
        return (new ValidateDelivery()).handle();
    }
}
