<template>
    <div id="header" class="header">
        <div class="navigation">
            <!-- nav-left -->
            <div class="navigation__left">
                <router-link v-if="isDesktop" :to="{ name: 'home'}" class="navigation__logo-container">
                    <img class="navigation__desktop-logo"
                        :src="freddiesLogo"
                        alt="Freddie's Flowers Desktop Logo"/>
                </router-link>
                <div v-if="showBackButton" class="back-button" @click="$router.go(-1)">
                    <img :src="leftArrowImage" alt="Back button">
                    <p v-if="isDesktop">{{$t('TOP_NAVIGATION_BACK_BUTTON')}}</p>
                </div>
                <help-button v-if="showHelpButton" class="navigation__help"></help-button>
            </div>

            <!-- nav-center -->
            <div v-if="!isDesktop" class="navigation__center">
                <router-link :to="{ name: 'home'}">
                    <img class="navigation__desktop-logo"
                        :src="freddiesLogo"
                        alt="Freddie's Flowers Desktop Logo"/>
                </router-link>
            </div>

            <!-- nav-right -->
            <div class="navigation__right" :class="{ 'nav-right': showSignIn }">
                <NavButtonGroup :buttons="filteredButtons" :isDesktop="isDesktop" />
                <!-- <router-link v-if="isFlowerBondHolder" target="_blank" :to="{ name: 'flowerbond'}">
                    <div class="bondHolderButton">
                        <img class="bondHolderButton__icon" :src="flowerBondIcon" alt="Flower Bond Holder"/>
                        <p class="bondHolderButton__text" v-text="$t('NAV_BOND_HOLDER')"></p>
                    </div>
                </router-link> -->
                <!-- <span v-if="showSignInSeparationLine" class="navigation__right--line"></span> -->
                <login-link class="navigation__right--login-link" v-if="showSignIn && !showMyAccount"></login-link>
                <button v-if="showSignUp" class="btn--primary-light sign-up-btn"
                    @click="$router.push({ name: 'register' })">
                    {{ $t('SEND_ME_FLOWERS')}}
                </button>
                <div class="after-login-container">
                    <order-summary-button v-if="showBasket"/>
                    <my-account v-if="showMyAccount"></my-account>
                </div>
                <!-- <div class="checkout-help-button" @click="go(checkoutHelpButton.route)" v-if="isPage(['register'])">
                    <img v-if="!isDesktop" src="@/assets/images/pages/checkout/hekpImage.png" alt="Help button">
                    <nav-button :customStyles="customStyles" :button="checkoutHelpButton" class="navigation__landing-button"/>
                </div> -->
            </div>
        </div>
        <div class="divider-paper--marker divider--not-fixed"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginLink from '@/components/structure/navigation/navigation/LogInLink';
import HelpButton from '@/components/structure/navigation/navigation/HelpButton';
import NavButtonGroup from '@/components/structure/navigation/navigation/NavButtonGroup';
import OrderSummaryButton from '@/components/structure/navigation/navigation/OrderSummaryButton';
import MyAccount from '@/components/structure/navigation/navigation/MyAccount';
import countryUtils from '@/resources/countryUtils.json';

export default {
    name: 'TopNavigation',
    data() {
        return {
            countryUtils: countryUtils.filter(item => item.locale.toLowerCase() === process.env.VUE_APP_DATE_LOCALE.toLowerCase())[0],
            flowerBondIcon: require('@/assets/images/decoration/icon-bondholder-nav.svg'),
            window: {
                width: 0,
                mobileBreakpoint: 767,
                desktopLowerBreakpoint: 1024,
            },
            isXmas: true,
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    computed: {
        ...mapGetters({
            isGBR: 'localisation/isGBR',
            buttons: 'nav/getButtons',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            isFlowerBondHolder: 'account/getFlowerBondStatus',
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
            isGifterAccount: 'account/isGifterAccount',
            themes: 'account/getThemes',
            isSubscriberAccount: 'account/isSubscriberAccount',
        }),
        filteredButtons() {
            const status = (this.isUserLoggedIn ? 'loggedIn' : 'loggedOut');
            return this.buttons.filter(button => this.hasNoActiveSubscription ? button['isLapser'] && button.label.toLowerCase() !== 'shop' : button[status] && button.enabled);
        },
        checkoutHelpButton() {
            return this.buttons.filter(button => button.label.toLowerCase() === 'help')[0];
        },
        isDesktop() {
            const isDesktop = this.window.width >= this.window.desktopLowerBreakpoint;
            this.$store.commit('account/setIsDesktop', isDesktop);
            return isDesktop;
        },
        leftArrowImage() {
            return this.isDesktop ? require('@/assets/images/decoration/arrow-left-secondary-color.svg') : require('@/assets/images/decoration/arrow-left-secondary-large.svg');
        },
        isMobile() {
            let isMobile = this.window.width <= this.window.mobileBreakpoint;
            this.$store.commit('account/setIsMobile', isMobile);
            return isMobile;
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
        showSignIn() {
            return !this.isPage(['account-confirmation', 'account', 'faq', 'register', 'login', 'gifter', 'account.restart-v2-subscription', 'account.restart-v2-gifting']);
        },
        showSignUp() {
            return this.isDesktop && !this.isUserLoggedIn && !this.isPage(['register', 'subscriptions']);
        },
        showSignInSeparationLine() {
            return (this.showSignIn || this.isPage(['account'])) && (!this.isPage(['guest-gifting']) || this.isPage(['guest-gifting.home'], true)) && this.isDesktop;
        },
        showBasket() {
            return this.isUserLoggedIn && this.hasActiveSubscription && this.isSubscriberAccount && !this.isPage(['guest-gifting']);
        },
        showMyAccount() {
            return !this.isMobile && ((this.isUserLoggedIn && this.hasSubscriptionFetched) || (this.isGifterAccount));
        },
        customStyles() {
            return !this.isDesktop ? { fontSize: '12px', lineHeight: '16px' } : {};
        },
        showBackButton() {
            return (this.isPage(['register', 'subscriptions', 'guest-gifting', 'gifting']) && !this.isPage(['guest-gifting.home'], true)) || this.isPage(['account.bumper-box'], true);
        },
        showHelpButton() {
            return !this.isPage(['register', 'subscriptions', 'guest-gifting']) || this.isPage(['guest-gifting.home'], true);
        },
        freddiesLogo() {
            return this.themes?.logo || require('@/assets/images/logos/freddies_flowers_horizontal_secondary_color.svg');
        },
    },
    methods: {
        isPage(pageArr, isSubPage = false) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = isSubPage ? this.$route.name : this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        go(route) {
            route === 'faq.categories' ? window.location.href = this.countryUtils.zendesk_hc_url : this.$router.push({ name: route });
        },
        handleResize() {
            this.window.width = window.innerWidth;
        },
    },
    components: {
        LoginLink,
        HelpButton,
        OrderSummaryButton,
        NavButtonGroup,
        MyAccount,
    },
};
</script>

<style lang="scss" >
@import '@/assets/sass/brand/theme.scss';

    .header {
        position: fixed;
        width: 100vw;
        z-index: 99;

        // display: none;
    }
    .header-hidden {
        display: none;
    }
    .navigation {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 0 24px;
        height: 53px;
        z-index: 99;
        background-color: $primary-color;
        color: $secondary-color;

        &__left {
            flex: 1;
        }

        &__center {
            flex: 1;
            // padding-bottom: 8px;
            justify-content: center;
        }

        &__right {
            flex: 4;
            justify-content: flex-end;

            // &--line {
            //     height: 31px;
            //     border-right: 1px solid #DFDFDF;
            // }

            &--login-link {
                margin-left: 15px !important;
            }
        }

        &__left, &__center, &__right {
            display: flex;
            align-items: center;
        }

        &__landing-button {
            cursor: pointer;
            text-align: center;
            img {
                margin: 0 auto;
                height: 45px;
            }
            h5 {
                font-size: 10px;
                margin: 0;
            }
        }

        &__help {
            display: block;
        }

        &__logo-container {
            margin-left: 32px;
        }

        &__desktop-logo {
            cursor: pointer;
            display: block;
            max-width: 200px;
            height: 25px;
        }
        &__mobile-logo-container {
            width: 100%;
        }
        &__mobile-logo {
            display: block;
            width: 100%;
            max-width: 380px;
        }
        &__title {
            font-size: 1.25rem;
            line-height: 1;
            font-family: $primary-font;
            padding-top: 4px;
        }
        &__title > span {
            font-size: 0.5em;
            font-family: $secondary-font;
            display: block;
            text-transform: uppercase;
        }
    }

    .after-login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 8px 0px;
    }
    @media (min-width: $small) {
        .navigation {
            &__center {
                // justify-content: space-between;
            }
        }
    }
    @media (max-width: $medium) {
        .navigation {
            &__right {
                flex: 1;
            }
        }
    }
    @media (min-width: $medium) {
        .navigation {

            &__left {
            }
            &__right {
                margin-right: -16px;
                flex: 1;
            }

            &__landing-buttons {
                display: none;
                margin: 0 !important;
                padding-right: 15px;
                border-right: 1px solid $secondary-color;
                line-height: 29px;
            }
        }

        .after-login-container {
            margin-bottom: 0px;
        }
    }
    @media (min-width: $large) {
        .navigation {
            height: 69px;
            padding: 16px 0px;

            &__right {
                align-items: center;
                display: flex;

                margin-right: 0px;
                padding-right: 8px;
                padding-bottom: 0;
                > * {
                    margin: 0 8px;
                }
                flex: 4;
            }

            &__landing-buttons-container {
                margin: 0 0 0 auto;
                order: 0;
                width: -webkit-max-content;
                width: max-content;
            }

            &__landing-buttons {
                align-items: center;
                display: flex;
                justify-content: space-around;
                margin: 0 0 0 auto;
                // max-width: 500px;

                grid-gap: 1rem;
                box-sizing: border-box;
                list-style: none;
                width: 100%;
            }

            li .underline {
                height: 3px;
                background-color: transparent;
                width: 0%;
                transition: width 0.2s, background-color 0.5s;
                -moz-transition: width 0.2s, background-color 0.5s;
                -webkit-transition: width 0.2s, background-color 0.5s;
                margin: 0 auto;
                border-radius: 24px;
            }

            li.active-link .underline {
                width: 100%;
                background-color: $secondary-color;
            }

            li:hover .underline {
                background-color: $secondary-color;
                width: 100%;
            }

            &__landing-button {
                margin-right: 25px;
            }

            &__help {
                display: none;
            }

            &__desktop-logo {
                cursor: pointer;
                display: block;
                max-width: 250px;
                height: 25px;
            }

            &__mobile-logo-container {
                width: auto;
            }

            &__mobile-logo {
                display: none;
            }
        }
    }

    .sign-up-btn {
        width: 182px;
        border-radius: 4px
    }

    .sign-up-btn:hover {
        opacity: 0.8;
    }

    .back-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        @media (min-width: $large) {
            margin-left: 16px;
        }
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
        & img {
            width: 24px;
        }
        & p {
            line-height: 24px;
            font-size: 20px;
            margin: 2px 0 0 0;
        }
    }

    .checkout-help-button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        font-size: 12px;
        & img {
            width: 36px;
            height: 36px;
        }
    }

    .nav-right {
        margin-right: 0px;
    }
</style>
