import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class GetTaxes {
    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    // Handle registering a new WEB customer.
    handle(payload) {
        return this.apiService.post(ApiEndpoints['taxes'], payload);
    }
}
