module.exports = {
    'addresses.index': 'users/$1/addresses',
    'addresses.delete': 'users/$1/addresses/$2',
    'gifting-addresses.index': 'users/$1/addresses/gifting',
    'gifting-addresses.store': 'users/$1/addresses/gifting',
    'gifting-addresses.delete': 'users/$1/addresses/gifting/$2',
    'gift.addresses.update': 'gifts/addresses/$1',
    'payments.address.update': 'user/$1/address/$2',
    'postcodes.deliverable': 'postcodes/$1/deliverable',
    'postcodes.delivery-days': 'postcodes/$1/delivery-days/$2',
    'postcodes.delivery-day': 'postcodes/$1/delivery-day',
    'postcodes.first-delivery-day': 'postcodes/$1/first-delivery-day?new_signup=true',
    'subscriptions.addresses.update': 'subscriptions/$1/addresses/$2',
    'products.families': 'product-families',
};
