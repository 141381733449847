import Gifting from '@/routes/web/Gifting';
import AuthMiddleware from '@/middleware/AuthMiddleware';
import routesMap from '@/routes/routeMap';

const i18n = require('@/i18n').default;

const country = process.env.VUE_APP_COUNTRY_ISO;
const isShopEnabled = process.env.VUE_APP_SHOP_ENABLED === 'true';
const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';

export default [
    {
        path: routesMap[country].account,
        name: 'account',
        beforeEnter: AuthMiddleware,
        redirect: { name: 'account.dashboard' },
        components: {
            // route level code-splitting. This generates a separate chunk (account.[hash].js) for this route which is
            // lazy-loaded when the route is visited.
            default: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
            footer: () => import(/* webpackChunkName: "footer" */ '@/components/structure/Footer'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopNavigation'),
            topNavigationV2: () => import(/* webpackChunkName: "top-navigation-v2" */ '@/components/structure/navigation/TopNavigationV2'),
            orderSummaryModal: isShopEnabled ? () => import(/* webpackChunkName: "order-modal" */ '@/components/modals/shop/OrderSummaryModal') : null,
        },
        children: [
            {
                path: `${routesMap[country].confirmation}`,
                name: 'account.confirmation',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/Confirmation'),
            },
            {
                path: `${routesMap[country].outstandingOrders}`,
                name: 'account.outstanding-orders',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/OutstandingOrders'),
            },
            {
                path: `${routesMap[country].accountConfirmation}`,
                name: 'account.account-confirmation',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/AccountConfirmation'),
            },
            {
                path: routesMap[country].dashboard,
                name: 'account.dashboard',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/Dashboard.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_DASHBOARD'),
                        titleTranslateKey: 'ACCOUNT_DASHBOARD',
                    },
                },
            },
            {
                path: routesMap[country].restart,
                name: 'account.restart',
                component: isGBR ? () => import(/* webpackChunkName: "account" */ '@/components/forms/account/RestartAccountGBR.vue') : () => import(/* webpackChunkName: "account" */ '@/components/forms/account/RestartAccountDEU.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RESTART'),
                        titleTranslateKey: 'ACCOUNT_RESTART',
                    },
                },
            },
            {
                path: routesMap[country].gifterDashboard,
                name: 'gifter.dashboard',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/GifterDashboard.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_DASHBOARD'),
                        titleTranslateKey: 'ACCOUNT_DASHBOARD',
                    },
                },
            },
            {
                path: 'outstanding-orders',
                name: 'account.outstanding-orders',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/OutstandingOrders'),
            },
            {
                path: routesMap[country].rateMyFlowers,
                name: 'account.rate-my-flowers',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/RateMyFlowers.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RATE_FLOWERS'),
                        titleTranslateKey: 'ACCOUNT_RATE_FLOWERS',
                    },
                },
            },
            {
                path: routesMap[country].freeFlowers,
                name: 'account.free-flowers',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/FreeFlowers.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_FREE_FLOWERS'),
                        titleTranslateKey: 'ACCOUNT_FREE_FLOWERS',
                    },
                },
            },
            {
                path: `${routesMap[country].yourBoxesRedirect}/:scheduleDate/:subscriptionId`,
                name: 'account.your-boxes.redirect',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/YourBoxes.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_YOUR_BOXES'),
                        titleTranslateKey: 'ACCOUNT_YOUR_BOXES',
                    },
                },
            },
            {
                path: routesMap[country].yourBoxes,
                name: 'account.your-boxes',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/YourBoxes.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_YOUR_BOXES'),
                        titleTranslateKey: 'ACCOUNT_YOUR_BOXES',
                    },
                },
            },
            {
                path: routesMap[country].deliverySchedule,
                name: 'account.delivery-schedule',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/MyDeliverySchedule.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_MY_DELIVERY_SCHEDULE'),
                        titleTranslateKey: 'ACCOUNT_MY_DELIVERY_SCHEDULE',
                    },
                },
            },
            {
                path: routesMap[country].subscriptionManagement,
                name: 'account.subscription-management',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/ManageSubscriptions.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('MANAGE_YOUR_SUBSCRIPTION'),
                        titleTranslateKey: 'MANAGE_YOUR_SUBSCRIPTION',
                    },
                },
            },
            {
                path: routesMap[country].shop,
                name: 'account.shop',
                component: isShopEnabled ? () => import(/* webpackChunkName: "account" */ '@/views/account/Shop.vue') : null,
                meta: {
                    navigation: {
                        title: 'Shop',
                        titleTranslateKey: 'Shop',
                    },
                },
            },
            {
                path: `${routesMap[country].shop}/:productId`,
                name: 'account.shop.product',
                component: isShopEnabled ? () => import(/* webpackChunkName: "account" */ '@/views/account/ShopProductDetails.vue') : null,
                meta: {
                    navigation: {
                        title: 'Shop',
                        titleTranslateKey: 'Shop',
                    },
                },
            },
            {
                path: 'pause',
                name: 'account.pause-account',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/Pause.vue'),
                meta: {
                    navigation: {
                        title: '',
                    },
                },
            },
            {
                path: routesMap[country].pauseOrCancel,
                name: 'account.pause-or-cancel',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/PauseOrCancel.vue'),
                meta: {
                    navigation: {
                        title: '',
                    },
                },
            },
            {
                path: routesMap[country].updateYourPlan,
                name: 'account.update-your-plan',
                component: () => import(/* webpackChunkName: "account" */ '@/components/partials/account/my-delivery-schedule/UpdateYourPlan.vue'),
                meta: {
                    navigation: {
                        title: 'Update Plan',
                    },
                },
            },
            {
                path: routesMap[country].orderAVase,
                name: 'account.order-a-vase',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/OrderVase.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_ORDER_VASE'),
                        titleTranslateKey: 'ACCOUNT_ORDER_VASE',
                    },
                },
            },
            {
                path: routesMap[country].shop,
                name: 'account.shop',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/Shop.vue'),
                meta: {
                    navigation: {
                        // title: 'Shop',
                    },
                },
            },
            {
                path: routesMap[country].product,
                name: 'account.shop.product',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/ShopProductDetails.vue'),
                meta: {
                    navigation: {
                        // title: 'Shop',
                    },
                },
            },
            {
                path: routesMap[country].deliveryAddresses,
                name: 'account.delivery-addresses',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/MyDeliveryAddresses.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_DELIVERY_ADDRESSES'),
                        titleTranslateKey: 'ACCOUNT_DELIVERY_ADDRESSES',
                    },
                },
            },
            {
                path: routesMap[country].addGiftAddresses,
                name: 'account.add-gift-addresses',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/AddNewAddresses.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_DELIVERY_ADDRESSES'),
                        titleTranslateKey: 'ACCOUNT_DELIVERY_ADDRESSES',
                    },
                },
            },
            {
                path: routesMap[country].editGiftAddresses,
                name: 'account.edit-gift-addresses',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/gifting/UpdateNewAddresses.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_DELIVERY_ADDRESSES'),
                        titleTranslateKey: 'ACCOUNT_DELIVERY_ADDRESSES',
                    },
                },
            },
            {
                path: routesMap[country].nextWeekDelivery,
                name: 'account.next-week-delivery',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/NextWeekDelivery.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_NEXT_DELIVERY'),
                        titleTranslateKey: 'ACCOUNT_NEXT_DELIVERY',
                    },
                },
            },
            {
                path: routesMap[country].cardDetails,
                name: 'account.card-details',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/CardDetails.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_CARD_DETAILS'),
                        titleTranslateKey: 'ACCOUNT_CARD_DETAILS',
                    },
                },
            },
            {
                path: routesMap[country].manageAccount,
                name: 'account.manage-account',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/ManageAccount.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_MANAGE_ACCOUNT'),
                        titleTranslateKey: 'ACCOUNT_MANAGE_ACCOUNT',
                    },
                },
            },
            {
                path: routesMap[country].gifting,
                name: 'account.gifting',
                redirect: { name: 'account.gifting.choose-your-gift' },
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/Gifting.vue'),
                children: Gifting,
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_GIFTING'),
                        titleTranslateKey: 'ACCOUNT_GIFTING',
                    },
                },
            },
            {
                path: routesMap[country].restartConfirmationPage,
                name: 'account.restart-confirmation-page',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/RestartConfirmationPage.vue'),
            },
            {
                path: 'gifting-reminders',
                name: 'account.gifting-reminders',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/GiftingReminders.vue'),
                meta: {
                    navigation: {
                        title: 'Gifting Reminders',
                    },
                },
            },
            {
                path: routesMap[country].bumperBox,
                name: 'account.bumper-box',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/BumperBox.vue'),
                meta: {
                    navigation: {
                        // title: 'Bumper Box',
                    },
                },
            },
            {
                path: 'gifter',
                name: 'gifting.register',
                redirect: { name: 'gifting.register.subscription-details' },
                component: () => import(/* webpackChunkName: "accountRegister" */ '@/views/Register'),
                children: [
                    {
                        path: 'subscription-details',
                        name: 'gifting.register.subscription-details',
                        component: () => import(/* webpackChunkName: "accountRegister" */ '@/views/register/SubscriptionDetails'),
                    },
                    {
                        path: 'delivery-details',
                        name: 'gifting.register.delivery-details',
                        component: () => import(/* webpackChunkName: "accountRegister" */ '@/views/register/DeliveryDetails'),
                    },
                    {
                        path: 'payment-details',
                        name: 'gifting.register.payment-details',
                        component: () => import(/* webpackChunkName: "accountRegister" */ '@/views/register/PaymentDetails'),
                    },
                ],
            },
            {
                path: '/account-confirmation-gifter',
                name: 'gifting.register.confirmation',
                component: () => import(/* webpackChunkName: "accountRegister" */ '@/views/account/AccountConfirmationGifter'),
            },
            {
                path: 'order-history',
                name: 'account.order-history',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/OrderHistory.vue'),
                meta: {
                    navigation: {
                        title: 'Order History',
                    },
                },
            },
            {
                path: routesMap[country].gifterFreeFlowers,
                name: 'account.gifter-free-flowers',
                component: () => import(/* webpackChunkName: "account" */ '@/views/account/FreeFlowers.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_FREE_FLOWERS'),
                        titleTranslateKey: 'ACCOUNT_FREE_FLOWERS',
                    },
                },
            },
            {
                path: routesMap[country].restartV2Subscription,
                name: 'account.restart-v2-subscription',
                component: () => import(/* webpackChunkName: "account" */ '@/components/forms/account/RestartV2Subscription.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RESTART'),
                        titleTranslateKey: 'ACCOUNT_RESTART',
                    },
                },
            },
            {
                path: routesMap[country].restartV2Gifting,
                name: 'account.restart-v2-gifting',
                component: () => import(/* webpackChunkName: "account" */ '@/components/forms/account/RestartV2Gifting.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RESTART'),
                        titleTranslateKey: 'ACCOUNT_RESTART',
                    },
                },
            },
            {
                path: routesMap[country].subscriptionSettings,
                name: 'account.subscription-settings',
                component: () => import(/* webpackChunkName: "account" */ '@/components/forms/account/SubscriptionSettings.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RESTART'),
                        titleTranslateKey: 'ACCOUNT_RESTART',
                    },
                },
            },
            {
                path: routesMap[country].confirmSubscription,
                name: 'account.confirm-subscription',
                component: () => import(/* webpackChunkName: "account" */ '@/components/forms/account/ConfirmSubscription.vue'),
                meta: {
                    navigation: {
                        title: i18n.t('ACCOUNT_RESTART'),
                        titleTranslateKey: 'ACCOUNT_RESTART',
                    },
                },
            },
        ],
    },
];
