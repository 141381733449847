<template>
    <div class="sticky-banner">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'StickyBanner',
};
</script>

<style lang="scss" scoped>
    .sticky-banner {
        display: flex;
        flex: 1;
        flex-direction: row;
        max-width: 1054px;
        width: 90%;
        min-height: 125px;
        border-radius: 8px;
        border: 1px solid #CECECE;
        background: #FFFFFF;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 999;
        bottom: 40px;
        @media (max-width: 640px) {
            display: none;
        }
    }

    .close-sticky-banner {
        display: none;
    }

</style>
