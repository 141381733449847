<template>
    <div class="country-switch" ref="countryDropdown" v-click-outside="closeDropdown">
        <div class="country-switch--selected" :style="selectedStyles" @click="isOpen = !isOpen">
            <div class="country-switch--selected--value" v-if="value">
                <p><span class="flag-icon country-switch--selected--value--icon" :class="value.icon"></span> {{`${showFullName ? value.name[selectedLocale] : ''}`}}</p>
            </div>
            <img class="country-switch--arrow" :class="titleIconClass" alt="Country Swich Arrow" src="@/assets/images/decoration/arrow-down-black.png">
        </div>
        <div class="country-switch--options" :style="optionsStyles" v-if="isOpen">
            <div class="country-switch--options--option" v-for="option in options" :key="option.iso" :class="{'current': option.iso === value.iso}" @click="onSelect(option)">
                <p><span class="flag-icon country-switch--option--icon" :class="option.icon"></span> {{option.name[selectedLocale]}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Countries from '@/resources/countrySwitcherDropdown.json';

export default {
    name: 'CountrySwitchDropdown',
    props: {
        showFullName: {
            type: Boolean,
            default: false,
        },
        showBorder: {
            type: Boolean,
            default: false,
        },
        optionsDirection: {
            type: String,
            default: 'bottom',
        },
        selectedBackground: {
            type: String,
            default: '#fff',
        },
        optionsBackground: {
            type: String,
            default: '#fff',
        },
        selectedTextColor: {
            type: String,
            default: '#000',
        },
    },
    data() {
        return {
            isOpen: false,
            value: null,
            position: null,
        };
    },
    mounted() {
        this.value = this.options.filter(option => option.iso === process.env.VUE_APP_COUNTRY_ISO)[0];
    },
    computed: {
        selectedStyles() {
            return {
                backgroundColor: this.selectedBackground,
                color: this.selectedTextColor,
                border: this.showBorder ? '1px solid #1a1a1a' : 'none',
            };
        },
        optionsStyles() {
            let styles = {
                backgroundColor: this.optionsBackground,
                left: this.showFullName ? '-15px' : '-50%',
            };
            this.optionsDirection === 'top' ? styles.bottom = '100%' : styles.top = '100%';
            return styles;
        },
        titleIconClass() {
            let classStr = 'faqsItem__titleIcon';

            return this.isOpen ? `${classStr} ${classStr}--active` : classStr;
        },
        options() {
            return Countries;
        },
        selectedLocale() {
            return this.$root.$i18n.locale.split('-')[1];
        },
    },
    methods: {
        onSelect(selectedOption) {
            this.closeDropdown();
            if (!selectedOption || process.env.VUE_APP_COUNTRY_ISO === selectedOption?.iso) return;
            window.location.href = selectedOption.link;
        },
        closeDropdown() {
            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.country-switch {
    position: relative;
    background: $secondary-color;
    &--selected {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        background: $secondary-color;
        &--value {
            padding: 10px;
            padding-right: 5px;
            background: $secondary-color;
            color: $primary-color;
            text-decoration: underline;
            p {
                margin: 0;
            }
            &--icon {
                margin-right: 10px;
            }
        }
    }
    &--options {
        position: absolute;
        padding: 20px 0;
        width: 100%;
        min-width: max-content;
        z-index: 100;
        box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: .3);
        background: $secondary-color;
        color: $primary-color;
        &--option {
            padding: 7px 35px;
            p {
                margin: 0;
            }
            &--icon {
                margin-right: 10px;
            }
            &:hover {
                background-color: $shade-light-color;
                cursor: pointer;
            }
        }

    }
    &--arrow {
     margin-right: 10px;
     background: $secondary-color;
    }
}
.current {
    background-color: $shade-light-color;
}
</style>
