import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class LegacyIsPostcodeDeliverable {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    async handle(postcode) {
        let apiUrl = url(ApiEndpoints['postcodes.deliverable'], [postcode]);
        let request = await this.apiService
            .get(apiUrl)
            .catch(() => false);

        return request.status === 200;
    }
}
