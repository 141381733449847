import { url } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class GetMissedArrangements {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId) {
        let apiUrl = url(ApiEndpoints['missed-arrangements'], [subscriptionId]);

        return this.apiService.get(apiUrl);
    }
}
