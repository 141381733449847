import store from '@/store';
import { CANVASSER_TYPE, USER_TYPE } from '@/constants/Account';
import AuthService from '@/services/auth/AuthService';

const authService = new AuthService();

const getDefaultState = () => {
    return {
        userLoggedIn: false,
        intendedUrl: process.env.VUE_APP_COUNTRY_ISO === 'GBR' ? 'account/dashboard' : 'konto/instrumententafel',
        refreshTokenAttempts: 0,
        userType: null,
        refreshProcessing: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            let sustainedIntendedUrl = null;
            if (getDefaultState().intendedUrl !== state.intendedUrl) sustainedIntendedUrl = state.intendedUrl;

            Object.assign(state, getDefaultState());
            if (sustainedIntendedUrl !== '/' && sustainedIntendedUrl) state.intendedUrl = sustainedIntendedUrl;
        },

        updateUserLoggedIn(state, payload) {
            state.userLoggedIn = payload;
        },

        updateIntendedUrl(state, payload) {
            if (payload !== '/login/refresh' && payload !== '/login') {
                state.intendedUrl = payload;
            }
        },

        updateRefreshTokenAttempts(state, payload) {
            state.refreshTokenAttempts = payload;
        },

        updateUserType(state, payload) {
            state.userType = payload;
        },

        updateRefreshProcessing(state, payload) {
            state.refreshProcessing = payload;
        },
    },
    actions: {
        login({ dispatch }, userData) {
            return new Promise((resolve, reject) => {
                authService.login(userData.email, userData.password)
                    .then((res) => {
                        dispatch('loginUser', USER_TYPE);
                        resolve(res);
                    })
                    .catch(error => reject(error));
            });
        },

        loginUser({ state, commit }, userType) {
            commit('updateUserLoggedIn', true);
            commit('updateUserType', userType);
            commit('updateRefreshTokenAttempts', 0);
            commit('updateRefreshProcessing', false);

            if (getDefaultState().intendedUrl !== state.intendedUrl) {
                store.dispatch('account/fetchUserData', true)
                    .then(() => store.dispatch('subscription/fetchAllSubscriptions'))
                    .then(() => store.dispatch('gifting/fetchGiftingCoupons'));
            }
        },

        resetAuth({ commit }) {
            commit('resetState');
        },

        logout({ dispatch, state }) {
            const type = state.userType;

            dispatch('resetAuth');

            switch (type) {
            case CANVASSER_TYPE:
                dispatch('canvasser/resetCanvasser', null, { root: true });
                dispatch('account/resetAccount', null, { root: true });
                break;

            case USER_TYPE:
                dispatch('account/resetAccount', null, { root: true });
                dispatch('address/resetAddress', null, { root: true });
                dispatch('cms/resetCms', null, { root: true });
                dispatch('gifting/resetGifting', null, { root: true });
                dispatch('order/resetOrder', null, { root: true });
                dispatch('shop/resetShop', null, { root: true });
                dispatch('scheduledOrder/resetScheduledOrders', null, { root: true });
                dispatch('subscription/resetSubscription', null, { root: true });
                break;
            }
        },
    },
    getters: {
        isUserLoggedIn: state => state.userLoggedIn,

        isUser: state => state.userLoggedIn && state.userType === USER_TYPE,

        isCanvasser: state => (process.env.VUE_APP_MODE === CANVASSER_TYPE) || (state.userLoggedIn && state.userType === CANVASSER_TYPE),

        getUserType: state => state.userType,

        getIntendedUrl: state => state.intendedUrl,

        getRefreshTokenAttempts: state => state.refreshTokenAttempts,

        isRefreshProcessing: state => state.refreshProcessing,
    },
};
