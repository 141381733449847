import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchPromotionalBanners {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = ApiEndpoints['users.promotional-banners'];
        apiUrl = payload ? `${apiUrl}?user_types=${payload.user_type_id}` : apiUrl;

        return this.apiService.get(apiUrl);
    }
}
