import store from '@/store';
import { GIFT_TYPE_SUBSCRIPTION } from '@/constants/Gifting';

export default function(to, from, next) {
    if (process.env.VUE_APP_GUEST_GIFTING_ENABLED === 'true' && process.env.VUE_APP_COUNTRY_ISO === 'GBR') {
        next({ name: 'guest-gifting.home' });
    } else if (process.env.VUE_APP_GIFTING_ENABLED === 'false') {
        next({ name: 'account.dashboard' });
    } else if (to.name === 'account.gifting.choose-your-gift') {
        next();
    } else if (store.getters['gifting/getGiftType'] === null) {
        next({ name: 'account.gifting.choose-your-gift' });
    } else if (to.name !== 'account.gifting.number-of-deliveries' &&
        store.getters['gifting/getGiftType'] === GIFT_TYPE_SUBSCRIPTION &&
        (
            store.getters['gifting/getNumberOfMonthsForSubscription'] === 0 ||
            store.getters['gifting/getFrequencyForSubscription'] === null)
    ) {
        next({ name: 'account.gifting.number-of-deliveries' });
    } else {
        next();
    }
}
