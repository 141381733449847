import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class CreateSubscriptionFromLapsedSubscription {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = ApiEndpoints['subscriptions.create-from-lapsed.store'];

        return this.apiService.post(apiUrl, payload);
    }
}
