<template>
    <div class="contact-us">
        <div class="contact-us--container">
            <div class="contact-us--container--links">

                <div class="contact-us--container--links--section">
                    <div @click="toggleSection(1)" class="contact-us--container--links--section--title-container">
                        <p class="contact-us--container--links--section--title">{{$t('CONTACT_US_OUR_FLOWERS')}}</p>
                        <img :src="smallArrowIcon" alt="Small Downwards Arrow Icon" loading="lazy" />
                    </div>

                    <transition name="transitionFade">
                        <div v-if="isActiveSection(1)" class="contact-us--container--links--section--links">
                            <p class="contact-us--container--links--section--links--link">
                                <router-link class="contact-us--link" :to="{name: 'flowers'}">{{$t('LANDING_FLOWERS')}}</router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link class="contact-us--link" :to="{name: 'arranging'}">{{$t('LANDING_ARRANGING')}}</router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link class="contact-us--link" :to="{name: 'delivery'}">{{$t('LANDING_DELIVERY')}}</router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link v-if="isGBR" class="contact-us--link" :to="{name: 'how-it-works'}">{{$t('LANDING_HOW_IT_WORKS')}}</router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link v-if="isGBR" class="contact-us--link" :to="{name: 'freddies-story'}">{{$t('LANDING_MEET_FREDDIE')}}</router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link class="contact-us--link" :to="{name: 'sustainability'}">{{$t('CONTACT_US_NAV_LINK_SUSTAINABILITY')}}</router-link>
                            </p>
                        </div>
                    </transition>
                </div>

                <div class="contact-us--container--links--section">
                    <div @click="toggleSection(2)" class="contact-us--container--links--section--title-container">
                        <p class="contact-us--container--links--section--title">{{$t('CONTACT_US_COMPANY')}}</p>
                        <img :src="smallArrowIcon" alt="Small Downwards Arrow Icon" loading="lazy" />
                    </div>

                    <transition name="transitionFade">
                        <div v-if="isActiveSection(2)" class="contact-us--container--links--section--links">
                            <p class="contact-us--container--links--section--links--link">
                                <router-link v-if="isGBR" :to="{ name: 'blog' }" class="contact-us--link" target="_blank">
                                    {{ $t('CONTACT_US_BLOG') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link :to="{ name: 'jobs' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_JOBS') }}
                                </router-link>
                            </p>
                            <p v-if="isDEU" class="contact-us--container--links--section--links--link">
                                <router-link :to="{ name: 'impressum' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_IMPRESSUM') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link v-if="isGBR" :to="{ name: 'survey-entrants' }" target="_blank" class="contact-us--link">
                                    {{ $t('CONTACT_US_SURVEY_ENTRANTS') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link v-if="isGBR" :to="{ name: 'tag-rugby-trust' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_TRT_PAGE') }}
                                </router-link>
                            </p>
                        </div>
                    </transition>
                </div>

                <div class="contact-us--container--links--section">
                    <div @click="toggleSection(3)" class="contact-us--container--links--section--title-container">
                        <p class="contact-us--container--links--section--title">{{$t('HAMBURGER_HELP')}}</p>
                        <img :src="smallArrowIcon" alt="Small Downwards Arrow Icon" loading="lazy" />
                    </div>

                    <transition name="transitionFade">
                        <div v-if="isActiveSection(3)" class="contact-us--container--links--section--links">
                            <p class="contact-us--container--links--section--links--link">
                                <a class="contact-us--link" :href="countryUtils.zendesk_hc_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                                    {{ $t('CONTACT_US_VISIT_OUR_HELP_CENTER') }}
                                    <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                                </a>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <a class="contact-us--link" :href="countryUtils.zendesk_hc_my_account_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                                    {{ $t('CONTACT_US_HELP_CENTER_MY_ACCOUNT') }}
                                    <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                                </a>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <a class="contact-us--link" :href="countryUtils.zendesk_hc_my_delivery_url" :target="isUserLoggedIn ? '_self' : '_blank'">
                                    {{ $t('CONTACT_US_HELP_CENTER_DELIVERIES') }}
                                    <img class="contact-us--icon" :src="openInNewTabIcon" alt="Open in New Tab icon" v-if="!isUserLoggedIn" loading="lazy" />
                                </a>
                            </p>
                            <router-link class="contact-us--container--links--section--links--link" :to="{name: 'business-enquiries'}">
                                {{ $t('CONTACT_US_BUSINESS') }}
                            </router-link>
                        </div>
                    </transition>
                </div>

                <div class="contact-us--container--links--section">
                    <div @click="toggleSection(4)" class="contact-us--container--links--section--title-container">
                        <p class="contact-us--container--links--section--title">{{$t('CONTACT_US_LEGAL')}}</p>
                        <img :src="smallArrowIcon" alt="Small Downwards Arrow Icon" loading="lazy" />
                    </div>

                    <transition name="transitionFade">
                        <div v-if="isActiveSection(4)" class="contact-us--container--links--section--links">
                            <p class="contact-us--container--links--section--links--link">
                                <a href="#" class="contact-us--link">
                                    <div @click="showCookieBanner()">
                                        {{ $t('CONTACT_US_COOKIE') }}
                                    </div>
                                </a>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link :to="{ name: 'terms-and-conditions' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_TERMS') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link" v-if="isDEU">
                                <router-link :to="{ name: 'payment-and-delivery' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_PAYMENT_AND_DELIVERY') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link" v-if="isDEU">
                                <router-link :to="{ name: 'right-to-cancel' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_RIGHT_TO_CANCEL') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link :to="{ name: 'privacy-policy' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_PRIVACY') }}
                                </router-link>
                            </p>
                            <p class="contact-us--container--links--section--links--link">
                                <router-link :to="{ name: 'accessibility' }" class="contact-us--link">
                                    {{ $t('CONTACT_US_ACCESSIBILITY') }}
                                </router-link>
                            </p>
                        </div>
                    </transition>
                </div>

            </div>

            <div class="contact-us--container--social-links">
                <p class="contact-us--container--social-links--text"> {{ $t('FOOTER_FOLLOW_US') }}: </p>
                <div class="contact-us--container--social-links--links">
                    <a class="mx-4" :href="$t('FACEBOOK_URL')" target="_blank">
                        <img class="hamburger__socialIcon"
                            src="@/assets/images/decoration/social-icon-facebook-secondary.svg"
                            alt="Facebook"
                            loading="lazy" />
                    </a>

                    <a class="mx-4" :href="$t('INSTAGRAM_URL')" target="_blank">
                        <img class="hamburger__socialIcon"
                            src="@/assets/images/decoration/social-icon-instagram-secondary.svg"
                            alt="Instagram"
                            loading="lazy" />
                    </a>
                </div>
            </div>

            <div class="contact-us--container--switch-and-payments">
                <div class="contact-us--container--switch-and-payments--country-switcher">
                    <country-switch-dropdown
                        :showFullName="true"
                        :showBorder="false"
                        selectedBackground="transparent"
                        optionsDirection="top"/>
                </div>

                <div class="contact-us--container--switch-and-payments--payment-methods">
                    <img
                        v-for="(paymentMethod, index) in paymentMethods"
                        :key="`payment-method-${index}`"
                        class="contact-us--payment-methods-container--payment-method"
                        :src="paymentMethod.image"
                        :alt="paymentMethod.imageAlt"
                        loading="lazy" />
                </div>
            </div>

            <div class="contact-us--container--certification-and-copyRights">
                <div class="contact-us--container--certification-and-copyRights--certification">
                    <img class="contact-us--container--certification-and-copyRights--certification--image" :src="bCorpLogo" alt="B Corp Certification Logo" loading="lazy" />
                </div>

                <div class="contact-us--container--certification-and-copyRights--copyRights">
                    <p v-html="$t('CONTACT_US_COPYRIGHT_WITH_LINE_BREAK')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import countryUtils from '@/resources/countryUtils.json';

import CountrySwitchDropdown from '@/components/structure/navigation/CountrySwitchDropdown';

import OpenInNewTabIcon from '@/assets/images/icons/open-in-new-tab.svg';
import SmallArrowIcon from '@/assets/icons/footer/arrow-small.svg';
import MasterCardIcon from '@/assets/icons/footer/master-card.svg';
import VISAIcon from '@/assets/icons/footer/visa.svg';
import ApplePayIcon from '@/assets/icons/footer/apple-pay.svg';
import PayPalIcon from '@/assets/icons/footer/pay-pal.svg';
import BCorpLogo from '@/assets/icons/footer/b-corp-logo.svg';

export default {
    name: 'ContactUsMobile',
    components: { CountrySwitchDropdown },
    data() {
        return {
            activeSection: 1,
            countryUtils: countryUtils.filter(item => item.iso === process.env.VUE_APP_COUNTRY_ISO)[0],
            openInNewTabIcon: OpenInNewTabIcon,
            smallArrowIcon: SmallArrowIcon,
            paymentMethods: [
                {
                    name: 'MasterCard',
                    image: MasterCardIcon,
                    imageAlt: 'MasterCard Icon',
                },
                {
                    name: 'VISA',
                    image: VISAIcon,
                    imageAlt: 'VISA card Icon',
                },
                {
                    name: 'ApplePay',
                    image: ApplePayIcon,
                    imageAlt: 'ApplePay Icon',
                },
                {
                    name: 'PayPal',
                    image: PayPalIcon,
                    imageAlt: 'PayPal Icon',
                },
            ],
            bCorpLogo: BCorpLogo,
        };
    },
    computed: {
        ...mapGetters({
            isGBR: 'localisation/isGBR',
            isDEU: 'localisation/isDEU',
            isUserLoggedIn: 'auth/isUserLoggedIn',
        }),
    },
    methods: {
        toggleSection(number) {
            number === this.activeSection
                ? this.activeSection = 0
                : this.activeSection = number;
        },
        isActiveSection(number) {
            return this.activeSection === number;
        },
        showCookieBanner() {
            this.$store.commit('cookies/showCookieBanner');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

.contact-us {
    &--container {
        &--links {
            display: flex;
            flex-direction: column;

            &--section {
                border-bottom: 1px solid $secondary-color;

                padding: 16px;

                &--title-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    cursor: pointer;

                    p {
                        @include button();
                    }
                }

                &--links {
                    padding-left: 9px;
                    padding-top: 9px;

                    transition: .1s ease-in;

                    &--link {
                        margin: 0;
                        padding-bottom: 16px;

                        .contact-us--link {
                            display: flex;
                        }

                        .contact-us--icon {
                            width: 15px;

                            padding-left: 5px;
                        }
                    }

                    &--link:first-child {
                        padding-top: 9px;
                    }

                    &--link:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }

        &--social-links {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 16px;

            border-bottom: 1px solid $secondary-color;

            &--links {
                display: flex;
            }
        }

        &--switch-and-payments {
            display: flex;
            flex-direction: column;

            border-bottom: 1px solid $secondary-color;

            padding: 16px;

            &--country-switcher {
                width: 70%;
                align-self: center;
            }

            &--payment-methods {
                display: flex;
                justify-content: center;
                gap: 16px;

                padding: 16px 16px 36px;

                img {
                    width: 60px;
                    height: 40px;
                }
            }
        }

        &--certification-and-copyRights {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 30px 16px;

            &--copyRights {
                text-align: center;
            }
        }
    }
}
</style>
