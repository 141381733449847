export const FULL_DAY = 'EEEE'; // Monday
export const SHORT_DAY = 'E'; // Mon
export const SINGLE_LETTER_DAY = 'EEEEE'; // M
export const SINGLE_NUMBER_DAY = 'd'; // 4

export const FULL_MONTH = 'MMMM'; // February
export const SHORT_MONTH = 'MMM'; // Feb

export const DAY_DATE_MONTH = 'EEEE, do MMMM'; // Monday, 7th July
export const DAY_DATE_MONTH_NO_COMMA = 'EEEE do MMMM'; // Monday 7th July
export const DAY_DATE_OF_MONTH = "EEEE, do 'of' MMMM"; // Monday 7th of July
export const DAY_DATE_MONTH_YEAR = 'PPPP'; // Monday, 7th July 2022
export const UK_DATE_FORMAT = 'dd-MMM-yyyy';
export const DATE_MONTH = 'do MMMM'; // 7th July
export const DAY_FULL_MONTH_YEAR = 'E, MMMM dd'; // Tues, June 22
export const DAY_MONTH_DATE = 'EEEE, MMM dd'; // Saturday, Aug 6
export const MONTH_DATE = 'MMMM dd'; // August 06

export const SLASHED_YEAR_MONTH_DAY = 'dd/MM/yyyy';
export const DASHED_YEAR_MONTH_DAY = 'yyyy-MM-dd'; // 2022-02-05
export const DASHED_YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS = 'yyyy-MM-dd HH:mm:ss'; // 2022-02-05 00:00:00
