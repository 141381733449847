const getDefaultState = () => {
    return {
        region: null,
        source: null,
        parentSource: null,
        fullName: null,
        stripe: {
            terminal: null,
            discoveredReaders: null,
            isConnectingToReader: false,
            connectedReader: null,
            connectionStatus: 'not_connected',
            cardDetails: null,
            selectedReader: null,
        },
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState: (state) => Object.assign(state, getDefaultState()),

        updateRegion: (state, payload) => state.region = payload,

        updateSource: (state, payload) => state.source = payload,

        updateParentSource: (state, payload) => state.parentSource = payload,

        updateFullName(state, payload) {
            state.fullName = payload;
        },

        setStripeTerminal: (state, payload) => state.stripe.terminal = payload,

        setDiscoveredReaders: (state, payload) => state.stripe.discoveredReaders = payload,

        setIsConnectingToReader: (state, payload) => state.stripe.isConnectingToReader = payload,

        setSelectedReader: (state, payload) => state.stripe.selectedReader = payload,

        setConnectedReader: (state, payload) => state.stripe.connectedReader = payload,

        setConnectionStatus: (state, payload) => state.stripe.connectionStatus = payload,

        updateCardDetails: (state, payload) => state.stripe.cardDetails = payload,

        resetCardDetails: (state) => state.stripe.cardDetails = null,

        resetStripe: (state) => state.stripe = getDefaultState().stripe,
    },
    actions: {
        resetCanvasser({ commit }) {
            commit('resetState');
        },

        discoverStripeTerminalReader({ commit, state }) {
            let config = { simulated: process.env.VUE_APP_USE_TEST_CARD === 'true' };
            state.stripe.terminal?.discoverReaders(config).then((discoverResult) => {
                commit('setDiscoveredReaders', discoverResult.discoveredReaders);
            }).catch(err => console.log(err));
        },

        connectReaderHandler({ commit, state }) {
            let selectedReader = state.stripe.selectedReader || state.stripe.discoveredReaders[0];
            commit('setIsConnectingToReader', true);
            state.stripe.terminal?.connectReader(selectedReader).then((connectResult) => {
                commit('setIsConnectingToReader', false);
                commit('setConnectedReader', connectResult);
                commit('setConnectionStatus', state.stripe.terminal.connectionStatus);
            }).catch(err => console.log(err));
        },

        disconnectReader({ commit, state }) {
            state.stripe.terminal?.disconnectReader().then(() => {
                commit('resetStripe');
                window.location.reload();
            }).catch(err => console.log(err));
        },

        collectCardDetails({ commit, state, rootGetters }) {
            const clientSecret = rootGetters['account/getSetupIntent'];
            try {
                state.stripe.terminal.collectSetupIntentPaymentMethod(clientSecret, true)
                    .then(res => state.stripe.terminal.confirmSetupIntent(res.setupIntent, true))
                    .then(result => commit('updateCardDetails', result.setupIntent));
            } catch (err) {
                commit('resetStripe');
                window.location.reload();
            }
        },
    },
    getters: {
        getFullName: (state) => state.fullName,

        getRegion: (state) => state.region,

        getSource: (state) => state.source,

        getParentSource: (state) => state.parentSource,

        getRegionId: (state) => state.region ? state.region.id : null,

        getSourceId: (state) => state.source ? state.source.id : null,

        getParentSourceId: (state) => state.parentSource ? state.parentSource.id : null,

        getStripeTerminal: state => state.stripe.terminal,

        getDiscoveredReaders: state => state.stripe.discoveredReaders,

        isConnectingToReader: state => state.stripe.isConnectingToReader,

        getConnectedReader: state => state.stripe.connectedReader,

        getConnectionStatus: state => state.stripe.connectionStatus,

        getCardDetails: state => state.stripe.cardDetails,

        getSelectedReader: state => state.stripe.selectedReader,
    },
};
