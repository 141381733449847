import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class GetGiftingMessage {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(giftRef) {
        let apiUrl = url(ApiEndpoints['gift-message'], [giftRef]);
        return this.apiService.get(apiUrl);
    }
}
