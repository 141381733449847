import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import { url } from '@/helpers/UrlHelpers';

export default class SendIncompleteGiftingData {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    async handle(giftingData, giftingId, userId) {
        let params = giftingId ? [userId, giftingId] : [userId];
        let endPoint = giftingId ? 'gifting.incomplete.update' : 'gifting.incomplete.create';
        let request = null;

        const apiUrl = url(ApiEndpoints[endPoint], params);

        request = giftingId
            ? await this.apiService.patch(apiUrl, giftingData).catch(() => false)
            : await this.apiService.post(apiUrl, giftingData).catch(() => false);

        return !giftingId ? request.data.properties.uuid : null;
    }
}
