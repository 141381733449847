import store from '@/store';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class ValidateIntroductions {
    apiService;

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
    }

    // Handle validating the introductions step of the registration.
    handle() {
        return this.apiService.post(ApiEndpoints['users.validate.registration.introductions.store'], this.getPayload());
    }

    getPayload() {
        return {
            email: store.getters['register/getEmail'],
            first_name: store.getters['register/getFirstName'],
            last_name: store.getters['register/getLastName'],
            opt_in_marketing: store.getters['register/getOptInMarketing'],
            opt_in_third_party_marketing: store.getters['register/getOptInMarketing'],
            password: store.getters['register/getPassword'],
            telephone: store.getters['register/getTelephone'],
        };
    }
}
