import { format, utcToZonedTime } from 'date-fns-tz';
import countriesJson from '@/resources/countries.json';
import i18n from '@/i18n';

const getDefaultState = () => {
    return {
        locale: i18n.locale,
        cmsLocaleKey: null,
        isUSA: process.env.VUE_APP_COUNTRY_ISO === 'USA',
        isDEU: process.env.VUE_APP_COUNTRY_ISO === 'DEU',
        isNLD: process.env.VUE_APP_COUNTRY_ISO === 'NLD',
        isAUT: process.env.VUE_APP_COUNTRY_ISO === 'AUT',
        isGBR: process.env.VUE_APP_COUNTRY_ISO === 'GBR',
        country: process.env.VUE_APP_COUNTRY_ISO,
        currency: countriesJson.find(country => country.iso === process.env.VUE_APP_COUNTRY_ISO).currency.symbol,
        currencyCode: countriesJson.find(country => country.iso === process.env.VUE_APP_COUNTRY_ISO).currency.code,
        timeZone: countriesJson.find(country => country.iso === process.env.VUE_APP_COUNTRY_ISO).time_zone,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        setLocale(state, payload) {
            state.locale = payload;
        },

        setCmsLocaleKey(state, payload) {
            state.cmsLocaleKey = payload;
        },
    },
    actions: {
        resetLocale({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        getSelectedLocale: state => state.locale,

        getCmsLocaleKey: state => state.cmsLocaleKey,

        enableKareWidget: state => state.isDEU,

        isUSA: state => state.isUSA,

        isDEU: state => state.isDEU,

        isNLD: state => state.isNLD,

        isAUT: state => state.isAUT,

        isGBR: state => state.isGBR,

        getCurrency: state => state.currency,

        country: state => state.country,

        getCurrencyCode: state => state.currencyCode,

        getCurrentTZDate: (state) => {
            let currentTime = new Date();
            let ukTime = utcToZonedTime(currentTime, state.timeZone);
            return {
                date: (format(ukTime, 'yyyy-MM-dd', { timeZone: state.timeZone })),
                hour: Number(format(ukTime, 'HH', { timeZone: state.timeZone })),
                min: Number(format(ukTime, 'mm', { timeZone: state.timeZone })),
                sec: Number(format(ukTime, 'ss', { timeZone: state.timeZone })),
            };
        },
    },
};
