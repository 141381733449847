import * as Sentry from '@sentry/vue';
import { capitalizeFirstLetter } from '@/helpers/StringHelpers';
import { USER_TYPE_GIFTER, USER_TYPE_SUBSCRIBER } from '@/constants/Gifting';
import UserService from '@/services/users/UserService';
import CouponsHistoryService from '@/services/cms/couponsHistoryService';
import boxType from '@/assets/images/decoration/box-type.svg';
import delivery from '@/assets/images/decoration/delivery.svg';
import holiday from '@/assets/images/decoration/holiday.svg';

const getDefaultState = () => {
    return {
        user: {
            hasFetched: false,
            data: null,
            banners: null,
            schedule: null,
            courierPartnerForConfirmationSummary: null,
        },
        rescheduledDeliveryDays: {
            data: [],
        },
        expandFormName: null,
        countrySwitch: {
            hasAnswered: localStorage.getItem('country-switch-answered'),
            location: null,
        },
        CouponsHistory: null,
        giftVouchers: null,
        signupCoupon: null,
        skipReasons: null,
        holidayGiftingEnabled: null,
        shiftDeliveryScheduleEnabled: false,
        skipConsecutiveHolidayEnabled: false,
        outstandingOrders: [],
        paymentIntent: '',
        paymentIntentBraintree: '',
        fetchingPaymentIntent: false,
        clientToken: null,
        fetchingClientToken: false,
        scrollbarEnabled: localStorage.getItem('scrollbar-enabled'),
        hasError: false,
        showFloatingBanner: false,
        bannerHeading: null,
        bannerMessage: null,
        canPauseOrCancel: true,
        isPauseFlow: false,
        restartFields: {
            'heading': 'How does it work?',
            'paragraph_without_delivery': '',
            'image_type': 'photo',
            'carousel_on_mobile': false,
            'rounded_image': true,
            'show_discount_copy': true,
            'section': [
                {
                    'image': 'https://cdn.buttercms.com/DiOWOULxQnej3pn6h0dY',
                    'heading': 'We pick the flowers',
                    'paragraph': 'Freddie curates the perfect arrangements, and work direct with growers to get you the best flowers.',
                },
                {
                    'image': 'https://cdn.buttercms.com/ZaQgoRdOQyWKsA462SbN',
                    'heading': 'and deliver them to you',
                    'paragraph': "We'll bring your box direct to your door. All our deliveries are certified Carbon Neutral.",
                },
                {
                    'image': 'https://cdn.buttercms.com/zNpr8ZKpSK6hZcJ2DUF4',
                    'heading': 'Learn to arrange like a florist',
                    'paragraph': "We teach you all about flowers with Freddie's arranging guides - then just sit back and admire your creation.",
                },
                {
                    'image': 'https://cdn.buttercms.com/hXnOEdRSGyn8HHNAOxCg',
                    'heading': "You're in control - no commitment",
                    'paragraph': 'Skip any delivery using the online calendar, and have flowers when it suits you. Cancel any time.',
                },
            ],
        },
        updateYourPlanData: {
            image_type: 'graphic',
            section: [
                {
                    heading: 'Change your box type',
                    paragraph: 'We have 3 box types to suite any budget',
                    image: boxType,
                },
                {
                    heading: 'Update your delivery frequency',
                    paragraph: 'Make savings by reducing your deliveries',
                    image: delivery,
                },
                {
                    heading: 'Take a holiday',
                    paragraph: 'Sometimes it\'s good to take a break',
                    image: holiday,
                },
            ],
        },
        pauseFeedbackOptions: [
            {
                id: 1,
                label: "I'm going away/moving house/renovating my place",
            },
            {
                id: 2,
                label: 'I prefer to order occassionally',
            },
            {
                id: 3,
                label: "It's out of my budget right now",
            },
        ],
        promotionalBanners: [],
        isMobile: false,
        isDesktop: false,
        themes: null,
        userPaymentMethods: null,
        surveyForm: null,
        isFormSubmitted: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        setUserGeolocation: (state, payload) => state.countrySwitch.location = payload,

        countrySwitchHasAnswered: (state, bool) => {
            state.countrySwitch.hasAnswered = bool;
            localStorage.setItem('country-switch-answered', bool);
        },

        setScrollbarEnabled: (state, bool) => {
            state.scrollbarEnabled = bool;

            if (bool) {
                document.getElementById('scrollbar').innerHTML = '::-webkit-scrollbar{  }';
            } else {
                document.getElementById('scrollbar').innerHTML = '::-webkit-scrollbar{ display: none }';
            }

            localStorage.setItem('scrollbar-enabled', bool);
        },

        updateUserData(state, payload) {
            state.user.data = payload;
        },

        updateSkipReasons(state, payload) {
            state.skipReasons = payload;
        },

        updateUserBanners(state, payload) {
            state.user.banners = payload;
        },

        updateUserSchedule(state, payload) {
            state.user.schedule = payload;
        },

        updateCouponsHistory(state, payload) {
            state.CouponsHistory = payload.coupon_data.data;
            state.giftVouchers = payload.voucher_data.data;
        },

        updateExpandFormName(state, payload) {
            state.expandFormName = payload;
        },

        updateRescheduledDeliveryDays(state, payload) {
            state.rescheduledDeliveryDays.data = payload;
        },

        updateOutstandingOrders(state, payload) {
            state.outstandingOrders = payload;
        },

        setCourierPartnerForConfirmationSummary(state, payload) {
            state.user.courierPartnerForConfirmationSummary = payload;
        },

        setSignupCoupon(state, payload) {
            state.signupCoupon = payload;
        },

        setHolidayGiftingEnabled: (state, payload) => state.holidayGiftingEnabled = payload,
        setShiftDeliveryScheduleEnabled: (state, payload) => state.shiftDeliveryScheduleEnabled = payload,
        setSkipConsecutiveHolidayEnabled: (state, payload) => state.skipConsecutiveHolidayEnabled = payload,
        resetCouponState(state) {
            state.signupCoupon = null;
        },

        setPaymentIntent: (state, payload) => state.paymentIntent = payload,

        setPaymentIntentBraintree: (state, payload) => state.paymentIntentBraintree = payload,

        setFetchingPaymentIntent: (state, payload) => state.fetchingPaymentIntent = payload,

        setClientToken: (state, payload) => state.clientToken = payload,

        setFetchingClientToken: (state, payload) => state.fetchingClientToken = payload,

        setError: (state, payload) => state.hasError = payload,

        setShowFloatingBanner: (state, payload) => state.showFloatingBanner = payload,

        setBannerHeading: (state, payload) => state.bannerHeading = payload,

        setBannerMessage: (state, payload) => state.bannerMessage = payload,

        setCanPauseOrCancel: (state, payload) => state.canPauseOrCancel = payload,

        setIsPauseFlow: (state, payload) => state.isPauseFlow = payload,

        setPromotionalBanners: (state, payload) => state.promotionalBanners = payload,

        setIsMobile: (state, payload) => state.isMobile = payload,

        setIsDesktop: (state, payload) => state.isDesktop = payload,

        setTheme: (state, payload) => state.themes = payload,

        setUserPaymentMethods: (state, payload) => state.userPaymentMethods = payload,

        setSurveyForm: (state, payload) => state.surveyForm = payload,

        setIsFormSubmitted: (state, payload) => state.isFormSubmitted = payload,
    },
    actions: {
        fetchUserData({ commit, state, rootGetters, dispatch }, forceReload = false) {
            commit('setError', false);
            if (state.user.hasFetched === false || forceReload === true) {
                state.user.hasFetched = true;
                let service = new UserService();

                return new Promise((resolve, reject) => {
                    try {
                        service.fetchUserData(rootGetters['auth/isCanvasser'])
                            .then(response => {
                                commit('updateUserData', response.data.data);
                                commit('updateUserBanners', response.data.banners);
                                commit('updateUserSchedule', response.data.schedule);
                                commit('setHolidayGiftingEnabled', response.data.data.holiday_gifting);
                                commit('setShiftDeliveryScheduleEnabled', response.data.data.early_defer);
                                commit('setSkipConsecutiveHolidayEnabled', response.data.data.change_plan);
                                dispatch('cookies/mountStripe', {}, { root: true });
                                resolve(response);
                            })
                            .catch(error => {
                                state.user.hasFetched = false;
                                console.error({ error });
                                reject(error);
                            });
                    } catch (err) {
                        Sentry.captureException(err);
                    }
                });
            }
        },

        fetchOutstandingOrders({ commit, state, rootGetters }) {
            let service = new UserService();

            return new Promise((resolve, reject) => {
                service.getOutstandingOrders(rootGetters['account/getUserId'])
                    .then(response => {
                        commit('updateOutstandingOrders', response.data);
                    })
                    .catch(error => reject(error));
            });
        },

        payOutstandingOrders({ commit, state, rootGetters }, payload) {
            let service = new UserService();

            return new Promise((resolve, reject) => {
                service.payOutstandingOrders(rootGetters['account/getUserId'], payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },

        fetchSkipDeliveryReasons({ commit }) {
            let service = new UserService();

            return new Promise((resolve, reject) => {
                service.fetchSkipDeliveryReasons()
                    .then(response => {
                        commit('updateSkipReasons', response.data.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        sendSkipDeliveryReasons({ commit }, data) {
            let service = new UserService();
            return new Promise((resolve, reject) => {
                service.sendSkipDeliveryReasons(data.subscriptionId, data.payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        async fetchCouponsHistory({ commit }) {
            let Service = new CouponsHistoryService();
            let history = await Service.getCouponsHistoryService();
            commit('updateCouponsHistory', history);
        },

        resetAccount({ commit }) {
            commit('resetState');
        },

        async fetchStripePaymentIntent({ commit }, payload) {
            let service = new UserService();
            commit('setFetchingPaymentIntent', true);
            let paymentIntent = await service.fetchStripePaymentIntent(payload);
            commit('setFetchingPaymentIntent', false);
            commit('setPaymentIntent', paymentIntent.data.intent_client_secret);
            commit('setPaymentIntentBraintree', paymentIntent.data.braintree_token.data);
        },

        async fetchClientToken({ commit }, payload) {
            let service = new UserService();
            commit('setFetchingClientToken', true);
            let clientToken = await service.fetchClientToken(payload);
            commit('setFetchingClientToken', false);
            commit('setClientToken', clientToken);
        },

        resetSignUpCouponCanvasser({ commit }) {
            commit('resetCouponState');
        },

        fetchPromotionalBanners({ commit, state, rootGetters, dispatch }, payload = null, forceReload = false) {
            let service = new UserService();
            return new Promise((resolve, reject) => {
                try {
                    service.fetchPromotionalBanners(payload)
                        .then(response => {
                            commit('setPromotionalBanners', response.data.data);
                            resolve(response);
                        })
                        .catch(error => {
                            console.error({ error });
                            reject(error);
                        });
                } catch (err) {
                    Sentry.captureException(err);
                }
            });
        },

        fetchThemes({ commit }) {
            let service = new UserService();
            return new Promise((resolve, reject) => {
                try {
                    service.fetchThemes()
                        .then(response => {
                            commit('setTheme', response.data.data);
                            resolve(response);
                        })
                        .catch(error => {
                            console.error({ error });
                            reject(error);
                        });
                } catch (err) {
                    Sentry.captureException(err);
                }
            });
        },

        async fetchUsersPaymentMethods({ commit, rootGetters }) {
            let service = new UserService();
            let userPaymentMethods = await service.fetchUsersPaymentMethods(rootGetters['account/getUserId']);
            commit('setUserPaymentMethods', userPaymentMethods.data);
        },

        async fetchSurveyForm({ commit, rootGetters }, payload) {
            let service = new UserService();
            let surveyForm = await service.fetchSurveyForm(payload);
            commit('setSurveyForm', surveyForm.data);
        },
    },
    getters: {
        getExpandFormName: (state) => state.expandFormName,

        getFirstName: (state, getters) => state.user?.hasFetched ? `${getters.getUser?.first_name && capitalizeFirstLetter(getters.getUser?.first_name)}` : null,

        getLastName: (state, getters) => state.user.hasFetched ? getters.getUser?.last_name : null,

        getEmail: (state, getters) => state.user.hasFetched ? getters.getUser?.email : null,

        getTelephone: (state, getters) => state.user.hasFetched ? getters.getUser?.telephone : null,

        getFullName: (state, getters) => {
            return state.user.hasFetched ? `${getters.getFirstName && capitalizeFirstLetter(getters.getFirstName)} ${getters.getLastName && capitalizeFirstLetter(getters.getLastName)}` : null;
        },

        getFlowerBondStatus: state => state.user.hasFetched && state.user.data?.bond_holder,

        getUser: state => state.user.hasFetched && state.user.data ? state.user.data : null,

        getOutstandingOrders: state => state.outstandingOrders,

        getUserProperty: (state) => (property = null) => {
            return state.user.hasFetched ? state.user.data[property] || state.user.data : null;
        },

        getUserBanners: state => state.user.hasFetched && state.user.banners?.data ? state.user.banners.data : null,

        getUserId: state => state.user.hasFetched && state.user.data ? state.user.data.id : null,

        getAllowPaused: state => state.user.hasFetched ? state.user.data?.allow_paused : false,

        getUserFriendCode: state => state.user.hasFetched ? state.user.data?.friend_code : false,

        getUserReferralsRemaining: state => state.user.hasFetched ? state.user.data?.referrals_remaining : null,

        getUserInvitesRemaining: state => state.user.hasFetched ? state.user.data?.invites_remaining : null,

        getRescheduledDeliveryDays: state => state.rescheduledDeliveryDays.data,

        getHasAnsweredCountrySwitch: state => state.countrySwitch.hasAnswered,

        getCoupons: state => state.user.hasFetched && state.user.data?.coupons,

        getCouponsHistory: state => state.CouponsHistory ? state.CouponsHistory : [],

        getGiftVouchers: state => state.giftVouchers,

        getSelfHostedUrl: state => state.user.hasFetched ? state.user.data.selfHostedUrl : null,

        getCourierPartnerForConfirmationSummary: state => state.user.courierPartnerForConfirmationSummary,

        getSignupCoupon: state => state.signupCoupon,

        getIsLilyFree: state => state.user.data.dislike_lilies,

        enableShop: state => (process.env.VUE_APP_SHOP_ENABLED === 'true') || (state.user.hasFetched && state.user.data.allow_shop),

        getPaymentIntent: state => state.paymentIntent,

        getPaymentIntentBraintree: state => state.paymentIntentBraintree,

        isfetchingPaymentIntent: state => state.fetchingPaymentIntent,

        getSetupIntent: state => state.clientToken?.data?.Stripe?.StripeIntentToken,

        getBraintreeClientToken: state => state.clientToken?.data?.BrainTree?.BraintreeClientToken?.data,

        isfetchingClientToken: state => state.fetchingClientToken,

        getLapserOptionsEnabled: state => state.user.data.change_plan_on_restart,

        getFourWeeklyRestart: state => state.user.data?.four_weekly_restart,

        getHasEssentialBox: state => state.user.data?.has_essential_box,

        getSkipReaasons: state => state.skipReasons,

        getSkipDeliveryPopupEnabled: state => state.user.data?.skipping_delivery_popup,

        getHolidayGiftingEnabled: state => state.holidayGiftingEnabled,

        getSkipConsecutiveHolidayEnabled: state => state.skipConsecutiveHolidayEnabled = true,

        getShiftDeliveryScheduleEnabled(state, getters, rootState, rootGetters) {
            const subscription = rootGetters['subscription/getActiveSubscription'];
            return state.shiftDeliveryScheduleEnabled && subscription && subscription.plan_interval !== 1;
        },

        getScrollbarEnabled: state => state.scrollbarEnabled,

        getShopInGiftingEnabled: state => state.user.data?.shop_enabled_in_gifting,

        getGiftingVaseEnabled: state => process.env.VUE_APP_MIX_GIFTING_ENABLE_VASE === 'true' && !state.user?.data?.shop_enabled_in_gifting,

        getSpecialDatesCoupon: state => state.user?.data?.special_dates,

        getSpecialDatesEnabled: state => (process.env.VUE_APP_SPECIAL_DATES_ENABLE === 'true' || state.user?.data?.special_dates_enabled),

        getBumperBoxEnabled: state => state.user.data?.change_primary_product_from_dashboard,

        getPrimaryProductInGiftingEnabled: state => state.user.data?.choose_primary_product_in_gifting,

        hasError: state => state.hasError,

        getEssentialBoxRestart: state => state.user.data?.essential_box_restart,

        isMonthlySubscriptionEnabled: state => state.user.data?.four_weekly_restart,

        showFloatingBanner: state => state.showFloatingBanner,

        getBannerHeading: state => state.bannerHeading,

        getBannerMessage: state => state.bannerMessage,

        getCanPauseOrCancel: state => state.canPauseOrCancel,

        getIsPauseFlow: state => state.isPauseFlow,

        getFieldsData: state => state.updateYourPlanData,

        getrestartFields: state => state.restartFields,

        getAllowCancel: state => state.user.hasFetched ? state.user.data?.allow_cancel : false,

        isThreeWeeklyEnabled: state => state.user.hasFetched ? state.user.data?.three_weekly_restart : false,

        getPauseFeedbackOptions: state => state.pauseFeedbackOptions,

        getPopSingleGift: state => state.user.data.pop_single_gifting,

        getPopMultiGift: state => state.user.data.pop_multi_gifting,

        getDashboardV2Enabled: state => state.user.data?.new_dashboard,

        getPromotionalBanners: state => state.promotionalBanners,

        isMobile: state => state.isMobile,

        isDesktop: state => state.isDesktop,

        isFreeVase: state => state.user?.data?.free_vase_gift_oneoff,

        getUserCanRefer: state => state.user.hasFetched && state.user.data?.can_refer,

        getUserHasPaidOrders: state => state.user.hasFetched && state.user.data?.having_paid_orders,

        getUserHasFailedLastPayment: state => state.user.hasFetched && state.user.data?.is_last_order_outstanding,

        getNextPossibleDate: state => state.user?.data?.next_possible_delivery_date,

        getLilyFamilyOptOutEnabled: state => state.user.data?.lily_family_opt_out,

        getUserType: state => state.user?.data?.user_type,

        getUserTypeId: state => state.user?.data?.user_type_id,

        isGifterAccount: state => state.user?.data?.user_type?.toLowerCase() === USER_TYPE_GIFTER,

        isSubscriberAccount: state => state.user?.data?.user_type?.toLowerCase() === USER_TYPE_SUBSCRIBER,

        isNewGiftingFlow: state => state.user?.data?.new_gifting_journey_customer,

        giftDeliveryDates: state => state.user?.data?.gift_delivery_dates || [],

        getThemes: state => state.themes,

        getGifterToSubscriberUpgradeCoupon: state => state.user?.data?.gift_to_subscriber_upgrade_coupon,

        getUserSchedule: state => state.user?.data?.schedule,

        isDeliveryOptIn: state => state.user?.data?.dashboard_delivery_opt_in,

        isBringForward: state => state.user?.data?.dashboard_bring_forward,

        experiments: state => state.user?.data?.experiments,

        segments: state => state.user?.data?.segments,

        getUserPaymentMethod: state => state.user?.data?.payment_method,

        getUserPaymentMethods: state => state.userPaymentMethods,

        isCalendarV2: state => state.user?.data?.calendar_toggle_v2,

        isCalendarGalleryV2: state => state.user?.data?.calendar_gallery_v2,

        getSurveyForm: state => state?.surveyForm?.survey_template_data || {},

        getSurveyId: state => state.surveyForm && state.surveyForm.survey_id,

        getIsFormSubmitted: state => state.isFormSubmitted,

        isZendeskEnabled: () => process.env.VUE_APP_ENABLE_ZENDESK === 'true',

        isFourWeeklyPlanSwitchEnabled: state => state.user?.data?.four_weekly,

        isThreeWeeklyPlanSwitchEnabled: state => state.user?.data?.three_weekly,

        getDashboardOverlayBanner: state => state.user.hasFetched && state.user?.data?.dashboard_overlay_banner,

        isRestartV2: state => state.user?.data?.restart_v2,

        restartPath: state => state.user?.data?.restart_v2 ? 'account.restart-v2-subscription' : 'account.restart',

        isSubscriptionManagementPageEnabled: state => state.user?.data?.subscription_management,

        cardEnding: state => state.user?.data?.card_ending,
    },
};
