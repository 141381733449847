export const prefillZDForm = (name, email, isACustomer) => {
    zE('webWidget', 'updateSettings', {
        webWidget: {
            contactForm: {
                // The id's and names of the fields are subject to changes in Ticket fields' value placeholders (tags) on Zendesk-Admin center!
                fields: [
                    { id: 'subject', prefill: { '*': name } },
                    { id: 'email', prefill: { '*': email } },
                    { id: 9654700202257, prefill: { '*': isACustomer && 'widget_active_customer' } },
                ],
            },
        },
    });
};
