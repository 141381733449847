<template>
    <div class="banner">
        <div :class="topBannerClass">
            <slot></slot>
            <div v-if="enableBottomDivider" class="divider-white--bottom"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBanner',
    props: {
        bannerImage: {
            required: true,
            type: String,
        },
        enableBottomDivider: {
            default: true,
            type: Boolean,
        },
    },
    computed: {
        topBannerClass() {
            return `divider--container banner__container banner__container--${this.bannerImage}`;
        },
    },
};
</script>
