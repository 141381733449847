import store from '@/store';

export default function(to, from, next) {
    if (store.getters['auth/isUser'] && to.name === 'subscriptions.account-confirmation') {
        next();
    } else if (store.getters['auth/isUser'] && to.name.includes('subscriptions') && to.name !== 'subscriptions.account-confirmation') {
        next({ name: 'account.dashboard' });
    } else if (to.name === 'subscriptions.account' && !store.getters['auth/isCanvasser']) {
        next({ name: 'subscriptions.checkout.your-details' });
    } else {
        next();
    }
}
