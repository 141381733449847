import GetSpecialDates from '@/services/users/SpecialDatesService/getSpecialDates';
import CreateSpecialDates from '@/services/users/SpecialDatesService/createSpecialDates';
import UpdateSpecialDates from '@/services/users/SpecialDatesService/updateSpecialDates';
import DeleteSpecialDates from '@/services/users/SpecialDatesService/deleteSpecialDates';
import GetOccasionTypes from '@/services/users/SpecialDatesService/GetOccasionTypes';

export default class SpecialDatesService {
    async getSpecialDates(userId) {
        return (new GetSpecialDates()).handle(userId);
    }
    async getOccasionTypes() {
        return (new GetOccasionTypes()).handle();
    }
    async createSpecialDates(payload) {
        return (new CreateSpecialDates()).handle(payload);
    }
    async updateSpecialDates(occasionId, payload) {
        return (new UpdateSpecialDates()).handle(occasionId, payload);
    }
    async deleteSpecialDates(occasionId) {
        return (new DeleteSpecialDates()).handle(occasionId);
    }
}
