import store from '@/store';

const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';
const GAEnabled = isGBR || store.getters['cookies/isGTMMounted'];

function gtg() {
    if (dataLayer.push) dataLayer.push(arguments);
};

export const gtmConsent = (type = 'default', payload) => {
    gtg('consent', type, payload);
};

export const gaEngagement = (action, label) => {
    gtg('event', action, {
        event_category: 'engagement',
        event_label: label,
    });
};

export const gaConversion = (action, sendTo, txnID) => {
    gtg('event', action, {
        send_to: sendTo || process.env.VUE_APP_GOOGLE_ANALYTICS_SEND_TO,
        transaction_id: txnID,
    });
};

export const pauseSubscription = (stepAttempt, pauseReason) => {
    GAEnabled && dataLayer.push({
        event: 'pauseSubscription',
        pause: {
            step: 'attempt',
            reason: pauseReason,
            resolution: stepAttempt,
        },
    });
};

const setCustomVariables = (customVariables, scope) => {
    let entries = Object.entries(customVariables);

    // Pushing Custom variables to dataLayer (for GTM)
    entries.map((entry) => {
        GAEnabled && dataLayer.push({ [entry[0]]: entry[1] });
    });
};

export const CSPageEvents = (eventName, customVariables, scope = 'visit') => {
    window._uxa = window._uxa || [];
    if (eventName) window._uxa.push(['trackPageEvent', eventName]);
    if (customVariables) {
        setCustomVariables(customVariables, scope);
    }

    // Pushing specific Custom variables to dataLayer (for GTM)
    if (GAEnabled) {
        dataLayer.push({ 'country': process.env.VUE_APP_COUNTRY_ISO });
        dataLayer.push({ 'userType': store.getters['account/getUserType'] });
        dataLayer.push({ 'segments': store.getters['account/segments']?.join(', ') });
        dataLayer.push({ 'experimentHandlers': store.getters['account/experiments']?.map(experiment => experiment.group_handler)?.join(', ') });

        // frequencies
        let subscriptionFrequencies = store.getters['subscription/getSubscriptions']?.map(subscription => subscription.status.toLowerCase() === 'active' && subscription.plan_slug).join(', ');
        dataLayer.push({ 'subscriptionFrequency': subscriptionFrequencies });

        // toggles
        let isCalendarV2 = store.getters['account/isCalendarV2'];
        let isCalendarGalleryV2 = store.getters['account/isCalendarGalleryV2'];
        let isSubsPage = store.getters['account/isSubscriptionManagementPageEnabled'];
        let toggles = [];
        isCalendarV2 && toggles.push('calendar_toggle_v2');
        isCalendarGalleryV2 && toggles.push('calendar_gallery_v2');
        isSubsPage && toggles.push('subscription-management');
        dataLayer.push({ 'toggle': toggles?.join(', ') });
    }
};

export const CSPageView = (url) => {
    window._uxa = window._uxa || [];
    window._uxa.push(['trackPageview', url]);
};

export const WR8Events = (eventName, payload) => {
    let options = {
        subscription: { customerEmail: payload.customerEmail },
        purchase: { purchaseAmount: payload.purchaseAmount },
    };
    eight('event', eventName, options[eventName]);
};

export const tikTokEvents = (payload) => {
    ttq.track('AddToCart', {
        content_name: payload.name,
        quantity: payload.total_quantity_per_items,
        price: payload.unit_member_price,
        currency: process.env.VUE_APP_CURRENCY,
    });
};

export const fbqPurchaseEvents = (payload) => {
    if (window.fbq) {
        fbq('track', 'Purchase', {
            content_category: payload.name,
            content_id: payload.id,
            value: payload.sell_price,
            currency: store.getters['localisation/getCurrencyCode'],
            content_name: payload.name,
            content_type: payload.content_type === 'Subscription' ? payload.content_type : 'Gift',
        });
    }
};

export const AWINTracking = (guestUserId, currencyCode, coupon, totalPrice, awinType) => {
    let awPixel = new Image(0, 0);
    awPixel.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${process.env.VUE_APP_AWIN_KEY}&amount=${totalPrice}&ch=aw&parts=${awinType}:${totalPrice}&ref=${guestUserId}${coupon ? '&vc=' + coupon : '&vc='}&cr=${currencyCode}&testmode=0`;
    document.body.appendChild(awPixel);

    if (window.AWIN && window.AWIN.Tracking) {
        window.AWIN.Tracking.Sale = {};
        window.AWIN.Tracking.Sale.amount = totalPrice;
        window.AWIN.Tracking.Sale.channel = 'aw';
        window.AWIN.Tracking.Sale.orderRef = guestUserId;
        window.AWIN.Tracking.Sale.parts = `${awinType}:${totalPrice}`;
        window.AWIN.Tracking.Sale.currency = currencyCode;
        window.AWIN.Tracking.Sale.voucher = coupon || '';
        window.AWIN.Tracking.Sale.test = process.env.VUE_APP_ENV === 'Production' ? '0' : '1';
        window.AWIN.Tracking.run();
    }
};

export const RulerThankYouPageTracking = (payload) => {
    try {
        if (!RulerAnalyticsVisitorId) {
            console.log('Ruler Analytics is not set on the page');
        }

        const RulerAnalyticsPayload = {
            action: 'convert',
            userType: payload.userType || 'NA',
            userID: payload.userId || 'NA',
            giftReference: payload.giftReference || 'NA',
            selectedGiftProductName: payload.selectedGiftProductName || 'NA',
            familyDisplayName: payload.selectedGiftProductFamilyName || 'NA',
            frequency: payload.selectedGiftProductFrequency || 'NA',
            totalPrice: payload.totalPrice || 'NA',
            GiftType: payload.selectedGiftType || 'NA',
            coupon: payload.coupon || 'NA',
            country: payload.country || 'NA',
        };

        RulerAnalytics.trackConversionAsync(RulerAnalyticsPayload, '', '', 500);
    } catch (error) {
        console.log(error);
    }
};

// eventName to be a string and eventData to be a non-empty array
export const gaEComEvent = (eventName, eventData, extraData = null) => {
    let payload = extraData ? { ...extraData, items: eventData } : { items: eventData };
    GAEnabled && dataLayer.push({ ecommerce: null });
    GAEnabled && dataLayer.push({ event: eventName, ecommerce: payload });
};
