
<template>
    <div class='primer-form'>
        <loader v-if="loading" />

        <div v-show="!loading" class="form-input-wrapper">
            <div class="text-center">
                <div class="padlock">
                    <img src="@/assets/images/decoration/icons/Padlock-black.svg" alt="secure payments image">
                    <p v-text="$t('PRIMER_NOW_FOR_PAYMENT_DETAILS')"/>
                </div>
            </div>
            <div class="other-options">
                <div id="pay-with-paypal"></div>
                <!-- For future use of apply/gpay from Primer -->
                <!-- <div v-if="applePayEnabled" id="pay-with-apple-pay"></div> -->
                <!-- <div v-if="googlePayEnabled" id="pay-with-google-pay"></div> -->
                <!-- <div class="pay-with-card">
                    <div class="card-logos">
                        <p v-text="$t('PRIMER_OR_USE_CARD')"></p>
                        <img src="@/assets/images/icons/visa-icon.svg" alt="visa card logo">
                        <img src="@/assets/images/icons/mastercard-icon.svg" alt="Master card logo">
                    </div>
                </div> -->
            </div>

            <div class="card-wrapper" :class="{'hide-card': hideCard}">
                <div class="card-input">
                    <p v-text="$t('PRIMER_CARD_NAME_ON_CARD')"></p>
                    <input type="text" required class="input__field" v-model="cardName" />
                </div>

                <div class="card-input">
                    <p class="margin-top" v-text="$t('PRIMER_CARD_NUMBER')"></p>
                    <div class='form-input' :class="inputState.card.state" id='number-input'></div>
                    <p class="error-message"
                        v-if="inputState.submitButton.state === 'error' && inputState.card.error"
                        v-html="inputState.card.error" />
                </div>

                <div class="expiry-ccv-inputs">
                    <div class="expiry-input">
                        <p class="margin-top" v-text="$t('PRIMER_CARD_EXPIRY')"></p>
                        <div class='form-input' :class="inputState.expiry.state" id='date-input'></div>
                        <p class="error-message"
                            v-if="inputState.submitButton.state === 'error' && inputState.expiry.error"
                            v-html="inputState.expiry.error" />
                    </div>

                    <div class="ccv-input">
                        <p class="margin-top" v-text="$t('PRIMER_CARD_SECURITY_CODE')"></p>
                        <div class='form-input' :class="inputState.ccv.state" id='cvv-input'></div>
                        <p class="error-message"
                            v-if="inputState.submitButton.state === 'error' && inputState.ccv.error"
                            v-html="inputState.ccv.error" />
                    </div>
                </div>
            </div>

            <p v-if="errorMessage" class="margin-top error-message" v-text="$t('PRIMER_CARD_A_PROBLEM')"></p>

            <button
                :class="{'hide-card': hideCard}"
                id='pay-now-button'
                class="btn btn--primary btn--full-width margin-top"
                :disabled="inputState.submitButton.state === 'submitted'">
                <i v-if="inputState.submitButton.state === 'submitted'" class="btn__loading-icon fa-spin"/>
                    {{submitButtonText}}
            </button>
        </div>

        <div v-show="display3ds" id="threeds-modal"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrimerService from '@/services/payments/PrimerService';
import Loader from '@/components/structure/Loader.vue';
import CountryService from '@/services/logistics/CountryService';
import errorMessages from '@/resources/primerErrorTranslations.json';

const primerService = new PrimerService();

export default {
    name: 'PrimerPaymentMethodForm',
    components: {
        Loader,
    },
    props: {
        showSupportedCards: {
            required: false,
            default: false,
        },
        submitButtonText: {
            required: false,
            default: 'Update',
        },
        showCancelButton: {
            required: false,
            default: true,
        },
        orderValue: {
            required: true,
        },
        hideCard: {
            default: false,
        },
    },
    data() {
        return {
            client: null,
            cardName: null,
            country: null,
            display3ds: false,
            errorMessage: '',
            loading: true,
            inputState: {
                card: {
                    state: 'untouched',
                    error: null,
                },
                name: {
                    state: 'untouched',
                    error: null,
                },
                expiry: {
                    state: 'untouched',
                    error: null,
                },
                ccv: {
                    state: 'untouched',
                    error: null,
                },
                submitButton: {
                    state: 'untouched',
                },
            },
            renderConfig: null,
        };
    },
    mounted() {
        this.country = CountryService.findCountryByIso(process.env.VUE_APP_COUNTRY_ISO);

        primerService.fetchClientToken(this.email).then((token) => {
            this.cardName = this.fullName ? this.fullName : this.registerFullName;
            this.initPrimerCheckout(token.data.clientToken);
        }).catch(() => this.errorMessage = true);

        this.$root.$on('payment-error', (error) => {
            this.errorMessage = error;
            this.inputState.submitButton.state = 'error';
        });
    },
    methods: {
        async initPrimerCheckout(clientToken) {
            this.client = new Primer({
                credentials: { clientToken },
            });

            await this.client.render(this.getRenderConfig());

            this.setGooglePayStyles();

            this.loading = false;
        },

        async onCardTokenized(paymentMethod) {
            this.errorMessage = null;

            if (this.shouldPerform3DS(paymentMethod)) {
                this.perform3DS(paymentMethod);
            } else {
                this.closeCardForm(paymentMethod);
            }
        },

        async perform3DS(paymentMethod) {
            const response = await this.client.threeDSecure.verify({
                token: paymentMethod.token,
                container: '#threeds-modal',
                onChallengeStart: () => this.display3ds = true,
                onChallengeEnd: () => this.display3ds = false,
                order: this.threeDSecureOptions.order,
            });

            const authorizationStatus = response.authentication ? response.authentication.responseCode : response.status;
            const tokenData = response.token ? response.token : response.data;

            if (authorizationStatus === Primer.ThreeDSecureStatus.FAILED) {
                this.errorMessage = true;
                this.inputState.submitButton.state = 'error';
            } else {
                this.closeCardForm(tokenData);
            }
        },

        closeCardForm(payload) {
            const paymentTypes = {
                'PAYMENT_CARD': 'card',
                'PAYPAL_BILLING_AGREEMENT': 'paypal',
                'GOOGLE_PAY': 'google',
            };

            payload.payment_method = paymentTypes[payload.paymentInstrumentType];
            payload.gateway = 'Primer';

            this.$root.$emit('close-card-form', payload);
        },

        shouldPerform3DS(paymentMethod) {
            const isEnabled = process.env.VUE_APP_3DS_ENABLED === 'true';
            const isNotPayPal = paymentMethod.paymentInstrumentType !== 'PAYPAL_BILLING_AGREEMENT';

            return isEnabled && isNotPayPal;
        },

        handleTokenFailed() {
            this.$scrollTo('#pay-now-button', 0, { offset: -300 });
            this.errorMessage = true;
            this.inputState.submitButton.state = 'error';
        },

        getRenderConfig() {
            return {
                locale: process.env.VUE_APP_I18N_LOCALE,
                paypal: this.getPayPalConfig(),
                applePay: this.getApplePayConfig(),
                googlePay: this.getGooglePayConfig(),
                card: this.getCardConfig(),
                countryCode: process.env.VUE_APP_COUNTRY_ISO.toUpperCase().substr(0, 2),
                purchaseInfo: {
                    totalAmount: {
                        currency: process.env.VUE_APP_CURRENCY,
                        value: this.orderValue,
                    },
                },

                onTokenizeSuccess: this.onCardTokenized,
                onTokenizeStart: () => {
                    this.inputState.submitButton.state = 'submitted';
                },
                onTokenizeError: () => {
                    this.errorMessage = true;
                    this.inputState.submitButton.state = 'error';
                },
            };
        },

        getCardConfig() {
            return {
                css: `input {
                    font-family: system-ui;
                    font-weight: 200;
                    font-size: 20px;
                    padding-left: 8px;
                }`,
                submitButton: '#pay-now-button',
                cardholderName: this.cardName,
                fields: {
                    cardNumber: {
                        container: '#number-input',
                        placeholder: '1234 1234 1234 1234',
                        onChange: (res) => {
                            this.inputState.card.error = this.getErrorMessage(res.meta) || null;
                            this.inputState.card.state = this.getInputFieldState(res.meta);
                        },
                    },
                    expiryDate: {
                        container: '#date-input',
                        placeholder: '02/24',
                        onChange: (res) => {
                            this.inputState.expiry.error = this.getErrorMessage(res.meta) || null;
                            this.inputState.expiry.state = this.getInputFieldState(res.meta);
                        },
                    },
                    cvv: {
                        container: '#cvv-input',
                        placeholder: '123',
                        onChange: (res) => {
                            this.inputState.ccv.error = this.getErrorMessage(res.meta) || null;
                            this.inputState.ccv.state = this.getInputFieldState(res.meta);
                        },
                    },
                },
            };
        },

        getErrorMessage(errorDetails) {
            const errors = errorMessages.find((item) => item.locale === process.env.VUE_APP_I18N_LOCALE);
            const errorMessage = errors && errorDetails.errorCode && errors.errors[errorDetails.errorCode] ? errors.errors[errorDetails.errorCode] : errorDetails.error;
            return errorMessage;
        },

        getPayPalConfig() {
            return {
                container: '#pay-with-paypal',
                buttonColor: 'gold', // gold, blue, silver, white, black
                buttonShape: 'rect', // rect, pill
                buttonSize: 'medium', // small, medium, large, responsive
                buttonLabel: 'paypal', // checkout, credit, pay, buynow, paypal, installment
                paymentFlow: 'PREFER_VAULT',
                buttonHeight: 55,
            };
        },

        getApplePayConfig() {
            return !this.applePayEnabled ? null : {
                container: '#pay-with-apple-pay',
                buttonType: 'plain', // 'plain' 'buy'  'set-up' 'donate' 'check-out' 'book' 'subscribe';
                buttonStyle: 'black', // 'white' | 'white-outline' | 'black';
            };
        },

        getGooglePayConfig() {
            return !this.googlePayEnabled ? null : {
                container: '#pay-with-google-pay',
                buttonType: 'long', // "long" | "short";
                buttonColor: 'default', // "default" | "black" | "white";
            };
        },

        setGooglePayStyles() {
            if (this.googlePayEnabled) {
                let ele = document.getElementsByClassName('gpay-card-info-container')[0];
                ele.style.width = '100%';
                ele.style.height = '50px';
            }
        },

        getInputFieldState(meta) {
            if (!meta.dirty) {
                return 'untouched';
            }

            return meta.valid ? 'success' : 'error';
        },
    },
    computed: {
        ...mapGetters({
            fullName: 'account/getFullName',
            registerFullName: 'register/getFullName',
            lastName: 'register/getFirstName',
            firstName: 'register/getLastName',
            accountEmail: 'account/getEmail',
            registerEmail: 'register/getEmail',
            address: 'address/getBillingAddress',
            deliveryAddress: 'register/getDeliveryAddress',
            uuid: 'register/getIncompleteRegistrantUuid',
            activeSubscriptionId: 'subscription/getActiveSubscriptionId',
        }),
        email() {
            return this.accountEmail || this.registerEmail;
        },
        billingAddress() {
            return !this.address ? this.deliveryAddress : this.address;
        },
        primerBillingAddressMap() {
            return {
                firstName: this.billingAddress.first_name || this.firstName,
                lastName: this.billingAddress.last_name || this.lastName,
                addressLine1: this.billingAddress.address1 || this.billingAddress.addressLine1,
                addressLine2: this.billingAddress.address2 || this.billingAddress.addressLine2,
                city: this.billingAddress.city,
                countryCode: process.env.VUE_APP_COUNTRY_ISO.toUpperCase().substr(0, 2),
                postalCode: this.billingAddress.postcode,
            };
        },
        threeDSecureOptions() {
            return {
                order: {
                    orderId: this.uuid || this.activeSubscriptionId || new Date(),
                    email: this.email,
                    billingAddress: this.primerBillingAddressMap,
                    amount: {
                        currency: process.env.VUE_APP_CURRENCY,
                        value: parseFloat(this.orderValue / 100).toFixed(2),
                    },
                },
            };
        },
        applePayEnabled() {
            return localStorage.getItem('apple-pay-enabled') || process.env.VUE_APP_APPLE_PAY_ENABLED === 'true';
        },
        googlePayEnabled() {
            return localStorage.getItem('google-pay-enabled') || process.env.VUE_APP_GOOGLE_PAY_ENABLED === 'true';
        },
    },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/sass/brand/theme.scss';

.primer-form {
    margin: 0 auto;
    width: 100%;
    max-width: 480px;
    padding: 20px;

    .form-input-wrapper {
        text-align: left;

        .text-center {
            margin: 0 auto;
        }

        .padlock {
            display: inline-flex;
            align-items: center;
            gap: 5px;

            p {
                line-height: 31px;
            }
        }

        .form-input {
            height: 50px;
            padding: 10px;
        }

        .form-input + .form-input {
            margin-top: 15px;
        }

        .expiry-ccv-inputs {
            display: inline-flex;
            gap: 15px;

            .expiry-input, .ccv-input {
                display: inline-block;
            }
        }

        .error {
            border: 1px solid $error-color;
        }

        .error-message {
            color: $error-color;
        }

        .success {
            border: 1px solid $success-color;
        }

        .untouched {
            border: 1px solid $primary-color;
        }

        .other-options {

            .pay-with-card {
                text-align: center;
                margin-top: 25px;

                .card-logos {
                    margin: 0 auto;
                    display: inline-flex;
                    gap: 20px;

                    p {
                        line-height: 36px;
                    }

                    img {
                        height: 55px;
                        margin-top: 15px;
                    }
                }
            }

            #pay-with-paypal, #pay-with-google-pay {
                height: 50px;
                border-radius: 0.5rem;
                overflow: hidden;
            }

            #pay-with-google-pay {
                margin-top: 20px;
            }

            #pay-with-card:hover {
                background-color: #3baae4;
            }
        }
    }

    #threeds-modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100%;
        height: 100%;
        background-color: $light-color;
    }

}
.hide-card {
    display: none;
}
.margin-top {
    margin-top: 1.25rem;
}
</style>
