import store from '@/store';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class Logout {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle() {
        return this.apiService.post(ApiEndpoints['auth.logout']);
    }

    frontendLogout() {
        store.dispatch('auth/logout');
    }
}
