const isGBR = process.env.VUE_APP_COUNTRY_ISO === 'GBR';

export const PLAN_WEEKLY = 'weekly';
export const PLAN_FORTNIGHTLY = 'fortnightly';
export const PLAN_THREE_WEEKLY = 'three-weekly';
export const PLAN_MONTHLY = 'monthly';
export const PLANS_ALLOWED = [
    PLAN_WEEKLY,
    PLAN_FORTNIGHTLY,
    PLAN_MONTHLY,
];

export const GIFT_TYPE_SUBSCRIPTION = 'subscription';
export const GIFT_TYPE_ONE_OFF = 'one-off';
export const GIFT_TYPE_SINGLE = 'single-gift';
export const GIFT_TYPE_MULTI = ' multi-gift';
export const SPECIAL_DATES_GIFT_COUPON = 'single+multi';

export const SECTION_CHOOSE_A_GIFT = 'chooseAGift';
export const SECTION_CHOOSE_A_BOX_TYPE = 'chooseABoxType';
export const SECTION_NUMBER_OF_DELIVERIES = 'numberOfDeliveries';
export const SECTION_DELIVERY_ADDRESS = 'deliveryAddress';
export const SECTION_FREDDIES_ADDONS = 'freddiesAddons';
export const SECTION_FREDDIES_SHOP = 'freddiesShop';
export const SECTION_DELIVERY_DATE = 'deliveryDate';
export const SECTION_GIFT_CARD = 'giftCardCompleted';

export const PRODUCT_TYPE_SINGLE = 'oneDelivery';
export const PRODUCT_TYPE_BUMPER_SINGLE = 'bumperSingle';
export const PRODUCT_TYPE_MULTI = 'multipleDeliveries';
export const PRODUCT_TYPE_BUMPER_MULTI = 'bumperMulti';
export const PRODUCT_TYPE_POP_SINGLE = 'popSingle';
export const PRODUCT_TYPE_POP_MULTI = 'popMulti';

export const PRODUCT_FAMILY_BUMPER = 'bumper';
export const PRODUCT_FAMILY_STANDARD = 'standard';
export const PRODUCT_FAMILY_LITE = 'lite';

export const DISPLAY_NAME_BUMPER = isGBR ? 'Showstopper' : 'Showstopper';
export const DISPLAY_NAME_STANDARD = isGBR ? 'Classic' : 'Klassisch';
export const DISPLAY_NAME_LITE = isGBR ? 'Letterbox' : 'Mini';

export const FREE_HURRICANE_VASE = 'NP Hurricane vase - free';
export const USER_TYPE_GUEST = 'guest';
export const USER_TYPE_GIFTER = 'gifter';
export const USER_TYPE_SUBSCRIBER = 'subscriber';

export const UNDELIVERABLE_TYPE_CLASSIC = 'data';
export const UNDELIVERABLE_TYPE_BUMPER = 'bumper';
export const UNDELIVERABLE_TYPE_LITE = 'lite';

export const GIFT_COUPON_TYPE = 'gift-product';

export const GIFT_VOUCHER_DELIVERY_DIGITAL = 'digital';
export const GIFT_VOUCHER_DELIVERY_PHYSICAL = 'physical';
export const PRODUCT_TYPE_VOUCHER = 'voucher-fixed';

export const DEFAULT_SUBSCRIPTION_PRODUCT = 'classic';
export const POPULAR_MULTI_GIFT_PLAN = isGBR ? '3 boxes over 3 months' : '3 Boxen über 3 Monate';

export const AWIN_GUEST_ONE_OFF = 'SALENEW';
export const AWIN_GUEST_SUBSCRIPTION = 'SALEGSUBNEW';
export const AWIN_CUSTOMER_ONE_OFF = 'SALEEXISTING';
export const AWIN_CUSTOMER_SUBSCRIPTION = 'SALESUBEXISTING';
