import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';

export default class FetchPrices {
    apiService

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = ApiEndpoints['gifting.prices.index'];

        return this.apiService.get(apiUrl);
    }
}
