import uniqBy from 'lodash/uniqBy';
import FaqSearch from '@/services/faq/faqService/FaqSearch';
import FaqAnswers from '@/services/faq/faqService/FaqAnswers';
import FaqCategories from '@/services/faq/faqService/FaqCategories';
import FaqPayment from '@/services/faq/faqService/pages/FaqPayment';
import FaqDelivery from '@/services/faq/faqService/pages/FaqDelivery';
import FaqIntroductions from '@/services/faq/faqService/pages/FaqIntroductions';

export default class FaqService {
    async getCategories() {
        let categories = [];

        await new FaqCategories().handle().then(response => {
            categories = response;
        }).catch(err => {
            console.error(err);
        });

        return categories.items;
    }

    getQuestions() {
        return new FaqAnswers().handle();
    }

    async getUniqQuestions() {
        let questions = [];

        await this.getQuestions().then(response => {
            questions = response;
        }).catch(err => {
            console.error(err);
        });

        return uniqBy(questions, (answer) => [answer.title, answer.loggedIn].join());
    }

    getSearchQuestions() {
        return new FaqSearch().handle();
    }

    getAnswersByPath(path) {
        return new FaqAnswers().findAnswersByPath(path);
    }

    getCategoryByPath(path) {
        return new FaqCategories().findCategoryByPath(path);
    }

    async getPaymentQuestions() {
        let questions = [];

        await (new FaqPayment()).handle().then(response => {
            questions = response.items;
        }).catch(err => {
            console.error(err);
        });

        return questions;
    };

    async getDeliveryQuestions() {
        let questions = [];

        await new FaqDelivery().handle().then(response => {
            questions = response.items;
        }).catch(err => {
            console.error(err);
        });

        return questions;
    };

    async getIntroductionsQuestions() {
        let questions = [];

        await new FaqIntroductions().handle().then(response => {
            questions = response.items;
        }).catch(err => {
            console.error(err);
        });

        return questions;
    }
}
