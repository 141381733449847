<template>
    <div :class="inputClass" :style="fieldContainerStyle">
        <label
            v-if="label"
            :for="`${_uid}-input`"
            class="input__label"
            :style="{ fontSize: labelFontSize, fontWeight: labelFontWeight, lineHeight: labelLineHeight, margin: labelMargin }"
            :class="{ 'required-input': showMandatory, 'required-input-primary': showMandatoryInPrimaryColor }"
            v-text="label"/>
        <input
            :id="`${_uid}-input`"
            name="name"
            :placeholder="placeholder"
            v-model="val"
            :autocomplete="autocomplete"
            type="text"
            @keyup.prevent="() => $emit('input-entered')"
            :style="inputFieldStyle"
            class="input__field"/>
        <p class="input__message error-bold"
            :style="inputMessageStyle"
            v-if="message"
            v-text="$t(splitMessage)"/>
    </div>
</template>

<script>
export default {
    name: 'InputField',
    data: () => {
        return {
            val: null,
        };
    },
    props: {
        autocomplete: {
            required: false,
            default: null,
        },
        value: {
            required: true,
        },
        label: {
            default: null,
            type: String,
        },
        labelFontSize: {
            type: String,
            default: '1rem',
        },
        labelFontWeight: {
            type: String,
            default: '300',
        },
        labelLineHeight: {
            type: String,
            default: '32.5px',
        },
        labelMargin: {
            type: String,
            default: '0.25rem',
        },
        fieldContainerStyle: {
            type: Object,
            default: () => {},
        },
        inputFieldStyle: {
            type: Object,
            default: () => {},
        },
        inputMessageStyle: {
            type: Object,
            default: () => {},
        },
        message: {
            required: false,
            type: String,
            default: null,
        },
        isValid: {
            default: true,
            type: Boolean,
        },
        noPadding: {
            default: false,
            type: Boolean,
        },
        mandatory: {
            default: false,
            type: Boolean,
        },
        showRequiredMark: {
            type: Boolean,
            default: true,
        },
        showRequiredMarkInPrimaryColor: Boolean,
        placeholder: {
            default: '',
            type: String,
        },
    },
    computed: {
        showMandatory() {
            return this.mandatory && this.showRequiredMark && !this.showRequiredMarkInPrimaryColor;
        },
        showMandatoryInPrimaryColor() {
            return this.mandatory && this.showRequiredMarkInPrimaryColor;
        },
        inputClass() {
            let noPadding = this.noPadding ? 'noPadding' : '';
            if (this.isValid !== false && (this.value === null || this.value === undefined)) {
                return `input ${noPadding}`;
            }
            if (!this.mandatory) {
                return this.val?.length ? `input input--success ${noPadding}` : `input ${noPadding}`;
            }

            return this.isValid && this.val ? `input input--success ${noPadding}` : `input input--invalid ${noPadding}`;
        },
        splitMessage() {
            return this.message?.includes(',') ? this.message?.split(',')[0].trim() : this.message;
        },
    },
    watch: {
        val(value) {
            this.$emit('input', value);
        },
        value: {
            immediate: true,
            handler(value) {
                this.val = value;
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/brand/theme.scss';

.noPadding {
    padding: 0;
}

.error-bold {
    @include small-bold();
    margin: 0;

    color: $error-color;
}
</style>
