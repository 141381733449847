import { url } from '@/helpers/UrlHelpers';
import ApiService from '@/services/ApiService';
import ApiEndpoints from '@/config/ApiEndpoints';
import store from '@/store';
import { getVariant, getDeliveryPrice, isFirstDeliveryFree, getPriceOverrideParams } from '@/helpers/QueryHelpers';

export default class GetCouponByCouponCode {
    apiService;

    constructor() {
        this.apiService = new ApiService(process.env.VUE_APP_API_URL);
    }

    handle(coupon, giftProductId = null, userId = null) {
        let params = {
            delivery_amount: getDeliveryPrice(),
            mode: getVariant(),
            free_first_delivery: isFirstDeliveryFree(),
            email: store.getters['register/getEmail'],
            user_id: userId,
        };
        let overrideParams = getPriceOverrideParams();
        if (overrideParams) {
            params.fam = overrideParams.fam;
            params.pov = overrideParams.pov;
        }
        if (giftProductId) params.gift_product_id = giftProductId;
        let apiUrl = url(ApiEndpoints['coupons.show'], [coupon], params);

        return this.apiService.get(apiUrl);
    }
}
