import FetchProducts from '@/services/shop/FetchProducts';
import FetchOrderSummary from '@/services/shop/FetchOrderSummary';
import UpdateOrder from '@/services/shop/UpdateOrder';
import RemoveFromOrder from '@/services/shop/RemoveFromOrder';
import GetNextDeliveryDate from '@/services/shop/GetNextDeliveryDate';

export default class ShopService {
    fetchProducts(payload) {
        return (new FetchProducts()).handle(payload);
    }
    fetchOrderSummary(subscriptionId) {
        return (new FetchOrderSummary()).handle(subscriptionId);
    }
    updateOrder(subscriptionId, products) {
        return (new UpdateOrder()).handle(subscriptionId, products);
    }
    removeFromOrder(subscriptionId, productId) {
        return (new RemoveFromOrder()).handle(subscriptionId, productId);
    }
    getNextDeliveryDate(subscriptionId) {
        return (new GetNextDeliveryDate()).handle(subscriptionId);
    }
}
