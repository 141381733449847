export default [
    {
        path: '/login',
        name: 'login',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/canvasser/Login'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
    },
    {
        path: '/logout',
        name: 'logout',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Logout'),
            topNavigation: () => import(/* webpackChunkName: "top-navigation" */ '@/components/structure/navigation/TopCanvasserNavigation'),
        },
    },
];
