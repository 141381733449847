import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class CreateHoliday {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = prepareUrl(ApiEndpoints['subscriptions.holiday.store'], [payload.subscriptionId]);
        let parameters = {
            delivery_date: payload.deliveryDate,
        };
        if (payload.hasholidayBox) {
            parameters.gifting_address_id = payload.gifting_address_id;
            parameters.message_body = payload.message_body;
            parameters.message_from = payload.message_from;
            parameters.message_to = payload.message_to;
            parameters.mobile_number = payload.mobile_number;
        }

        if (payload.hasHolidayBox) {
            parameters.gifting_address_id = payload.gifting_address_id;
            parameters.message_body = payload.message_body;
            parameters.message_from = payload.message_from;
            parameters.message_to = payload.message_to;
            parameters.mobile_number = payload.mobile_number;
        }

        return this.apiService.post(apiUrl, parameters);
    }
}
