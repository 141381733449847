import { mergeObjects } from '@/helpers/ObjectHelpers';
import CmsApiEndpoints from '@/config/apiEndpoints/Cms';
import AuthApiEndpoints from '@/config/apiEndpoints/Auth';
import CookieApiEndpoints from '@/config/apiEndpoints/Cookie';
import UserApiEndpoints from '@/config/apiEndpoints/Users';
import OrderApiEndpoints from '@/config/apiEndpoints/Orders';
import GiftApiEndpoints from '@/config/apiEndpoints/Gifting';
import CouponApiEndpoints from '@/config/apiEndpoints/Coupons';
import CanvasserEndpoints from '@/config/apiEndpoints/Canvasser';
import PaymentsApiEndpoints from '@/config/apiEndpoints/Payments';
import PrimerApiEndpoints from '@/config/apiEndpoints/Primer';
import LogisticApiEndpoints from '@/config/apiEndpoints/Logistics';
import SubscriptionApiEndpoints from '@/config/apiEndpoints/Subscriptions';
import ShopEndpoints from '@/config/apiEndpoints/Shop';
import TaxApiEndpoints from '@/config/apiEndpoints/Taxes';
import GiftVouchers from '@/config/apiEndpoints/GiftVouchers';

export default mergeObjects([
    CmsApiEndpoints,
    AuthApiEndpoints,
    CookieApiEndpoints,
    UserApiEndpoints,
    OrderApiEndpoints,
    GiftApiEndpoints,
    CouponApiEndpoints,
    CanvasserEndpoints,
    PaymentsApiEndpoints,
    PrimerApiEndpoints,
    LogisticApiEndpoints,
    SubscriptionApiEndpoints,
    ShopEndpoints,
    TaxApiEndpoints,
    GiftVouchers,
]);
