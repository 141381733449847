import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class RedeemPauseCoupon {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(couponId, subscriptionId) {
        let apiUrl = ApiEndpoints['redeem-pause-coupon'];

        return this.apiService.post(apiUrl, {
            coupon_id: couponId,
            subscription_id: subscriptionId,
        });
    }
}
