import CancelHoliday from '@/services/subscriptions/Subscriptions/ScheduledOrderService/CancelHoliday';
import CreateHoliday from '@/services/subscriptions/Subscriptions/ScheduledOrderService/CreateHoliday';
import CancelOptInOrder from '@/services/subscriptions/Subscriptions/ScheduledOrderService/CancelOptInOrder';
import CreateOptInOrder from '@/services/subscriptions/Subscriptions/ScheduledOrderService/CreateOptInOrder';
import FetchAllScheduledOrders from '@/services/subscriptions/Subscriptions/ScheduledOrderService/FetchAllScheduledOrders';
import FetchAllDeliveries from '@/services/subscriptions/Subscriptions/ScheduledOrderService/FetchAllDeliveries';
import CancelHolidayBox from '@/services/subscriptions/Subscriptions/ScheduledOrderService/CancelHolidayBox';

export default class ScheduledOrderService {
    cancelHoliday(payload, apiService = null) {
        return (new CancelHoliday(apiService)).handle(payload);
    }

    cancelOptInOrder(payload, apiService = null) {
        return (new CancelOptInOrder(apiService)).handle(payload);
    }

    createHoliday(payload, apiService = null) {
        return (new CreateHoliday(apiService)).handle(payload);
    }

    createOptInOrder(payload, apiService = null) {
        return (new CreateOptInOrder(apiService)).handle(payload);
    }

    fetchAllScheduledOrders(subscriptionId, apiService = null) {
        return (new FetchAllScheduledOrders(apiService)).handle(subscriptionId);
    }

    fetchAllDeliveries(subscriptionId, to = null, from = null) {
        return (new FetchAllDeliveries()).handle(subscriptionId, to, from);
    }

    cancelHolidayBox(payload, apiService = null) {
        return (new CancelHolidayBox(apiService)).handle(payload);
    }
}
