import StripeElementsForm from '@/components/forms/cardDetails/StripeElementsForm';
import StripePaymentRequestButton from '@/components/forms/cardDetails/StripePaymentRequestButton';
import PrimerPaymentMethodForm from '@/components/forms/cardDetails/PrimerPaymentMethodForm';
import applePay from '@/assets/images/icons/apple-pay.svg';
import gPay from '@/assets/images/icons/gpay.svg';
import msPay from '@/assets/images/icons/ms-pay.svg';
import card from '@/assets/images/icons/visa-icon.svg';

const i18n = require('@/i18n').default;

function detectBrowser() {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/chrome|chromium|crios/i)) {
        return 'Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        return 'Firefox';
    } else if (userAgent.match(/safari/i)) {
        return 'Safari';
    } else if (userAgent.match(/opr\//i)) {
        return 'Opera';
    } else if (userAgent.match(/edg/i)) {
        return 'Edge';
    } else if ((userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
        return 'IE';
    } else {
        return 'Unknown';
    }
}

const detectedBrowser = detectBrowser();

function showWallet() {
    return ['Chrome', 'Safari', 'Edge'].includes(detectedBrowser);
}

function getWalletIcon() {
    if (detectedBrowser === 'Chrome') return gPay;
    if (detectedBrowser === 'Safari') return applePay;
    if (detectedBrowser === 'Edge') return msPay;
}

function getWalletCopy() {
    if (detectedBrowser === 'Chrome') return i18n.t('GOOGLE_PAY');
    if (detectedBrowser === 'Safari') return i18n.t('APPLE_PAY');
    if (detectedBrowser === 'Edge') return i18n.t('MS_PAY');
}

export const payments = [
    {
        key: 'paypal',
        component: PrimerPaymentMethodForm,
        icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/100px-PayPal.svg.png',
        label: i18n.t('PAYPAL_PAY'),
        val: 'paypal',
        isSelected: false,
        gateway: {
            DEU: 'Primer',
            GBR: 'Stripe',
        },
        enabled: ['DEU', 'NLD'].includes(process.env.VUE_APP_COUNTRY_ISO),
    },
    {
        key: 'wallet',
        component: StripePaymentRequestButton,
        icon: getWalletIcon(),
        label: getWalletCopy(),
        val: 'wallet',
        isSelected: false,
        gateway: {
            DEU: 'StripeEUR',
            GBR: 'Stripe',
        },
        enabled: process.env.VUE_APP_ENABLE_STRIPE_WALLET === 'true' && showWallet(),
    },
    {
        key: 'card',
        component: StripeElementsForm,
        icon: null,
        label: i18n.t('CARD_OPTION'),
        val: 'card',
        isSelected: true,
        gateway: {
            DEU: 'StripeEUR',
            GBR: 'Stripe',
        },
        enabled: true,
    },
];
