<template>
    <div class="cookie-banner-wrapper">
        <div class="cookie-banner">
            <div v-if="!showSelection" class="cookie-banner--content-wrapper">
                <div class="cookie-banner--content">
                    <div class="cookie-banner--content--icon-section">
                        <img class="cookie-banner--content--icon-section--icon" :src="infoIcon" alt="Info icon">
                    </div>
                    <div class="cookie-banner--content--text-section">
                        <div class="cookie-banner--content--text-section--icon-text">
                            <img class="cookie-banner--content--text-section--icon" :src="infoIcon" alt="Info icon">
                            <p class="cookie-banner--content--text-section--text--1" v-html="$t('COOKIE_BANNER_STATEMENT_NEW_1')"></p>
                        </div>
                        <p class="cookie-banner--content--text-section--text-link">
                            <span class="cookie-banner--content--text-section--text--2" v-html="$t('COOKIE_BANNER_STATEMENT_NEW_2')"></span>
                            <a v-if="isGBR" class="cookie-banner--content--text-section--text--link" href="https://www.freddiesflowers.com/privacy-policy" v-html="$t('KNOW_MORE') + '.'"></a>
                        </p>
                    </div>
                </div>
                <hr class="cookie-banner--hr-line" />
                <div class="cookie-banner--buttons">
                    <div v-if="isDEU" class="cookie-banner--buttons--section">
                        <div class="cookie-banner--button-wrapper cookie-banner--button-wrapper--secondary" @click="expandBanner">
                            <button
                                class="cookie-banner--button-wrapper--button cookie-banner--button-wrapper--button--secondary"
                                v-text="$t('COOKIE_BANNER_MANAGE')"
                                type="button">
                            </button>
                        </div>
                        <div class="cookie-banner--button-wrapper cookie-banner--button-wrapper--primary" @click="onApplyCookies()">
                            <button
                                class="cookie-banner--button-wrapper--button cookie-banner--button-wrapper--button--primary"
                                v-text="$t('COOKIE_BANNER_ACCEPT')"
                                type="button">
                            </button>
                        </div>
                    </div>
                    <div v-if="isGBR" class="cookie-banner--buttons--section">
                        <div class="cookie-banner--button-wrapper cookie-banner--button-wrapper--primary" @click="onApplyCookies()">
                            <button
                                class="cookie-banner--button-wrapper--button cookie-banner--button-wrapper--button--primary"
                                v-text="$t('COOKIE_BANNER_ACCEPT')"
                                type="button">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showSelection" class="cookie-banner--content-wrapper cookie-banner--expanded-content" ref="expandedContent">
                <div class="cookie-banner--expanded-content--content">
                    <div class="cookie-banner--expanded-content--content--heading-and-description--desktop">
                        <p class="cookie-banner--expanded-content--heading--text--desktop" v-html="$t('COOKIE_BANNER_NECESSARY') + '.'"></p> <p class="cookie-banner--expanded-content--description--text--desktop" v-html="$t('COOKIE_BANNER_NECESSARY_DESCRIPTION')"></p>
                    </div>
                    <div class="cookie-banner--expanded-content--heading">
                        <p class="cookie-banner--expanded-content--heading--text" v-html="$t('COOKIE_BANNER_NECESSARY')"></p>
                    </div>
                    <div class="cookie-banner--expanded-content--description">
                        <p class="cookie-banner--expanded-content--description--text" v-html="$t('COOKIE_BANNER_NECESSARY_DESCRIPTION')"></p>
                    </div>

                    <div class="cookie-banner--expanded-content--toggles-section">
                        <div v-for="(toggle, index) in toggles" :key="`toggle-${index}`" class="cookie-banner--expanded-content--toggle-section">
                            <div class="cookie-banner--expanded-content--toggle-section--heading-description">
                                <p class="cookie-banner--expanded-content--toggle-section--heading" v-html="toggle.heading"></p>
                                <p class="cookie-banner--expanded-content--toggle-section--description" v-html="toggle.description"></p>
                            </div>

                            <div class="cookie-banner--expanded-content--toggle-section--toggle" @click="switchToggle(toggle)" :style="toggleStyle(toggle)">
                                <div v-if="toggle.switch" class="cookie-banner--expanded-content--toggle-section--toggle--dragger"></div>
                                <div v-else class="cookie-banner--expanded-content--toggle-section--toggle--dragger-alternative"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="cookie-banner--hr-line" />
                <div class="cookie-banner--buttons cookie-banner--buttons--manage">
                    <div v-if="isDEU" class="cookie-banner--buttons--section">
                        <div class="cookie-banner--button-wrapper cookie-banner--button-wrapper--secondary" @click="expandBanner">
                            <button
                                class="cookie-banner--button-wrapper--button cookie-banner--button-wrapper--button--secondary"
                                v-text="$t('COOKIE_BANNER_MINIMIZE')"
                                type="button">
                            </button>
                        </div>
                        <div class="cookie-banner--button-wrapper cookie-banner--button-wrapper--primary" @click="onApplyCookies()">
                            <button
                                class="cookie-banner--button-wrapper--button cookie-banner--button-wrapper--button--primary"
                                v-text="$t('COOKIE_BANNER_ACCEPT')"
                                type="button">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { gtmConsent } from '@/helpers/GAHelpers';
import InfoIcon from '@/assets/icons/info.svg';
import CookieOptInService from '@/services/cookie/cookieOptInService';

export default {
    name: 'CookieBanner',
    data() {
        return {
            cookiePreferences: {
                analytics: true,
                necessary: true,
                preferences: true,
                tracking: true,
            },
            showSelection: false,
            infoIcon: InfoIcon,
        };
    },
    computed: {
        ...mapGetters({
            preferences: 'cookies/getCookiePreferences',
            isGBR: 'localisation/isGBR',
            isDEU: 'localisation/isDEU',
        }),
        toggles() {
            return [
                {
                    heading: this.$t('COOKIE_BANNER_PREFERENCES'),
                    description: this.$t('COOKIE_BANNER_PREFERENCES_DESCRIPTION'),
                    switch: this.cookiePreferences.preferences,
                    key: 'preferences',
                },
                {
                    heading: this.$t('COOKIE_BANNER_ANALYTICS'),
                    description: this.$t('COOKIE_BANNER_ANALYTICS_DESCRIPTION'),
                    switch: this.cookiePreferences.analytics,
                    key: 'analytics',
                },
                {
                    heading: this.$t('COOKIE_BANNER_MARKETING'),
                    description: this.$t('COOKIE_BANNER_MARKETING_DESCRIPTION'),
                    switch: this.cookiePreferences.tracking,
                    key: 'tracking',
                },
            ];
        },
        togglesPreferred() {
            return {
                analytics: this.toggles[1].switch,
                necessary: true,
                preferences: this.toggles[0].switch,
                tracking: this.toggles[2].switch,
            };
        },
    },
    mounted() {
        if (this.isGBR) this.acceptCookies();
    },
    methods: {
        acceptCookies() {
            this.$store.commit('cookies/setCookiePreferences', this.cookiePreferences);
            this.$store.dispatch('cookies/mountScripts');
            this.sendCookieOptIn();
        },
        onApplyCookies() {
            this.$store.commit('cookies/hideCookieBanner');
            this.$store.commit('cookies/setHasAgreedToCookies', true);
            this.$store.commit('cookies/setCookiePreferences', this.togglesPreferred);
            if (!this.isGBR) this.sendCookieOptIn();
        },
        sendCookieOptIn() {
            let Service = new CookieOptInService();

            gtmConsent('update', {
                ad_storage: this.cookiePreferences.tracking ? 'granted' : 'denied',
                analytics_storage: this.cookiePreferences.analytics ? 'granted' : 'denied',
                functionality_storage: this.cookiePreferences.necessary ? 'granted' : 'denied',
                personalization_storage: this.cookiePreferences.preferences ? 'granted' : 'denied',
            });
            Service.SendCookieDataService({
                essential_cookies: this.cookiePreferences.necessary,
                marketing_cookies: this.cookiePreferences.tracking,
                preference_cookies: this.cookiePreferences.preferences,
                analytics_cookies: this.cookiePreferences.analytics,
                date_time: (new Date()).toISOString(),
            });
        },
        expandBanner() {
            this.showSelection = !this.showSelection;
        },
        switchToggle(toggle) {
            this.cookiePreferences[toggle?.key] = !this.cookiePreferences[toggle?.key];
        },
        toggleStyle(toggle) {
            return {
                backgroundColor: '#57B471',
                opacity: toggle.switch ? 1 : 0.5,
                transition: '.5s',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/brand/theme.scss';

@keyframes fadeDisplay {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1.0;
    }
}

.cookie-banner-wrapper {
    margin: auto 8px;

    background: #fffffff3;

    max-width: 1388px;
    min-height: 73px;

    display: flex;
    align-items: center;

    color: $secondary-color;
    border-radius: 8px;
    @include border-thin2();
    box-shadow: $shadow-small;

    @media screen and (min-width: $medium) {
        margin: auto 26px;
    }
}
.cookie-banner {
    text-align: center;

    @media screen and (min-width: $medium) {
        flex-direction: row;

        text-align: left;
    }

    &--content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: $medium) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        animation: fadeDisplay .2s;

        @media screen and (min-width: $medium) {
            flex-direction: row;

            padding: 14px 0 14px 27.95px;
        }

        &--icon-section {
            &--icon {
                max-width: none;
                width: 20px;
                height: 20px;
                display: none;

                @media screen and (min-width: $medium) {
                    display: block;
                }
            }
        }

        &--text-section {
            padding-left: 20px;
            padding-right: 20px;

            @media screen and (min-width: $medium) {
                @include small-label();
            }

            &--icon-text {
                display: flex;
                justify-content: center;
                align-items: center;

                padding-top: 20px;
                padding-bottom: 12px;

                @media screen and (min-width: $medium) {
                    display: inline;
                }
            }

            &--icon {
                padding-right: 6.475px;
                @media screen and (min-width: $medium) {
                    display: none;
                }
            }

            &--text-link {
                @include small();

                margin: 0;

                @media screen and (min-width: $medium) {
                    display: inline;

                    @include small-label();
                }
            }

            &--text {
                margin: 0;

                &--1 {
                    @include body-bold();

                    padding-left: 6.475px;

                    margin: 0;

                    @media screen and (min-width: $medium) {
                        padding-left: 0;

                        display: inline;
                    }
                }

                &--2 {
                    @include small();

                    margin: 0;
                    padding: 0;

                    @media screen and (min-width: $medium) {
                        font-size: $font-small;
                    }
                }

                &--link {
                    text-decoration: underline;

                    @include small-link();

                    color: $link-color !important;

                    @media screen and (min-width: $medium) {
                        @include body-link();

                        color: $primary-color !important;
                    }
                }
            }
        }
        &--text-section * {
            color: $primary-color;
        }
    }

    &--expanded-content {
        animation: fadeDisplay .2s;
        &--heading {
            &--text {
                @include body-bold();

                margin: 20px 16px 0 16px;
            }
        }

        &--description {
            &--text {
                @include small();

                margin: 12px 16px 0 16px;
            }
        }

        &--heading, &--description {
            @media screen and (min-width: $medium) {
                display: none;
            }
        }

        &--content {
            @include small-label();

            &--heading-and-description--desktop {
                display: none;

                @media screen and (min-width: $medium) {
                    display: block;

                    margin: 14px 20px 0 24px;
                }
            }
        }

        &--heading {
            &--text--desktop {
                display: none;

                @media screen and (min-width: $medium) {
                    font-weight: $font-weight-500;
                }
            }
        }

        &--description {
            &--text--desktop {
                display: none;

                @media screen and (min-width: $medium) {
                    font-weight: $font-weight-300;
                }
            }
        }

        &--heading--text, &--description--text {
            display: block;

            color: $primary-color;

            @media screen and (min-width: $medium) {
                display: none;
            }
        }

        &--heading--text--desktop, &--description--text--desktop {
            display: none;

            @media screen and (min-width: $medium) {
                display: inline;

                color: $primary-color;
                font-size: $font-small;
            }
        }

        &--toggles-section {
            margin: 12px 19.5px 0 19.5px;

            display: flex;
            flex-direction: column;

            @media screen and (min-width: $medium) {
                flex-direction: row;

                margin: 22px 24px 24px 24px;
            }

            @media screen and (min-width: $medium) and (max-width: 1050px) {
                flex-wrap: wrap;

                max-width: 80%;
            }
        }

        &--toggle-section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-top: 12px;

            @media screen and (min-width: $medium) {
                margin-right: 46.5px;
            }

            &--heading-description {
                display: flex;
                flex-direction: column;

                color: $primary-color;

                text-align: left;
            }

            &--heading {
                @include body-bold();

                margin: 0;
            }

            &--description {
                @include small();

                width: 242px;

                margin: 4px 0 0 0;

                @media screen and (min-width: $medium) {
                    width: 90%;
                }
            }

            &--toggle {
                width: 42px;
                height: 24px;

                position: relative;

                /* Success */
                border-radius: 40px;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;

                cursor: pointer;

                &--dragger, &--dragger-alternative {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: 1.78px;

                    border-radius: 50%;

                    /* White */
                    background: $light-color;

                    transition: .2s;
                }
                &--dragger {
                    left: 20px
                }

                &--dragger-alternative {
                    left: 2px
                }
            }
        }

        &--toggle-section:first-child {
            margin-top: 0;
        }
    }

    &--hr-line {
        display: block;
        width: 252px;
        @include border-thin($shade-color);
        margin: 12px 0;

        @media screen and (min-width: $medium) {
            display: none;
        }
    }

    &--buttons {
        display: flex;

        max-width: 85%;

        @media screen and (min-width: $medium) {
            margin-right: 12px;
        }

        &--section {
            display: flex;
            justify-content: center;

            width: 100%;
        }

        &--manage:nth-last-of-type(1) {
            @media screen and (min-width: $medium) {
                align-self: flex-start;

                margin-top: 12px;
            }
        }

        &--manage .cookie-banner--button-wrapper--secondary {
            @media screen and (min-width: $medium) {
                width: auto !important;
            }
        }
    }

    .cookie-banner--button-wrapper {
        width: 137px;
        cursor: pointer;
    }

    &--button-wrapper {
        height: 49px;

        margin-bottom: 12px;

        @include button();

        border-radius: 4px;
        color: $secondary-light-color;
        background: $primary-color;

        display: flex;
        justify-content: center;

        @media screen and (min-width: $medium) {
            margin-bottom: 0;
        }

        &--primary {
            width: 298px;

            @media screen and (min-width: $medium) {
                width: 138px;
            }
        }

        &--secondary {
            background: transparent;
            color: $primary-color;

            width: auto !important;

            @media screen and (min-width: $medium) {
                width: 138px !important;
            }
        }

        &--button {
            text-align: center;

            @media screen and (min-width: $medium) {
                @include button();
            }

            &--primary {
                @include label();
            }

            &--secondary {
                @include button();
            }
        }
    }

    &--button-wrapper:first-child {
        margin-right: 12px;
    }
    &--button-wrapper:last-child {
        margin-left: 12px;
    }

    &--button-wrapper:hover {
        opacity: 0.5;
    }
}
</style>
