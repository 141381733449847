import { render, staticRenderFns } from "./UserMenuV2.vue?vue&type=template&id=21f8d0d5&scoped=true"
import script from "./UserMenuV2.vue?vue&type=script&lang=js"
export * from "./UserMenuV2.vue?vue&type=script&lang=js"
import style0 from "./UserMenuV2.vue?vue&type=style&index=0&id=21f8d0d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f8d0d5",
  null
  
)

export default component.exports