import {
    SUBSCRIPTION_STATUS_PAUSED,
    SUBSCRIPTION_STATUS_ACTIVE,
    SUBSCRIPTION_STATUS_CANCELLED,
    SUBSCRIPTION_STATUS_PENDING,
    SUBSCRIPTION_STATUS_PAUSED_PENDING,
} from '@/constants/Subscription';
import SubscriptionService from '@/services/subscriptions/SubscriptionService';
import PostcodeService from '@/services/logistics/PostcodeService';
import router from '@/router';

const getDefaultState = () => {
    return {
        activeSubscriptionId: null,
        subscriptions: null,
        hasFetched: false,
        primaryProductTypes: [],
        nextAvailableDelivery: {
            date: null,
            fetched: false,
        },
        plans: [
            {
                plan_name: 'Weekly',
                plan_slug: 'weekly',
            },
            {
                plan_name: 'Fortnightly',
                plan_slug: 'fortnightly',
            },
            {
                plan_name: '3 weekly',
                plan_slug: 'three-weekly',
            },
            {
                plan_name: '4 weekly',
                plan_slug: 'monthly',
            },
        ],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        updateSubscriptions(state, payload) {
            state.subscriptions = payload;
        },

        updateNextDeliveryDate: (state, date) => {
            state.nextAvailableDelivery.date = date;
        },

        resetNextDeliveryDate(state) {
            state.nextAvailableDelivery.date = null;
        },

        setActiveSubscriptionId: (state, payload) => state.activeSubscriptionId = payload,

        setHasFetched: (state, payload) => state.hasFetched = payload,

        setProductTypes: (state, payload) => state.primaryProductTypes = payload,
    },
    actions: {
        async fetchAvailableDeliveryDay({ commit, state, rootGetters, getters }, postcode = null) {
            if (!postcode) {
                let currentSub = getters['getActiveSubscription'];
                if (!currentSub) return;
                let selectedAddress = rootGetters['address/getAddressById'](currentSub?.delivery_address_id);
                postcode = selectedAddress?.postcode;
            }
            return await new Promise(function(resolve, reject) {
                let postcodeService = new PostcodeService();
                postcode && postcodeService.getAvailableDeliveryDay(postcode)
                    .then(response => {
                        commit('setProductTypes', response.data.products);
                    })
                    .catch(error => reject(error));
            });
        },
        async fetchAllSubscriptions({ commit, state, rootGetters, dispatch }, forceReload = false) {
            if (!state.hasFetched || forceReload) {
                let service = new SubscriptionService();
                let uid = rootGetters['account/getUserId'];
                if (!uid) return;
                return await new Promise(function(resolve, reject) {
                    service.fetchAllSubscriptions(uid)
                        .then(response => {
                            commit('updateSubscriptions', response.data.data);
                            dispatch('setDefaultActiveSubscription');
                            commit('setHasFetched', true);
                            resolve(response);
                        })
                        .catch(error => {
                            commit('setHasFetched', true);
                            reject(error);
                        });
                });
            }
        },

        async getNextDeliveryDate({ commit, state, rootGetters }, forceReload = false) {
            const activeSubscription = rootGetters['subscription/getActiveSubscription'];
            const activeSubscriptionId = activeSubscription.id || null;
            const notFetched = activeSubscriptionId && !state.nextAvailableDelivery.fetched;
            const suscriptionIdHasChanged = activeSubscriptionId && activeSubscriptionId !== state.activeSubscriptionId;

            if (notFetched || suscriptionIdHasChanged || (activeSubscriptionId && forceReload)) {
                let service = new SubscriptionService();
                commit('resetNextDeliveryDate');

                return await new Promise(function(resolve, reject) {
                    service.getNextDeliveryDate(activeSubscriptionId).then(response => {
                        commit('updateNextDeliveryDate', response.data.next_scheduled_delivery);
                        state.nextAvailableDelivery.fetched = true;
                        state.activeSubscriptionId = activeSubscriptionId;
                        resolve(response);
                    })
                        .catch(error => {
                            reject(error);
                        });
                });
            }
        },
        async updateProductType({ dispatch }, { subId, prodId, name, date, plan }) {
            let service = new SubscriptionService();

            return await new Promise(function(resolve, reject) {
                service.updateProductType(subId, prodId, name, date, plan)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        setDefaultActiveSubscription({ commit, state, getters }) {
            if (state.activeSubscriptionId === null && getters.getActiveSubscriptions.length) {
                commit('setActiveSubscriptionId', getters.getActiveSubscriptions[0].id);
            }
        },

        resetSubscription({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        allowCancel(state, getters) {
            return getters.getActiveSubscriptions && getters.getActiveSubscriptions.length > 1;
        },

        getSubscriptions: state => state.subscriptions,

        subscriptionsHaveFetched: state => state.hasFetched,

        getActiveSubscriptions(state) {
            return state.subscriptions
                ? state.subscriptions?.filter(subscription => {
                    return subscription.status === SUBSCRIPTION_STATUS_ACTIVE || subscription.status === SUBSCRIPTION_STATUS_PAUSED;
                })
                : [];
        },

        getPausedSubscriptions(state) {
            return state.subscriptions.filter(subscription => subscription.status === SUBSCRIPTION_STATUS_PAUSED) || [];
        },

        getSubscriptionProperty: (state) => (subscriptionId, property = null) => {
            if (state.subscriptions) {
                const subscription = state.subscriptions.find(subscription => subscription.id === subscriptionId);
                const subscriptionProperty = property ? subscription && subscription[property] : subscription;
                return subscriptionProperty;
            }
        },

        getActiveSubscriptionId: state => state.activeSubscriptionId,

        getInactiveSubscriptions(state) {
            return state.subscriptions
                ? state.subscriptions.filter(subscription => {
                    if (process.env.VUE_APP_COUNTRY_ISO === 'USA') {
                        return subscription.status === SUBSCRIPTION_STATUS_PAUSED || subscription.status === SUBSCRIPTION_STATUS_CANCELLED;
                    } else {
                        return subscription.status === SUBSCRIPTION_STATUS_CANCELLED || subscription.status === SUBSCRIPTION_STATUS_PAUSED_PENDING;
                    }
                })
                : [];
        },

        hasInactiveSubscription(state, getters) {
            const hasInactiveSubscription = getters.getInactiveSubscriptions && getters.getInactiveSubscriptions.length;
            return hasInactiveSubscription;
        },

        hasPendingSubscription(state) {
            const getPendingSubscriptions = state.subscriptions ? state.subscriptions.filter(subscription => { return subscription.status === SUBSCRIPTION_STATUS_PENDING; }) : [];
            return getPendingSubscriptions.length > 0;
        },

        getSubscriptionById(state) {
            return subscriptionId => {
                return state.subscriptions
                    ? state.subscriptions?.find(item => {
                        return item.id === subscriptionId;
                    })
                    : {};
            };
        },

        getActiveSubscription(state, getters) {
            return state.activeSubscriptionId !== null ? getters.getSubscriptionById(state.activeSubscriptionId) : null;
        },

        getProductTypes: state => state.primaryProductTypes,

        hasMultipleActiveSubscriptions(state, getters) {
            return state.hasFetched && getters.getActiveSubscriptions.length > 1;
        },

        hasActiveSubscription(state) {
            return state.subscriptions?.find((item) => item.status === SUBSCRIPTION_STATUS_ACTIVE) !== undefined;
        },

        hasMultiplePausedSubscriptions(state, getters) {
            return getters.getPausedSubscriptions.length > 0;
        },

        getNextAvailableDeliveryDate: (state) => state.nextAvailableDelivery.date,

        getPauseIsCancel: () => process.env.VUE_APP_COUNTRY_ISO === 'USA',

        getPlans: state => state.plans,

    },
};
