import routesMap from '@/routes/routeMap';

const country = process.env.VUE_APP_COUNTRY_ISO;

export default [
    {
        path: `${routesMap[country].coupon}/:code`,
        name: 'coupon',
        component: () => import(/* webpackChunkName: "raf" */ '@/views/ReferenceFromAFriend'),
        props: true,
    },
];
