import CreateGiftingAddress from '@/services/logistics/addressService/CreateGiftingAddress';
import FetchAllUserAddresses from '@/services/logistics/addressService/FetchAllUserAddresses';
import UpdateSubscriptionAddress from '@/services/logistics/addressService/UpdateSubscriptionAddress';
import FetchAllUserGiftingAddresses from '@/services/logistics/addressService/FetchAllUserGiftingAddresses';
import DeleteAddresses from '@/services/logistics/addressService/DeleteAddresses';
import DeleteGiftingAddresses from '@/services/logistics/addressService/DeleteGiftingAddresses';
import UpdateGiftAddress from '@/services/logistics/addressService/UpdateGiftAddress';

export default class AddressService {
    createGiftingAddress(payload, userId) {
        return (new CreateGiftingAddress()).handle(payload, userId);
    }

    fetchAllUserAddresses(userId) {
        return (new FetchAllUserAddresses()).handle(userId);
    }

    fetchAllUserGiftingAddresses(userId) {
        return (new FetchAllUserGiftingAddresses()).handle(userId);
    }

    updateSubscriptionAddress(subscriptionId, addressId, payload) {
        return (new UpdateSubscriptionAddress()).handle(subscriptionId, addressId, payload);
    }

    deleteAddress(userId, addressId) {
        return (new DeleteAddresses()).handle(userId, addressId);
    }

    deleteGiftingAddress(userId, addressId) {
        return (new DeleteGiftingAddresses()).handle(userId, addressId);
    }

    updateGiftAddress(addressId, payload) {
        return (new UpdateGiftAddress()).handle(addressId, payload);
    }
}
