import PauseSubscription from '@/services/subscriptions/PauseSubscription';
import CancelSubscription from '@/services/subscriptions/CancelSubscription';
import CreateSubscription from '@/services/subscriptions/CreateSubscription';
import ContinueSubscription from '@/services/subscriptions/ContinueSubscription';
import FetchAllSubscriptions from '@/services/subscriptions/FetchAllSubscriptions';
import UpdateSubscriptionPlan from '@/services/subscriptions/UpdateSubscriptionPlan';
import UpdateSubscriptionQuantity from '@/services/subscriptions/UpdateSubscriptionQuantity';
import CreateSubscriptionFromLapsedSubscription from '@/services/subscriptions/CreateSubscriptionFromLapsedSubscription';
import UpdateProductType from '@/services/subscriptions/UpdateProductType';
import GetNextDeliveryDate from '@/services/subscriptions/GetNextDeliveryDate';
import CharityPrice from '@/services/subscriptions/CharityPrice';
import PausedUntil from '@/services/subscriptions/PausedUntil';

export default class SubscriptionService {
    cancelSubscription(subscriptionId) {
        return (new CancelSubscription()).handle(subscriptionId);
    }

    continueSubscription(subscriptionId, payload) {
        return (new ContinueSubscription()).handle(subscriptionId, payload);
    }

    createSubscription(payload) {
        return (new CreateSubscription()).handle(payload);
    }

    createSubscriptionFromLapsedSubscription(payload) {
        return (new CreateSubscriptionFromLapsedSubscription()).handle(payload);
    }

    fetchAllSubscriptions(userId) {
        return (new FetchAllSubscriptions()).handle(userId);
    }

    pauseSubscription({ subscriptionId, reason, feedback, resumeDate, flowersStart }) {
        return (new PauseSubscription()).handle({ subscriptionId, reason, feedback, resumeDate, flowersStart });
    }

    updateSubscriptionPlan(subscriptionId, plan, date, scheduleChange, restartDate, selectedDeliveryDate, source) {
        return (new UpdateSubscriptionPlan()).handle(subscriptionId, plan, date, scheduleChange, restartDate, selectedDeliveryDate, source);
    }

    updateSubscriptionQuantity(subscriptionId, quantity) {
        return (new UpdateSubscriptionQuantity()).handle(subscriptionId, quantity);
    }

    updateProductType(subscriptionId, prodId, name, date, plan) {
        return (new UpdateProductType()).handle(subscriptionId, prodId, name, date, plan);
    }

    charityPrice(subscriptionId, price, source) {
        return (new CharityPrice()).handle(subscriptionId, price, source);
    }

    pausedUntil(payload, apiService = null) {
        return (new PausedUntil(apiService)).handle(payload);
    }
}
