import { url } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class FetchProducts {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);
        this.apiService.enableAuth();
    }

    handle(payload) {
        let apiUrl = url(ApiEndpoints['shop'], null, payload);
        return this.apiService.get(apiUrl);
    }
}
