import { prepareUrl } from '@/helpers/UrlHelpers';
import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class UpdateSubscriptionPlan {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(subscriptionId, plan, date, scheduleChange = false, restartDate, selectedDeliveryDate = null, source = null) {
        const apiUrl = prepareUrl(ApiEndpoints['subscriptions.plan.update'], [subscriptionId]);

        let params = {
            plan_slug: plan,
            schedule_plan_change: scheduleChange,
            selected_delivery_date: selectedDeliveryDate,
            source,
        };

        if (date) {
            params.new_start_date = date;
        }

        if (restartDate) {
            params.new_start_date = restartDate;
        }

        return this.apiService.patch(apiUrl, params);
    }
}
