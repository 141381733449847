import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { formatDate, addDays, getStartOfISOWeek } from '@/helpers/DateHelpers';
import * as Dates from '@/constants/Dates';
import CmsService from '@/services/cms/CmsService';
import UndeliverableDatesService from '@/services/cms/UndeliverableDatesService';

const getDefaultState = () => {
    return {
        rateableArrangements: {
            fetched: false,
            items: null,
        },
        schedules: {
            fetched: false,
            fetchedAt: null,
            items: null,
            recentItems: null,
            activeWeekStart: '',
            activeScheduleIndex: 0,
        },
        missedArrangements: {
            fetched: false,
            missedItems: [],
        },
        undeliverableDates: {
            gifting: {
                fetched: false,
                items: null,
            },
        },
        plans: [],
        butterCMSDataAvailable: false,
        landingPageMessages: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },

        // Reset the currently selected date.
        resetDeliveryDate: function(state) {
            // Sunday should be the first day of the week in this component, so to be independent on the timezone,
            // we use 'isoWeek' (which is always Monday) and subtract one day
            const startDate = getStartOfISOWeek(new Date());
            state.schedules.activeWeekStart = formatDate(addDays(startDate, -1), Dates.DASHED_YEAR_MONTH_DAY);
        },

        // Update the state rateable arrangements.
        updateRateableArrangements: (state, payload) => state.rateableArrangements.items = payload,

        updateRecentArrangements: (state, payload) => state.schedules.recentItems = payload,

        updateMissedArrangements: (state, payload) => state.missedArrangements.missedItems = payload,

        // Update the state schedules.
        updateSchedules: (state, payload) => state.schedules.items = payload,

        updatePlans: (state, payload) => state.plans = payload,

        // Update the gifting undeliverable dates.
        updateGiftingUndeliverableDates: (state, payload) => state.undeliverableDates.gifting.items = payload,

        // Update the gifting undeliverable dates.
        updateScheduleIndex: (state, payload) => state.schedules.activeScheduleIndex = payload,

        // true when fetch data from ButterCMS is successful
        setButterCMSDataAvailable: (state, payload) => state.butterCMSDataAvailable = payload,

        // Update the state schedules.
        updateSchedulesActiveWeekStart: (state, payload) => {
            Vue.set(state.schedules, 'activeWeekStart', payload);
        },

        updateLandingPageMessages: (state, payload) => {
            state.landingPageMessages = payload;
        },
    },
    actions: {
        async fetchRateableArrangements({ commit, state }, forceReload = false) {
            if (state.rateableArrangements.fetched === false || forceReload === true) {
                state.rateableArrangements.fetched = true;

                let service = new CmsService();

                return new Promise(function(resolve, reject) {
                    service.getRateableArrangements()
                        .then(response => {
                            commit('updateRateableArrangements', response.data.items);
                            resolve(response);
                        })
                        .catch(error => {
                            state.rateableArrangements.fetched = false;
                            reject(error);
                        });
                });
            }
        },

        async fetchSchedules({ commit, state, rootGetters }, forceReload = false) {
            if (formatDate(new Date(), Dates.DASHED_YEAR_MONTH_DAY) !== state.schedules.fetchedAt) {
                forceReload = true;
            }

            if (state.schedules.fetched === false || forceReload === true) {
                state.schedules.fetched = true;
                state.schedules.fetchedAt = formatDate(new Date(), Dates.DASHED_YEAR_MONTH_DAY);

                let service = new CmsService();
                return new Promise(function(resolve, reject) {
                    service.getAllSchedules(rootGetters['subscription/getActiveSubscriptionId'])
                        .then(response => {
                            let arrangements = [];
                            if (response.data.properties.future_arrangements && response.data.properties.future_arrangements.length) {
                                arrangements = arrangements.concat(response.data.properties.future_arrangements);
                            }
                            if (response.data.properties.current_arrangement) {
                                arrangements.push(response.data.properties.current_arrangement);
                            }
                            if (response.data.properties.past_arrangements && response.data.properties.past_arrangements.length) {
                                arrangements = arrangements.concat(response.data.properties.past_arrangements);
                            }
                            arrangements.sort((a, b) => new Date(a.delivery_at) - new Date(b.delivery_at));
                            commit('updateSchedules', [...arrangements]);

                            let allDeliveries = rootGetters['scheduledOrder/getAllDeliveries'];
                            let nextDelivery = allDeliveries?.find(item => !item.is_locked);
                            let defaultSchedule = arrangements.find(item => item.delivery_at === nextDelivery?.datetime);
                            const activeScheduleIndex = arrangements.findIndex((item) => item.delivery_at === defaultSchedule?.delivery_at);
                            commit('updateScheduleIndex', activeScheduleIndex);
                            resolve(response);
                        })
                        .catch(error => {
                            state.schedules.fetched = false;
                            reject(error);
                        });
                });
            }
        },

        async fetchPlans({ commit, state, rootGetters }, forceReload = false) {
            let service = new CmsService();
            return new Promise(function(resolve, reject) {
                service.getPlans().then(response => {
                    commit('updatePlans', response.data.data);
                    resolve(response);
                }).catch(error => console.error(error));
            });
        },

        async fetchMissedArrangements({ commit, state, rootGetters }) {
            let service = new CmsService();
            return new Promise(function(resolve, reject) {
                service.getMissedArrangements(rootGetters['subscription/getActiveSubscriptionId'])
                    .then(response => {
                        commit('updateMissedArrangements', response.data.properties.missedSchedules);
                        resolve(response);
                    })
                    .catch(error => {
                        state.schedules.fetched = false;
                        reject(error);
                    });
            });
        },

        async fetchRecentArrangements({ commit, state, rootGetters }, forceReload = false) {
            let service = new CmsService();

            return new Promise(function(resolve, reject) {
                try {
                    service.getRecentArrangements()
                        .then(response => {
                            commit('updateRecentArrangements', response.data.data);
                            resolve(response);
                        })
                        .catch(error => {
                            state.schedules.fetched = false;
                            console.error({ error });
                            reject(error);
                        });
                } catch (err) {
                    Sentry.captureException(err);
                }
            });
        },

        async fetchLandingPageMessages({ commit, state }, forceReload = false) {
            let service = new CmsService();

            return new Promise((resolve, reject) => {
                service.getLandingPageMessages()
                    .then(response => {
                        commit('updateLandingPageMessages', response.data.data);
                        resolve(response);
                    })
                    .catch(error => {
                        console.error({ error });
                        reject(error);
                    });
            });
        },

        async fetchGiftingUndeliverableDates({ commit, state }, forceReload = false) {
            if (state.undeliverableDates.gifting.fetched === false || forceReload === true) {
                state.undeliverableDates.gifting.fetched = true;

                let service = new UndeliverableDatesService();

                return new Promise(function(resolve, reject) {
                    service.getGiftingUndeliverableDates()
                        .then(response => {
                            commit('updateGiftingUndeliverableDates', response.data);
                            resolve(response);
                        })
                        .catch(error => {
                            state.undeliverableDates.gifting.fetched = false;
                            reject(error);
                        });
                });
            }
        },

        incrementActiveScheduleWeek({ commit, getters }) {
            const activeSchedule = getters.getActiveSchedule;
            const activeScheduleIndex = getters.getSchedules.findIndex((item) => item.delivery_at === activeSchedule.delivery_at);
            commit('updateScheduleIndex', activeScheduleIndex + 1);
        },

        decrementActiveScheduleWeek({ commit, getters }) {
            const activeSchedule = getters.getActiveSchedule;
            const activeScheduleIndex = getters.getSchedules.findIndex((item) => item.delivery_at === activeSchedule.delivery_at);
            commit('updateScheduleIndex', activeScheduleIndex - 1);
        },

        setActiveScheduleWeek({ commit, getters }, week) {
            const newScheduleIndex = getters.getSchedules.findIndex((item) => item.deliveries_start_at === formatDate(week, Dates.DASHED_YEAR_MONTH_DAY));
            commit('updateScheduleIndex', newScheduleIndex < 0 ? 0 : newScheduleIndex);
            commit('updateSchedulesActiveWeekStart', formatDate(week, Dates.DASHED_YEAR_MONTH_DAY));
        },

        // Reset CMS module state.
        resetCms({ commit }) {
            commit('resetState');
        },
    },
    getters: {
        getRateableArrangements: state => state.rateableArrangements.items || [],

        getSchedules: state => state.schedules.items || [],

        getGiftingUndeliverableDates: state => state.undeliverableDates.gifting.items || [],

        getButterCMSDataAvailable: state => state.butterCMSDataAvailable,

        // Get the first day of the active week.
        getActiveWeekStart: state => state.activeWeekStart,

        // Gets recent schedule for arrangement type A excluding the upcoming arrangement
        getRecentArrangements(state, getters, actions) {
            const arrangementType = 'A';
            const allArrangements = state.schedules.recentItems || [];
            const filtered = allArrangements.filter(schedule => schedule.arrangement === arrangementType);
            const numberOfArrangements = filtered.length;
            const finalArray = filtered.slice(1, numberOfArrangements);
            return finalArray;
        },

        getLandingPageMessages: state => state.landingPageMessages,

        getPlans: state => state.plans,

        getMissedArrangements: state => state.missedArrangements.missedItems,

        // Get the active schedule based on the current active week and subscription.
        getActiveSchedule(state, getters, actions, rootGetters) {
            if (!getters.getSchedules.length) {
                return null;
            }
            let length = getters.getSchedules?.length;
            return getters.getSchedules[state.schedules.activeScheduleIndex] || getters.getSchedules[length - 1];
        },

        // Get the active schedules delivery date
        getActiveScheduleDeliveryDate(state, getters) {
            const activeSchedule = getters.getActiveSchedule;
            const currentSchedule = getters.getSchedules;
            return activeSchedule ? activeSchedule.delivery_at : currentSchedule[0]?.delivery_at;
        },

        // Determine whether the current active schedule can be rated.
        canRateActiveSchedule(state, getters, actions, rootGetters) {
            let rateableSchedules = getters.getRateableArrangements;
            let activeSchedule = getters.getActiveSchedule;
            let activeSubscription = rootGetters['subscription/getActiveSubscription'];

            if (rateableSchedules.length && activeSchedule) {
                return rateableSchedules.find(item => {
                    return item.id === activeSchedule.id &&
                        item.subscription.id === activeSubscription.id;
                });
            }

            return false;
        },

        // Determine whether there are schedules before the current active schedule for the active subscription
        // and arrangement.
        canDecrementActiveSchedule(state, getters) {
            let activeSchedule = getters.getActiveSchedule;

            if (activeSchedule) {
                let activeScheduleIndex = getters.getSchedules.findIndex((item) => item.delivery_at === activeSchedule.delivery_at);

                if (getters.getSchedules[activeScheduleIndex - 1]) {
                    return true;
                }
            }

            return false;
        },

        // Determine whether there are schedules after the current active schedule for the active subscription
        // and arrangement.
        canIncrementActiveSchedule(state, getters) {
            let activeSchedule = getters.getActiveSchedule;

            if (activeSchedule) {
                let activeScheduleIndex = getters.getSchedules.findIndex((item) => item.delivery_at === activeSchedule.delivery_at);

                if (getters.getSchedules[activeScheduleIndex + 1]) {
                    return true;
                }
            }

            return false;
        },
    },
};
