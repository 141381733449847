<template>
    <div v-if="getScheduleData" class="opt-in--banner">
        <img class="opt-in--banner--img" :src="getScheduleData.image_url" alt="">
        <div class="opt-in--banner--message">
            <h3 class="opt-in--banner--message--heading-lite">{{ $t('OPT_IN_DATE_FLOWERS', {dateString}) }}</h3>
            <div class="opt-in-flex">
                <h3 class="opt-in--banner--message--heading">{{ getScheduleData.title }}</h3>
                <p v-if="getScheduleData.is_normal_delivery_day" class="opt-in--banner--message--current-status" v-text="$t('OPT_IN_SKIPPED')"></p>
            </div>
            <p class="opt-in--banner--message--paragraph">{{ optInParagraph }}</p>
        </div>
        <div class="opt-in--banner--btn-container">
            <submit-button class="btn btn-half btn--primary-black btn-green btn-width" :processing="isProcessing" @click="getArrangement">
                    {{ mainCTAText }}
            </submit-button>
            <submit-button class="btn btn-half btn--secondary-black btn-width" @click="remindLater">{{ $t('OPT_IN_REMIND_LATER') }}</submit-button>
        </div>
        <div class="opt-in--banner--close">
            <p class="opt-in--banner--close--btn" @click="remindLater()">X</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Dates from '@/constants/Dates';
import { formatDate } from '@/helpers/DateHelpers';
import ScheduledOrderService from '@/services/subscriptions/Subscriptions/ScheduledOrderService';
import SubscriptionService from '@/services/subscriptions/SubscriptionService';
import SubmitButton from '@/components/forms/buttons/SubmitButton';
import { setCookie } from '@/helpers/QueryHelpers';

let scheduledOrderService = new ScheduledOrderService();
let subscriptionService = new SubscriptionService();

export default {
    name: 'OptInUpcomingDeliveryBanner',
    components: { SubmitButton },
    data() {
        return {
            isProcessing: false,
        };
    },
    computed: {
        ...mapGetters({
            isBringForward: 'account/isBringForward',
            isDeliveryOptIn: 'account/isDeliveryOptIn',
            getScheduleData: 'account/getUserSchedule',
            nextPossibleDate: 'account/getNextPossibleDate',
            activeSubscription: 'subscription/getActiveSubscription',
        }),
        dateString() {
            let nextDeliveryDate = this.getScheduleData?.next_delivery_date;
            return nextDeliveryDate && formatDate(nextDeliveryDate, Dates.DAY_DATE_MONTH);
        },
        mainCTAText() {
            return this.isBringForward ? this.$t('OPT_IN_BRING_FORWARD') : this.$t('OPT_IN_GET_ARRANGEMENT');
        },
        optInParagraph() {
            return this.isBringForward ? this.$t('OPT_IN_PARAGRAPH_BRING_FORWARD', { planName: this.getScheduleData?.plan_slug }) : this.$t('OPT_IN_PARAGRAPH');
        },
    },
    methods: {
        getArrangement() {
            if (this.isDeliveryOptIn) {
                this.getScheduleData?.is_normal_delivery_day ? this.cancelHoliday() : this.createOptInDelivery();
            }
            this.isBringForward && this.changePlan();
        },
        cancelHoliday() {
            this.isProcessing = true;
            scheduledOrderService.cancelHoliday({
                subscriptionId: this.getScheduleData?.subscription_id,
                holidayId: this.getScheduleData?.holiday_id,
                source: 'dashboard',
            }).then(() => {
                this.$store.dispatch('account/fetchUserData', true)
                    .then(() => {
                        this.isProcessing = false;
                        this.setNotificationMsg();
                    }).catch(() => this.isProcessing = false);
            }).catch(() => this.isProcessing = false);
        },
        createOptInDelivery() {
            scheduledOrderService.createOptInOrder({
                subscriptionId: this.getScheduleData?.subscription_id,
                deliveryDate: this.getScheduleData?.next_delivery_date,
                source: 'dashboard',
            }).then(() => {
                this.$store.dispatch('account/fetchUserData', true)
                    .then(() => {
                        this.isProcessing = false;
                        this.setNotificationMsg();
                    }).catch(() => this.isProcessing = false);
            }).catch(() => this.isProcessing = false);
        },
        changePlan() {
            this.isProcessing = true;
            let subsId = this.getScheduleData?.subscription_id;
            let currentPlanSlug = this.getScheduleData?.plan_slug;
            let nextDeliveryDate = this.getScheduleData?.next_delivery_date;
            subscriptionService.updateSubscriptionPlan(subsId, currentPlanSlug, nextDeliveryDate, true, null, null, 'dashboard')
                .then(() => {
                    this.$store.dispatch('account/fetchUserData', true)
                        .then(() => this.$store.dispatch('subscription/fetchAllSubscriptions', true)
                            .then(() => this.$store.dispatch('scheduledOrder/fetchAllDeliveries', true))
                            .then(() => {
                                this.isProcessing = false;
                                this.setNotificationMsg();
                            }));
                }).catch(error => console.log(error.response.data));
        },
        setNotificationMsg() {
            let dateStr = this.nextPossibleDate && formatDate(this.nextPossibleDate, Dates.DAY_DATE_MONTH);
            let planName = this.activeSubscription?.plan_slug;
            this.$root.$emit('hide-sticky-banner');
            this.$root.$emit('hide-bottom-sheet');
            this.$store.commit('account/setShowFloatingBanner', true);
            this.$store.commit('account/setBannerHeading', this.$t('FLOATING_BANNER_DELIVERY_DATE_MSG', { dateString: dateStr }));
            this.isBringForward
                ? this.$store.commit('account/setBannerMessage', this.$t('FLOATING_BANNER_PLAN_CHANGE_MSG_BRING_FORWARD', { planName }))
                : this.$store.commit('account/setBannerMessage', this.$t('FLOATING_BANNER_PLAN_CHANGE_MSG', { planName }));
            setTimeout(() => {
                this.$store.commit('account/setShowFloatingBanner', false);
                this.$store.commit('account/setBannerMessage', null);
            }, 5000);
        },
        remindLater() {
            setCookie('remind-later-opt-in', 1, 1);
            this.$root.$emit('hide-sticky-banner');
            this.$root.$emit('hide-bottom-sheet');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/brand/theme.scss';
    .opt-in {
        &--banner {
            display: flex;
            flex-direction: row;
            &--img {
                width: 125px;
                object-fit: cover;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
            }
            &--message {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px 30px;
                @media (max-width: 768px) {
                    padding: 10px 10px;
                }
                &--heading-lite {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 0px;
                }
                &--heading {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 0px;
                }
                &--current-status {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #868686;
                    background: #ECE9E8;
                    border-radius: 4px;
                    width: 117px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    text-align: center;
                }
                &--paragraph {
                    font-family: $secondary-color;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            &--btn-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px 30px;
                @media (max-width: 768px) {
                    padding: 10px 10px;
                }
            }
            &--close {
                position: relative;
                min-width: 30px;
                &--btn {
                    position: absolute;
                    top: 5px;
                    right: 13px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    font-family: $secondary-color;
                    cursor: pointer;
                    margin: 0px;
                }
            }
        }
    }
    .btn-green {
        background: #64A667;
        color: white;
    }
    .btn-width {
        @media (max-width: 960px) {
            min-width: 150px;
        }
    }
    .opt-in-flex {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
</style>
