import store from '@/store';
import { CANVASSER_TYPE } from '@/constants/Account';

export default function(to, from, next) {
    if (store.getters['auth/getUserType'] === CANVASSER_TYPE) {
        next();
    } else {
        store.commit('auth/updateIntendedUrl', to.path);
        next({ name: 'login' });
    }
}
