import ApiEndpoints from '@/config/ApiEndpoints';
import ApiService from '@/services/ApiService';

export default class RateArrangementForOrder {
    apiService

    constructor(apiService = null) {
        this.apiService = apiService || new ApiService(process.env.VUE_APP_API_URL);

        this.apiService.enableAuth();
    }

    handle(orderId, payload) {
        let apiUrl = ApiEndpoints['rate-flowers.index'];
        let data = {
            order_id: orderId,
            value: payload.value,
            quality: payload.quality,
            style: payload.style,
            delivery: payload.delivery || null,
            comments: payload.comments,
            feedbackImage: payload.feedbackImage || null,
            delivery_issues: payload.deliveryIssues || false,
            delivery_comments: payload.deliveryIssue ? payload.deliveryComments : null,
        };

        return this.apiService.post(apiUrl, data);
    }
}
