<template>
    <transition name="transitionSlideHamburgerFromLeft">
        <div v-if="show" class="overlay" @click="$emit('closed')">
            <div class="hamburger__container" @click.stop="() => null" style="width: 80%">
                <div v-if="isFlowerBondHolder" class="bondholder">
                    <router-link  target="_blank" :to="{ name: 'flowerbond'}">
                        <div class="bondHolderButton">
                            <img class="bondHolderButton__icon" :src="flowerBondIcon" alt="Flower Bond Holder"/>
                            <span class="bondHolderButton__text" v-text="$t('NAV_BOND_HOLDER')"></span>
                        </div>
                    </router-link>
               </div>
                <div class="close-container">
                    <button class="hamburgerHeader__left__button close-btn" :class="{'close-btn-bh' : isFlowerBondHolder}" @click="toggleShow">
                        <span class="hamburger__close"></span>
                    </button>
                </div>

                <router-link :to="{ name: 'home'}">
                    <div @click="$emit('closed')" style="display: flex; justify-content: center;">
                        <img
                            width="120"
                            height="120"
                            src="@/assets/images/logos/freddies_flowers_stacked.svg"
                            alt="Freddie's Flowers"/>
                    </div>
                </router-link>
                <nav v-if="hasNoActiveSubscription" class="menu-container">
                    <ul class="menu-container--list">
                        <menu-item
                            class="mt-2"
                            v-for="(item) in lapserMenuItems"
                            :disabled="false"
                            :key="item.route"
                            :item="item"
                            @menu="close()"/>
                        <li class="mt-2">
                            <a class="help" :href="countryUtils && countryUtils.zendesk_hc_url">{{ $t('TOP_NAVIGATION_HELP') }}</a>
                        </li>
                    </ul>
                </nav>
                <nav v-if="isGifterAccount && !hasNoActiveSubscription" class="menu-container">
                    <ul class="menu-container--list">
                        <menu-item
                            class="mt-2"
                            v-for="(item) in menuItems3"
                            :disabled="false"
                            :key="item.route"
                            :item="item"
                            @menu="close()"/>
                        <li class="mt-2">
                            <a class="help" :href="countryUtils && countryUtils.zendesk_hc_url">{{ $t('TOP_NAVIGATION_HELP') }}</a>
                        </li>
                    </ul>
                </nav>
                <nav v-if="!hasNoActiveSubscription && !isGifterAccount" class="menu-container">
                    <ul class="menu-container--list">
                        <menu-item
                            class="mt-2"
                            v-for="(item) in menuItems1"
                            :disabled="false"
                            :key="item.route"
                            :item="item"
                            @menu="close()"/>
                        <li class="mt-2">
                            <a class="help" :href="countryUtils && countryUtils.zendesk_hc_url">{{ $t('TOP_NAVIGATION_HELP') }}</a>
                        </li>
                    </ul>
                </nav>
                <div v-if="isGifterAccount || !hasNoActiveSubscription" class="hamburger__divider"/>
                <nav class="menu-container">
                    <ul class="menu-container--list" v-if="isMobile && !hasNoActiveSubscription ">
                        <menu-item
                            class="mt-2"
                            v-for="(item, index) in menuItems2"
                            :disabled="false"
                            :key="index"
                            :item="item"
                            @menu="close()"
                            >
                        </menu-item>
                    </ul>
                </nav>
                <img class="bottom-illustration" :class="{ 'image-class' : isGifterAccount || (isFlowerBondHolder && !isUserLoggedIn)}" src="@/assets/images/decoration/burger-flowers.jpg" alt="">
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationLinks from '@/config/NavigationLinks';
import MenuItem from '@/components/partials/account/MenuItem';
import countryUtils from '@/resources/countryUtils.json';

export default {
    name: 'UserMenu',
    components: {
        MenuItem,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            flowerBondIcon: require('@/assets/images/decoration/icon-bondholder-nav.svg'),
            countryUtils: countryUtils.filter(item => item.locale.toLowerCase() === process.env.VUE_APP_DATE_LOCALE.toLowerCase())[0],
            window: {
                width: 0,
                mobileBreakpoint: 768,
            },
        };
    },
    computed: {
        ...mapGetters({
            hasUserFetched: 'account/getUserId',
            hasSubscriptionFetched: 'subscription/subscriptionsHaveFetched',
            hasActiveSubscription: 'subscription/hasActiveSubscription',
            isFlowerBondHolder: 'account/getFlowerBondStatus',
            isUserLoggedIn: 'auth/isUserLoggedIn',
            isGifterAccount: 'account/isGifterAccount',
        }),
        menuItems() {
            const allMenuItems = NavigationLinks.items;
            const enabled = allMenuItems.filter((item) => { return typeof item.hamburgerMenu !== 'undefined' && item.hamburgerMenu.enabled; });
            const hamburgerItems = enabled.map((item) => Object.assign(item.hamburgerMenu, item));
            const sortedItems = hamburgerItems.sort((prevItem, nextItem) => prevItem.order - nextItem.order);
            return sortedItems;
        },
        menuItems1() {
            return this.menuItems.slice(0, 3);
        },
        menuItems2() {
            let length = this.menuItems.length;
            return this.isGifterAccount
                ? this.menuItems?.filter(menuItem => menuItem?.hamburgerMenu?.gifterDashboard)
                : this.menuItems.slice(3, length);
        },
        isMobile() {
            return this.window.width <= this.window.mobileBreakpoint;
        },
        menuItems3() {
            return this.menuItems.slice(0, 2);
        },
        hasNoActiveSubscription() {
            return this.hasUserFetched && this.hasSubscriptionFetched && !this.hasActiveSubscription;
        },
        lapserMenuItems() {
            const allMenuItems = NavigationLinks.items;
            const enabled = allMenuItems.filter((item) => { return typeof item.hamburgerMenu !== 'undefined' && item.hamburgerMenu.isLapser; });
            const hamburgerItems = enabled.map((item) => Object.assign(item.hamburgerMenu, item));
            const sortedItems = hamburgerItems.sort((prevItem, nextItem) => prevItem.order - nextItem.order);
            return sortedItems;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
        },
        toggleShow() {
            this.$emit('closed');
        },
        isPage(pageArr) {
            if (this.$route.name.includes('account-confirmation')) return true;
            const pageRoot = this.$route.name.split('.')[0];
            return pageArr.find(page => pageRoot === page);
        },
        close() {
            this.$emit('menu-clicked');
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/sass/brand/theme.scss';

    .hamburger__divider {
        height: 5px;
        background-image: url('~@/assets/images/decoration/burger-marker.png');
        background-repeat: no-repeat;
        align-self: center;
        margin: 16px auto;
        width: 200px;
        background-size: cover;
    }
    .hamburger__landing-buttons__container {
        width: 100%;
    }
    .hamburger__landing-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 16px 10px;
    }
    .hamburger__landing-button {
        text-align: center;
        margin: 0 24px;
        img {
            margin: 0 auto;
            height: 45px;
            width: 45px;
        }
        h5 {
            font-size: 12px;
            margin: 8px 0 0;
        }
    }
    @media (min-width: $large) {
        .hamburger__landing-buttons__container {
            display: none;
        }
    }
    .menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-top: 1rem;
        color: $primary-color;
        &--list {
            list-style: none;
        }
    }

    .mt-2 {
        margin-top: 0.5rem;
    }

    .mt-4 {
        margin-top: 1rem;
    }

    .close-container {
        height: 48px;
    }

    .close-btn {
        position: absolute;
        right: 24px;
        top: 24px;
    }
    .image-class {
        position: absolute;
        bottom: 0px;
    }
    .close-btn-bh {
        position: absolute;
        right: 24px;
        top: 75px;
    }
    .bondholder {
        background-color: $shade-light-color;
        height: 60px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-right: 23px;
    }
    .close-btn-bh {
        position: absolute;
        right: 24px;
        top: 75px;
    }
    .bondholder {
        background-color: $shade-light-color;
        height: 60px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-right: 23px;
    }
    .help {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .bondHolderButton {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (min-width: $large) {
        margin: 20px;
    }
    @media (min-width: $medium) {
        margin: 20px;
    }
    &:hover {
        cursor: pointer;
    }
    &__icon {
        margin: 0 auto;
        padding: 0;
        width: 22px;
        height: 21px;
        &__container {
            display: flex;
            justify-content: center;
        }
    }
    &__text {
        @include body();
        font-size: 20px;
        line-height: 24px;
        padding-top: 0;
        width: 99px;
        padding-left: 8.22px;
        margin-top: 3.3px;
        margin-bottom: 0px;
        color: #285a53;
    }
}
.bottom-illustration {
    position: absolute;
}
</style>
